import { Box, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../CustomCard/CustomCard";

interface QueryPageTitleCardProps {
  title: string;
  componentItem?: React.ReactNode;
}

const QueryPageTitleCard: React.FC<QueryPageTitleCardProps> = ({
  title,
  componentItem,
}) => {
  return (
    <CustomCard
      px={2.4}
      py={3.6}
      sx={{ boxShadow: "none", border: "1px solid #DFE0EB" }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography color={"shadesOfDark.dark"} fontSize={32} fontWeight={700}>
          {title}
        </Typography>
        {componentItem}
      </Box>
    </CustomCard>
  );
};

export default QueryPageTitleCard;
