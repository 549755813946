import {
  PipelineScheduleStatus,
  Row,
} from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { formatCNPJ, getThousand } from "../../../utils";
import { formatCurrency } from "../../../utils/currencyFormatter";
import {
  DateAndHoursToFormat,
  DateToFormat,
} from "../../../utils/dateFormatter";

export const getRoadResponseToModel = (item: Row) => ({
  scheduleNumber: item.refId ?? "",
  scheduleDate: DateToFormat(item.scheduleDate),
  createdAt: DateAndHoursToFormat(item.createdAt),
  product: item.contract?.product?.description ?? "",
  quantity: getThousand(item.volumeRequested ?? 0)?.toString(),
  unit: item.contract?.product?.measurementUnit ?? "",
  customer: item.contract?.customerCompany?.description ?? "",
  subsidiary: item.contract?.subsidiary?.description ?? "",
  paymentCondition: item.paymentConditionDescription ?? "",
  shippingCompany: item.shippingCompany?.description ?? "",
  driver: item.driver?.name ?? "",
  vehicle_plate: item.vehicleBundle?.plate ?? "",
  horse_plate: item.vehicleBundle?.compartments?.length
    ? item.vehicleBundle?.compartments[0].plate ?? ""
    : "",
  statusText: item.status ?? PipelineScheduleStatus.VENDOR_PENDING,
  mod: item.contract?.mod ?? "",
  customerRequester: item.customerRequester ?? "",
  customerRequesterNote: item.customerRequesterNote ?? "",
  price: formatCurrency(item.billedTotal) ?? "",
  cnpj: formatCNPJ(item.contract?.customerCompany?.document) ?? "",
  id: item.id ?? "",
  customerInvoiceReference: item.customerInvoiceReference ?? "",
  attachments: item.attachments ?? undefined,
  type: item.type ?? undefined,
});
