import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import { calcPagesQuantity } from "../../../utils";
import {
  ROW_PER_PAGE,
  TableRow,
  TabsDeliveriesRiverProps,
} from "../models/defaut";
import { TableHeaderColumns } from "../models/TableHeaderColumns";
import useDialogAlert from "../../../hooks/DialogAlert";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";
import { convertToISO } from "../../../utils/dateFormatter";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";
import { openedInitQuery } from "../models/OptionsQuery";
import { getRiverResponseToModel } from "../models/parseResponse";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import StatusTextTag from "../../../components/StatusTextTag/StatusTextTag";

const OpenedRiverDeliveries: React.FC<TabsDeliveriesRiverProps> = ({
  subsidiary,
  customerCompanyIds,
  onChangeCount,
  dateFilterTo,
  dateFilterFrom,
  menuIcon,
}) => {
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [, setIsLoadingData] = useState(false);
  const [query, setQuery] = useState<IGetPipelineSchedulesQuery>({
    ...openedInitQuery,
    subsidiaryId: undefined,
    scheduleDateFrom: dateFilterFrom ? convertToISO(dateFilterFrom) : undefined,
    scheduleDateTo: dateFilterTo ? convertToISO(dateFilterTo) : undefined,
  });

  const getRiverShedules = useCallback(async () => {
    try {
      setErrorData(false);
      setIsLoadingData(true);
      const response = await operationalService.getRiverSchedules(query);
      const newData = response.rows?.map(getRiverResponseToModel) ?? [];
      const count = response.count ?? 0;
      setTotal(count);
      if (onChangeCount) onChangeCount(count);
      setData(newData);
      if (newData.length === 0) {
        setErrorData(true);
      }
    } catch (error) {
      setErrorData(true);
      snackbar({
        message: "Erro ao carregar entregas fluviais",
        variant: "error",
      });
    } finally {
      setIsLoadingData(false);
    }
  }, [query]);

  useEffect(() => {
    getRiverShedules();
  }, [query]);

  useEffect(() => {
    setQuery((oldState) => ({
      ...oldState,
      subsidiaryId: subsidiary,
      ...(dateFilterFrom &&
        dateFilterTo && { scheduleDateFrom: convertToISO(dateFilterFrom) }),
      ...(dateFilterFrom &&
        dateFilterTo && { scheduleDateTo: convertToISO(dateFilterTo) }),
      ...(customerCompanyIds !== "" && {
        customerCompanyId: customerCompanyIds,
      }),
    }));
  }, [dateFilterFrom, dateFilterTo, customerCompanyIds, subsidiary]);

  return (
    <>
      {errorData ? (
        <Box alignSelf={"center"}>
          <CardDataNotFound
            icon="data-not-found"
            title="Não há pedidos de agendamento."
            subtitle={`Para pedir clique no botão "Novo pedido de agendamento"`}
          />
        </Box>
      ) : (
        <TablePaginated
          loadFromServer
          isLoadingData={false}
          columns={TableHeaderColumns}
          data={data.map((item) => ({
            ...item,
            statusText: <StatusTextTag value={item.statusText} />,
            menuIcon: menuIcon?.(item),
          }))}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: 1,
          }}
          resultsTotal={total}
          rowsPerPage={ROW_PER_PAGE}
          onChangePage={(page) => {
            setQuery((oldState) => ({
              ...oldState,
              page,
            }));
          }}
        />
      )}
    </>
  );
};

export default OpenedRiverDeliveries;
