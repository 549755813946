import { Box, Typography } from "@mui/material";
import React from "react";

export type ModalTitleAndDetailType =
  | "success"
  | "warning"
  | "error"
  | "standard";

interface ModalTitleAndDetailProps {
  title: string;
  detail: string | React.ReactNode;
  textAlign?: "left" | "center" | "right";
  fontSize?: number;
  paddingBottom?: number;
  fontFamily?: string;
  type?: ModalTitleAndDetailType;
}

const ModalTitleAndDetail: React.FC<ModalTitleAndDetailProps> = ({
  title,
  detail,
  textAlign = "right",
  paddingBottom,
  fontFamily = "Open Sans",
  type = "standard",
  fontSize = 19,
}) => {
  const getSubtitleColor = () => {
    switch (type) {
      case "standard":
        return "shadesOfDark.dark";
      case "success":
        return "systemColors.success.main";
      case "warning":
        return "systemColors.caution.main";
      case "error":
        return "systemColors.error.main";
    }
  };

  return (
    <Box>
      <Typography
        fontSize={fontSize}
        fontWeight={700}
        textAlign={textAlign}
        color={"shadesOfDark.dark"}
        paddingBottom={paddingBottom}
      >
        {title}
      </Typography>

      <Typography
        fontWeight={400}
        textAlign={textAlign}
        color={getSubtitleColor()}
        fontFamily={fontFamily}
      >
        {detail}
      </Typography>
    </Box>
  );
};

export default ModalTitleAndDetail;
