import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import ModalTitleAndDetail, {
  ModalTitleAndDetailType,
} from "../ModalTitleAndDetail/ModalTitleAndDetail";
import { StyledIcon } from "../StyledIcon/StyledIcon";

export interface IPaymentsDetailsItem {
  title: string;
  subtitle: string;
  type?: ModalTitleAndDetailType;
}

interface PaymentsDetailsCardProps {
  title: string;
  subTitle: string;
  items: IPaymentsDetailsItem[];
  icon?: React.ReactNode;
  isLoadingData?: boolean;
}

const PaymentsDetailsCard: React.FC<PaymentsDetailsCardProps> = ({
  title,
  subTitle,
  items,
  icon,
  isLoadingData = false,
}) => {
  const LoadingData = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rectangular" width="100%" height={"10rem"} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      {isLoadingData ? (
        LoadingData()
      ) : (
        <Box
          sx={{
            background: "#fff",
            border: "1px solid #E8E9EB",
            borderRadius: "4px",
            py: 3,
            px: 4,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={3} display={"flex"} alignItems={"center"}>
              <StyledIcon
                iconType="data-not-found"
                sx={{
                  width: "8rem",
                  height: "8rem",
                }}
              />
              <Box width={40}></Box>
              <ModalTitleAndDetail
                title={title}
                detail={subTitle}
                textAlign={"left"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                justifyContent={{ xs: "space-between", md: "flex-end" }}
                alignItems={"center"}
                my={{ xs: 2, md: 0 }}
              >
                {items.map((item) => (
                  <Box key={item.title} mx={3} my={{ xs: 1, md: 1 }}>
                    <ModalTitleAndDetail
                      title={item.title}
                      detail={item.subtitle}
                      type={item.type}
                    />
                  </Box>
                ))}
              </Box>
              <Box>{icon}</Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default PaymentsDetailsCard;
