import { Box, styled, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptBR from "date-fns/locale/pt-BR";

const InputWrapper = styled("div")(
  ({ theme }) => `
  background-color: #fff;
  padding: 1px 10px 1px 1px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #D2D3D6;
  border-radius: 4px;

  & input {
    box-sizing: border-box;
    padding: 8px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    outline: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: ${theme.palette.shadesOfDark.dark}
  }
`
);

interface DatePickerDefaultProps<TInputDate, TDate>
  extends Omit<
    DatePickerProps<TInputDate, TDate>,
    "value" | "onChange" | "renderInput"
  > {
  label?: string;
  value: TInputDate | null;
  onChange?: (value: TDate | null) => void;
  onBlur?: () => void;
}

function DatePickerDefault<TInputDate, TDate>({
  label,
  value,
  onChange,
  onBlur,
  ...dateProps
}: DatePickerDefaultProps<TInputDate, TDate>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Box display={"flex"} flexDirection={"column"}>
        {label && (
          <Typography
            fontFamily={"Open Sans"}
            fontWeight={700}
            color={"shadesOfDark.dark"}
            mb={0.5}
          >
            {label}
          </Typography>
        )}
        <DatePicker
          value={value}
          onChange={(newValue: TDate | null) => {
            if (onChange && newValue !== null) onChange(newValue);
          }}
          inputFormat="dd/MM/yyyy"
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <InputWrapper>
              <input
                ref={inputRef}
                {...inputProps}
                placeholder={"dd/mm/aaaa"}
                onBlur={onBlur}
              />
              {InputProps?.endAdornment}
            </InputWrapper>
          )}
          {...dateProps}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DatePickerDefault;
