import React, { useCallback, useEffect, useState } from "react";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import useDialogAlert from "../../../hooks/DialogAlert";
import { GetSummaryStatementQuery } from "../../../modules/supply/models/GetSummaryStatementQuery";
import { ISupplyService } from "../../../modules/supply/models/ISupplyService";
import { Types } from "../../../ioc/types";
import {
  TableHeaderSummaryStatementColumns,
  labelToNotShowUnity,
} from "../models/TableHeaderStatementColumns";
import { applyVolumeMask, getColorIfGreaterThanZero } from "../../../utils";
import { replaceDashWithSlash } from "../../../utils/dateFormatter";
import { Grid, Typography } from "@mui/material";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { downloadExcel } from "../../../utils/download";
import AppError from "../../../utils/appError";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";

interface ITableRow {
  day?: string;
  operationType?: string;
  volume: string;
  associatedContract?: string;
}

interface ISummaryStatementProps {
  dataFilter: GetSummaryStatementQuery | null;
  isClear: boolean;
}

const SummaryStatementTableSection: React.FC<ISummaryStatementProps> = ({
  dataFilter,
  isClear = false,
}) => {
  const [data, setData] = useState<ITableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { serviceContainer } = useIocContext();
  const { snackbar } = useDialogAlert();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const { selectedDistributor } = useDistributor();
  const { productSelected } = useOrderQuote();

  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );

  useEffect(() => {
    if (isClear) {
      setData([]);
    }
  }, [isClear, setData]);

  const getSummaryStatementData = useCallback(
    async (query: GetSummaryStatementQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);
        const response = await supplyService.getAllSummaryStatement?.(query);
        const operations = response.operations;

        if (Object.keys(operations)?.length === 0) {
          setErrorData(true);
        }
        const newData: ITableRow[] =
          operations.map((item) => ({
            ...item,
            day: replaceDashWithSlash(`${item.day ?? ""}`),
            volume: applyVolumeMask(`${item.volume ?? ""}`),
            associatedContract: item.associatedContract
              ? item.associatedContract
              : "-",
            volumeWithColor: (
              <Typography color={getColorIfGreaterThanZero(item.volume)}>
                {applyVolumeMask(`${item.volume ?? ""}`)}
              </Typography>
            ),
          })) ?? [];
        setData(newData);
      } catch (error) {
        setErrorData(true);
        snackbar({
          message: (error as AppError).message,
          variant: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData]
  );

  useEffect(() => {
    if (dataFilter !== null) {
      getSummaryStatementData(dataFilter);
    }
  }, [dataFilter]);

  const downloadReportSummaryStatement = useCallback(async () => {
    try {
      if (dataFilter !== null) {
        setIsLoadingDownload(true);
        const response = await supplyService.downloadReportSummary(dataFilter);
        downloadExcel(response);
        snackbar({
          message: "Download feito com sucesso",
          variant: "success",
        });
      }
    } catch (error) {
      snackbar({
        message: "Erro ao fazer download. Tentar novamente",
        variant: "error",
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [dataFilter]);

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <>
          <Grid
            item
            xs={8}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            style={{ paddingBottom: "2px" }}
          >
            <CustomButton
              title="Baixar relatório"
              variant={
                isLoadingDownload
                  ? CustomButtonVariant.CONTAINED_DOWNLOAD_LOADING
                  : CustomButtonVariant.CONTAINED_DOWNLOAD
              }
              onClick={() => {
                if (!selectedDistributor?.document?.length) {
                  snackbar({
                    message: "Selecione um cliente para baixar relatório.",
                    variant: "warning",
                  });
                } else {
                  downloadReportSummaryStatement();
                }
              }}
            />
          </Grid>
          <TablePaginated
            columns={TableHeaderSummaryStatementColumns.map((item) => ({
              ...item,
              label: `${item.label} ${
                productSelected && !labelToNotShowUnity.includes(item.id)
                  ? `(${productSelected})`
                  : ""
              }`,
            }))}
            data={data}
            rowsPerPage={10}
            isLoadingData={isLoadingData}
          />
        </>
      )}
    </>
  );
};

export default SummaryStatementTableSection;
