import { Avatar, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import { routesConfig } from "../../config/routes";
import { useOrderQuote } from "../../contexts/orderQuote/OrderQuoteContext";
import { getFormattedTimestamp } from "../../utils/dateFormatter";

import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { Roles } from "../../components/SideBar/Content/ListItem";
import { TypesPermissions } from "../../components/Routes/TypesPermissions";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import { CustomButtonVariant } from "../../components/CustomButton/CustomButton";
import FilterTableIcmsSection from "./Sections/FilterTableIcmsSection";
import TaxReimbursementTableSection from "./Sections/TaxReimbursementTableSection";
import { GetRefundIcmsQuery } from "../../modules/financial/models/GetRefundIcmsQuery";

const TaxReimbursement: React.FC = () => {
  const navigate = useNavigate();
  const { setDataItemInView, setItemInView } = useOrderQuote();
  const [query, setQuery] = useState<GetRefundIcmsQuery | null>({});
  const { authByPermission, permissionSet, roles } =
    useAuth() as AuthenticationContextData;

  const handleSearch = (e: any): any => {
    setQuery(e);
  };

  useEffect(() => {
    setDataItemInView?.({});
    setItemInView?.("");
  }, [setDataItemInView]);

  const permissionIs = authByPermission(
    permissionSet.SYSTEM_MODULES,
    roles.has(Roles.ADMIN),
    TypesPermissions.TAX_REIMBURSEMENT_REQUEST
  );

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid item xs={12}>
        <QueryPageTitleCard title="Ressarcimento de ICMS fiscal" />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <CustomCard
          sx={{
            boxShadow: "none",
            border: "1px solid #DFE0EB",
            height: "100%",
            padding: 2.4,
          }}
        >
          <CardTitleWithSubtitle
            title="Solicitações de ressarcimento de ICMS fiscal"
            subtitle={getFormattedTimestamp(new Date())}
            buttonProps={{
              title: "Solicitar novo ressarcimento",
              onClick: () => {
                navigate(routesConfig.FINANCIAL().TAX_REIMBURSEMENT_REQUEST);
              },
              variant: permissionIs
                ? CustomButtonVariant.CONTAINED
                : CustomButtonVariant.DISABLED,
              disabled: !permissionIs,
            }}
          />
          <FilterTableIcmsSection dataFilter={(e) => handleSearch(e)} />
          <TaxReimbursementTableSection dataFilter={query} key={1} />
        </CustomCard>
      </Grid>
    </Layout>
  );
};

export default TaxReimbursement;
