import { TextField, styled } from "@mui/material";
import MuiBox from "@mui/material/Box";

export const TextFielddBox = styled(TextField)(() => ({
  height: "60px",
  width: "100%",
  backgroundColor: "#fff",
  "& .Mui-disabled": {
    background: "#E8E9EB",
  },
  "& .datepicker": {
    border: "2px solid #58595B",
  },
  "& .MuiInputLabel-shrink": {
    fontWeight: 700,
    backgroundColor: "#fff",
    borderRadius: "4px",
    color: "#58595B",
    paddingRight: 6,
    fontFamily: "Open Sans",
    fontSize: "20px",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "2px solid green",
  },
  "& .MuiOutlinedInput": {
    fontSize: "20px",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px",
    padding: "4px 8px",
    fontFamily: "Open Sans",
    color: "#58595B",
    height: "32px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "4px",
    opacity: "0.6",
  },
}));

export const Box = styled(MuiBox)({
  background: "#FFFFFF",
  boxShadow:
    "0px 6px 10px rgba(0, 0, 0, 0.12), 0px 1px 18px rgba(0, 0, 0, 0.06)",
  borderRadius: "4px",
  "& .react-datepicker": {
    zIndex: 3,
    border: "none",
    padding: "15px",
    position: "absolute",
    boxShadow:
      "0px 6px 10px rgba(0, 0, 0, 0.12), 0px 1px 18px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__header--custom": {
    borderBottom: "0px solid #aeaeae",
    backgroundColor: "#fff",
  },
  "& .react-datepicker__day": {
    fontSize: "13px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    width: "40px",
    height: "30px",
    margin: "4px 0px",
    borderRadius: "0px",
    paddingTop: "4px",
  },

  "& .react-datepicker__day-names": {
    fontWeight: "700",
    fontSize: "12px",
    marginTop: "20px",
  },

  "& .react-datepicker__day-name": {
    color: "#58595B",
    width: "auto",
    padding: "0 6px",
  },

  "& .react-datepicker__day--in-range": {
    background: "#E8E9EB",
    color: "#58595B",
    ":hover": {
      background: "#8C8D8F!important",
      color: "#FFF!important",
    },
  },
  "& .react-datepicker__day--range-start": {
    paddingTop: "4px",
    width: "40px",
    height: "30px",
    background: "#FFFFFF",
    borderRadius: "30px 0px 0px 30px",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__day--range-end": {
    paddingTop: "4px",
    width: "40px",
    height: "30px",
    background: "#FFFFFF",
    borderRadius: "0px 30px 30px 0px",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#FFFFFF",
    color: "#58595B",
  },
  "& .react-datepicker__day--in-selecting-range": {
    background: "#8C8D8F!important",
    color: "#FFF!important",
  },
});
