/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Divider, Grid, Typography } from "@mui/material";
import * as _ from "lodash";
import { format, isSameDay } from "date-fns";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import AnimatedBox from "../../../components/AnimatedBox/AnimatedBox";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import DatePickerDefault from "../../../components/DatePickerDefault/DatePickerDefault";
import Select, { SelectVariant } from "../../../components/Select/Select";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import useDialogAlert from "../../../hooks/DialogAlert";
import { useFetchData } from "../../../hooks/FetchData";
import { Types } from "../../../ioc/types";
import { GetMonthDetailsDTO } from "../../../modules/operational/models/GetMonthDetailsDTO";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import {
  getCurrentMonthYear,
  getNextMonthYear,
  getThousand,
  getTitleAndValue,
  isLastBusinessDayOfMonth,
  removeSpecialCharactersAndLetters,
} from "../../../utils";
import AppError from "../../../utils/appError";
import ModalRiverView from "./ModalRiverView";
import { DateToFormat, isValidDate } from "../../../utils/dateFormatter";
import { useRiverRequestContext } from "../context/RiverRequestContext";
import {
  InitialValuesStep2,
  initialValidationSchemaStep2,
} from "../form/schema";
import { RiverRequestFormData } from "../models/customIconRiver";
import {
  ContractModel,
  GetContractsDTO,
} from "../../../modules/operational/models/GetContractsDTO";
import { routesConfig } from "../../../config/routes";
import { useNavigate } from "react-router-dom";

const RiverRequestStep2: React.FC = () => {
  const [monthReference] = useState<string>(format(new Date(), "MM-yyyy"));
  const [monthDetails, setMonthDetails] = useState<GetMonthDetailsDTO | null>(
    null
  );
  const [contractList, setContractList] = useState<GetContractsDTO>([]);
  const [isLoadingContract, setIsLoadingContract] = useState(false);

  const {
    formData,
    modalData,
    isCreating,
    setStep,
    setFormData,
    setModalData,
    setIsCreatingSchedule,
  } = useRiverRequestContext();

  const { snackbar } = useDialogAlert();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const getContracts = useCallback(async () => {
    try {
      setIsLoadingContract(true);
      if (!formData?.customerCompanyId?.length) {
        throw new AppError("ID do cliente não encontrato", "error");
      }
      if (!formData?.subsidiaryId?.length) {
        throw new AppError("ID da filial não encontrato", "error");
      }

      const currentDate = new Date();
      let date: ContractModel = {};

      if (isLastBusinessDayOfMonth(currentDate)) {
        date = {
          monthReferenceFrom: getCurrentMonthYear(),
          monthReferenceTo: getNextMonthYear(),
        };
      } else {
        date = {
          monthReference: getCurrentMonthYear(),
        };
      }

      const response = await operationalService.getContracts(
        formData?.customerCompanyId,
        formData?.subsidiaryId,
        "FLUVIAL",
        date
      );
      setContractList(response);
      if (response.length === 0) {
        snackbar({
          message: "Nenhum contrato encontrado",
          variant: "error",
        });
      }
    } catch (error) {
      snackbar({
        message: "Erro ao carregar contratos",
        variant: "error",
      });
    } finally {
      setIsLoadingContract(false);
    }
  }, []);

  const creatingSchedule = useCallback(async () => {
    try {
      setIsCreatingSchedule(true);
      const filteredValues = _.omit(formData, ["mod", "productId"]);
      await operationalService.createRiverSchedule({
        ...filteredValues,
      });
      snackbar({
        message: "Agendamento solicitado com sucesso.",
        variant: "success",
      });
      navigate(routesConfig.OPERATIONAL().RIVER);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
        autoHideDuration: 7000,
      });
    } finally {
      setIsCreatingSchedule(false);
    }
  }, [formData]);

  useEffect(() => {
    getContracts();
  }, []);

  const getMonthDetails = useCallback(async () => {
    try {
      const response = await operationalService.getMonthDetails(monthReference);
      setMonthDetails(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    }
  }, [monthReference]);

  const paymentConditions = useFetchData(
    async () => await operationalService.getPaymentConditions()
  );

  const shouldDisableDate = (date: Date) => {
    const monthDetailsKey = Object.keys(monthDetails ?? {});

    const datesWithVacancy = monthDetailsKey.filter(
      (item) => monthDetails?.[item].hasVacancy ?? false
    );

    const disabledDates = datesWithVacancy.map((day) => {
      const monthReferenceSplit = monthReference.split("-");
      const getMonthReference = monthReferenceSplit[0];
      const getYearReference = monthReferenceSplit[1];

      const date = `${getYearReference}-${getMonthReference}-${day.padStart(
        2,
        "0"
      )}`;

      return new Date(`${date}T04:00:00Z`);
    });

    return !disabledDates.some((disabledDate) => isSameDay(date, disabledDate));
  };

  useEffect(() => {
    getMonthDetails();
  }, [monthReference]);

  function getInitialValues(
    formData: RiverRequestFormData
  ): InitialValuesStep2 {
    return {
      contractId: formData?.contractId ?? "",
      scheduleLikeDate: formData?.scheduleLikeDate ?? null,
      productId: formData?.productId ?? "",
      mod: formData?.mod ?? "",
      volumeRequested:
        typeof formData?.volumeRequested === "string"
          ? getThousand(parseFloat(formData?.volumeRequested))
          : "0",
      paymentCondition: formData?.paymentCondition ?? "",
      note: formData?.note ?? "",
    };
  }

  return (
    <AnimatedBox>
      <Formik
        initialValues={getInitialValues(formData ?? {})}
        validationSchema={initialValidationSchemaStep2}
        onSubmit={function (values: InitialValuesStep2) {
          setIsOpen(true);
          setFormData((oldState) => ({
            ...oldState,
            contractId: values.contractId,
            productId: values.productId,
            mod: values.mod,
            paymentCondition: values.paymentCondition,
            note: values.note,
            scheduleDate: values.scheduleDate ?? "",
          }));
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const hasErrorContract =
            touched.contractId && Boolean(errors.contractId);
          const hasErrorVolumeRequested =
            touched.volumeRequested && Boolean(errors.volumeRequested);
          const hasErrorPaymentMethod =
            touched.paymentCondition && Boolean(errors.paymentCondition);
          const hasErrorObservation = touched.note && Boolean(errors.note);

          return (
            <Form>
              <Grid container spacing={1} mt={2}>
                <Grid item>
                  <ModalRiverView
                    isEdit={false}
                    handleClose={(open) => handleClose(open)}
                    isOpen={isOpen}
                    data={modalData}
                    buttons={[
                      {
                        title: "Cancelar",
                        variant: CustomButtonVariant.OUTLINED,
                        onClick: () => {
                          handleClose(false);
                        },
                      },
                      {
                        title: "Confirmar agendamento",
                        variant: isCreating
                          ? CustomButtonVariant.CONTAINED_LOADING
                          : CustomButtonVariant.CONTAINED,
                        onClick: async () => await creatingSchedule(),
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={"Open Sans"}
                    fontSize={16}
                    fontWeight={700}
                    color={"shadesOfDark.dark"}
                  >
                    Pedido de produto
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} mt={2.5}>
                  <Select
                    variant={
                      isLoadingContract
                        ? SelectVariant.LOADING
                        : SelectVariant.STANDARD
                    }
                    label="Contrato"
                    options={contractList.map((option) => ({
                      option,
                    }))}
                    value={
                      contractList.find(
                        (item) => item.id === values.contractId
                      ) ?? {}
                    }
                    placeholder={
                      isLoadingContract ? "Carregando..." : "Selecione"
                    }
                    getOptionItemLabel={(value: ContractModel) =>
                      value.label ?? ""
                    }
                    getSelectedOptionLabel={(value: ContractModel) =>
                      value.label ?? ""
                    }
                    onChange={(value) => {
                      setFieldValue("contractId", value.id ?? "");
                      setFieldValue("productId", value.productId ?? "");
                      setFieldValue("mod", value.mod ?? "");

                      setModalData((oldState) => ({
                        ...oldState,
                        productName: getTitleAndValue(
                          "Nome do produto",
                          value.product
                        ),
                        modality: getTitleAndValue("Modalidade", value.mod),
                        quantity: getTitleAndValue(
                          "Quantidade",
                          `${values.volumeRequested?.toString()} ${
                            value.productMeasurementUnit ?? ""
                          }`
                        ),
                      }));
                    }}
                    onBlur={() => setFieldTouched("contractId", true)}
                    hasError={hasErrorContract}
                    messageError={errors.contractId}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={4}>
                  <DatePickerDefault
                    label={"Data para carregamento"}
                    disablePast
                    value={values.scheduleLikeDate}
                    shouldDisableDate={shouldDisableDate}
                    onChange={(value) => {
                      if (isValidDate(value as Date)) {
                        setFieldValue("scheduleLikeDate", value as Date);
                        setFieldValue(
                          "scheduleDate",
                          DateToFormat(value as Date)
                        );
                        setModalData((oldState) => ({
                          ...oldState,
                          type: getTitleAndValue(
                            "Data para Carregamento",
                            DateToFormat(value as Date)
                          ),
                        }));
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("scheduleLikeDate", true);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={0} />
                <Grid container item spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Select
                      isDisabled
                      label="Produto"
                      value={contractList.find(
                        (item) => item.productId === values.productId
                      )}
                      options={contractList.map((option) => ({
                        option,
                      }))}
                      getOptionItemLabel={(value) => value.product ?? ""}
                      getSelectedOptionLabel={(value) => value.product ?? ""}
                      placeholder={
                        values.productId.length ? values.productId : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Select
                      isDisabled
                      label="Modalidade"
                      value={contractList.find(
                        (item) => item.mod === values.mod
                      )}
                      options={contractList.map((option) => ({
                        option,
                      }))}
                      getOptionItemLabel={(value) => value.mod ?? ""}
                      getSelectedOptionLabel={(value) => value.mod ?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} />

                  <Grid item xs={12} md={4}>
                    <TextFieldCustom
                      label={`Quantidade ${modalData?.unit?.value ?? ""}`}
                      placeholder="Informe a quantidade"
                      value={values.volumeRequested}
                      messageError={errors.volumeRequested}
                      onChange={(value) => {
                        const numberWithoutCharactersAndLetters = Number(
                          removeSpecialCharactersAndLetters(value.target.value)
                        );

                        const formattedValue = getThousand(
                          numberWithoutCharactersAndLetters
                        );
                        setFieldValue("volumeRequested", formattedValue);

                        const volume = Number(
                          formattedValue.replaceAll(".", "")
                        );
                        setFormData((oldState) => ({
                          ...oldState,
                          volumeRequested: volume,
                        }));

                        setModalData((oldState) => ({
                          ...oldState,
                          quantity: getTitleAndValue(
                            "Quantidade",
                            formattedValue
                          ),
                        }));
                      }}
                      onBlur={() => setFieldTouched("volumeRequested", true)}
                      hasError={hasErrorVolumeRequested}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Select
                      isDisabled={paymentConditions.errorLoading}
                      label="Condição de pagamento"
                      variant={
                        paymentConditions.loading
                          ? SelectVariant.LOADING
                          : SelectVariant.STANDARD
                      }
                      value={paymentConditions.value?.find(
                        (item) => item.code === values.paymentCondition
                      )}
                      options={
                        paymentConditions.value?.map((option) => ({
                          option,
                        })) ?? []
                      }
                      getOptionItemLabel={(value) => value.description ?? ""}
                      getSelectedOptionLabel={(value) =>
                        value.description ?? ""
                      }
                      onChange={(value) => {
                        setFieldValue("paymentCondition", value.code);

                        setModalData((oldState) => ({
                          ...oldState,
                          paymentCondition: getTitleAndValue(
                            "Tipo de pagamento",
                            value.description
                          ),
                        }));
                      }}
                      onBlur={() => setFieldTouched("paymentCondition", true)}
                      hasError={hasErrorPaymentMethod}
                      messageError={errors.paymentCondition}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextFieldCustom
                    fullWidth
                    label={"Observação"}
                    multiline
                    rows={4}
                    placeholder="Deixa sua observação"
                    value={values.note}
                    hasError={hasErrorObservation}
                    messageError={errors.note}
                    onChange={(value) => {
                      setFieldValue("note", value.target.value);
                      setModalData((oldState) => ({
                        ...oldState,
                        requestNote: {
                          title: "Observação",
                          value: value.target.value,
                        },
                      }));
                    }}
                    onBlur={() => setFieldTouched("note", true)}
                  />
                </Grid>

                <Grid item xs={12} mt={10}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Box display={"flex"}>
                    <CustomButton
                      title={"Voltar"}
                      variant={CustomButtonVariant.OUTLINED}
                      onClick={() => {
                        setStep(0);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      title={"Agendar"}
                      variant={CustomButtonVariant.CONTAINED}
                      onClick={() => {}}
                      onSubmit={() => handleSubmit}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </AnimatedBox>
  );
};

export default RiverRequestStep2;
