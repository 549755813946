import { inject, injectable } from "inversify";
import { Types } from "../../ioc/types";
import { sanitizeParameters } from "../../utils";
import { IHttpClientService } from "../http/models/IHttpClientService";
import { DownloadNfeQuery } from "./models/DownloadNfeQuery";
import { DownloadPaymentSlipQuery } from "./models/DownloadPaymentSlipQuery";
import { GetAllFinancialDTO } from "./models/GetAllFinancialDTO";
import { GetAllFinancialQuery } from "./models/GetAllFinancialQuery";
import { GetAllNfeDTO } from "./models/GetAllNfeDTO";
import { GetAllNfeQuery } from "./models/GetAllNfeQuery";
import { GetCreditLimitExtractDTO } from "./models/GetCreditLimitExtractDTO";
import { GetCreditLimitExtractQuery } from "./models/GetCreditLimitExtractQuery";
import { IFinancialService } from "./models/IFinancialService";
import { GetRefundIcmsQuery } from "./models/GetRefundIcmsQuery";
import { GetRefundIcmsDTO } from "./models/GetRefundIcmsDTO";
import { IGetUser } from "./models/IRegisterFilters";
import config from "../../config/default";
import { IFileUploadDTO, IIcmsRequest } from "./models/IFileUpload";

@injectable()
export class FinancialService implements IFinancialService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  private readonly authServiceStandalone = config.auth.url;

  public async getAllFinancial(
    query: GetAllFinancialQuery
  ): Promise<GetAllFinancialDTO> {
    return await this.httpInstance.get("/ms-sap/financial", {
      params: sanitizeParameters({
        customerNumber: query.customerNumber,
        page: query.page,
        limit: query.limit,
        category: query.code,
        statusInvoice: query.statusInvoice,
        dueDateBegin: query.dueDateBegin,
        dueDateEnd: query.dueDateEnd,
        orderBy: query.orderBy,
        orderDirection: query.orderDirection,
        avoidCategory: "NotInvoice",
      }),
    });
  }

  // TODO: filtro da nota fiscal aqui

  public async getAllNfe(query: GetAllNfeQuery): Promise<GetAllNfeDTO> {
    return await this.httpInstance.get("/ms-sap/nfe", {
      params: sanitizeParameters({
        customerNumber: query.customerNumber,
        materialNumber: query.id,
        plantCode: query.plantCode,
        page: query.page,
        limit: query.limit,
        documentDateBegin: query.documentDateBegin,
        documentDateEnd: query.documentDateEnd,
        orderBy: query.orderBy,
        orderDirection: query.orderDirection,
        electronicInvoiceNumber: query.electronicInvoiceNumber,
      }),
    });
  }

  public async getCreditLimitExtract(
    query: GetCreditLimitExtractQuery
  ): Promise<GetCreditLimitExtractDTO> {
    return await this.httpInstance.get("/ms-sap/credit-limit", {
      params: {
        customerNumber: query.customerNumber,
      },
    });
  }

  public async downloadReport(query: GetAllFinancialQuery): Promise<Blob> {
    const sanitizedQuery = sanitizeParameters({
      service: query.service,
      statusInvoice: query.statusInvoice,
      customerNumber: query.customerNumber,
      materialNumber: query.materialNumber,
      plantCode: query.plantCode,
      documentDate: {
        begin: query.documentDateBegin,
        end: query.documentDateEnd,
      },
      dueDate: {
        begin: query.dueDateBegin,
        end: query.dueDateEnd,
      },
      category: query.code,
      avoidCategory: "NotInvoice",
    });

    return await this.httpInstance.post("/ms-sap/reports/", sanitizedQuery, {
      responseType: "blob",
    });
  }

  public async downloadReportNfe(query: GetAllNfeQuery): Promise<Blob> {
    return await this.httpInstance.post(
      "/ms-sap/reports/",
      {
        service: query.service,
        customerNumber: query.customerNumber,
        materialNumber: query.materialNumber,
        plantCode: query.plantCode,
        electronicInvoiceNumber: query.electronicInvoiceNumber,
        documentDate: {
          begin: query.documentDateBegin,
          end: query.documentDateEnd,
        },
        dueDate: {
          begin: query.dueDateBegin,
          end: query.dueDateEnd,
        },
      },
      {
        responseType: "blob",
      }
    );
  }

  public async downloadNfeReport(query: DownloadNfeQuery): Promise<Blob> {
    return await this.httpInstance.post("/mw-mastersaf/nfe/download", query, {
      responseType: "blob",
    });
  }

  public async downloadPaymentSlip(
    query: DownloadPaymentSlipQuery
  ): Promise<Blob> {
    return await this.httpInstance.post("/ms-sap/payment-slip", query, {
      responseType: "blob",
    });
  }

  public async downloadCreditNoteOrDebitNote(
    query: GetAllNfeQuery
  ): Promise<Blob> {
    return await this.httpInstance.post(
      "/ms-sap/reports/",
      {
        service: query.service,
        customerNumber: query.customerNumber,
        documentNumber: query.documentNumber,
        companyCode: query.companyCode,
        fiscalYear: query.fiscalYear,
        documentItem: query.documentItem,
      },
      {
        responseType: "blob",
      }
    );
  }

  public async getRefundIcms(
    query: GetRefundIcmsQuery
  ): Promise<GetRefundIcmsDTO> {
    return await this.httpInstance.get("ms-contracts/refund-icms", {
      params: query,
    });
  }

  public async getUsers(query: any): Promise<IGetUser> {
    return await this.httpInstance.get(
      `${this.authServiceStandalone}/rest/rbac/users?paginate`,
      {
        params: query,
      }
    );
  }

  public async uploadIcmsFile(file: FormData): Promise<IFileUploadDTO[]> {
    return await this.httpInstance.post(
      `ms-contracts/refund-icms/upload`,
      file
    );
  }

  public async createRefundIcms(file: IIcmsRequest): Promise<any> {
    return await this.httpInstance.post(`ms-contracts/refund-icms`, file);
  }
}
// eslint-disable-next-line prettier/prettier

