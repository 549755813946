import React, { useCallback, useEffect, useState } from "react";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { CommonMenuList, RedirectPage } from "../models/OptionsMenu";
import { SacTableRow, StyledMenuProps } from "../models/SacSectionsTableProps";
import useDialogAlert from "../../../hooks/DialogAlert";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import {
  ROW_PER_PAGE,
  RegisterDescriptionType,
  SacRegisterStatus,
  SacRegisterStatusIcons,
  SacRegisterStatusTooltip,
} from "../models/SearchFilterOptions";
import { calcPagesQuantity, formatCNPJ } from "../../../utils";
import { TableWaitingSacColumns } from "../models/TableHeaderColumns";
import { useSac } from "../../../contexts/sac/sacContext";
import { SacRegister } from "../../../modules/information/models/GetSacRegisterDTO";
import { format } from "date-fns";
import { ISODateToFormat } from "../../../utils/dateFormatter";
import { SacRegisterQuery } from "../../../modules/information/models/SacRegisterQuery";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
interface AwaitingConfirmationCallsTabProps {
  dataFilter?: SacRegisterQuery | null;
}

const AwaitingConfirmationCallsTab: React.FC<
  AwaitingConfirmationCallsTabProps
> = ({ dataFilter }) => {
  const navigate = useNavigate();
  const { setDataItemInView, setItemInView } = useSac();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [data, setData] = useState<SacTableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const { snackbar } = useDialogAlert();
  const { getAllRegisters } = useSac();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const StyledMenu: React.FC<StyledMenuProps> = (data: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async (type: RedirectPage) => {
      setAnchorEl(null);
      switch (type) {
        case "viewOrder": {
          setDataItemInView(data.data.dataComplete);
          setItemInView(data.data.dataComplete.refId);
          navigate(routesConfig.INFORMATION().SAC_DETAILS);
          break;
        }
        case "deleteOrder":
          break;
        default:
          break;
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {CommonMenuList.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(option.type);
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const getRegisterData = useCallback(
    async (query: SacRegisterQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);
        const response = await getAllRegisters?.({
          ...query,
          status: `${SacRegisterStatus.WAITING_CONFIRMATION},${SacRegisterStatus.WAITING_VENDOR}`,
        });
        const newArray = response?.content;
        setTotal(response?.total ?? 0);
        if (newArray?.length === 0) {
          setErrorData(true);
        }
        const newDate: SacTableRow[] =
          newArray?.map(
            (item: SacRegister): SacTableRow => ({
              id: item.id || "",
              refId: item.refId,
              type: RegisterDescriptionType[
                item.type as keyof typeof RegisterDescriptionType
              ],
              subsidiaryDescription: item.subsidiary.description || "",
              customerRequester: item.customerRequester || "",
              cnpj: formatCNPJ(item.customerCompany?.document) || "",
              cnpjDescription: item.customerCompany?.description || "",
              requestDate: `${ISODateToFormat(
                item.requestDate ?? "-"
              )} às ${format(new Date(item.requestDate || ""), "HH:mm")}h`,
              dataComplete: item,
              waiting:
                item.status === SacRegisterStatus.WAITING_CONFIRMATION
                  ? "Aguardando confirmação do cliente"
                  : "Aguardando confirmação do analista",

              status: (
                <StyledIcon
                  iconType={
                    SacRegisterStatusIcons[
                      item.status as keyof typeof SacRegisterStatusIcons
                    ]
                  }
                  tooltip={{
                    title:
                      SacRegisterStatusTooltip[
                        item.status as keyof typeof SacRegisterStatusIcons
                      ],
                  }}
                />
              ),
            })
          ) ?? [];

        setData(newDate);
      } catch (error) {
        snackbar({
          message:
            "Erro ao carregar tabela de pedidos realizados. Tentar novamente",
          variant: "error",
        });
        setErrorData(true);
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData]
  );

  useEffect(() => {
    if (dataFilter !== null) {
      getRegisterData(dataFilter as SacRegisterQuery);
      setCurrentPage(dataFilter?.page ?? 1);
    }
  }, [dataFilter, setCurrentPage]);

  const getbyPage = useCallback(
    (page: number) => {
      if (dataFilter !== null && page) {
        const pageFilter = { ...dataFilter, page: page };
        setCurrentPage(page);
        getRegisterData(pageFilter as SacRegisterQuery);
      }
    },
    [dataFilter, setCurrentPage]
  );

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <TablePaginated
          columns={TableWaitingSacColumns}
          data={data.map((item, index) => ({
            ...item,
            icon: <StyledMenu data={item} />,
          }))}
          isLoadingData={isLoadingData}
          loadFromServer
          onChangePage={(newPage) => {
            getbyPage(newPage);
          }}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: currentPage,
          }}
          resultsTotal={total}
        />
      )}
    </>
  );
};

export default AwaitingConfirmationCallsTab;
