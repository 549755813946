import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Select from "../../../components/Select/Select";
import { searchFilterList } from "../models/SearchFilterOptions";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import MultipleSelectDistributors from "../../../components/MultipleSelectDistributors/MultipleSelectDistributors";
import { isEmpty } from "lodash";
import { SacRegisterQuery } from "../../../modules/information/models/SacRegisterQuery";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";

interface ButtonSearch {
  dataFilter: (query: any) => void;
  enableSelect?: boolean;
}

const SacFilter: React.FC<ButtonSearch> = ({
  dataFilter,
  enableSelect = true,
}) => {
  const { selectedDistributorsStoraged, subsidiaries } = useDistributor();
  const [searchQuery, setSearchQuery] = useState<SacRegisterQuery>({});
  const [type, setType] = useState<string | null>(null);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const handleSearchChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | any,
      field: keyof SacRegisterQuery
    ) => {
      setSearchQuery((prevSearchQuery) => ({
        ...prevSearchQuery,
        [field]: event ?? null,
      }));
    },
    [setSearchQuery]
  );

  const handleSearchCompany = useCallback(() => {
    if (selectedDistributorsStoraged.length !== 0) {
      handleSearchChange(
        selectedDistributorsStoraged.join(","),
        "customerCompanyIds"
      );
    }
  }, [selectedDistributorsStoraged]);

  useEffect(() => {
    handleSearchCompany();
  }, [selectedDistributorsStoraged]);

  const handleConsultChange = useCallback(() => {
    dataFilter(searchQuery);
  }, [searchQuery, dataFilter]);

  useEffect(() => {
    if (firstLoading && !isEmpty(searchQuery)) {
      handleConsultChange();
      setFirstLoading(false);
    }
  }, [searchQuery, firstLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={3}>
            <MultipleSelectDistributors maxWidth={1200} label={"Cliente"} />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <SelectSubsidiaries
              label={"Filial"}
              valueSubsidiary={subsidiaries.find(
                (item) => item.id === searchQuery.subsidiaryIds
              )}
              onChangeValue={(selectedOption) => {
                if (selectedOption) {
                  handleSearchChange(selectedOption.id, "subsidiaryIds");
                }
              }}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <Select
              options={searchFilterList.map((item) => ({ option: item }))}
              value={searchFilterList.find((ele) => ele.value === type)}
              getOptionItemLabel={(value) => value.label}
              getSelectedOptionLabel={(value) => value.label}
              onChange={(register) => {
                setType(register.value);
                handleSearchChange(register.value, "type");
              }}
              label="Registros"
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <TextFieldCustom
              placeholder="Busque pelo número do ID"
              value={searchQuery.refIds || ""}
              onChange={(value) =>
                handleSearchChange(
                  value.target.value.replace(/^-\D/g, ""),
                  "refIds"
                )
              }
              endComponent={<StyledIcon iconType="search" />}
              label="ID"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      >
        <CustomButton
          title="Limpar filtros"
          variant={CustomButtonVariant.OUTLINED}
          onClick={() => {
            setSearchQuery({});
            setType(null);
            handleSearchCompany();
            setFirstLoading(true);
          }}
        />

        <CustomButton
          sx={{ marginLeft: "16px" }}
          title="Consultar"
          variant={
            !isEmpty(searchQuery)
              ? CustomButtonVariant.CONTAINED
              : CustomButtonVariant.DISABLED
          }
          onClick={handleConsultChange}
          disabled={isEmpty(searchQuery)}
        />
      </Grid>
    </Grid>
  );
};

export default SacFilter;
