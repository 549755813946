import { ITableHome } from "../../../contexts/home/models/homeModels";
import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";

export const ROW_PER_PAGE = 10;

export const openedInitQuery: IGetPipelineSchedulesQuery = {
  limit: ROW_PER_PAGE,
  page: 1,
  status: [
    PipelineScheduleStatus.VENDOR_APPROVED,
    PipelineScheduleStatus.VENDOR_PENDING,
    PipelineScheduleStatus.FINANCIAL_BLOCKED,
    PipelineScheduleStatus.INVOICE_PENDING,
    PipelineScheduleStatus.IN_LOADING,
    PipelineScheduleStatus.IN_SCHEDULING,
  ].join(","),
};

export const clearedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.INVOICE_CLEARED].join(","),
};

export const rejectedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.VENDOR_REJECTED].join(","),
};

export interface PropsTable {
  data: ITableHome[] | [];
  title: string;
  subTitle: string;
  count: number;
  isLoading: boolean;
  currentPageTable: number;
  query: IGetPipelineSchedulesQuery | undefined;
  setQuery: React.Dispatch<React.SetStateAction<IGetPipelineSchedulesQuery>>;
}

export interface TableColumn {
  id: number;
  name: string;
  key: keyof ITableHome;
}

export enum InitialTableStatus {
  STATUS_OPEN = "Em aberto",
  STATUS_CLEARED = "Faturado",
  STATUS_REJECTED = "Reprovado",
}

export interface IOption {
  isChecked: boolean;
  isIndeterminate: boolean;
  option: InitialTableStatus;
}

export const optionsMenu: IOption[] = [
  {
    isChecked: false,
    isIndeterminate: false,
    option: InitialTableStatus.STATUS_OPEN,
  },
  {
    isChecked: false,
    isIndeterminate: false,
    option: InitialTableStatus.STATUS_CLEARED,
  },
  {
    isChecked: false,
    isIndeterminate: false,
    option: InitialTableStatus.STATUS_REJECTED,
  },
];
