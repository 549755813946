import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableHeaderColumns: Column[] = [
  {
    label: "N. agendamento",
    id: "scheduleNumber",
    align: "left",
  },
  {
    label: "Filial",
    id: "subsidiary",
    align: "left",
  },
  {
    label: "Cliente",
    id: "customer",
    align: "left",
  },
  {
    label: "Condição de pagamento",
    id: "paymentCondition",
    align: "left",
  },
  {
    label: "Modalidade",
    id: "mod",
    align: "left",
  },
  {
    label: "Agendado para",
    id: "scheduleDate",
    align: "left",
  },
  {
    label: "Produto",
    id: "product",
    align: "left",
  },
  {
    label: "Quantidade",
    id: "quantity",
    align: "right",
  },
  {
    label: "Preço",
    id: "price",
    align: "right",
  },
  {
    label: "Unidade",
    id: "unit",
    align: "center",
  },
  {
    label: "",
    id: "statusIcon",
    align: "right",
  },
  {
    label: "",
    id: "menuIcon",
    align: "right",
  },
];
