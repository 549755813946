import { Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React from "react";
import * as Yup from "yup";
import AnimatedBox from "../../../components/AnimatedBox/AnimatedBox";
import AutoComplete, {
  AutoCompleteVariant,
} from "../../../components/AutoComplete/AutoComplete";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { useFetchData } from "../../../hooks/FetchData";
import { Types } from "../../../ioc/types";
import {
  Compartment,
  VehicleBundle,
} from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import { getTitleAndValue } from "../../../utils";
import { useRoadScheduleRequestContext } from "../context/RoadScheduleRequestContext";
import { Clear } from "@mui/icons-material";
import { styleButton } from "../models/customIconRoadSchedule";

interface FormProps {
  shippingCompanyId: string;
  driverId: string;
  vehicle_plates: VehicleBundle;
}

const RoadScheduleRequestStep2: React.FC = () => {
  const { formData, setStep, setFormData, setModalData } =
    useRoadScheduleRequestContext();

  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const driversList = useFetchData(
    async () => await operationalService.getDrivers()
  );

  const shippingCompaniesList = useFetchData(
    async () => await operationalService.getShippingCompanies()
  );

  const platesList = useFetchData(
    async () => await operationalService.getPlates()
  );

  const platesMatchesRegex = /^[A-Z]{3}\d([A-Z]|\d)\d{2}$/;
  const platesMatchesMessage = "Informe uma placa de veículo válida";

  const initCompartments =
    formData?.vehicleBundle?.compartments?.length &&
    (formData?.vehicleBundle?.compartments?.[0].plate?.length ?? 0) > 0
      ? formData?.vehicleBundle?.compartments ?? []
      : [{ plate: "" }];

  return (
    <AnimatedBox>
      <Formik
        initialValues={{
          shippingCompanyId: formData?.shippingCompanyId ?? "",
          driverId: formData?.driverId ?? "",
          vehicle_plates: {
            plate: formData?.vehicleBundle?.plate ?? "",
            compartments: [...initCompartments],
          },
        }}
        validationSchema={Yup.object({
          shippingCompanyId: Yup.string().required(
            "Selecione uma transportadora"
          ),
          driverId: Yup.string().required("Informe o motorista"),
          vehicle_plates: Yup.object().shape({
            plate: Yup.string()
              .matches(platesMatchesRegex, platesMatchesMessage)
              .required("Informe a placa"),
            compartments: Yup.array()
              .optional()
              .of(
                Yup.object().shape({
                  plate: Yup.string().matches(
                    platesMatchesRegex,
                    platesMatchesMessage
                  ),
                })
              ),
          }),
        })}
        onSubmit={function (
          values: FormProps,
          formikHelpers: FormikHelpers<FormProps>
        ) {
          const hasPlate =
            (values.vehicle_plates?.compartments?.[0]?.plate?.length ?? 0) > 0;
          setFormData((oldState) => ({
            ...oldState,
            shippingCompanyId: values.shippingCompanyId,
            driverId: values.driverId,
            vehicleBundle: {
              plate: values.vehicle_plates.plate,
              compartments: hasPlate
                ? values.vehicle_plates.compartments?.map((item) => ({
                    ...item,
                    type: "container",
                  }))
                : [],
            },
          }));
          setStep(2);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const hasErrorShippingCompanyId =
            touched.shippingCompanyId && Boolean(errors.shippingCompanyId);

          const hasErrorDriverId = touched.driverId && Boolean(errors.driverId);

          const compartiments = errors.vehicle_plates?.compartments?.[0] as
            | Compartment
            | undefined;
          const platesMessageError = compartiments?.plate ?? "";
          const hasErrorPlates =
            touched.vehicle_plates?.compartments && Boolean(platesMessageError);

          return (
            <Form>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={"Open Sans"}
                    fontSize={16}
                    fontWeight={700}
                    color={"shadesOfDark.dark"}
                  >
                    Transportadora
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} mt={2.5}>
                  <AutoComplete
                    iconButton={
                      values.shippingCompanyId !== "" && (
                        <Clear
                          fontSize="small"
                          sx={styleButton}
                          onClick={() => {
                            setFieldValue("shippingCompanyId", "");
                          }}
                        />
                      )
                    }
                    variant={
                      shippingCompaniesList?.loading
                        ? AutoCompleteVariant.LOADING
                        : AutoCompleteVariant.STANDARD
                    }
                    isDisabled={shippingCompaniesList?.loading}
                    label="Transportadora"
                    value={
                      shippingCompaniesList?.value?.find(
                        (item) => item.id === values.shippingCompanyId
                      ) ?? {}
                    }
                    options={shippingCompaniesList?.value ?? []}
                    getOptionItemLabel={(value) => value.description ?? ""}
                    onChange={(value) => {
                      setFieldValue("shippingCompanyId", value.id);
                      setModalData((oldState) => ({
                        ...oldState,
                        shippingCompany: getTitleAndValue(
                          "Transportadora",
                          value.description
                        ),
                      }));
                    }}
                    onBlur={() => {
                      setFieldTouched("local", true);
                    }}
                    hasError={hasErrorShippingCompanyId}
                    messageError={errors.shippingCompanyId}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={6}>
                  <AutoComplete
                    iconButton={
                      values.driverId !== "" && (
                        <Clear
                          fontSize="small"
                          sx={styleButton}
                          onClick={() => {
                            setFieldValue("driverId", "");
                          }}
                        />
                      )
                    }
                    variant={
                      driversList.loading
                        ? AutoCompleteVariant.LOADING
                        : AutoCompleteVariant.STANDARD
                    }
                    label="Motorista"
                    value={
                      driversList?.value?.find(
                        (item) => item.id === values.driverId
                      ) ?? {}
                    }
                    options={driversList?.value ?? []}
                    getOptionItemLabel={(value) => value.name ?? ""}
                    isDisabled={driversList.loading}
                    onChange={(value) => {
                      setFieldValue("driverId", value.id);
                      setModalData((oldState) => ({
                        ...oldState,
                        driver: getTitleAndValue(
                          "Nome do motorista",
                          value.name ?? ""
                        ),
                      }));
                    }}
                    hasError={hasErrorDriverId}
                    messageError={errors.driverId}
                  />
                </Grid>

                <Grid item xs={12} md={0} />
                <Grid container item xs={12} md={6} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AutoComplete
                      iconButton={
                        values.vehicle_plates.plate !== "" && (
                          <Clear
                            fontSize="small"
                            sx={styleButton}
                            onClick={() => {
                              setFieldValue("vehicle_plates.plate", "");
                            }}
                          />
                        )
                      }
                      variant={
                        platesList?.loading
                          ? AutoCompleteVariant.LOADING
                          : AutoCompleteVariant.STANDARD
                      }
                      isDisabled={platesList?.loading}
                      label="Placa do cavalo"
                      value={
                        platesList?.value?.find(
                          (item) => item.plate === values.vehicle_plates.plate
                        ) ?? {}
                      }
                      options={platesList?.value ?? []}
                      getOptionItemLabel={(value) => value.plate ?? ""}
                      onChange={(value) => {
                        setFieldValue("vehicle_plates.plate", value.plate);
                        setModalData((oldState) => ({
                          ...oldState,
                          vehicle_plate: getTitleAndValue(
                            "Placa do cavalo",
                            value.plate
                          ),
                        }));
                      }}
                      onBlur={() => {
                        setFieldTouched("vehicle_plates.plate", true);
                      }}
                      hasError={
                        touched.vehicle_plates?.plate &&
                        Boolean(errors.vehicle_plates?.plate)
                      }
                      messageError={errors.vehicle_plates?.plate}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoComplete
                      iconButton={
                        values.vehicle_plates?.compartments?.[0].plate !==
                          "" && (
                          <Clear
                            fontSize="small"
                            sx={styleButton}
                            onClick={() => {
                              setFieldValue(
                                "vehicle_plates.compartments[0].plate",
                                ""
                              );
                            }}
                          />
                        )
                      }
                      variant={
                        platesList?.loading
                          ? AutoCompleteVariant.LOADING
                          : AutoCompleteVariant.STANDARD
                      }
                      isDisabled={platesList?.loading}
                      label="Placa da carreta"
                      value={
                        platesList?.value?.find(
                          (item) =>
                            item.plate ===
                            values.vehicle_plates.compartments?.[0].plate
                        ) ?? {}
                      }
                      options={platesList?.value ?? []}
                      getOptionItemLabel={(value) => value.plate ?? ""}
                      onChange={(value) => {
                        setFieldValue(
                          `vehicle_plates.compartments[0].plate`,
                          value.plate
                        );
                        setModalData((oldState) => ({
                          ...oldState,
                          horse_plate: getTitleAndValue(
                            "Placa da carreta",
                            value.plate
                          ),
                        }));
                      }}
                      onBlur={() => {
                        setFieldTouched(
                          "vehicle_plates.compartments[0].plate",
                          true
                        );
                      }}
                      hasError={hasErrorPlates}
                      messageError={platesMessageError}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={10}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Box display={"flex"}>
                    <CustomButton
                      title={"Voltar"}
                      variant={CustomButtonVariant.OUTLINED}
                      onClick={() => {
                        setStep(0);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      title={"Avançar"}
                      variant={CustomButtonVariant.CONTAINED}
                      onClick={() => {}}
                      onSubmit={() => handleSubmit}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </AnimatedBox>
  );
};

export default RoadScheduleRequestStep2;
