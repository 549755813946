import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

interface DefaultOptionsProps<T> {
  isDisabled?: boolean;
  option: T;
}
interface SelectProps<T> {
  variant?: SelectVariant;
  value?: T;
  options: Array<DefaultOptionsProps<T>>;
  label?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  messageError?: string;
  placeholder?: string;
  emptyOptionsText?: string;
  getOptionItemLabel: (value: T) => string;
  onChange?: (selectedOption: T) => void;
  onBlur?: () => void;
  getSelectedOptionLabel: (value: T) => string;
}

export default function Select<T>({
  variant = SelectVariant.STANDARD,
  value,
  options,
  label,
  isDisabled,
  hasError,
  messageError,
  getOptionItemLabel,
  onChange,
  onBlur,
  getSelectedOptionLabel,
  placeholder,
  emptyOptionsText,
}: SelectProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const hasSelected = options.some((item) => item.option === value);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: T, index: number) => {
    if (onChange) onChange(option);
    if (value === undefined) setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onBlur) onBlur();
  };

  const getIsSelectedItem = (index: number) => {
    if (value === undefined) {
      return index === selectedIndex;
    }
    return options[index].option === value;
  };

  const getSelectedValue = () => {
    if (value === undefined && selectedIndex >= 0 && options.length > 0) {
      return getSelectedOptionLabel(options[selectedIndex].option);
    }

    if (value !== undefined && hasSelected) {
      return getSelectedOptionLabel(value);
    }
    if (!hasSelected) {
      return placeholder ?? "Selecione";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {label && (
        <Typography
          fontFamily={"Open Sans"}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          mb={0.5}
        >
          {label}
        </Typography>
      )}
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
          bgcolor: isDisabled ? "shadesOfDark.ultraLight" : "background.paper",
          border: `1px solid ${
            hasError && !isDisabled
              ? theme.palette.systemColors.error.main ?? ""
              : theme.palette.shadesOfDark.light ?? ""
          }`,
          borderRadius: 1,
          padding: 0,
          color: isDisabled ? "shadesOfDark.steel" : "shadesOfDark.dark",
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 0.9,
            cursor: isDisabled ? "not-allowed" : "pointer",
          }}
        >
          <Typography
            color={
              hasSelected || selectedIndex >= 0
                ? isDisabled
                  ? "shadesOfDark.steel"
                  : "shadesOfDark.dark"
                : "shadesOfDark.medium"
            }
            fontSize={16}
            noWrap
          >
            {getSelectedValue()}
          </Typography>
          {variant === SelectVariant.STANDARD ? (
            <KeyboardArrowDownIcon sx={{ color: "shadesOfDark.steel" }} />
          ) : (
            <CircularProgress sx={{ color: "shadesOfDark.steel" }} size={20} />
          )}
        </ListItem>
      </List>
      {hasError && (
        <Typography
          mt={0.5}
          fontFamily={"Open Sans"}
          fontSize={10}
          color={"systemColors.error.main"}
        >
          {messageError}
        </Typography>
      )}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          minWidth: 300,
        }}
      >
        {options.length === 0 && (
          <Typography color={"shadesOfDark.steel"} p={2}>
            {emptyOptionsText ?? "Lista vazia"}
          </Typography>
        )}
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            disabled={option.isDisabled}
            selected={getIsSelectedItem(index)}
            onClick={() => handleMenuItemClick(option.option, index)}
            sx={{
              minWidth: 200,
              fontSize: 14,
              fontFamily: "Open Sans",
              "&.Mui-selected": {
                color: "shadesOfDark.dark",
                backgroundColor: "shadesOfDark.ultraLight",
                fontWeight: 700,
              },
              "&.Mui-selected:hover": {
                backgroundColor: "shadesOfDark.ultraLight",
              },
            }}
          >
            {getOptionItemLabel(option.option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export enum SelectVariant {
  STANDARD,
  LOADING,
}
