import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface StatusTagProps {
  text: string;
  type: StatusTagType;
  marginLeft?: string;
}

const StatusTag: React.FC<StatusTagProps> = ({ text, type, marginLeft }) => {
  const theme = useTheme();

  const selectColor = () => {
    switch (type) {
      case StatusTagType.LATE:
        return 2;
      case StatusTagType.NEXT:
        return 1;
      case StatusTagType.ON_TIME:
        return 0;
      default:
        return 0;
    }
  };

  return (
    <Box
      display={"inline-block"}
      width={"fit-content"}
      height={"fit-content"}
      paddingY={0.5}
      paddingX={1}
      bgcolor={theme.palette.tagsAndStatus[selectColor()]}
      color={theme.palette.tagAndStatusText[selectColor()]}
      borderRadius={4}
      textAlign={"center"}
      marginLeft={marginLeft}
    >
      <Typography textTransform={"capitalize"} fontSize={13}>
        {text}
      </Typography>
    </Box>
  );
};

export default StatusTag;

export enum StatusTagType {
  NONE,
  LATE,
  NEXT,
  ON_TIME,
}
