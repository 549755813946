import { Box, Typography } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface GlobalQuotaDetailProps {
  title?: string;
  percentage?: string;
  subTitle?: string;
  isPositive?: boolean;
}

const GlobalQuotaDetail: React.FC<GlobalQuotaDetailProps> = ({
  title,
  percentage,
  subTitle,
  isPositive = true,
}) => {
  return (
    <Box py={0.5}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography
          fontSize={30}
          fontWeight={500}
          color={"rgba(58, 53, 65, 0.87)"}
        >
          {title} Litros
        </Typography>

        {percentage && isPositive && (
          <ArrowDropUpIcon sx={{ color: "tagAndStatusText.0", mx: 0.5 }} />
        )}

        {percentage && !isPositive && (
          <ArrowDropDownIcon sx={{ color: "tagAndStatusText.2", mx: 0.5 }} />
        )}
        <Typography
          fontWeight={600}
          color={isPositive ? "tagAndStatusText.0" : "tagAndStatusText.2"}
        >
          {percentage}
        </Typography>
      </Box>
      <Typography fontSize={12} color={"rgba(58, 53, 65, 0.6)"}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default GlobalQuotaDetail;
