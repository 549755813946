import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React from "react";
import CardHeader from "../CardHeader/CardHeader";
import CustomCard from "../CustomCard/CustomCard";
import GlobalQuotaDetail from "../GlobalQuotaDetail/GlobalQuotaDetail";
import ConsumptionProductName from "../ConsumptionProductName/ConsumptionProductName";
import ConsumptionProgress from "../ConsumptionProgress/ConsumptionProgress";
import CardDataNotFound from "../CardDataNotFound/CardDataNotFound";
import { getCurrentMonthName } from "../../utils/dateFormatter";
import { formatNumberWithDots } from "../../utils";

interface IProduct {
  productTitle: string;
  productDescription: string;
  productPercentageConsumed: number;
  productTotalVolume: number;
  productVolumeConsumed: number;
}

interface ConsumptionByProductCardProps {
  totalQuota: number;
  firstSubTitle?: string;
  secondSubTitle?: string;
  titlePercentage?: string;
  data?: IProduct[] | undefined;
  isLoadingData: boolean;
}

const RANGE_NUMBER_PAGE = 5;

const TableLoading: React.FC = () => {
  return (
    <>
      <Box px={2.4}>
        <Skeleton variant="text" width={"25rem"} height={"5rem"} />
        <Skeleton variant="text" width={"10rem"} height={"2rem"} />
      </Box>
      <Box
        px={2.4}
        mt={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        {Array.from(Array(RANGE_NUMBER_PAGE)).map((_, index) => (
          <Grid container my={1.5} key={index}>
            <Grid item xs={1}>
              <Skeleton variant="rectangular" height={"4rem"} width={"4rem"} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="text" height={"3rem"} width={"15rem"} />
              <Skeleton variant="text" height={"1.5rem"} width={"10rem"} />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Skeleton variant="text" height={"1.5rem"} width={"10rem"} />
              </Box>
              <Skeleton variant="text" height={"3rem"} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

const ConsumptionByProductCard: React.FC<ConsumptionByProductCardProps> = ({
  totalQuota,
  firstSubTitle,
  secondSubTitle,
  titlePercentage,
  data,
  isLoadingData,
}) => {
  const currentmonthName = getCurrentMonthName();

  return (
    <CustomCard mb={2} sx={{ minHeight: 300 }} pb={1}>
      <CardHeader
        title="Consumo de cota por produto"
        subTitle={firstSubTitle}
        secondSubTitle={secondSubTitle}
      />
      <Divider variant="middle" />

      {isLoadingData ? (
        <TableLoading />
      ) : data && data?.length > 0 ? (
        <>
          <Box px={2.4}>
            <GlobalQuotaDetail
              title={formatNumberWithDots(totalQuota)}
              subTitle={`Cota global do mês de ${getCurrentMonthName()}`}
              percentage={titlePercentage ?? ""}
            />
          </Box>
          <Box
            px={2.4}
            mt={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            {data.map((item, index) => (
              <Grid container my={1.5} key={index}>
                <Grid item xs={6}>
                  <ConsumptionProductName
                    productTitle={item.productTitle}
                    productDescription={item.productDescription}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ConsumptionProgress
                    label={formatNumberWithDots(item.productTotalVolume)}
                    percentageConsumed={item.productPercentageConsumed}
                    volumeConsumed={formatNumberWithDots(
                      item.productVolumeConsumed
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </>
      ) : (
        <Box m={2}>
          <CardDataNotFound
            icon="search-empty"
            title=""
            subtitle={`Não foram encontrados dados desse cliente referente ao mês de ${currentmonthName}`}
          />
        </Box>
      )}
    </CustomCard>
  );
};

export default ConsumptionByProductCard;
