export enum FilterICMSTypes {
  ALL = "all",
  ID = "id",
  CNPJ = "cnpj",
  STATUS = "status",
  DATE = "date",
}

export enum RefundIcmsStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum RefundIcmsStatusIcons {
  PENDING = "clock",
  APPROVED = "check-circle",
  REJECTED = "global-error",
}

export enum RefundIcmsStatusTooltip {
  PENDING = "Pendente",
  APPROVED = "Aprovado",
  REJECTED = "Rejeitado",
}

export const filter = [
  {
    label: "Todos",
    value: FilterICMSTypes.ALL,
  },
  {
    label: "ID",
    value: FilterICMSTypes.ID,
  },
  {
    label: "CNPJ",
    value: FilterICMSTypes.CNPJ,
  },
  {
    label: "Status",
    value: FilterICMSTypes.STATUS,
  },
  {
    label: "Data de solicitação",
    value: FilterICMSTypes.DATE,
  },
];

export const statusIcms = [
  {
    label: "Aprovado",
    value: RefundIcmsStatus.APPROVED,
  },
  {
    label: "Rejeitado",
    value: RefundIcmsStatus.REJECTED,
  },
  {
    label: "Pendente",
    value: RefundIcmsStatus.PENDING,
  },
];
