import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect } from "react";

interface CustomTabsProps {
  tabsHeader: string[];
  body?: React.ReactNode[];
  total?: React.ReactNode[];
  headerBorder?: 0 | 1;
  bodyBorder?: 0 | 1;
  tabIndicator?: boolean;
  paddingY?: number;
  minHeight?: number;
  indexTab?: number;
  onChangeTab?: (index?: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  borderWidth: number;
  paddingY?: number;
  minHeight?: number;
}

function TabPanel(props: TabPanelProps) {
  const theme = useTheme();
  const { children, value, index, borderWidth, paddingY, minHeight, ...other } =
    props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        border: borderWidth,
        borderStyle: "solid",
        borderRadius: 4,
        borderColor: theme.palette.shadesOfDark.ultraLight,
        minHeight: minHeight,
      }}
    >
      {value === index && <Box py={paddingY}>{children}</Box>}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface StyledTabProps {
  label: string;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.shadesOfDark.dark,
    },
  })
);

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.shadesOfDark.dark,
  "&.Mui-selected": {
    color: theme.palette.shadesOfDark.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabsHeader,
  body,
  total,
  headerBorder = 1,
  bodyBorder = 0,
  tabIndicator = true,
  paddingY = 3,
  minHeight,
  indexTab = 0,
  onChangeTab,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTab && onChangeTab(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(indexTab);
  }, [indexTab]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: headerBorder,
          borderColor: "shadesOfDark.ultraLight",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
        >
          {tabsHeader.map((item, index) => (
            <StyledTab key={item} label={item} {...tabProps(index)} />
          ))}
        </StyledTabs>
      </Box>
      {body?.map((item, index) => (
        <TabPanel
          borderWidth={bodyBorder}
          key={index}
          value={value}
          index={index}
          paddingY={paddingY}
          minHeight={minHeight}
        >
          {item}
        </TabPanel>
      ))}
      {total?.map(
        (item, index) => value === index && <div key={index}>{item}</div>
      )}
    </Box>
  );
};

export default CustomTabs;
