import React from "react";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import ModalPumpDetailsHeader from "../../PumpRequest/Section/ModalPumpDetailsHeader";
import ModalOrderDetailsContent from "../../QuoteRequest/Section/ModalOrderDetailsContent";
import { Box, Grid, Typography } from "@mui/material";
import ModalDetailsNote from "../../../components/ModalDetailsNote/ModalDetailsNote";
import {
  OperationModeType,
  operationModeList,
} from "./RoadScheduleRequestStep1";

export type ScheduleModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
interface ModalScheduleRequestLoaderProps {
  isEdit: boolean;
  isOpen: boolean;
  data: ScheduleModalData | any;
  handleClose: (open: boolean) => void;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
}

const ModalScheduleRequestLoader: React.FC<ModalScheduleRequestLoaderProps> = ({
  isEdit,
  isOpen,
  data,
  handleClose,
  buttons,
}) => {
  const tabBody: React.ReactNode[] = [];

  const modalOrderHeader = (
    <ModalPumpDetailsHeader
      distributor={data.local ?? ""}
      requestNumber={data.requestNumber ?? ""}
      requestDate={data.requestDateAndHours ?? ""}
      requestUser={data.requestUser ?? ""}
      subsidiaryDescription={data.subsidiaryDescription ?? ""}
    />
  );

  Object.keys(data).map((key) =>
    tabBody.push(
      <ModalOrderDetailsContent
        key={key}
        modality={{
          title: data.modality?.title ?? "",
          value: data.modality?.value ?? "",
        }}
        productName={{
          title: data.productName?.title ?? "",
          value: data.productName?.value ?? "",
        }}
        quantity={{
          title: data.quantity?.title ?? "",
          value: data.quantity?.value ?? "",
        }}
      />
    )
  );

  const PlateCard: React.FC = () => {
    const LabelCard: React.FC<{ label?: string; value?: string }> = ({
      label,
      value,
    }) => {
      return (
        <Typography
          fontWeight={700}
          fontSize={19}
          mb={0.5}
          sx={{ "& span": { fontWeight: 400, fontSize: 16 } }}
        >
          {label} <span>{value}</span>
        </Typography>
      );
    };

    const cardProps = {
      item: true,
      xs: 12,
      md: 5.5,
      borderRadius: 0.5,
      bgcolor: "shadesOfDark.background",
      py: 1.8,
      px: 2.5,
    };

    return (
      <Grid container mb={2.5}>
        <Grid {...cardProps}>
          <LabelCard
            label={data.shippingCompany?.title ?? ""}
            value={data.shippingCompany?.value ?? ""}
          />
          <LabelCard
            label={data.driver?.title ?? ""}
            value={data.driver?.value ?? ""}
          />
        </Grid>
        <Grid item md={1}></Grid>
        <Grid {...cardProps}>
          <LabelCard
            label={data.vehicle_plate?.title ?? ""}
            value={data.vehicle_plate?.value ?? ""}
          />
          <LabelCard
            label={data.horse_plate?.title ?? ""}
            value={data.horse_plate?.value ?? ""}
          />
        </Grid>
      </Grid>
    );
  };

  const modalOrderTabs = (
    <Box mb={3}>
      <ModalOrderDetailsContent
        borderWidth={1}
        modality={{
          title: data.modality?.title ?? "",
          value: data.modality?.value ?? "",
        }}
        productName={{
          title: data.productName?.title ?? "",
          value: data.productName?.value ?? "",
        }}
        quantity={{
          title: data.quantity?.title ?? "",
          value: data.quantity?.value ?? "",
        }}
        payment={{
          title: data.paymentCondition?.title ?? "",
          value: data.paymentCondition?.value ?? "",
        }}
        type={{
          title: data.type?.title ?? "",
          value: data.type?.value ?? "",
        }}
      />
    </Box>
  );

  const modalOrderNote = (
    <Box mb={3}>
      <ModalDetailsNote
        productNote={{
          title: data.requestNote?.title ?? "",
          value: data.requestNote?.value ?? "",
        }}
      />
    </Box>
  );

  const modalOrderInvoices = (
    <Box mb={3}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ModalDetailsNote
            productNote={{
              title: data.invoiceReferenceNumber?.title || "",
              value: data.invoiceReferenceNumber?.value || "Não informado",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ModalDetailsNote
            productNote={{
              title: data.invoicesFiles?.title || "",
              value: data.invoicesFiles?.value || "Sem anexo",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const isShipmentOnAccountAndOrder = (id: OperationModeType): boolean =>
    id === operationModeList[1].label;

  const modalContent = (
    <>
      {modalOrderHeader}
      <PlateCard />
      {modalOrderTabs}
      {modalOrderNote}
      {isEdit &&
        isShipmentOnAccountAndOrder(data.type?.value) &&
        modalOrderInvoices}
    </>
  );

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false)}
      componentItems={modalContent}
      titleModal={"Detalhes do pedido de agendamento"}
      buttons={buttons}
    />
  );
};

export default ModalScheduleRequestLoader;
