import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { IFileUploadDTO } from "../../modules/financial/models/IFileUpload";

export interface FileItemProps {
  onDelete?: (e: any) => void;
  onDownload?: (e: any) => void;
  data?: IFileUploadDTO;
}

const FileItem: React.FC<FileItemProps> = ({ data, onDelete, onDownload }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      md={12}
      mt={1}
      padding={1}
      display="flex"
      justifyItems="center"
      justifyContent="space-between"
      style={{
        borderRadius: 4,
        border: "1px solid #E8E9EB",
      }}
    >
      <Grid item md={8} display="flex" flexDirection="row" alignItems="center">
        <StyledIcon
          iconType="image"
          mr={1}
          sx={{
            width: "3rem",
            height: "3rem",
          }}
        />
        <Typography color={`${theme.palette.shadesOfDark.dark}`}>
          {data && data.fileName}
        </Typography>
      </Grid>
      <Grid
        item
        md={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {onDownload && (
          <StyledIcon
            onClick={() => onDownload(data)}
            style={{ cursor: "pointer" }}
            iconType="download"
            mr={1}
          />
        )}
        {onDelete && (
          <StyledIcon
            onClick={() => onDelete(data)}
            style={{ cursor: "pointer" }}
            iconType="trash"
            mr={1}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FileItem;
