import { inject, injectable } from "inversify";
import "reflect-metadata";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import { IExecutiveService } from "./models/IExecutiveService";
import { MonthListDTO } from "./models/MonthListDTO";

@injectable()
export class ExecutiveService implements IExecutiveService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async list(action: string): Promise<MonthListDTO> {
    return await this.httpInstance.get("/ms-files/files/list", {
      params: {
        action,
      },
    });
  }

  public async download(action: string, date: string): Promise<Blob> {
    return await this.httpInstance.get("/ms-files/files/download", {
      params: {
        action,
        date,
      },
      responseType: "blob",
    });
  }
}
