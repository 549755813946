import { Grid } from "@mui/material";
import React from "react";
import TitleAndDetailExtract from "../ModalTitleAndDetail/ModalTitleAndDetail";
import { useMedia } from "use-media";

interface ModalExtractContentProps {
  icon: React.ReactNode;
  category: DetailInputsModalProps;
  value: DetailInputsModalProps;
  accessKey: DetailInputsModalProps;
  bank: DetailInputsModalProps;
  valueNumber: DetailInputsModalProps;
  formOfPayment: DetailInputsModalProps;
  compensationDate: DetailInputsModalProps;
  description: DetailInputsModalProps;
}

export interface DetailInputsModalProps {
  title: string;
  value: string;
}

const ModalExtractContent: React.FC<ModalExtractContentProps> = ({
  icon,
  category,
  value,
  accessKey,
  bank,
  valueNumber,
  formOfPayment,
  compensationDate,
  description,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid
      container
      sx={{ border: "1px solid #E8E9EB", borderRadius: "4px" }}
      padding={3}
      marginTop={2}
    >
      <Grid container>
        <Grid
          item
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
          xs={6}
          sm={4}
          md={2}
          lg={1}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
        >
          {icon}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          margin={"auto"}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
            paddingLeft: {
              lg: "30px",
            },
          }}
        >
          <TitleAndDetailExtract
            title={category.title}
            detail={category.value}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
        >
          <TitleAndDetailExtract
            title={value.title}
            detail={value.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
        >
          <TitleAndDetailExtract
            title={accessKey.title}
            detail={accessKey.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={1}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={bank.title}
            detail={bank.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={valueNumber.title}
            detail={valueNumber.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={9}
          lg={9}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "30px" : "20px"}
        >
          <TitleAndDetailExtract
            title={formOfPayment.title}
            detail={formOfPayment.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={3}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={2}
        >
          <TitleAndDetailExtract
            title={compensationDate.title}
            detail={compensationDate.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          margin={"auto"}
          display={"flex"}
          justifyContent={"left"}
          paddingTop={5}
        >
          <TitleAndDetailExtract
            title={description.title}
            detail={description.value}
            textAlign={"left"}
            paddingBottom={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalExtractContent;
