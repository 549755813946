import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
import CustomButton, {
  CustomButtonVariant,
} from "../CustomButton/CustomButton";

interface ModalExtractProps {
  componentItems: React.ReactNode;
  titleModal: string;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
  openElement?: React.ReactNode;
  openDialog: boolean;
  handleClose: () => void;
}

export const Button = styled(MuiButton)({
  padding: "4px 16px",
  fontWeight: "700",
  textTransform: "none",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid #58595B",
  marginBottom: "20px",
  "&#download": {
    background: "#FFFF",
    color: "#58595B",
  },
  "&#close": {
    background: "#58595B",
    color: "#FFF",
    marginRight: "31px",
  },
});

const ModalExtract: React.FC<ModalExtractProps> = ({
  componentItems,
  titleModal,
  buttons,
  openElement,
  openDialog,
  handleClose,
}) => {
  return (
    <div>
      {openElement}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle fontSize={24} fontWeight={700} color={"shadesOfDark.dark"}>
          {titleModal}
        </DialogTitle>
        <DialogContent>{componentItems}</DialogContent>
        <DialogActions>
          {buttons?.map((item, index) => (
            <CustomButton key={index} {...item} />
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalExtract;
