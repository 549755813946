export type RedirectPage = "viewOrder" | "deleteOrder";
export interface IOptionItem {
  label: string;
  type: RedirectPage;
}

export const CommonMenuList: IOptionItem[] = [
  {
    label: "Ver solicitação",
    type: "viewOrder",
  },
];
