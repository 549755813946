import { FormGroup } from "@material-ui/core";
import { Typography } from "@mui/material";
import { format, getMonth, getYear } from "date-fns";
import React, { useEffect, useRef, RefObject } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { Box, TextFielddBox } from "./styles";

const monthsBR = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const daysBR = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

const ptbr: Locale = {
  localize: {
    ordinalNumber: (...args: any[]) => "",
    era: (...args: any[]) => "",
    quarter: (...args: any[]) => "",
    day: (n: any) => daysBR[n],
    month: (n: any) => monthsBR[n],
    dayPeriod: (...args: any[]) => "",
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
    time: (...args: any[]) => "",
    dateTime: (...args: any[]) => "",
  },
};

interface CalendarRangeProps {
  name: string;
  label: string;
  value?: [Date, Date] | [null, null];
  onChangeDateStartEnd?: (dateBegin: Date) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  clearValueCalendar?: boolean;
  disabledTextField?: boolean;
}

const CalendarSingle: React.FC<CalendarRangeProps> = ({ ...props }) => {
  const [singleDate, setSingleDate] = React.useState<Date | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const datePickerRef: RefObject<HTMLDivElement> = useRef(null);

  const handleChange = (date: [Date, Date]) => {
    if (props.onChangeDateStartEnd) props.onChangeDateStartEnd(date[0]);
    setSingleDate(date[0]);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  function range(start: number, end: number) {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }

  const years = range(1990, new Date().getFullYear() + 1);

  const getDate = (startDate: Date | null) =>
    startDate ? format(startDate, "dd/MM/yyyy") : "";

  useEffect(() => {
    if (props.clearValueCalendar) {
      setSingleDate(null);
    }
  }, [props.clearValueCalendar]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <FormGroup ref={datePickerRef}>
      <Typography
        sx={{
          color: "#58595B",
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: "700",
          paddingBottom: "4px",
        }}
      >
        {props.label}
      </Typography>
      <TextFielddBox
        disabled={props.disabledTextField}
        type="text"
        placeholder="DD/MM/AAAA"
        id="outlined-basic"
        variant="outlined"
        name={props.name}
        onClick={handleClick}
        onBlur={props.onBlur}
        value={getDate(singleDate)}
        autoComplete="off"
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />

      <Box>
        {isOpen && !props.disabledTextField && (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <button
                    type="button"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                      background: "#FFF",
                      border: "none",
                    }}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <StyledIcon
                      iconType="left-arrow"
                      sx={{
                        height: "12px",
                        width: "14px",
                      }}
                    />
                  </button>

                  <select
                    style={{
                      color: "#58595B",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      border: "none",
                      textAlign: "center",
                      padding: "0 4px 0 4px",
                      cursor: "pointer",
                    }}
                    value={monthsBR[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(monthsBR.indexOf(value))
                    }
                  >
                    {monthsBR.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    style={{
                      backgroundColor: "#fff",
                      color: "#58595B",
                      fontSize: "14px",
                      border: "none",
                      cursor: "pointer",
                      marginRight: "25px",
                    }}
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(parseInt(value))
                    }
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    style={{
                      border: "none",
                      padding: "0",
                      cursor: "pointer",
                      background: "#FFF",
                    }}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <StyledIcon
                      iconType="right-arrow"
                      sx={{
                        height: "12px",
                        width: "14px",
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
            wrapperClassName="datePicker"
            selected={singleDate}
            locale={ptbr}
            onChange={handleChange}
            selectsRange
            inline
            isClearable
          />
        )}
      </Box>
    </FormGroup>
  );
};

export default CalendarSingle;
