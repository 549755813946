import { GetOrdersPaginatedQuery } from "../../../modules/supply/models/GetOrdersPaginatedQuery";

export type RegisterSacType = "" | "WAITING_CONFIRMATION" | "OPEN" | "CLOSED";

export enum RegisterType {
  COMPLIMENT = "COMPLIMENT",
  COMPLAINT = "COMPLAINT",
  SUGGESTION = "SUGGESTION",
  INFORMATION = "INFORMATION",
}

export enum RegisterDescriptionType {
  COMPLIMENT = "Elogio",
  COMPLAINT = "Reclamação",
  SUGGESTION = "Sugestão",
  INFORMATION = "Informação",
}

export enum SacRegisterStatus {
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  WAITING_VENDOR = "WAITING_VENDOR",
}

export const statusByTab: Record<any, string> = {
  0: "WAITING_CONFIRMATION",
  1: "OPEN",
  2: "CLOSED",
};

export enum SacRegisterStatusIcons {
  WAITING_CONFIRMATION = "await",
  OPEN = "clock",
  CLOSED = "check-circle",
  WAITING_VENDOR = "await",
}

export enum SacRegisterTabs {
  WAITING_CONFIRMATION = 1,
  OPEN = 0,
  CLOSED = 2,
  WAITING_VENDOR = 1,
}

export enum SacRegisterStatusTooltip {
  WAITING_CONFIRMATION = "Aguardando confirmação",
  OPEN = "Aguardando atendimento",
  WAITING_VENDOR = "Aguardando atendimento",
  CLOSED = "Finalizado",
}

export const ROW_PER_PAGE = 10;
interface IRegisterSac {
  label: string;
  value: RegisterType | null;
}

export const commonFilter: GetOrdersPaginatedQuery = {
  page: 1,
  limit: ROW_PER_PAGE,
  plain: "",
};

export const customerDraftFilter: GetOrdersPaginatedQuery = {
  ...commonFilter,
  status: "CUSTOMER_DRAFT",
};

export const allOrderFilter: GetOrdersPaginatedQuery = {
  ...commonFilter,
  status: "SENT",
};

export const commonFilterSac: IRegisterSac[] = [
  {
    label: "Elogio",
    value: RegisterType.COMPLIMENT,
  },
  {
    label: "Reclamação",
    value: RegisterType.COMPLAINT,
  },
  {
    label: "Sugestão",
    value: RegisterType.SUGGESTION,
  },
  {
    label: "Informação",
    value: RegisterType.INFORMATION,
  },
];

export const searchFilterList: IRegisterSac[] = [
  {
    label: "Todos",
    value: null,
  },
  ...commonFilterSac,
];
