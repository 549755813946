import { Box, Typography } from "@mui/material";
import React from "react";

interface ModalExtractTitleAndDetailProps {
  title: string;
  detail: string;
  textAlign?: "left" | "center" | "right";
  color?: string;
  underline?: boolean;
}

const ModalExtractTitleAndDetail: React.FC<ModalExtractTitleAndDetailProps> = ({
  title,
  detail,
  textAlign = "center",
  color = "shadesOfDark.dark",
  underline = false,
}) => {
  return (
    <Box>
      <Typography
        fontSize={19}
        fontWeight={700}
        textAlign={textAlign}
        color={"shadesOfDark.dark"}
      >
        {title}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        textAlign={textAlign}
        color={color}
        fontFamily={"Open Sans"}
        sx={{ textDecoration: underline ? "underline" : "none" }}
      >
        {detail}
      </Typography>
    </Box>
  );
};

export default ModalExtractTitleAndDetail;
