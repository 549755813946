/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import * as _ from "lodash";
import { endOfMonth, format, isSameDay, parse, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "../../components/BreadcrumbCustom/BreadcrumbCustom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import DatePickerDefault from "../../components/DatePickerDefault/DatePickerDefault";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import Select, { SelectVariant } from "../../components/Select/Select";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import TextFieldCustom from "../../components/TextFieldCustom/TextFieldCustom";
import { routesConfig } from "../../config/routes";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../contexts/ioc/IocContext";
import useDialogAlert from "../../hooks/DialogAlert";
import { useFetchData } from "../../hooks/FetchData";
import { Types } from "../../ioc/types";
import {
  ContractModel,
  GetContractsDTO,
} from "../../modules/operational/models/GetContractsDTO";
import { GetMonthDetailsDTO } from "../../modules/operational/models/GetMonthDetailsDTO";
import { IOperationalService } from "../../modules/operational/models/IOperationalService";
import {
  formatInfoCompany,
  getCurrentMonthYear,
  getNextMonthYear,
  getThousand,
  getTitleAndValue,
  isLastBusinessDayOfMonth,
  removeSpecialCharactersAndLetters,
} from "../../utils";
import AppError from "../../utils/appError";
import {
  DateToFormat,
  getFormattedHour,
  getFormattedTimestamp,
  isValidDate,
} from "../../utils/dateFormatter";
import ModalPumpRequestLoader, {
  PumpModalData,
} from "./Section/ModalPumpRequestLoader";
import SelectSubsidiaries from "../../components/SelectSubsidiary/SelectSubsidiary";

const INIT_DATE = new Date();
export interface PumpResquestFormProps {
  customerCompanyId: string;
  volumeRequested: string;
  paymentCondition: string;
  productId?: string;
  scheduleDate: string;
  note: string;
  pumpDate: Date;
  pumpTime: string;
  contractId: string;
  mod?: string;
  suggestedQuantity: number;
  subsidiaryId?: string;
}

const PumpRequestPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<PumpModalData>({});
  const navigate = useNavigate();
  const { distributors, subsidiaries } = useDistributor();
  const [contractList, setContractList] = useState<GetContractsDTO>([]);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [isCreatePipelineSchedule, setIsCreatePipelineSchedule] =
    useState(false);
  const [minDate, setMinDate] = useState(startOfMonth(INIT_DATE));
  const [maxDate, setMaxDate] = useState(endOfMonth(INIT_DATE));
  const [monthReference, setMonthReference] = useState<string>(
    format(new Date(), "MM-yyyy")
  );
  const [monthDetails, setMonthDetails] = useState<GetMonthDetailsDTO | null>(
    null
  );
  const [contractId, setContractId] = useState<string>("");
  const [subsidiaryId, setSubsidiaryId] = useState<string>("");
  const [isLoadingTimes, setIsLoadingTimes] = useState(false);
  const [timeList, setTimeList] = useState<string[]>([]);
  const auth = useAuth();
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const getContracts = useCallback(
    async (customerId: string, subsidiaryId: string) => {
      try {
        setIsLoadingContract(true);
        const currentDate = new Date();
        let payload: ContractModel = {};

        if (isLastBusinessDayOfMonth(currentDate)) {
          payload = {
            monthReferenceFrom: getCurrentMonthYear(),
            monthReferenceTo: getNextMonthYear(),
          };
        } else {
          payload = {
            monthReference: getCurrentMonthYear(),
          };
        }
        const response = await operationalService.getContracts(
          customerId,
          subsidiaryId,
          "DUTO",
          payload
        );
        setContractList(response);
        if (response.length === 0) {
          snackbar({
            message: "Nenhum contrato encontrado",
            variant: "error",
          });
        }
      } catch (error) {
        snackbar({
          message: "Erro ao carregar contratos",
          variant: "error",
        });
      } finally {
        setIsLoadingContract(false);
      }
    },
    []
  );

  const paymentConditions = useFetchData(
    async () => await operationalService.getPaymentConditions()
  );

  useEffect(() => {
    if (subsidiaryId !== "" && contractId !== "") {
      getContracts(contractId, subsidiaryId);
    }
  }, [subsidiaryId, contractId]);

  useEffect(() => {
    setModalData((oldState) => ({
      ...oldState,
      requestDate: getTitleAndValue("Data do pedido", DateToFormat(INIT_DATE)),
      requestUser: getTitleAndValue("Solicitado por", auth?.name),
      pumpDate: getTitleAndValue("Data para bombeio", DateToFormat(INIT_DATE)),
      pumpTime: getTitleAndValue("Horário", getFormattedHour(INIT_DATE)),
    }));
  }, []);

  const getScheduleDate = (date: Date, hour: string) =>
    `${DateToFormat(date)} ${hour}`;

  const handleOnSubmit = async (values: PumpResquestFormProps) => {
    try {
      setIsCreatePipelineSchedule(true);

      const filteredValues = _.omit(values, ["mod", "productId", "contract"]);
      await operationalService.createPipelineSchedule({
        ...filteredValues,
        volumeRequested: values.volumeRequested.replaceAll(".", ""),
      } as PumpResquestFormProps);
      snackbar({
        message: "Pedido solicitado com sucesso.",
        variant: "success",
      });
      navigate(routesConfig.OPERATIONAL().PIPELINES);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
        autoHideDuration: 7000,
      });
    } finally {
      setIsCreatePipelineSchedule(false);
    }
  };

  const setDateRange = (dateString?: string) => {
    if (dateString === undefined) return;
    const date = parse(dateString, "MM-yyyy", new Date());
    const firstDayOfMonth = startOfMonth(date);
    const lastDayOfMonth = endOfMonth(date);

    setMinDate(firstDayOfMonth);
    setMaxDate(lastDayOfMonth);

    return [firstDayOfMonth, lastDayOfMonth];
  };

  const breadcrumbs: BreadcrumbChild[] = [
    {
      isIcon: true,
      link: routesConfig.HOME,
      icon: <StyledIcon iconType={"home"} />,
    },
    {
      link: routesConfig.OPERATIONAL().PIPELINES,
      label: "ENTREGAS DUTOVIÁRIAS",
    },
    { label: "NOVO PEDIDO DE BOMBEIO" },
  ];

  const getMonthDetails = useCallback(async () => {
    try {
      setIsLoadingTimes(true);
      const response = await operationalService.getPipeMonthDetails(
        monthReference
      );

      setMonthDetails(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    } finally {
      setIsLoadingTimes(false);
    }
  }, [monthReference]);

  const setNewAvailableTimes = (selectedDay?: string) => {
    if (!monthDetails) return;
    if (!selectedDay) selectedDay = format(new Date(), "dd");

    const dayStringToNumber = parseInt(selectedDay).toString();

    if (monthDetails[dayStringToNumber] === undefined) return;

    const vacancies = monthDetails[dayStringToNumber].vacancies;
    const availableTimes = vacancies
      ? Object.keys(vacancies ?? {}).filter((key) => vacancies[key] > 0)
      : [];

    if (vacancies && availableTimes.length) {
      setTimeList(availableTimes);
    }
  };

  const shouldDisableDate = (date: Date) => {
    const monthDetailsKey = Object.keys(monthDetails ?? {});

    const datesWithVacancy = monthDetailsKey.filter(
      (item) => monthDetails?.[item].hasVacancy ?? false
    );

    const disabledDates = datesWithVacancy.map((day) => {
      const monthReferenceSplit = monthReference.split("-");
      const getMonthReference = monthReferenceSplit[0];
      const getYearReference = monthReferenceSplit[1];

      const date = `${getYearReference}-${getMonthReference}-${day.padStart(
        2,
        "0"
      )}`;

      return new Date(`${date}T04:00:00Z`);
    });

    return !disabledDates.some((disabledDate) => isSameDay(date, disabledDate));
  };

  useEffect(() => {
    if (monthDetails) {
      setNewAvailableTimes();
    }
  }, [monthDetails]);

  useEffect(() => {
    getMonthDetails();
  }, [monthReference]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Entregas dutoviárias" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title="Novo pedido de bombeio"
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Voltar para pedidos de bombeios",
                onClick: () => {
                  navigate(routesConfig.OPERATIONAL().PIPELINES);
                },
              }}
            />
            <BreadcrumbCustom data={breadcrumbs} />
            <Box mt={2}></Box>
            <Divider />
            <Formik
              initialValues={{
                customerCompanyId: "",
                volumeRequested: "1",
                paymentCondition: "",
                productId: "",
                mod: "",
                scheduleDate: getScheduleDate(INIT_DATE, ""),
                note: "",
                contractId: "",
                subsidiaryId: "",
                pumpDate: INIT_DATE,
                pumpTime: "",
                suggestedQuantity: 0,
              }}
              validationSchema={Yup.object({
                customerCompanyId: Yup.string().required(
                  "Selecione um nome de cliente"
                ),
                volumeRequested: Yup.string().required(
                  "Este campo é obrigatório"
                ),
                paymentCondition: Yup.string().required(
                  "Selecione uma condição de pagamento"
                ),
                note: Yup.string(),

                subsidiaryId: Yup.string().required("Selecione uma Filial"),
                contractId: Yup.string().required("Selecione uma contrato"),
                pumpDate: Yup.date().required(
                  "Selecione uma data para bombeio"
                ),
                pumpTime: Yup.string().required(
                  "Selecione uma hora para bombeio"
                ),
              })}
              onSubmit={function (
                values: PumpResquestFormProps,
                formikHelpers: FormikHelpers<PumpResquestFormProps>
              ) {
                setIsOpen(true);
              }}
            >
              {({
                values,
                touched,
                errors,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => {
                const hasErrorCustomerName =
                  touched.customerCompanyId &&
                  Boolean(errors.customerCompanyId);
                const hasErrorSubsidiary =
                  touched.subsidiaryId && Boolean(errors.subsidiaryId);
                const hasErrorContract =
                  touched.contractId && Boolean(errors.contractId);
                const hasErrorVolumeRequested =
                  touched.volumeRequested && Boolean(errors.volumeRequested);
                const hasErrorPaymentMethod =
                  touched.paymentCondition && Boolean(errors.paymentCondition);
                const hasErrorObservation =
                  touched.note && Boolean(errors.note);
                const hasErrorPumpTime =
                  touched.pumpTime && Boolean(errors.pumpTime);

                return (
                  <Form>
                    <Grid container spacing={1} mt={2}>
                      <Grid item>
                        <ModalPumpRequestLoader
                          handleClose={(open) => handleClose(open)}
                          isOpen={isOpen}
                          data={modalData}
                          buttons={[
                            {
                              title: "Cancelar",
                              variant: CustomButtonVariant.OUTLINED,
                              onClick: () => {
                                handleClose(false);
                              },
                            },
                            {
                              title: "Confirmar solicitação",
                              variant: isCreatePipelineSchedule
                                ? CustomButtonVariant.CONTAINED_LOADING
                                : CustomButtonVariant.CONTAINED,
                              onClick: async () => await handleOnSubmit(values),
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} mb={2}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                        >
                          Informações do pedido
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          label="Nome do cliente"
                          options={distributors.map((option) => ({
                            option,
                          }))}
                          value={distributors.find(
                            (item) => item.id === values.customerCompanyId
                          )}
                          getOptionItemLabel={(value) =>
                            formatInfoCompany(
                              value.description ?? "",
                              value.document ?? ""
                            )
                          }
                          getSelectedOptionLabel={(value) =>
                            value.description ?? ""
                          }
                          onChange={(value) => {
                            if (value.id) setContractId(value.id);
                            setFieldValue("contractId", "");
                            setFieldValue("productId", "");
                            setFieldValue("mod", "");
                            setFieldValue("suggestedQuantity", "0");
                            setFieldValue("customerCompanyId", value.id);
                            setModalData((oldState) => ({
                              ...oldState,
                              distributor: getTitleAndValue(
                                "Nome da razão social",
                                value.description
                              ),
                            }));
                          }}
                          hasError={hasErrorCustomerName}
                          messageError={errors.customerCompanyId}
                          onBlur={() => {
                            setFieldTouched("customerCompanyId", true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={0} />
                      <Grid item xs={12} md={4}>
                        <SelectSubsidiaries
                          label={"Filial"}
                          valueSubsidiary={subsidiaries.find(
                            (item) => item.id === values.subsidiaryId
                          )}
                          onChangeValue={(selectedOption) => {
                            if (selectedOption) {
                              setSubsidiaryId(selectedOption.id);
                              setFieldValue("subsidiaryId", selectedOption.id);
                              setModalData((oldState) => ({
                                ...oldState,
                                subsidiaryDescription: getTitleAndValue(
                                  "Filial",
                                  selectedOption.description
                                ),
                              }));
                            }
                          }}
                          onBlurValue={() => {
                            setFieldTouched("subsidiaryId", true);
                          }}
                          hasErrorValue={hasErrorSubsidiary}
                          messageErrorValue={errors.subsidiaryId}
                        />
                      </Grid>
                      <Grid item xs={12} md={0} />
                      <Grid item xs={12} md={4}>
                        <Select
                          variant={
                            isLoadingContract
                              ? SelectVariant.LOADING
                              : SelectVariant.STANDARD
                          }
                          label="Contrato"
                          placeholder={
                            isLoadingContract ? "Carregando..." : "Selecione"
                          }
                          options={contractList.map((option) => ({
                            option,
                          }))}
                          value={
                            contractList.find(
                              (item) => item.id === values.contractId
                            ) ?? {}
                          }
                          getOptionItemLabel={(value: ContractModel) =>
                            value.label ?? ""
                          }
                          getSelectedOptionLabel={(value: ContractModel) =>
                            value.label ?? ""
                          }
                          onChange={(value) => {
                            setFieldValue("pumpTime", "");
                            setFieldValue("contractId", value.id ?? "");
                            setFieldValue("productId", value.productId ?? "");
                            setFieldValue("mod", value.mod ?? "");
                            setFieldValue(
                              "suggestedQuantity",
                              value.suggestedVolume ?? ""
                            );
                            if (value.monthReference !== undefined) {
                              setMonthReference(value.monthReference);
                            }
                            const range = setDateRange(value.monthReference);
                            if (range?.length) {
                              setFieldValue("pumpDate", range[0]);
                            }
                            setModalData((oldState) => ({
                              ...oldState,
                              productName: getTitleAndValue(
                                "Nome do produto",
                                value.product
                              ),
                              modality: getTitleAndValue(
                                "Modalidade",
                                value.mod
                              ),
                              quantity: getTitleAndValue(
                                "Quantidade",
                                `${values.volumeRequested} ${
                                  value.productMeasurementUnit ?? ""
                                }`
                              ),
                              unit: getTitleAndValue(
                                "Unidade",
                                value.productMeasurementUnit ?? ""
                              ),
                              pumpDate: getTitleAndValue(
                                "Data para bombeio",
                                range?.length
                                  ? DateToFormat(range[0])
                                  : DateToFormat(INIT_DATE)
                              ),
                            }));
                          }}
                          onBlur={() => setFieldTouched("contractId", true)}
                          hasError={hasErrorContract}
                          messageError={errors.contractId}
                        />
                      </Grid>
                      <Grid item xs={12} md={0} />
                      <Grid container item xs={12} md={4} spacing={1}>
                        <Grid item xs={12} md={6}>
                          <DatePickerDefault
                            label={"Data para bombeio"}
                            disablePast
                            minDate={minDate}
                            maxDate={maxDate}
                            shouldDisableDate={shouldDisableDate}
                            value={values.pumpDate}
                            onChange={(value) => {
                              if (isValidDate(value as Date)) {
                                setFieldValue("pumpDate", value);
                                setFieldValue(
                                  "scheduleDate",
                                  getScheduleDate(
                                    value as Date,
                                    values.pumpTime
                                  )
                                );
                                setFieldValue("pumpTime", "");
                                setNewAvailableTimes(
                                  format(value as Date, "dd")
                                );
                                setModalData((oldState) => ({
                                  ...oldState,
                                  pumpDate: getTitleAndValue(
                                    "Data para bombeio",
                                    DateToFormat(value as Date)
                                  ),
                                }));
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched("pumpDate", true);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Select
                            variant={
                              isLoadingTimes
                                ? SelectVariant.LOADING
                                : SelectVariant.STANDARD
                            }
                            isDisabled={isLoadingTimes}
                            label="Grade de horário"
                            emptyOptionsText="Horários indisponíveis para este dia"
                            value={values.pumpTime}
                            options={timeList.map((option) => ({
                              option,
                            }))}
                            getOptionItemLabel={(value) => value}
                            getSelectedOptionLabel={(value) => value}
                            onChange={(value) => {
                              setFieldValue("pumpTime", value);
                              setFieldValue(
                                "scheduleDate",
                                getScheduleDate(values.pumpDate, value)
                              );
                              setModalData((oldState) => ({
                                ...oldState,
                                pumpTime: getTitleAndValue("Horário", value),
                              }));
                            }}
                            onBlur={() => {
                              setFieldTouched("pumpTime", true);
                            }}
                            hasError={hasErrorPumpTime}
                            messageError={errors.pumpTime}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={0} />
                      <Grid container item spacing={1} mt={3}>
                        <Grid item xs={12}>
                          <Typography
                            fontFamily={"Open Sans"}
                            fontSize={16}
                            fontWeight={700}
                            color={"shadesOfDark.dark"}
                          >
                            Pedido de produtos
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={2.4}>
                          <Select
                            isDisabled
                            label="Produto"
                            value={contractList.find(
                              (item) => item.productId === values.productId
                            )}
                            options={contractList.map((option) => ({
                              option,
                            }))}
                            getOptionItemLabel={(value) => value.product ?? ""}
                            getSelectedOptionLabel={(value) =>
                              value.product ?? ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                          <Select
                            isDisabled
                            label="Modalidade"
                            value={contractList.find(
                              (item) => item.mod === values.mod
                            )}
                            options={contractList.map((option) => ({
                              option,
                            }))}
                            getOptionItemLabel={(value) => value.mod ?? ""}
                            getSelectedOptionLabel={(value) => value.mod ?? ""}
                          />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                          <TextFieldCustom
                            disabled
                            label={`Quantidade sugerida ${
                              modalData.unit?.value ?? ""
                            }`}
                            placeholder="Informe a quantidade"
                            value={getThousand(values.suggestedQuantity)}
                          />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                          <TextFieldCustom
                            label={`Quantidade (bombeio) ${
                              modalData.unit?.value ?? ""
                            }`}
                            placeholder="Informe a quantidade"
                            value={values.volumeRequested}
                            hasError={hasErrorVolumeRequested}
                            messageError={errors.volumeRequested}
                            onChange={(value) => {
                              const numberWithoutCharactersAndLetters = Number(
                                removeSpecialCharactersAndLetters(
                                  value.target.value
                                )
                              );

                              const formattedValue = getThousand(
                                numberWithoutCharactersAndLetters
                              );
                              setFieldValue("volumeRequested", formattedValue);
                              setModalData((oldState) => ({
                                ...oldState,
                                quantity: getTitleAndValue(
                                  "Quantidade",
                                  `${formattedValue} ${
                                    oldState.unit?.value ?? ""
                                  }`
                                ),
                              }));
                            }}
                            onBlur={() =>
                              setFieldTouched("volumeRequested", true)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                          <Select
                            isDisabled={paymentConditions.errorLoading}
                            label="Condição de pagamento"
                            variant={
                              paymentConditions.loading
                                ? SelectVariant.LOADING
                                : SelectVariant.STANDARD
                            }
                            value={paymentConditions.value?.find(
                              (item) => item.code === values.paymentCondition
                            )}
                            options={
                              paymentConditions.value?.map((option) => ({
                                option,
                              })) ?? []
                            }
                            getOptionItemLabel={(value) =>
                              value.description ?? ""
                            }
                            getSelectedOptionLabel={(value) =>
                              value.description ?? ""
                            }
                            onChange={(value) => {
                              setFieldValue("paymentCondition", value.code);
                              setModalData((oldState) => ({
                                ...oldState,
                                paymentMethod: getTitleAndValue(
                                  "Condição de pagamento",
                                  value.description
                                ),
                              }));
                            }}
                            onBlur={() =>
                              setFieldTouched("paymentCondition", true)
                            }
                            hasError={hasErrorPaymentMethod}
                            messageError={errors.paymentCondition}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          fullWidth
                          label={"Observação"}
                          multiline
                          rows={4}
                          placeholder="Deixa sua observação"
                          value={values.note}
                          hasError={hasErrorObservation}
                          messageError={errors.note}
                          onChange={(value) =>
                            setFieldValue("note", value.target.value)
                          }
                          onBlur={() => setFieldTouched("note", true)}
                        />
                      </Grid>

                      <Grid item xs={12} mt={10}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-end"}
                      >
                        <Box display={"flex"}>
                          <CustomButton
                            title={"Cancelar"}
                            variant={CustomButtonVariant.OUTLINED}
                            onClick={() => {
                              navigate(routesConfig.OPERATIONAL().PIPELINES);
                            }}
                          />
                          <CustomButton
                            type="submit"
                            title={"Solicitar"}
                            variant={CustomButtonVariant.CONTAINED}
                            onClick={() => {}}
                            onSubmit={() => handleSubmit}
                            sx={{
                              ml: 1,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PumpRequestPage;
