import React, { useMemo, useState } from "react";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import { Grid } from "@mui/material";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { removeSpecialCharactersAndLetters } from "../../../utils";

export type ScheduleModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
interface ModalScheduleUpdateProps {
  isOpen: boolean;
  data: ScheduleModalData | any;
  cleanFilter?: () => void;
  handleClose: (open: boolean) => void;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
}

const ModalRiverUpdate: React.FC<ModalScheduleUpdateProps> = ({
  isOpen,
  data,
  handleClose,
  cleanFilter,
}) => {
  const [invoice, setInvoice] = React.useState<string>("");
  const [error, setError] = useState("");
  const [isValid] = useState(false);
  const [isLoading] = useState(false);

  const getButtonVariant = () => {
    if (isLoading) return CustomButtonVariant.CONTAINED_LOADING;
    if (isValid) return CustomButtonVariant.CONTAINED;
    return CustomButtonVariant.DISABLED;
  };

  const updatedButtons = useMemo(
    () => [
      {
        title: "Salvar",
        variant: getButtonVariant(),
        onClick: () => {
          // TODO: implementar a função de salvar
        },
      },
      {
        title: "Fechar",
        variant: CustomButtonVariant.CONTAINED,
        onClick: () => handleClose(false),
      },
    ],
    [isLoading, isValid, handleClose]
  );

  const modalUpdate = (
    <Grid container spacing={4} pb={3}>
      <Grid item xs={12} md={6}>
        <TextFieldCustom
          disabled={data.invoiceReferenceNumber?.value}
          label={"Número da Nota fiscal"}
          placeholder="Informe o valor"
          value={invoice}
          hasError={error !== ""}
          messageError={error}
          onChange={(value) => {
            const numberWithoutCharactersAndLetters =
              removeSpecialCharactersAndLetters(value.target.value);
            setError(
              numberWithoutCharactersAndLetters.length > 9
                ? "O valor deve conter no máximo 10 dígitos."
                : ""
            );

            setInvoice(numberWithoutCharactersAndLetters);
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false)}
      componentItems={modalUpdate}
      titleModal={"Editar detalhes do pedido de agendamento"}
      buttons={updatedButtons}
    />
  );
};

export default ModalRiverUpdate;
