import { Avatar, CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import QueryProductCell from "../../components/QueryProductCell/QueryProductCell";
import QueryTable, {
  IQueryTableRow,
} from "../../components/QueryTable/QueryTable";
import Select from "../../components/Select/Select";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { Types } from "../../ioc/types";
import { IExecutiveService } from "../../modules/executive/models/IExecutiveService";
import { MonthListDTO } from "../../modules/executive/models/MonthListDTO";
import { formatDataForExtension } from "../../utils/dateFormatter";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ColumnsList } from "./models/ColumnModel";
import { ProductstList } from "./models/ProductModel";
import { downloadOpenWindow } from "../../utils/download";

interface IQueryData extends IQueryTableRow {
  key: string;
  type: string;
  monthList: MonthListDTO;
}

const ConsultOperatingConditionsPage: React.FC = () => {
  const { serviceContainer } = useIocContext();
  const executiveService = serviceContainer.get<IExecutiveService>(
    Types.Executive.IExecutiveService
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState<IQueryData[]>([]);

  const reportDownload = async (month: { key: string; type: string }) => {
    const downloadFile = await executiveService.download(month.type, month.key);

    downloadOpenWindow(downloadFile, "pdf");
  };

  const listProductMonth = useCallback(async () => {
    const request = Object.keys(ProductstList).map(
      async (action) => await executiveService.list(action)
    );

    try {
      setLoadingTable(true);
      const responses = await Promise.all(request);
      let newData = data;
      newData = [];
      responses.forEach((item, index) => {
        if (item.length > 0) {
          newData.push({
            ...item[0],
            id: index,
            product: (
              <QueryProductCell title={ProductstList[item[0].type].title} />
            ),
            monthList: item,
          });
        }
      });
      setData(newData);
    } catch (error) {
      console.log("TO DO: error modal. Error on loading parameters monthly");
    } finally {
      setLoadingTable(false);
    }
  }, []);

  const updateDataOnDownload = (index: number, value: boolean) => {
    const newData = [...data];
    newData[index].isLoadingDownload = value;
    setData(newData);
  };

  useEffect(() => {
    listProductMonth();
  }, [listProductMonth]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Consultar condições operacionais" />
        </Grid>

        <Grid item xs={12}>
          <QueryTable
            title="Produtos"
            columns={ColumnsList}
            data={data.map((item, index) => ({
              ...item,
              month: (
                <Select
                  options={item.monthList.map((i) => ({
                    option: {
                      label: formatDataForExtension(i.key),
                      key: i.key,
                    },
                  }))}
                  getOptionItemLabel={(value) => value.label}
                  getSelectedOptionLabel={(value) => value.label}
                  onChange={(selected) => {
                    const newData = data;
                    newData[index].key = selected.key;
                    setData(newData);
                  }}
                />
              ),
              actions: (
                <IconButton
                  onClick={() => {
                    async function report() {
                      updateDataOnDownload(index, true);

                      await reportDownload(item);

                      updateDataOnDownload(index, false);
                    }
                    report();
                  }}
                >
                  {item.isLoadingDownload ? (
                    <CircularProgress size={16} />
                  ) : (
                    <ArrowDownwardIcon style={{ color: "#58595B" }} />
                  )}
                </IconButton>
              ),
            }))}
            isLoading={loadingTable}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ConsultOperatingConditionsPage;
