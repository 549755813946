import { Grid } from "@mui/material";
import React from "react";
import { DetailInputsModalProps } from "../ModalExtractContent/ModalExtractContent";
import TitleAndDetailExtract from "../ModalExtractTitleAndDetail/ModalExtractTitleAndDetail";

interface ModalDetailsNoteProps {
  productNote: DetailInputsModalProps;
  color?: string;
  borderWidth?: number;
}

const ModalDetailsNote: React.FC<ModalDetailsNoteProps> = ({
  productNote,
  color,
  borderWidth = 0,
}) => {
  return (
    <Grid container paddingBottom={1}>
      <Grid container>
        <Grid
          item
          xs={12}
          display={"flex"}
          sx={{
            justifyContent: {
              xs: "left",
            },
            border: borderWidth,
            padding: borderWidth ? 2 : 0,
            borderStyle: "solid",
            borderColor: "shadesOfDark.ultraLight",
            borderRadius: "4px",
          }}
        >
          <TitleAndDetailExtract
            color={color}
            title={productNote.title}
            detail={productNote.value}
            textAlign={"left"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalDetailsNote;
