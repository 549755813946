export interface SingleDataOrder {
  label: string;
  productName: string;
  modality: string;
  quantity: string;
}

export type OrderTitleDetail = Record<
  string,
  {
    value?: string;
    title?: string;
  }
>;

export interface Data {
  label: string;
  productName: string;
  modality: string;
  quantity: string;
  measurementUnit: string;
}

export type DataOrder = Record<string, Data | OrderTitleDetail | string>;

export interface ModalIcmsLoaderProps {
  withTab: boolean;
  isOpen: boolean;
  data: DataOrder | any;
  isApproved?: boolean;
  isView?: boolean;
  handleClose: (open: boolean, flag: string) => void;
}

export const tabLabels = ["Aprovado", "Solicitado"];

export const content = {
  productName: {
    title: "Nome do produto",
  },
  modality: {
    title: "Modalidade",
  },
  quantity: {
    title: "Quantidade",
  },
};

export const data: DataOrder = {
  approved: {
    label: "Aprovado",
    productName: "Gasolina",
    modality: "modalidade",
    quantity: "11234",
    measurementUnit: "m3",
  },
  orded: {
    label: "Solicitado",
    productName: "Gasolina",
    modality: "modalidade",
    quantity: "12341231234",
    measurementUnit: "m3",
  },
  header: {
    distributor: {
      title: "Nome da distribuidora",
      value: "teste",
    },
    requestNumber: {
      title: "N. do pedido",
      value: "teste",
    },
    requestDate: {
      title: "Data do pedido",
      value: "teste",
    },
  },
  content: { ...content },
};
