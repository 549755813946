import * as _ from "lodash";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "../../../components/Select/Select";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import { IGetPeriodByCompanyDTO } from "../../../modules/supply/models/IGetPeriodByCompanyDTO";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { ISupplyService } from "../../../modules/supply/models/ISupplyService";
import { Types } from "../../../ioc/types";
import AppError from "../../../utils/appError";
import useDialogAlert from "../../../hooks/DialogAlert";
import {
  ContractDTO,
  IQueryBalanceProps,
} from "../../../modules/supply/models/ContractDTO";
import MultipleSelectCheckbox from "../../../components/MultipleSelectCheckbox";
import { convertToOptionsList } from "../../../utils";
import MultipleSelectDistributors from "../../../components/MultipleSelectDistributors/MultipleSelectDistributors";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";

interface IBalanceFilterTableProps {
  onButtonChange: (query: IQueryBalanceProps) => void;
  onButtonClearFilter: (isClear: boolean) => void;
}

const BalanceFilterTableSection: React.FC<IBalanceFilterTableProps> = ({
  onButtonChange,
  onButtonClearFilter,
}) => {
  const { selectedDistributorsStoraged, subsidiaries } = useDistributor();
  const { serviceContainer } = useIocContext();
  const { snackbar } = useDialogAlert();
  const [codeDistributor, setCodeDistributor] = useState<string | null>(null);
  const [codePeriod, setCodePeriod] = useState<string | null>(null);
  const [codeContract, setCodeContract] = useState<string[] | string>([]);
  const [codeSubsidiary, setCodeSubsidiary] = useState<string | undefined>(
    undefined
  );
  const [codeMods, setCodeMods] = useState<string[] | string>([]);
  const [codeProducts, setCodeProducts] = useState<string[] | string>([]);
  const [period, setPeriod] = useState<IGetPeriodByCompanyDTO[]>([]);
  const [contracts, setContracts] = useState<ContractDTO[]>([]);
  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );

  const getPeriodData = useCallback(async (id: string) => {
    try {
      const response = await supplyService.getAllPeriodByCompany?.(id);
      setPeriod(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    }
  }, []);

  const handleChangeSelectedCompanies = useCallback(
    (event: string[]) => {
      if (event?.length > 0) {
        const selectedContracts = contracts.filter((ele) =>
          event.includes(ele.id)
        );
        setCodeContract(event);
        setCodeMods(_.uniq(selectedContracts.map((ele) => ele.mod)).join(","));
        setCodeProducts(
          _.uniq(selectedContracts.map((ele) => ele.productId)).join(",")
        );
      }
    },
    [setCodeContract, contracts]
  );

  const getContracts = useCallback(
    async (id: string, monthReference: string) => {
      try {
        setCodeContract([]);
        const responseContracts =
          await supplyService.getAllContractsInInterval?.({
            id: id,
            from: monthReference,
            to: monthReference,
          });

        setContracts(responseContracts);
      } catch (error) {
        snackbar({
          message: (error as AppError).message,
          variant: "error",
        });
      }
    },
    []
  );

  useEffect(() => {
    if (codeDistributor && codePeriod) {
      getContracts(codeDistributor, codePeriod);
    }
  }, [codePeriod]);

  const handleChange = useCallback(() => {
    onButtonClearFilter(false);

    const query: IQueryBalanceProps = {
      ...(codeSubsidiary && { subsidiaryId: codeSubsidiary }),
      ...(codePeriod && { monthReference: codePeriod }),
      ...(selectedDistributorsStoraged?.length !== 0 && {
        productId: codeProducts as string,
        mod: codeMods as string,
        customerCompanyId: selectedDistributorsStoraged.join(","),
      }),
    };
    onButtonChange(query);
  }, [codeDistributor, codePeriod, codeMods, codeProducts, codeSubsidiary]);

  const handleClearFilter = useCallback(() => {
    setCodePeriod(null);
    setCodeProducts([]);
    setCodeSubsidiary(undefined);
    setCodeMods([]);
    setCodeContract([]);
    onButtonClearFilter(true);
  }, [onButtonClearFilter]);

  useEffect(() => {
    if (selectedDistributorsStoraged.length !== 0) {
      setCodeDistributor(selectedDistributorsStoraged.join(","));
      getPeriodData(selectedDistributorsStoraged.join(","));
    }
  }, [selectedDistributorsStoraged]);

  const isAllSelected = useMemo(
    () =>
      codePeriod !== null &&
      codeContract.length !== 0 &&
      selectedDistributorsStoraged?.length !== 0,
    [codePeriod, codeContract, selectedDistributorsStoraged]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={3}>
            <MultipleSelectDistributors
              maxWidth={1200}
              label={"Selecione o cliente"}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <SelectSubsidiaries
              label={"Filial"}
              valueSubsidiary={subsidiaries.find(
                (item) => item.id === codeSubsidiary
              )}
              onChangeValue={(selectedOption) => {
                if (selectedOption) {
                  setCodeSubsidiary(selectedOption.id);
                }
              }}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <Select
              label="Período"
              isDisabled={!codeDistributor}
              emptyOptionsText={`Não há períodos disponíveis para os clientes selecionados`}
              options={period.map((option) => ({
                option,
              }))}
              getOptionItemLabel={(value) => value?.month ?? ""}
              getSelectedOptionLabel={(value) => value?.month ?? ""}
              onChange={(value) => {
                setCodePeriod(value?.monthReference ?? "");
              }}
              value={
                codePeriod !== null
                  ? period.find((item) => item.monthReference === codePeriod)
                  : null
              }
            />
          </Grid>
          <Grid item xs={10} sm={6} md={3}>
            <MultipleSelectCheckbox
              maxWidth={1200}
              minWidth={100}
              label="Contratos"
              isDisabled={!codePeriod}
              options={convertToOptionsList(contracts, "id", "label")}
              value={codeContract as string[]}
              onChange={handleChangeSelectedCompanies}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      >
        <CustomButton
          title="Limpar filtros"
          variant={CustomButtonVariant.OUTLINED}
          onClick={handleClearFilter}
        />

        <CustomButton
          sx={{ marginLeft: "16px" }}
          title="Consultar"
          variant={
            isAllSelected
              ? CustomButtonVariant.CONTAINED
              : CustomButtonVariant.DISABLED
          }
          onClick={handleChange}
          disabled={!isAllSelected}
        />
      </Grid>
    </Grid>
  );
};

export default BalanceFilterTableSection;
