import { Avatar, Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CardDataNotFound from "../../components/CardDataNotFound/CardDataNotFound";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Layout from "../../components/Layout/Layout";
import PaymentsDetailsCard, {
  IPaymentsDetailsItem,
} from "../../components/PaymentsDetailsCard/PaymentsDetailsCard";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import SelectDistributors from "../../components/SelectDistributors/SelectDistributors";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../contexts/ioc/IocContext";
import useDialogAlert from "../../hooks/DialogAlert";
import { Types } from "../../ioc/types";
import { GetCreditLimitExtractDTO } from "../../modules/financial/models/GetCreditLimitExtractDTO";
import { GetCreditLimitExtractQuery } from "../../modules/financial/models/GetCreditLimitExtractQuery";
import { IFinancialService } from "../../modules/financial/models/IFinancialService";
import { formatCurrency } from "../../utils/currencyFormatter";
import { getFormattedTimestamp } from "../../utils/dateFormatter";

const FinancialMovementPage: React.FC = () => {
  const { serviceContainer } = useIocContext();
  const financialService = serviceContainer.get<IFinancialService>(
    Types.Financial.IFinancialService
  );
  const [creditLimitResponse, setCreditLimitResponse] =
    useState<GetCreditLimitExtractDTO>();
  const { snackbar } = useDialogAlert();
  const { selectedDistributor } = useDistributor();
  const storageExternalId = selectedDistributor?.externalId;
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const storageHasCNPJ = localStorage.getItem("storageExternalId");

  const initFilters: GetCreditLimitExtractQuery = {
    customerNumber: storageExternalId,
    amountToPay: "",
    availableLimit: "",
  };

  const [filters, setFilters] =
    useState<GetCreditLimitExtractQuery>(initFilters);

  const getCreditLimitExtract = useCallback(async () => {
    setErrorData(false);
    setIsLoadingData(true);
    try {
      const response = await financialService.getCreditLimitExtract(filters);
      setCreditLimitResponse(response);

      if (response.amountToPay === undefined) {
        setErrorData(true);
      }
    } catch (error) {
      snackbar({
        message: "Erro ao carregar Movimentação financeira. Tentar novamente",
        variant: "error",
      });
      setErrorData(true);
    } finally {
      setIsLoadingData(false);
    }
  }, [filters]);

  const items: IPaymentsDetailsItem[] = [
    {
      title: "Limite de crédito",
      subtitle: creditLimitResponse?.creditLimit
        ? `${formatCurrency(parseFloat(creditLimitResponse?.creditLimit))}`
        : "--",
      type:
        parseFloat(creditLimitResponse?.creditLimit ?? "0") >= 0
          ? "standard"
          : "error",
    },
    // { comentado as opções devido ainda não ter no back-end
    //   title: "Depósito",
    //   subtitle: "R$ 0,01",
    // },
    {
      title: "Valor a pagar",
      subtitle: creditLimitResponse?.amountToPay
        ? `${formatCurrency(parseFloat(creditLimitResponse?.amountToPay))}`
        : "--",
      type:
        parseFloat(creditLimitResponse?.amountToPay ?? "0") >= 0
          ? "standard"
          : "error",
    },
    // {
    //   title: "Reserva",
    //   subtitle: "R$ 0,01",
    // },
    {
      title: "Saldo total",
      subtitle: creditLimitResponse?.availableLimit
        ? `${formatCurrency(parseFloat(creditLimitResponse?.availableLimit))}`
        : "--",
      type:
        parseFloat(creditLimitResponse?.availableLimit ?? "0") >= 0
          ? "standard"
          : "error",
    },
  ];

  useEffect(() => {
    if (storageExternalId?.length) {
      setFilters((oldState) => ({
        ...oldState,
        customerNumber: storageExternalId,
      }));
      localStorage.setItem("storageExternalId", "true");
    }
  }, [selectedDistributor, storageExternalId]);

  useEffect(() => {
    if (storageExternalId) {
      getCreditLimitExtract();
    }
  }, [filters, getCreditLimitExtract]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Consultar movimentação financeira" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title="Saldo de pagamento a prazo"
              subtitle={getFormattedTimestamp(new Date())}
            />
            <Grid container>
              <Grid item xs={12}>
                <CustomTabs
                  // TODO: por enquanto essa aba não vai exitir agora, somente nas próximas sprints. em 03.05.23
                  // tabsHeader={["Antecipado", "A prazo"]}
                  tabsHeader={["A prazo"]}
                  body={[
                    // <Box key={0}>
                    //   <Box mb={2}>
                    //     {/* TODO: integrar a uma data dinâmica quando tiver rota do back-end, exemplo na ConsultStatementPage.tsx */}
                    //     <CalendarRange
                    //       name="date"
                    //       label="Período de vencimento"
                    //       value={[new Date(), new Date()]}
                    //     />
                    //   </Box>
                    //   <PaymentsDetailsCard
                    //     title="Saldo de pagamento antecipado"
                    //     subTitle="Valores sujeitos a alteração"
                    //     items={items}
                    //     icon={<StyledIcon iconType="three-vertical-points" />}
                    //   />
                    // </Box>,
                    <Box key={0}>
                      <Box mb={2}>
                        <Grid container>
                          <Grid item display={"flex"}>
                            <Box flex={1}>
                              <SelectDistributors
                                label={"Selecione o cliente"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      {errorData && (
                        <CardDataNotFound
                          icon="data-not-found"
                          title="Não foi encontrado resultado"
                          subtitle="Informe outros critérios de busca e consulte novamente"
                        />
                      )}

                      {storageExternalId &&
                        storageExternalId !== undefined &&
                        !errorData && (
                          <PaymentsDetailsCard
                            title="Saldo de pagamento a prazo"
                            subTitle="Valores sujeitos a alteração"
                            isLoadingData={isLoadingData}
                            items={items}
                            icon={
                              <StyledIcon iconType="three-vertical-points" />
                            }
                          />
                        )}

                      {!storageHasCNPJ && (
                        <Box alignSelf={"center"}>
                          <CardDataNotFound
                            icon="search-empty"
                            title="Selecione o CNPJ para começar"
                            subtitle="Informe outros critérios de busca e comece a consultar!"
                          />
                        </Box>
                      )}
                    </Box>,
                  ]}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default FinancialMovementPage;
