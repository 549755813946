import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

interface IOption<T> {
  isChecked: boolean;
  isIndeterminate: boolean;
  option: T;
}

export interface TableDropdownProps<T> {
  title?: string;
  onChange?: (index: number, value: T, oldChecked: boolean) => void;
  options?: Array<IOption<T>>;
  getOptionLabel?: (value: T) => string;
  getSelectedText?: (value: T[]) => string;
  isDropDownArrow?: boolean;
}

function TableDropdown<T>({
  title,
  options,
  getOptionLabel,
  getSelectedText,
  onChange,
  isDropDownArrow,
}: TableDropdownProps<T>) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    index: number,
    option: T,
    oldChecked: boolean
  ) => {
    if (onChange) onChange(index, option, oldChecked);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="dropdown"
        sx={{
          bgcolor: "background.paper",
          border: isDropDownArrow ? "1px solid #E8E8E8" : "1px solid #FFFF",
          borderRadius: 2,
          padding: 0,
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="table dropdown"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            padding: 1,
          }}
        >
          <ListItemText
            primary={
              getSelectedText && options
                ? getSelectedText(
                    options
                      .filter((item) => item.isChecked)
                      .map((item) => item.option)
                  )
                : ""
            }
          />
          {isDropDownArrow ? (
            <ArrowDropDownRoundedIcon color={"disabled"} />
          ) : (
            <MoreVertRoundedIcon />
          )}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <Box padding={1} pl={2}>
          <Typography color={theme.palette.tagsAndStatus[3]}>
            {title}
          </Typography>
        </Box>
        {options?.map((option, index) => {
          return (
            <Box key={index} display={"flex"} alignItems={"center"}>
              <MenuItem
                onClick={(e) =>
                  handleMenuItemClick(index, option.option, option.isChecked)
                }
                sx={{
                  flex: 1,
                  pt: 1,
                  pb: 1,
                }}
              >
                <Checkbox
                  color="default"
                  checked={option.isChecked}
                  indeterminate={option.isIndeterminate}
                  sx={{
                    padding: 0,
                    pr: 1,
                  }}
                />
                {getOptionLabel && getOptionLabel(option.option)}
              </MenuItem>
            </Box>
          );
        })}
      </Menu>
    </div>
  );
}

export default TableDropdown;
