import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "notistack";
import React from "react";
import RoutesComponent from "./components/Routes/RoutesComponent";
import { AppTheme } from "./components/Theme/Theme";
import { AuthenticationProvider } from "./contexts/authentication/AuthenticationContext";
import { IocProvider } from "./contexts/ioc/IocContext";
import CustomNotification from "./components/CustomNotification/CustomNotification";
import { DistributorProvider } from "./contexts/distributor/DistributorContext";
import { OrderQuoteProvider } from "./contexts/orderQuote/OrderQuoteContext";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { datadogRum } from "@datadog/browser-rum";
import { isDevelopment } from "./config/default";
import { SacProvider } from "./contexts/sac/sacContext";
import { HomeProvider } from "./contexts/home/HomeContext";

const src: React.FC = () => {
  return (
    <FlagsmithProvider
      options={{
        environmentID: isDevelopment
          ? "mfHjRuJKHDaLeCbWiUF5Pm"
          : "mWu4NcPKdcrbHcjQZN8t2n",
        api: "https://flagsmith.ream.com.br/api/v1/",
        datadogRum: {
          client: datadogRum,
          trackTraits: true,
        },
      }}
      flagsmith={flagsmith}
    >
      <IocProvider>
        <AuthenticationProvider>
          <ThemeProvider theme={AppTheme()}>
            <SnackbarProvider
              autoHideDuration={3000}
              Components={{
                success: CustomNotification,
                error: CustomNotification,
                warning: CustomNotification,
              }}
            >
              <DistributorProvider>
                <OrderQuoteProvider>
                  <SacProvider>
                    <HomeProvider>
                      <CssBaseline />
                      <RoutesComponent />
                    </HomeProvider>
                  </SacProvider>
                </OrderQuoteProvider>
              </DistributorProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </AuthenticationProvider>
      </IocProvider>
    </FlagsmithProvider>
  );
};

export default src;
