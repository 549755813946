type ProductsType = Record<string, { title: string }>;

export const ProductstList: ProductsType = {
  alcool: {
    title: "Ácool",
  },
  diesel: {
    title: "Diesel",
  },
  gasolina: {
    title: "Gasolina",
  },
  glp: {
    title: "GLP",
  },
  qav: {
    title: "QAV",
  },
  oleo_combustivel: {
    title: "Óleo Combustível",
  },
  oleo_asfalto: {
    title: "Óleo asfalto",
  },
};
