import { Box, Typography } from "@mui/material";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface ConsumptionProductNameProps {
  productTitle: string;
  productDescription: string;
  isBellowQuota?: boolean;
}

const ConsumptionProductName: React.FC<ConsumptionProductNameProps> = ({
  productTitle,
  productDescription,
  isBellowQuota = false,
}) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display={"flex"} alignItems={"center"}>
      <StyledIcon
        iconType="product-name"
        sx={{
          width: isMediumScreen ? "3rem" : "4.6rem",
          height: isMediumScreen ? "3rem" : "4.4rem",
        }}
      />
      <Box ml={1.5}>
        <Typography color={"rgba(58, 53, 65, 0.87)"} fontWeight={600}>
          {productTitle}
        </Typography>
        <Typography fontSize={12} color={isBellowQuota ? "#E83F48" : "#89868D"}>
          {productDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default ConsumptionProductName;
