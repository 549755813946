export type RedirectPage = "viewOrder" | "deleteOrder";

export interface IOptionItem {
  label: string;
  type: RedirectPage;
}

export const CommonMenuList: IOptionItem[] = [
  {
    label: "Ver registro",
    type: "viewOrder",
  },
];

export const OptionsMenuList: IOptionItem[] = [
  ...CommonMenuList,
  {
    label: "Excluir atendimento",
    type: "deleteOrder",
  },
];
