import { Avatar, Grid, debounce } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import { routesConfig } from "../../config/routes";
import { useOrderQuote } from "../../contexts/orderQuote/OrderQuoteContext";
import { GetOrdersPaginatedQuery } from "../../modules/supply/models/GetOrdersPaginatedQuery";
import { getFormattedTimestamp } from "../../utils/dateFormatter";
import ButtonSearchItemTableSection from "./Sections/ButtonSearchItemTableSection";
import DraftOrdersSection from "./Sections/DraftOrdersSection";
import PlacedOrdersSection from "./Sections/PlacedOrdersSection";
import {
  commonFilter,
  customerDraftFilter,
} from "./models/SearchFilterOptions";
import { StatusOrder } from "./models/StatusOrderIcon";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { Roles } from "../../components/SideBar/Content/ListItem";
import { TypesPermissions } from "../../components/Routes/TypesPermissions";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import { CustomButtonVariant } from "../../components/CustomButton/CustomButton";
import { sidebar } from "../../utils/featureFlags";
import { useFlags } from "flagsmith/react";

const ConsultQuotaOrdersPage: React.FC = () => {
  const flags = useFlags(sidebar);
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [query, setQuery] = useState<GetOrdersPaginatedQuery | null>(null);
  const [showStatusSelect, setShowStatusSelect] = useState<boolean>(true);
  const { authByPermission, permissionSet, roles } =
    useAuth() as AuthenticationContextData;

  const navigate = useNavigate();
  const {
    ordersQuotesTotal,
    draftTotal,
    setDataItemInView,
    setItemInView,
    setItemUpdate,
  } = useOrderQuote();

  const tabsLabel = useMemo(() => {
    return [
      `Pedidos realizados${
        ordersQuotesTotal !== 0 ? ` (${ordersQuotesTotal})` : ""
      }`,
      `Pedidos em rascunho${draftTotal !== 0 ? ` (${draftTotal})` : ""}`,
    ];
  }, [ordersQuotesTotal, draftTotal]);

  const handleDebounce = (e: any): any => {
    if (currentTab === 0) {
      setQuery({
        ...commonFilter,
        ...(e.status ? e : { ...e, status: "SENT" }),
      });
    } else {
      setQuery({ ...customerDraftFilter, ...e });
    }
  };

  useEffect(() => {
    setDataItemInView?.({});
    setItemInView?.("");
    setItemUpdate("");
  }, [setDataItemInView]);

  useEffect(() => {
    if (state === null) return;
    setCurrentTab(state.tab);
    navigate(routesConfig.SUPPLIES().QUOTE, {});
  }, [state]);

  const isAllowed = useMemo(() => {
    return (
      authByPermission(
        permissionSet.SYSTEM_MODULES,
        roles.has(Roles.ADMIN),
        TypesPermissions.REQUEST
      ) && flags.supply_new_quote.enabled
    );
  }, [authByPermission, flags]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid item xs={12}>
        <QueryPageTitleCard title="Pedidos de cota" />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <CustomCard
          sx={{
            boxShadow: "none",
            border: "1px solid #DFE0EB",
            height: "100%",
            padding: 2.4,
          }}
        >
          <CardTitleWithSubtitle
            title="Pedidos de cota"
            subtitle={getFormattedTimestamp(new Date())}
            buttonProps={{
              title: "Novo pedido de cota",
              onClick: () => {
                navigate(routesConfig.SUPPLIES().REQUEST);
              },
              variant: isAllowed
                ? CustomButtonVariant.CONTAINED
                : CustomButtonVariant.DISABLED,
              disabled: !isAllowed,
            }}
          />

          <ButtonSearchItemTableSection
            enableSelect={showStatusSelect}
            dataFilter={(e) => debounce(handleDebounce(e), 4000)}
          />
          <CustomTabs
            indexTab={currentTab}
            tabsHeader={tabsLabel}
            onChangeTab={(idx) => {
              setShowStatusSelect(!idx);
              setCurrentTab(idx as number);
            }}
            body={[
              <PlacedOrdersSection dataFilter={query} key={1} />,
              <DraftOrdersSection
                dataFilter={{
                  ...query,
                  status: StatusOrder.customerDraft,
                }}
                key={2}
              />,
            ]}
          />
        </CustomCard>
      </Grid>
    </Layout>
  );
};

export default ConsultQuotaOrdersPage;
