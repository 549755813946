/* eslint-disable @typescript-eslint/restrict-template-expressions */
export function downloadOpenWindow(data: any, type: string) {
  let file: Blob = new Blob();

  switch (type) {
    case "pdf":
      file = new Blob([data], { type: "application/pdf" });
      break;
    case "xml":
      file = new Blob([data], { type: "text/xml" });
      break;
    default:
      break;
  }

  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export function downloadThisWindow(data: any, type: string, query?: any) {
  let fileName = "";
  let file: Blob = new Blob();

  switch (type) {
    case "pdf":
      fileName = `${query.codes[0]}.pdf`;
      file = new Blob([data], { type: "application/pdf" });
      break;
    case "xml":
      fileName = `${query.codes[0]}.xml`;
      file = new Blob([data], { type: "text/xml" });
      break;
    default:
      break;
  }

  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(fileURL);
}

export function downloadExcel(data: any) {
  const file = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export enum FileType {
  PDF = "application/pdf",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ZIP = "application/zip",
  X_ZIP_COMPRESSED = "application/x-zip-compressed",
  ZIP_COMPRESSED = "application/zip-compressed",
  ODS = "application/vnd.oasis.opendocument.spreadsheet",
  XML = "text/xml",
  JPEG = "image/jpeg",
  PNG = "image/png",
}

export type FileTypeZip =
  | "application/zip"
  | "application/x-zip-compressed"
  | "application/zip-compressed";

export const fileTypeOptionsZip: FileTypeZip[] = [
  "application/zip",
  "application/x-zip-compressed",
  "application/zip-compressed",
];

export function joinTypes(types: FileTypeZip[]): string {
  return types.join(";");
}

export function downloadXML(data: any) {
  const file = new Blob([data], { type: FileType.XML });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export function downloadZIP(data: any) {
  const file = new Blob([data], { type: joinTypes(fileTypeOptionsZip) });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

// JPEG e PNG - Informar o fileType
export function downloadJPEG(data: any, fileName: string, fileType: FileType) {
  const file = new Blob([data], { type: fileType });
  const fileURL = URL.createObjectURL(file);

  const a = document.createElement("a");
  a.href = fileURL;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(fileURL);
}
