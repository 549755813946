export const sidebar = [
  "sidebar_financial_invoices",
  "sidebar_financial_movement",
  "sidebar_financial_statement",
  "sidebar_financial_refunds_icms",
  "sidebar_supply_quote_extract",
  "sidebar_supply_quote_balance",
  "sidebar_supply_quote",
  "sidebar_operational_pipelines",
  "sidebar_operational_road_stations",
  "sidebar_operational_river",
  /* Forms */
  "operational_new_pipelines",
  "operational_new_road_stations",
  "supply_new_quote",
  "operational_new_river",
  /* List */
  "list_all_subsidiaries",
];
