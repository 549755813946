import * as _ from "lodash";
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import React, { Dispatch, useCallback, useRef, useState } from "react";
import * as Yup from "yup";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import Select from "../../components/Select/Select";
import TextFieldCustom from "../../components/TextFieldCustom/TextFieldCustom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import { getFormattedTimestamp } from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "../../components/BreadcrumbCustom/BreadcrumbCustom";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { useOrderQuote } from "../../contexts/orderQuote/OrderQuoteContext";
import { getFormatCNPJ } from "../../utils";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import useDialogAlert from "../../hooks/DialogAlert";
import { StatusOrderIcon } from "../ConsultQuotaOrders/models/StatusOrderIcon";
import { DataOrder } from "../QuoteRequest/models/ModalOrdes";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { IFinancialService } from "../../modules/financial/models/IFinancialService";
import { Types } from "../../ioc/types";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import FileInput from "../../components/FileInput";
import {
  IFileUploadDTO,
  IIcmsRequest,
} from "../../modules/financial/models/IFileUpload";
import ModalIcmsLoader from "../TaxReimbursement/Sections/ModalIcmsLoader";
import { format } from "date-fns";
import { useDistributor } from "../../contexts/distributor/DistributorContext";

interface FormProps {
  customerCompanyId: string;
  value: number;
  note: string;
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TaxReimbursementRequest: React.FC = () => {
  const authContext = useAuth() as AuthenticationContextData;
  const { distributors } = useDistributor();
  const { snackbar } = useDialogAlert();
  const { dataItemInView } = useOrderQuote();
  const navigate = useNavigate();
  const { serviceContainer } = useIocContext();
  const [modalData, setModalData] = useState<DataOrder>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pdfFile, setPdfFile] = React.useState<File | null>(null);
  const [xmlFile, setXmlFile] = React.useState<File | null>(null);
  const [files, setFiles] = React.useState<IFileUploadDTO[] | null>(null);
  const [requestIcmsData, setRequestIcmsData] =
    React.useState<IIcmsRequest | null>(null);
  const formRef = useRef<FormikProps<FormProps>>(null);

  const finacialService = serviceContainer.get<IFinancialService>(
    Types.Financial.IFinancialService
  );

  const handleClose = useCallback(
    async (open: boolean, flag: string) => {
      switch (flag) {
        case "refund": {
          try {
            if (_.isNull(requestIcmsData)) return;

            const response = await finacialService.createRefundIcms(
              requestIcmsData
            );

            if (response) {
              snackbar({
                message: "Pedido de ressarcimento solicitado com sucesso.",
                variant: "success",
              });
              navigate(routesConfig.FINANCIAL().TAX_REIMBURSEMENT);
            }
          } catch (error) {
            snackbar({
              message: "Erro ao realizar pedido de ressarcimento.",
              variant: "error",
            });
          }
          break;
        }
        case "close": {
          break;
        }
      }

      setIsOpen(open);
    },
    [requestIcmsData]
  );

  const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          prefix="R$ "
          thousandSeparator={"."}
          decimalSeparator={","}
          valueIsNumericString
        />
      );
    }
  );

  const breadcrumbs: BreadcrumbChild[] = [
    {
      isIcon: true,
      link: routesConfig.HOME,
      icon: <StyledIcon iconType={"home"} />,
    },
    {
      link: routesConfig.FINANCIAL().TAX_REIMBURSEMENT,
      label: "RESSARCIMENTO DE ICMS FISCAL",
    },
    {
      label: "NOVA SOLICITAÇÃO",
    },
  ];

  const handleUploadFile = useCallback(
    async (
      file: File | null,
      setFile: Dispatch<React.SetStateAction<File | null>>
    ) => {
      if (_.isNull(file)) return;
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await finacialService.uploadIcmsFile(formData);

        setFiles((old) => {
          return old ? [...old, ...response] : response;
        });
      } catch (error) {
        setFile(null);
        snackbar({
          message: "Erro ao realizar upload, tente novamente.",
          variant: "error",
        });
      }
    },
    [setFiles]
  );

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Ressarcimento de ICMS fiscal" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title={"Solicitação de ressarcimento"}
              icon={
                dataItemInView?.status !== undefined
                  ? StatusOrderIcon[
                      dataItemInView?.status as keyof typeof StatusOrderIcon
                    ]
                  : undefined
              }
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Voltar para solicitações de ressarcimento",
                onClick: () => {
                  navigate(routesConfig.SUPPLIES().QUOTE, {
                    state: {
                      tab: dataItemInView?.step === "NOT_SENT" ? 1 : 0,
                    },
                  });
                },
              }}
            />
            <BreadcrumbCustom data={breadcrumbs} />
            <Divider sx={{ marginTop: 2 }} />
            <Formik
              innerRef={formRef}
              initialValues={{
                customerCompanyId: "",
                value: 0,
                note: "",
              }}
              isInitialValid={false}
              validationSchema={Yup.object({
                customerCompanyId: Yup.string().required(
                  "Selecione uma distribuidora"
                ),
                value: Yup.number()
                  .required("Campo obrigatório")
                  .positive("Campo é obrigatório e deve ser positivo"),
                note: Yup.string(),
              })}
              onSubmit={async function (values: FormProps) {
                try {
                  setLoading(true);
                  const costumerData = distributors.find(
                    (ele) => ele.id === values.customerCompanyId
                  );

                  setModalData({
                    content: {
                      petition: {
                        title: "Petição",
                      },
                      xmlNotes: {
                        title: "Notas",
                      },
                      refundValues: {
                        title: "Valor do ressarcimento",
                      },
                      requestNote: {
                        title: "Observação",
                      },
                    },
                    header: {
                      distributor: {
                        title: "Nome da razão social",
                        value: costumerData?.description,
                      },
                      requestDate: {
                        title: "Data da Solicitação",
                        value: format(new Date(), "dd/MM/yyyy"),
                      },
                      requester: {
                        title: "Solicitado por",
                        value: authContext.name,
                      },
                    },
                    petition: pdfFile?.name ?? "",
                    xmlNotes: xmlFile?.name ?? "",
                    refundValues: values.value.toString(),
                    requestNote: values.note,
                  });

                  setIsOpen(true);
                  setRequestIcmsData({
                    customerCompanyId: costumerData?.id ?? "",
                    customerRequesterNote: values.note,
                    files: files ?? [],
                    subsidiaryId:
                      authContext.permissionSet.INTERNAL_SUBSIDIARIES[0] ?? "",
                    value: values.value,
                  });
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                }
              }}
            >
              {({
                errors,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                isValid,
              }) => {
                const hasErrorDistributor =
                  touched.customerCompanyId &&
                  Boolean(errors.customerCompanyId);
                const hasErrorValue = touched.value && Boolean(errors.value);

                return (
                  <Form>
                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                        >
                          Informações da empresa
                        </Typography>
                        <Divider sx={{ marginTop: 1 }} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          label="CNPJ"
                          options={distributors.map((option) => ({
                            option,
                          }))}
                          value={distributors.find(
                            (item) => item.id === values.customerCompanyId
                          )}
                          getOptionItemLabel={(value) =>
                            getFormatCNPJ(value) ?? ""
                          }
                          getSelectedOptionLabel={(value) =>
                            getFormatCNPJ(
                              value,
                              value.id === values.customerCompanyId ?? false,
                              true,
                              18
                            )
                          }
                          onBlur={() => {
                            setFieldTouched("customerCompanyId", true);
                          }}
                          onChange={(value) =>
                            setFieldValue("customerCompanyId", value.id)
                          }
                          hasError={hasErrorDistributor}
                          messageError={errors.customerCompanyId}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                        >
                          Anexar documentos
                        </Typography>
                        <Divider sx={{ marginTop: 1 }} />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        <FileInput
                          value={pdfFile}
                          label="PDF"
                          accept=".zip"
                          helperText="Formato aceito (.zip)"
                          onChangeFile={(file) => {
                            setPdfFile(file);
                            handleUploadFile(file, setPdfFile);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        <FileInput
                          value={xmlFile}
                          label="Notas anexadas (xml)"
                          accept=".zip"
                          helperText="Formato aceito (.zip)"
                          onChangeFile={(file) => {
                            setXmlFile(file);
                            handleUploadFile(file, setXmlFile);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                        >
                          Informações do ressarcimento
                        </Typography>
                        <Divider sx={{ marginTop: 1 }} />
                      </Grid>

                      <Grid container item spacing={1}>
                        <Grid item xs={12} md={4}>
                          <TextFieldCustom
                            label="Valor do ressarcimento (R$)"
                            placeholder="Informe o valor"
                            value={values.value}
                            hasError={hasErrorValue}
                            messageError={errors.value}
                            // disabled={!values.modality || isView}
                            onChange={(value) =>
                              setFieldValue("value", value.target.value)
                            }
                            onBlur={() => {
                              setFieldTouched("value", true);
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustom as any,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} mt={1}>
                          <TextFieldCustom
                            fullWidth
                            label={"Observação (opcional)"}
                            multiline
                            rows={4}
                            placeholder="Deixa sua observação"
                            value={values.note}
                            onChange={(value) => {
                              setFieldValue("note", value.target.value);
                            }}
                            onBlur={() => setFieldTouched("note", true)}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} mt={10}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-end"}
                      >
                        <Box display={"flex"}>
                          <CustomButton
                            title={"Cancelar"}
                            variant={CustomButtonVariant.OUTLINED}
                            onClick={() =>
                              navigate(
                                routesConfig.FINANCIAL().TAX_REIMBURSEMENT
                              )
                            }
                          />
                          <CustomButton
                            type="submit"
                            title={"Solicitar"}
                            disabled={!isValid}
                            variant={
                              isLoading
                                ? CustomButtonVariant.CONTAINED_LOADING
                                : isValid && pdfFile && xmlFile
                                ? CustomButtonVariant.CONTAINED
                                : CustomButtonVariant.DISABLED
                            }
                            onClick={() => null}
                            sx={{
                              ml: 1,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CustomCard>
          {modalData && (
            <ModalIcmsLoader
              handleClose={async (open: boolean, flag: string) =>
                await handleClose(open, flag)
              }
              isOpen={isOpen}
              data={modalData}
              withTab={false}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TaxReimbursementRequest;
