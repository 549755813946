import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomButton, { CustomButtonProps } from "../CustomButton/CustomButton";
import { StyledIcon, IconType } from "../StyledIcon/StyledIcon";

interface CardTitleWithSubtitleProps {
  title: string;
  subtitle?: string;
  buttonProps?: CustomButtonProps;
  icon?: IconType | undefined;
}

const CardTitleWithSubtitle: React.FC<CardTitleWithSubtitleProps> = ({
  title,
  subtitle,
  buttonProps,
  icon,
}: CardTitleWithSubtitleProps) => {
  return (
    <Grid container mb={2} spacing={2}>
      <Grid item xs={12} sm={6} md={8}>
        <Typography
          style={{
            fontFamily: "'Archivo', sans-serif",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "19px",
            lineHeight: "21px",
            letterSpacing: "0.4px",
            color: "#58595B",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
          {icon && <StyledIcon sx={{ marginLeft: 1 }} iconType={icon} />}
        </Typography>
        {subtitle && (
          <Typography
            style={{
              fontFamily: "'Open Sans', sans-serif",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              display: "flex",
              alignItems: "center",
              color: "#8C8D8F",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
      >
        {buttonProps && <CustomButton {...buttonProps} />}
      </Grid>
    </Grid>
  );
};

export default CardTitleWithSubtitle;
