import * as Yup from "yup";

export interface InitialValuesStep1 {
  customerCompanyId: string;
  subsidiaryId: string;
  receiverCustomerId: string;
  shippingCompanyId: string;
}

export const initialValidationSchemaStep1 = Yup.object({
  customerCompanyId: Yup.string().required("Selecione um cliente"),
  subsidiaryId: Yup.string().required("Selecione uma filial"),
  receiverCustomerId: Yup.string().required(
    "Selecione um cliente recebedor da mercadoria"
  ),
  shippingCompanyId: Yup.string().required("Selecione uma transportadora"),
});

export interface InitialValuesStep2 {
  contractId: string;
  scheduleDate?: string;
  scheduleLikeDate?: Date | null;
  productId: string;
  mod: string;
  volumeRequested: string;
  paymentCondition: string;
  note: string;
}

export const initialValidationSchemaStep2 = Yup.object({
  contractId: Yup.string().required("Selecione um contrato"),
  scheduleLikeDate: Yup.date().required("Selecione uma data para carregamento"),
  productId: Yup.string(),
  volumeRequested: Yup.string().required("Informe quantidade"),
  paymentCondition: Yup.string().required(
    "Selecione uma condição de pagamento"
  ),
});
