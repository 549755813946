import * as React from "react";
import { ReactNode, useCallback, useState } from "react";
import { Types } from "../../ioc/types";
import { GetOrdersPaginatedQuery } from "../../modules/supply/models/GetOrdersPaginatedQuery";
import { ISupplyService } from "../../modules/supply/models/ISupplyService";
import { useIocContext } from "../ioc/IocContext";
import {
  GetOrdersDTO,
  GetOrdersPaginatedByIdDTO,
} from "../../modules/supply/models/GetOrdersDTO";
import { StatusOrder } from "../../pages/ConsultQuotaOrders/models/StatusOrderIcon";
import { IDistributorsService } from "../../modules/distributors/models/IDistributorsService";
import useDialogAlert from "../../hooks/DialogAlert";

import {
  CategoryDTO,
  ModalityDTO,
  ProductDTO,
} from "../../modules/distributors/models/DistributorListDTO";
import { IEditOrder } from "../../modules/supply/models/EditOrder";
import { ICreateOrder } from "../../modules/supply/models/CreateOrder";

interface OrderQuoteContextData {
  getOrders?: (filters: GetOrdersPaginatedQuery) => Promise<GetOrdersDTO>;
  getOrdersById?: (id: string) => Promise<GetOrdersPaginatedByIdDTO>;
  getProducts?: () => Promise<ProductDTO[] | undefined>;
  getCategoryFromGroup?: () => Promise<CategoryDTO[] | undefined>;
  getModalities?: () => Promise<ModalityDTO[] | undefined>;
  setItemInView?: React.Dispatch<React.SetStateAction<string>>;
  setDataItemInView?: (data: GetOrdersPaginatedByIdDTO) => void;
  createDraft?: (
    data: ICreateOrder
  ) => Promise<GetOrdersPaginatedByIdDTO | undefined>;
  updateOrder?: (id: string, data: IEditOrder) => Promise<void>;
  commitOrderDraft?: (idOrder: string) => Promise<any>;
  ordersQuotesTotal: number;
  draftTotal: number;
  itemInView?: string;
  dataItemInView?: GetOrdersPaginatedByIdDTO;
  itemUpdate: string;
  setItemUpdate: React.Dispatch<React.SetStateAction<string>>;
  productSelected?: string;
  setProductSelected?: React.Dispatch<React.SetStateAction<string>>;
}

const OrderQuoteContext = React.createContext<OrderQuoteContextData>({
  ordersQuotesTotal: 0,
  draftTotal: 0,
  itemUpdate: "",
  setItemUpdate: () => {},
});

export const OrderQuoteProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: React.PropsWithChildren) => {
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();

  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );
  const distributorService = serviceContainer.get<IDistributorsService>(
    Types.Distributors.IDistributors
  );

  const [draftTotal, setDraftTotal] = useState<number>(0);
  const [ordersQuotesTotal, setOrdersQuotesTotal] = React.useState<number>(0);
  const [itemInView, setItemInView] = React.useState("");
  const [itemUpdate, setItemUpdate] = React.useState("");
  const [productSelected, setProductSelected] = React.useState("");
  const [dataItemInView, setDataItemInView] =
    React.useState<GetOrdersPaginatedByIdDTO>({});

  const getOrders = useCallback(async (filters: GetOrdersPaginatedQuery) => {
    const response = await supplyService.getOrdersPaginated(filters);

    switch (filters.status) {
      case StatusOrder.sent: {
        setOrdersQuotesTotal(response.total ?? 0);
        break;
      }
      case StatusOrder.customerDraft: {
        setDraftTotal(response.total ?? 0);
        break;
      }
      default: {
        setOrdersQuotesTotal(response.total ?? 0);
      }
    }
    return response;
  }, []);

  const getProducts = useCallback(async () => {
    try {
      const response = await distributorService.getProducts();
      return response;
    } catch (error) {
      snackbar({
        message: "Erro ao carregar lista de produtos. Tentar novamente",
        variant: "error",
      });
    }
  }, []);

  const getModalities = useCallback(async () => {
    try {
      const response = await distributorService.getModality();
      return response;
    } catch (error) {
      snackbar({
        message: "Erro ao carregar lista de modalidades. Tentar novamente",
        variant: "error",
      });
    }
  }, []);

  const getCategoryFromGroup = useCallback(async () => {
    try {
      const response = await distributorService.getCategoryFromGroup();
      return response;
    } catch (error) {
      snackbar({
        message: "Erro ao carregar lista de categoria. Tentar novamente",
        variant: "error",
      });
    }
  }, []);

  const getOrdersById = useCallback(
    async (id: string) => {
      const response = await supplyService.getOrdersPaginatedById(id);
      setDataItemInView(response);
      return response;
    },
    [setDataItemInView]
  );

  const createDraft = useCallback(async (data: ICreateOrder) => {
    try {
      const response = await supplyService.createOrderDraft(data);
      return response;
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: "error",
      });
      snackbar({
        message: "Erro ao salvar pedido de cota em rascunho.",
        variant: "error",
      });
    }
  }, []);

  const commitOrderDraft = useCallback(async (idOrder: string) => {
    const response = await supplyService.commitOrder(idOrder);
    return response;
  }, []);

  const updateOrder = useCallback(async (idOrder: string, data: IEditOrder) => {
    try {
      await supplyService.editOrder(idOrder, data);
      snackbar({
        message: "Pedido de cota alterado com sucesso",
        variant: "success",
      });
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: "error",
      });
      snackbar({
        message: "Erro ao editar pedido de cota",
        variant: "error",
      });
    }
  }, []);

  React.useEffect(() => {
    if (itemInView !== "") {
      getOrdersById(itemInView);
    }

    if (itemUpdate !== "") {
      getOrdersById(itemUpdate);
    }
  }, [itemInView, getOrdersById, itemUpdate]);

  const OrderQuoteProviderData = React.useMemo(
    () => ({
      getOrders,
      getOrdersById,
      getModalities,
      getCategoryFromGroup,
      getProducts,
      setItemInView,
      setDataItemInView,
      createDraft,
      updateOrder,
      commitOrderDraft,
      ordersQuotesTotal,
      draftTotal,
      itemInView,
      dataItemInView,
      productSelected,
      setProductSelected,
      setItemUpdate,
      itemUpdate,
    }),
    [
      getOrders,
      getOrdersById,
      getModalities,
      getProducts,
      getCategoryFromGroup,
      setItemInView,
      setDataItemInView,
      createDraft,
      updateOrder,
      commitOrderDraft,
      ordersQuotesTotal,
      draftTotal,
      ordersQuotesTotal,
      draftTotal,
      itemInView,
      dataItemInView,
      productSelected,
      setProductSelected,
      setItemUpdate,
      itemUpdate,
    ]
  );

  return (
    <OrderQuoteContext.Provider value={OrderQuoteProviderData}>
      {children}
    </OrderQuoteContext.Provider>
  );
};

export const useOrderQuote = () => {
  const context = React.useContext(OrderQuoteContext);
  if (context === undefined) {
    throw new Error(
      "useOrderQuote não pode ser utilizado fora de um AuthProvider"
    );
  }
  return context;
};
