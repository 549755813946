import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface OrderStatusProgressProps {
  statusList?: Array<{
    icon: React.ReactNode;
    description: string;
    isMarked?: boolean;
  }>;
}

const OrderStatusProgress: React.FC<OrderStatusProgressProps> = ({
  statusList,
}) => {
  const firstText = useRef<HTMLParagraphElement>(null);
  const lastText = useRef<HTMLParagraphElement>(null);
  const [widthFirst, setWidthFirst] = useState(10);
  const [widthLast, setWidthLast] = useState(10);

  useEffect(() => {
    const textElementFisrt = firstText.current;
    const textElementLast = lastText.current;
    setWidthFirst(textElementFisrt?.offsetWidth ?? 0);
    setWidthLast(textElementLast?.offsetWidth ?? 0);
  }, []);

  return (
    <Box display={"flex"} flex={1} flexDirection={"column"} width={"100%"}>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          pl: `${widthFirst / 2 - 10}px`,
          pr: `${widthLast / 2 - 10}px`,
        }}
      >
        {statusList?.map((item, index) => (
          <>
            <Grid key={`${index}ico`} item display={"flex"}>
              {item.icon}
            </Grid>
            {index + 1 < statusList.length && (
              <Grid key={`${index}border`} item alignItems="center" flex={1}>
                <Box
                  key={`${index}boxBorder`}
                  border={0.8}
                  borderColor={"shadesOfDark.dark"}
                />
              </Grid>
            )}
          </>
        ))}
      </Grid>
      <Box display={"flex"} justifyContent={"space-between"}>
        {statusList?.map((item, index) => (
          <Box key={`${index}box`}>
            <Typography
              ref={
                index === 0
                  ? firstText
                  : index === statusList.length - 1
                  ? lastText
                  : null
              }
              key={`${index}type`}
              mt={0.8}
              fontFamily={"Open Sans"}
              fontSize={12}
              fontWeight={item.isMarked ? 700 : 400}
              noWrap
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OrderStatusProgress;
