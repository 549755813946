import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableCommonHeaderColumns: Column[] = [
  {
    label: "ID",
    id: "id",
    align: "left",
    showIcon: true,
    sortable: true,
  },
  {
    label: "Razão social",
    id: "customerCompany",
    align: "left",
    showIcon: true,
  },
  {
    label: "CNPJ",
    id: "cnpj",
    align: "left",
    showIcon: true,
  },
  {
    label: "Solicitante",
    id: "customerRequester",
    align: "left",
    showIcon: true,
  },
  {
    label: "Data de Solicitação",
    id: "createdAt",
    align: "left",
    showIcon: true,
  },
  {
    label: "Última atualização",
    id: "updatedAt",
    align: "left",
    showIcon: true,
  },
];

export const TableIconHeaderColumn: Column[] = [
  {
    label: "",
    id: "icon",
    align: "center",
  },
];

export const TablePlacedHeaderColumns: Column[] = [
  ...TableCommonHeaderColumns,

  {
    label: "",
    id: "status",
    align: "center",
  },
  ...TableIconHeaderColumn,
];
