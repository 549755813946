export type RedirectPage = "viewOrder" | "deleteOrder" | "updateOrder";

export enum orderMenuStatus {
  VIEW_ORDER = "viewOrder",
  DELETE_ORDER = "deleteOrder",
  UPDATE_ORDER = "updateOrder",
}

export interface IOptionItem {
  label: string;
  type: RedirectPage;
}

export const CommonMenuList: IOptionItem[] = [
  {
    label: "Ver pedido",
    type: orderMenuStatus.VIEW_ORDER,
  },
];

export const OptionsMenuList: IOptionItem[] = [
  ...CommonMenuList,
  {
    label: "Excluir pedido",
    type: orderMenuStatus.DELETE_ORDER,
  },
];

export const UpdateMenuList: IOptionItem[] = [
  ...CommonMenuList,
  {
    label: "Editar pedido",
    type: orderMenuStatus.UPDATE_ORDER,
  },
];
