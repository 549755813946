import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

export interface ConsumptionProgressProps {
  volumeConsumed: string;
  percentageConsumed: number;
  label: string;
  levelQuota?: ConsumptionProgressQuotaLevel;
}

const ConsumptionProgress: React.FC<ConsumptionProgressProps> = ({
  percentageConsumed,
  volumeConsumed,
  label,
  levelQuota = ConsumptionProgressQuotaLevel.BELOW,
}) => {
  const newPercentage = `${percentageConsumed}%`;

  const barColor = () => {
    switch (levelQuota) {
      case ConsumptionProgressQuotaLevel.INSIDE:
        return "#8A8D93";
      case ConsumptionProgressQuotaLevel.BELOW:
        return "#E83F48";
      case ConsumptionProgressQuotaLevel.ABOVE:
        return "#FFA301";
    }
  };

  return (
    <Box>
      <Typography textAlign={"right"} fontWeight={600}>
        {label}
      </Typography>
      <Tooltip title={volumeConsumed} arrow>
        <Box bgcolor={"#f1f1f2"} mt={1}>
          <Box height={10} bgcolor={barColor()} width={newPercentage}></Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ConsumptionProgress;

export enum ConsumptionProgressQuotaLevel {
  INSIDE,
  ABOVE,
  BELOW,
}
