export const routesConfig = {
  HOME: "/dashboard",
  EXECUTIVE: () => {
    const module = "/executive";

    return {
      PRICE: `${module}/precos`,
      MONTHLY_PARAMETERS: `${module}/parametros-mensais`,
      OPERATING_CONDITIONS: `${module}/condicoes-operacionais`,
    };
  },

  FINANCIAL: () => {
    const module = "/financeiro";

    return {
      INVOICES: `${module}/notas`,
      MOVEMENT: `${module}/movimentacao`,
      STATEMENT: `${module}/extrato`,
      TAX_REIMBURSEMENT: `${module}/ressarcimento-fiscal`,
      TAX_REIMBURSEMENT_REQUEST: `${module}/novo-ressarcimento-fiscal`,
    };
  },

  SUPPLIES: () => {
    const module = "/suprimentos";

    return {
      FINANCIAL_MOVEMENT: `${module}/extratocotadia`,
      QUOTE_EXTRACT: `${module}/extratocota`,
      QUOTE_BALANCE: `${module}/saldocota`,
      QUOTE: `${module}/cota`,
      REQUEST: `${module}/cota/solicitacao`,
    };
  },

  OPERATIONAL: () => {
    const module = "/operacional";

    return {
      PIPELINES: `${module}/dutoviarias`,
      PIPELINES_REQUEST: `${module}/dutoviarias/solicitacao`,
      ROAD_STATIONS: `${module}/rodoviarias`,
      ROAD_STATIONS_REQUEST: `${module}/rodoviarias/solicitacao`,
      RIVER: `${module}/fluviais`,
      RIVER_REQUEST: `${module}/fluviais/solicitacao`,
      MARITIME: `${module}/maritima`,
      PROGRAMMING: `${module}/programacao`,
    };
  },

  INFORMATION: () => {
    const module = "/informacao";
    return {
      WARNINGS: `${module}/informacao`,
      SAC: `${module}/sac`,
      SAC_REQUEST: `${module}/sac/novo-atendimento`,
      SAC_DETAILS: `${module}/sac/detalhes`,
    };
  },
  HITS: "/acessos",
};
