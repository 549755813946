import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

export interface DragAndDropProps {
  onDrop?: (item: { files: any[] }) => void;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ onDrop }) => {
  const theme = useTheme();

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: any) {
        return true;
      },
      hover(item: any) {},
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );
  const isActive = canDrop && isOver;

  return (
    <Grid item xs={12} md={12}>
      <Grid
        item
        xs={12}
        md={12}
        mt={1}
        ref={drop}
        padding={2}
        display="flex"
        justifyContent="center"
        justifyItems="center"
        style={{
          borderRadius: 4,
          background: isActive ? "#efefef" : "none",
          borderWidth: isActive ? 2 : 1,
          borderColor: "#D2D3D6",
          borderStyle: "dashed",
        }}
      >
        <label
          style={{
            display: "flex",
            justifyItems: "center",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            hidden
            onChange={(file) => {
              onDrop &&
                onDrop({
                  files: [...(file.target.files as unknown as File[])],
                });
            }}
          />
          <StyledIcon iconType="upload" mr={1} />
          {isActive ? (
            <Typography color={`${theme.palette.shadesOfDark.dark}`}>
              Solte aqui
            </Typography>
          ) : (
            <Typography color={`${theme.palette.shadesOfDark.dark}`}>
              Arraste e solte o arquivo aqui ou
              <b> selecione do seu computador</b>
            </Typography>
          )}
        </label>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography
          color={`${theme.palette.shadesOfDark.dark}`}
          fontSize={10}
          fontFamily="Open Sans"
        >
          Formatos suportados: JPEG, JPG, PNG, PDF...
        </Typography>
        <Typography
          color={`${theme.palette.shadesOfDark.dark}`}
          fontSize={10}
          fontFamily="Open Sans"
        >
          Tamanho máximo: 100GB
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DragAndDrop;
