import * as React from "react";
import { Box } from "@mui/material";

interface DeliveryTableRowProps {
  children?: React.ReactNode;
}

const DeliveryTableRow: React.FC<DeliveryTableRowProps> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      color={"rgba(58, 53, 65, 0.87)"}
      justifyContent={"center"}
      alignItems={"center"}
      borderTop={"0.5px solid #94A3B8"}
    >
      {children}
    </Box>
  );
};

export default DeliveryTableRow;
