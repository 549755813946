import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useDialogAlert from "../../hooks/DialogAlert";
import { getCurrentMonthYear, getThousand } from "../../utils";
import {
  DateToFormat,
  convertToISO,
  getCurrentDateMinusThirtyDays,
} from "../../utils/dateFormatter";
import { PipelineScheduleStatus } from "../../modules/operational/models/GetPipelineSchedulesDTO";
import { IGetPipelineSchedulesQuery } from "../../modules/operational/models/GetPipelineSchedulesQuery";
import {
  openedInitQuery,
  rejectedInitQuery,
  clearedInitQuery,
} from "../../pages/Home/models/default";
import { useIocContext } from "../ioc/IocContext";
import { IOperationalService } from "../../modules/operational/models/IOperationalService";
import { Types } from "../../ioc/types";
import { IAnalyticsMap, ITableHome } from "./models/homeModels";
import { useDistributor } from "../distributor/DistributorContext";
import { IHomeService } from "../../modules/home/models/IHomeService";

interface HomeContextDataProps {
  dataPipelineSchedules: ITableHome[];
  setDataPipelineSchedules: React.Dispatch<React.SetStateAction<ITableHome[]>>;
  totalPipelineSchedules: number;
  setTotalPipelineSchedules: React.Dispatch<React.SetStateAction<number>>;
  setQueryPipelineSchedules?: React.Dispatch<
    React.SetStateAction<IGetPipelineSchedulesQuery>
  >;
  queryPipelineSchedules?: IGetPipelineSchedulesQuery;
  isLoadingPipelineSchedules?: boolean;

  dataRoadlineSchedules?: ITableHome[];
  setDataRoadlineSchedules?: React.Dispatch<React.SetStateAction<ITableHome[]>>;
  totalRoadlineSchedules?: number;
  setTotalRoadlineSchedules?: React.Dispatch<React.SetStateAction<number>>;
  setQueryRoadlineSchedules?: React.Dispatch<
    React.SetStateAction<IGetPipelineSchedulesQuery>
  >;
  queryRoadlineSchedules?: IGetPipelineSchedulesQuery;
  isLoadingRoadlineSchedules?: boolean;
  companyDescription?: string;
  dataAnalytics?: IAnalyticsMap;
  isLoadingDataAnalytics?: boolean;
}

const HomeContext = createContext<HomeContextDataProps>({
  dataPipelineSchedules: [],
  setDataPipelineSchedules: () => {},
  totalPipelineSchedules: 0,
  setTotalPipelineSchedules: () => 0,
});

export const HomeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: React.PropsWithChildren) => {
  const { snackbar } = useDialogAlert();
  const { selectedDistributor } = useDistributor();

  const companyDescription = selectedDistributor
    ? selectedDistributor.description
    : "";
  const companyId = selectedDistributor ? selectedDistributor.id : "";

  const [totalPipelineSchedules, setTotalPipelineSchedules] = useState(0);
  const [isLoadingPipelineSchedules, setIsLoadingPipelineSchedules] =
    useState(false);
  const [dataPipelineSchedules, setDataPipelineSchedules] = useState<
    ITableHome[]
  >([]);
  const [queryPipelineSchedules, setQueryPipelineSchedules] =
    useState<IGetPipelineSchedulesQuery>({
      ...openedInitQuery,
      scheduleDateFrom: getCurrentDateMinusThirtyDays(),
      scheduleDateTo: convertToISO(new Date()),
      status: `${openedInitQuery.status ?? ""},${
        clearedInitQuery.status ?? ""
      },${rejectedInitQuery.status ?? ""}`,
    });

  const [totalRoadlineSchedules, setTotalRoadlineSchedules] = useState(0);
  const [isLoadingRoadlineSchedules, setIsLoadingRoadlineSchedules] =
    useState(false);
  const [dataRoadlineSchedules, setDataRoadlineSchedules] = useState<
    ITableHome[]
  >([]);
  const [dataAnalytics, setAnalytics] = useState<IAnalyticsMap>();
  const [isLoadingDataAnalytics, setIsLoadingDataAnalytics] = useState(false);
  const [queryRoadlineSchedules, setQueryRoadlineSchedules] =
    useState<IGetPipelineSchedulesQuery>({
      ...openedInitQuery,
      scheduleDateFrom: getCurrentDateMinusThirtyDays(),
      scheduleDateTo: convertToISO(new Date()),
      status: `${openedInitQuery.status ?? ""},${
        clearedInitQuery.status ?? ""
      },${rejectedInitQuery.status ?? ""}`,
    });

  useEffect(() => {
    setQueryPipelineSchedules((prevQuery) => ({
      ...prevQuery,
      ...(companyId && { customerCompanyId: companyId }),
      page: 1,
    }));

    setQueryRoadlineSchedules((prevQuery) => ({
      ...prevQuery,
      ...(companyId && { customerCompanyId: companyId }),
      page: 1,
    }));
  }, [companyId]);

  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const homeService = serviceContainer.get<IHomeService>(
    Types.Home.IHomeService
  );

  const getPipelineSchedules = useCallback(async () => {
    if (!companyId) return;
    try {
      setIsLoadingPipelineSchedules(true);
      const response = await operationalService.getPipelineSchedules(
        queryPipelineSchedules
      );
      const newData: ITableHome[] =
        response.rows?.map((item) => ({
          id: item.id ?? "-",
          refId: item.refId ?? "-",
          local: item.contract?.subsidiary?.description ?? "-",
          product: item.contract?.product?.description ?? "-",
          status: item.status ?? PipelineScheduleStatus.VENDOR_PENDING,
          dueDate: DateToFormat(item.scheduleDate) ?? "-",
          quantidade: getThousand(item.volumeRequested ?? 0)?.toString(),
        })) ?? [];

      const count = response.count ?? 0;
      setTotalPipelineSchedules(count);
      setDataPipelineSchedules(newData);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar entregas dutoviárias",
        variant: "error",
      });
    } finally {
      setIsLoadingPipelineSchedules(false);
    }
  }, [queryPipelineSchedules, companyId]);

  const getRoadShedules = useCallback(async () => {
    if (!companyId) return;
    try {
      setIsLoadingRoadlineSchedules(true);
      const response = await operationalService.getRoadlineSchedules(
        queryRoadlineSchedules
      );

      const newData: ITableHome[] =
        response.rows?.map((item) => ({
          id: item.id ?? "-",
          refId: item.refId ?? "-",
          local: item.contract?.subsidiary?.description ?? "-",
          product: item.contract?.product?.description ?? "-",
          status: item.status ?? PipelineScheduleStatus.VENDOR_PENDING,
          dueDate: DateToFormat(item.scheduleDate) ?? "-",
          quantidade: getThousand(item.volumeRequested ?? 0)?.toString(),
        })) ?? [];

      const count = response.count ?? 0;
      setTotalRoadlineSchedules(count);
      setDataRoadlineSchedules(newData);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar entregas rodoviárias",
        variant: "error",
      });
    } finally {
      setIsLoadingRoadlineSchedules(false);
    }
  }, [queryRoadlineSchedules, companyId]);

  const getContractsAnalytics = useCallback(async () => {
    if (!companyId) return;

    try {
      setIsLoadingDataAnalytics(true);
      const response = await homeService.getContractsAnalytics({
        monthReference: getCurrentMonthYear(),
        customerCompanyId: companyId,
      });

      const newData: IAnalyticsMap = {
        dataSubsidiary:
          response.entries?.map((itemResponse) => ({
            subsidiaryId: itemResponse.subsidiary.id,
            subsidiaryDescription: itemResponse.subsidiary.description,

            companyId: itemResponse.company.id,
            companyDescription: itemResponse.company.description,

            totalSubsidiaryVolume: itemResponse.total.volumeAfterChanges,
            totalSubsidiaryVolumeConsumed: itemResponse.total.withdrawn,

            entries: itemResponse.entries?.map((item) => ({
              entriesId: item.id,
              refId: item.refId,
              productTitle: item.product.description,
              productDescription: item.product.productGroup,
              productTotalVolume: item.volumeAfterChanges,
              productPercentageConsumed: parseFloat(item.percentageConsumed),
              productVolumeConsumed: item.withdrawn,
            })),
          })) ?? [],
        totalCompanyVolumeConsumed: response.total?.withdrawn ?? 0,
        totalCompanyVolume: response.total?.volumeAfterChanges ?? 0,
      };

      setAnalytics(newData);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar dados",
        variant: "error",
      });
    } finally {
      setIsLoadingDataAnalytics(false);
    }
  }, [companyId]);

  useEffect(() => {
    getRoadShedules();
  }, [queryRoadlineSchedules]);

  useEffect(() => {
    getContractsAnalytics();
  }, [companyId]);

  useEffect(() => {
    getPipelineSchedules();
  }, [queryPipelineSchedules]);

  const HomeProviderData = useMemo(
    () => ({
      totalPipelineSchedules,
      setTotalPipelineSchedules,
      dataPipelineSchedules,
      setDataPipelineSchedules,
      queryPipelineSchedules,
      setQueryPipelineSchedules,
      isLoadingPipelineSchedules,
      totalRoadlineSchedules,
      setTotalRoadlineSchedules,
      dataRoadlineSchedules,
      setDataRoadlineSchedules,
      queryRoadlineSchedules,
      setQueryRoadlineSchedules,
      isLoadingRoadlineSchedules,
      companyDescription,
      dataAnalytics,
      isLoadingDataAnalytics,
    }),
    [
      totalPipelineSchedules,
      setTotalPipelineSchedules,
      dataPipelineSchedules,
      setDataPipelineSchedules,
      queryPipelineSchedules,
      setQueryPipelineSchedules,
      isLoadingPipelineSchedules,
      totalRoadlineSchedules,
      setTotalRoadlineSchedules,
      dataRoadlineSchedules,
      setDataRoadlineSchedules,
      queryRoadlineSchedules,
      setQueryRoadlineSchedules,
      isLoadingRoadlineSchedules,
      companyDescription,
      dataAnalytics,
      isLoadingDataAnalytics,
    ]
  );

  return (
    <HomeContext.Provider value={HomeProviderData}>
      {children}
    </HomeContext.Provider>
  );
};

export const useHome = () => {
  const context = useContext(HomeContext);
  if (context === undefined) {
    throw new Error("useHome não pode ser utilizado fora de um HomeProvider");
  }
  return context;
};
