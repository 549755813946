import { Skeleton, TableContainer } from "@mui/material";
import { Box } from "@mui/system";
import CardHeader from "../CardHeader/CardHeader";
import CustomCard from "../CustomCard/CustomCard";
import DeliveryTableFooter from "../DeliveryTableFooter/DeliveryTableFooter";
import DeliveryTableRow from "../DeliveryTableRow/DeliveryTableRow";
import { calcPagesQuantity } from "../../utils";
import {
  IOption,
  ROW_PER_PAGE,
  TableColumn,
} from "../../pages/Home/models/default";
import CardDataNotFound from "../CardDataNotFound/CardDataNotFound";

export interface IRowContent {
  id: number;
  [key: string]: any;
}

interface DeliveryTableProps {
  columns?: TableColumn[];
  data?: IRowContent[];
  titleTable: string;
  subTitleTable: string;
  totalTable: number;
  totalLine: number;
  currentPage: number;
  onChangePage: (newPage: number) => void;
  isLoadingData: boolean;
  options: IOption[];
  handleOptions: (option: IOption[]) => void;
}

const DeliveryTable: React.FC<DeliveryTableProps> = ({
  columns,
  data,
  titleTable,
  subTitleTable,
  totalTable,
  totalLine,
  currentPage,
  onChangePage,
  isLoadingData,
  options,
  handleOptions,
}) => {
  const handleChangePage = (newPage: number) => {
    onChangePage(newPage);
  };

  const RANGE_NUMBER_PAGE = 5;

  const TableLoading: React.FC = () => {
    return (
      <Box>
        {Array.from(Array(RANGE_NUMBER_PAGE)).map((item, index) => (
          <Box key={index} display={"flex"} border={"1px solid #DFE0EB"}>
            {columns?.map((column) => (
              <Box
                key={column.key}
                display={"flex"}
                p={"1rem"}
                flex={column.id ?? 1}
              >
                <Skeleton
                  key={item}
                  variant="rectangular"
                  height={"3rem"}
                  width={"100%"}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <CustomCard sx={{ minHeight: 250 }}>
      <CardHeader
        title={titleTable}
        subTitle={subTitleTable}
        tableDropDownProps={{
          isDropDownArrow: false,
          title: "Status",
          options: options,
          getOptionLabel: (option) => option,
          onChange: (index, _selectedValue, oldChecked) => {
            const newValues: IOption[] = [...options];
            newValues[index].isChecked = !oldChecked;
            handleOptions(newValues);
          },
        }}
      />
      <TableContainer>
        <Box sx={{ minWidth: 400, minHeight: 250 }} aria-label="caption table">
          <Box>
            {isLoadingData ? (
              <TableLoading />
            ) : data?.length ? (
              data.map((row, id) => (
                <DeliveryTableRow key={id}>
                  {columns?.map((column) => (
                    <Box
                      key={column.key}
                      px={2}
                      py={0.5}
                      display={"flex"}
                      flex={1}
                      justifyContent={"left"}
                    >
                      {row[column.key]}
                    </Box>
                  ))}
                </DeliveryTableRow>
              ))
            ) : (
              <Box alignSelf="center">
                <CardDataNotFound
                  icon="search-empty"
                  title=""
                  subtitle="Não foram encontrados pedidos para esse cliente."
                />
              </Box>
            )}
          </Box>
        </Box>
      </TableContainer>
      <DeliveryTableFooter
        displayedResults={currentPage ?? 0}
        pagesTotalTable={totalTable ?? 0}
        pagesTotalLine={totalLine}
        pagesQuantity={calcPagesQuantity(ROW_PER_PAGE, totalTable ?? 0)}
        onChangePage={handleChangePage}
      />
    </CustomCard>
  );
};

export default DeliveryTable;
