import { IQueryTableColumn } from "../../../components/QueryTable/QueryTable";

export const ColumnsList: IQueryTableColumn[] = [
  {
    id: 0,
    name: "Produtos",
    key: "product",
    flex: 3,
    align: "left",
    boxProps: {
      pl: 4,
    },
  },
  { id: 1, name: "Mês", key: "month", flex: 1, align: "left" },
  {
    id: 2,
    name: "Ações",
    key: "actions",
    flex: 1,
    boxProps: {
      pr: 4,
    },
  },
];
