import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Pagination, { PaginationProps } from "../Pagination/Pagination";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { ContentCopy } from "@mui/icons-material";
const ROW_HEIGHT = 56.8;

export interface Column {
  id: string;
  label: string;
  align?: "left" | "center" | "right";
  sortable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
  showIcon?: boolean;
}

interface Props {
  columns: Column[];
  data: any[];
  rowsPerPage?: number;
  isSortable?: boolean;
  onHeaderClick?: (columnId: string) => void;
  loadFromServer?: boolean;
  isLoadingData?: boolean;
  onChangePage?: (newPage: number) => void;
  paginationProps?: PaginationProps;
  resultsTotal?: number;
}

function TablePaginated({
  columns,
  data,
  rowsPerPage = 10,
  onHeaderClick,
  loadFromServer = false,
  isLoadingData = false,
  onChangePage,
  paginationProps,
  resultsTotal,
}: Props) {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<string>(columns[0].id);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [open, setOpen] = useState(false);
  const [noteNumberValue, setNoteNumberValue] = useState("");

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    const orderMultiplier = order === "asc" ? 1 : -1;
    if (a[orderBy] < b[orderBy]) {
      return -1 * orderMultiplier;
    }
    if (a[orderBy] > b[orderBy]) {
      return 1 * orderMultiplier;
    }
    return 0;
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (onChangePage) onChangePage(newPage + 1);
  };

  interface TableCellWrapperProps {
    align?: "left" | "center" | "right";
  }

  const TableCellWrapper = styled("div")((props: TableCellWrapperProps) => {
    let newAlign = "center";

    switch (props.align) {
      case "left":
        newAlign = "flex-start";
        break;
      case "right":
        newAlign = "flex-end";
        break;
      default:
        newAlign = "center";
        break;
    }

    return {
      display: "flex",
      alignItems: "center",
      justifyContent: newAlign,
    };
  });

  const firstRowIndex = page * rowsPerPage + 1;
  const lastRowIndex = page * rowsPerPage + sortedData.length;
  resultsTotal = loadFromServer ? resultsTotal : sortedData.length;
  const rowCountText = `${firstRowIndex}-${lastRowIndex} de ${
    resultsTotal ?? 0
  }`;
  const paddingHeight = ROW_HEIGHT * (rowsPerPage - sortedData.length);

  const LoadingData = () => {
    return Array.from({ length: rowsPerPage }, (_, index) => (
      <TableRow key={`${index}-loading`} sx={{ width: "90%" }}>
        {columns.map((column) => (
          <TableCell key={`${index}-${column.id}-loading`}>
            <Skeleton variant="rectangular" height={"3rem"} width={"100%"} />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const copyToClipboard = (textToCopy: string) => {
    setOpen(true);

    navigator.clipboard.writeText(textToCopy);
    setNoteNumberValue(textToCopy);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={`${column.id}-header`}
                  onClick={() => {
                    if (column.sortable) {
                      handleSort(column.id);
                    } else if (onHeaderClick) {
                      onHeaderClick(column.id);
                    }
                  }}
                  sx={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: "16px",
                    color: "shadesOfDark.dark",
                    cursor: "pointer",
                  }}
                >
                  <TableCellWrapper align={column.align}>
                    {column.label}
                    {column.showIcon && (
                      <StyledIcon
                        iconType="arrow-sort"
                        sx={{
                          width: "5.66px",
                          height: "13.33px",
                          marginLeft: "6px",
                          visibility:
                            orderBy === column.id ? "visible" : "hidden",
                        }}
                      />
                    )}
                  </TableCellWrapper>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingData
              ? LoadingData()
              : (!loadFromServer
                  ? sortedData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => (
                  <TableRow key={`${index}-row`} sx={{ width: "90%" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "24px",
                          color: "shadesOfDark.dark",
                        }}
                      >
                        {row[column.id]}
                        {(column.id === "noteNumber" ||
                          column.id === "document") && (
                          <IconButton
                            onClick={() => copyToClipboard(row[column.id])}
                          >
                            <ContentCopy sx={{ width: "20px" }} />
                          </IconButton>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

            {paddingHeight > 0 && !isLoadingData && (
              <TableRow
                style={{
                  height: paddingHeight,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        message={"Nota fiscal nº "
          .concat(noteNumberValue ?? "")
          .concat(" copiada!")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Grid
        container
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {paginationProps && (
          <Grid item>
            <Typography
              variant="caption"
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "shadesOfDark.dark",
              }}
            >
              {rowCountText}
            </Typography>
          </Grid>
        )}
        {paginationProps && (
          <Grid
            item
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              onPageChange={handleChangePage}
              showFirstButton
              showLastButton
              {...paginationProps}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default TablePaginated;
