import { Container } from "inversify";
import { ISupplyService } from "./../modules/supply/models/ISupplyService";
import { ExecutiveService } from "../modules/executive/ExecutiveService";
import { IExecutiveService } from "../modules/executive/models/IExecutiveService";
import HttpClientService from "../modules/http/HttpClientService";
import { IHttpClientService } from "../modules/http/models/IHttpClientService";
import { Types } from "./types";
import { IFinancialService } from "../modules/financial/models/IFinancialService";
import { FinancialService } from "../modules/financial/FinancialService";
import { IDistributorsService } from "../modules/distributors/models/IDistributorsService";
import { DistributorsService } from "../modules/distributors/DistributorsService";
import { SupplyService } from "../modules/supply/supplyService";
import { OperationalService } from "../modules/operational/OperationalService";
import { IOperationalService } from "../modules/operational/models/IOperationalService";
import { IInformationService } from "../modules/information/models/IInformationService";
import { InformationService } from "../modules/information/informationService";
import { IHomeService } from "../modules/home/models/IHomeService";
import { HomeService } from "../modules/home/homeService";

const appIocContainer = new Container({ defaultScope: "Singleton" });

appIocContainer
  .bind<IHttpClientService>(Types.IHttpService)
  .to(HttpClientService);

appIocContainer
  .bind<IExecutiveService>(Types.Executive.IExecutiveService)
  .to(ExecutiveService);

appIocContainer
  .bind<IFinancialService>(Types.Financial.IFinancialService)
  .to(FinancialService);

appIocContainer
  .bind<IDistributorsService>(Types.Distributors.IDistributors)
  .to(DistributorsService);

appIocContainer
  .bind<ISupplyService>(Types.Supply.ISupplyService)
  .to(SupplyService);

appIocContainer
  .bind<IOperationalService>(Types.Operational.IOperationalService)
  .to(OperationalService);

appIocContainer
  .bind<IInformationService>(Types.Information.IInformationService)
  .to(InformationService);

appIocContainer.bind<IHomeService>(Types.Home.IHomeService).to(HomeService);

export { appIocContainer };
