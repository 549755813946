import { Box } from "@mui/material";
import React, { useState } from "react";
import AppBar, { AppBarProps } from "../AppBar/AppBar";
import SideBar from "../SideBar/SideBar";

interface LayoutProps {
  children?: React.ReactNode;
  enableMargin?: boolean;
  appBarProps?: AppBarProps;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  enableMargin = false,
  appBarProps,
}) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <Box display={"flex"}>
      <AppBar
        {...appBarProps}
        onMenuClick={() => setOpenSidebar(!openSidebar)}
      />
      <SideBar open={openSidebar} onClose={() => setOpenSidebar(false)} />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: enableMargin ? 3 : 0,
        }}
      >
        <Box minHeight={100}></Box>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
