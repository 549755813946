import { OperationModeType } from "../../../pages/RoadScheduleRequest/Section/RoadScheduleRequestStep1";
import { DriverDTO } from "./GetDriversDTO";

export interface GetPipelineSchedulesDTO {
  rows?: Row[];
  count?: number;
}

export interface Row {
  id?: string;
  refId?: string;
  externalId?: any;
  customerCompanyId?: string;
  subsidiaryId?: string;
  paymentCondition?: string;
  status?: PipelineScheduleStatus;
  monthReference?: string;
  customerRequesterNote?: string;
  scheduleDate?: string;
  scheduleTime?: string;
  predictedTime?: number;
  vehicleBundle: VehicleBundle;
  volumeRequested?: number;
  volumeProvided?: number;
  volumeEffective?: number;
  vendorAuthorityJustification?: any;
  vendorAuthorityNote?: any;
  customerRequesterId?: string;
  vendorAuthorityId?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  contractId?: string;
  contract?: ContractModel;
  vendorAuthority?: string;
  customerRequester?: string;
  paymentConditionDescription?: string;
  suggestedVolume?: number;
  shippingCompany?: CustomerCompany;
  driver?: DriverDTO;
  billedTotal: number;
  customerInvoiceReference?: string;
  attachments?: [File];
  type?: OperationModeType;
}

export interface File {
  fileKey: string;
  fileName: string;
  fileType: string;
}

export interface ContractModel {
  id?: string;
  contractRequestId?: string;
  contractRequestEntryId?: string;
  productId?: string;
  subsidiaryId?: string;
  volumeBalance?: number;
  volumeRequested?: number;
  volumeProvided?: number;
  mod?: string;
  status?: PipelineScheduleStatus;
  customerRequesterId?: string;
  customerAuthorityId?: string;
  vendorAuthorityId?: string;
  monthReference?: string;
  customerCompanyId?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  product?: ProductModel;
  customerCompany?: CustomerCompany;
  subsidiary?: Subsidiary;
}

export interface ProductModel {
  id?: string;
  description?: string;
  externalId?: string;
  productGroup?: any;
  measurementUnit?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface CustomerCompany {
  id?: string;
  description?: string;
  externalId?: string;
  document?: string;
  metadata?: Metadata2;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  headquartersCustomerCompanyId?: string;
}

export interface Metadata2 {
  UF?: string;
}

export interface Subsidiary {
  id?: string;
  description?: string;
  externalId?: string;
  document?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface VehicleBundle {
  plate?: string;
  compartments?: Compartment[];
}

export interface Compartment {
  plate?: string;
  type?: string;
}

export enum PipelineScheduleStatus {
  INVOICE_PENDING = "INVOICE_PENDING",
  VENDOR_APPROVED = "VENDOR_APPROVED",
  INVOICE_BLOCKED = "INVOICE_BLOCKED",
  FINANCIAL_BLOCKED = "FINANCIAL_BLOCKED",
  IN_SCHEDULING = "IN_SCHEDULING",
  IN_LOADING = "IN_LOADING",

  VENDOR_REJECTED = "VENDOR_REJECTED",

  INVOICE_CANCELED = "INVOICE_CANCELED",

  VENDOR_PENDING = "VENDOR_PENDING",

  INVOICE_CLEARED = "INVOICE_CLEARED",
}
