import { interfaces } from "inversify";
import React, { createContext, useContext } from "react";
import { appIocContainer } from "../../ioc/ioc";

export interface IIocContext {
  serviceContainer: interfaces.Container;
}
const initServiceContainer: IIocContext = {
  serviceContainer: appIocContainer,
};
const IocContext = createContext<IIocContext>(initServiceContainer);

interface IocContextProps {
  children: React.ReactNode;
}

const IocProvider: React.FC<IocContextProps> = ({ children }) => {
  return (
    <IocContext.Provider value={{ serviceContainer: appIocContainer }}>
      {children}
    </IocContext.Provider>
  );
};

const useIocContext = (): IIocContext => {
  const context = useContext(IocContext);

  if (!Object.keys(context).length) {
    throw new Error("Ioc Context must be used inside an IocProvider component");
  }
  return context;
};

export { useIocContext, IocProvider };
