import { TableColumn } from "./default";

export const TableHeaderColumns: TableColumn[] = [
  { id: 1, name: "Local", key: "local" },
  { id: 3, name: "Id", key: "refId" },
  { id: 2, name: "Produto", key: "product" },
  { id: 3, name: "Agendado para", key: "dueDate" },
  { id: 4, name: "Quantidade", key: "quantidade" },
  { id: 5, name: "Status", key: "status" },
];
