import React from "react";
import { Box, Typography } from "@mui/material";

interface DeliveryTableCellProps {
  title: React.ReactNode;
  subTitle?: string;
  widthValue?: string;
}

const DeliveryTableCell: React.FC<DeliveryTableCellProps> = ({
  title,
  subTitle,
  widthValue,
}) => {
  return (
    <Box>
      <Typography
        fontSize={14}
        fontWeight={400}
        color={"shadesOfDark.dark"}
        width={widthValue}
      >
        {title}
      </Typography>
      <Typography
        fontWeight={400}
        color={"shadesOfDark.steel"}
        width={widthValue}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default DeliveryTableCell;
