import {
  PipelineScheduleStatus,
  Row,
} from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { getThousand } from "../../../utils";
import { formatCurrency } from "../../../utils/currencyFormatter";
import { DateToFormat } from "../../../utils/dateFormatter";
import { format } from "date-fns";

export const getPipelineResponseToModel = (item: Row) => ({
  scheduleDate: DateToFormat(item.scheduleDate),
  scheduleTime: item.scheduleTime ?? "",
  product: item.contract?.product?.description ?? "",
  quantity: getThousand(item.volumeRequested ?? 0)?.toString(),
  unit: item.contract?.product?.measurementUnit ?? "",
  scheduleNumber: item.refId ?? "",
  customer: item.contract?.customerCompany?.description ?? "",
  subsidiary: item.contract?.subsidiary?.description ?? "",
  paymentCondition: item.paymentConditionDescription ?? "",
  statusText: item.status ?? PipelineScheduleStatus.VENDOR_PENDING,
  mod: item.contract?.mod ?? "",
  customerRequester: item.customerRequester ?? "",
  createdAt: format(new Date(item.createdAt ?? ""), "dd/MM/yyyy") ?? "",
  customerRequesterNote: item.customerRequesterNote ?? "",
  price: formatCurrency(item.billedTotal) ?? "",
});
