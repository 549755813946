import { GetOrdersPaginatedQuery } from "../../../modules/supply/models/GetOrdersPaginatedQuery";

export type StatusOrderType =
  | "SENT"
  | "VENDOR_CONFIRMED"
  | "VENDOR_REJECTED"
  | "VENDOR_DRAFT";
export const ROW_PER_PAGE = 10;
interface IStatus {
  label: string;
  value: StatusOrderType;
}

export const commonFilter: GetOrdersPaginatedQuery = {
  page: 1,
  limit: ROW_PER_PAGE,
  plain: "",
};

export const customerDraftFilter: GetOrdersPaginatedQuery = {
  ...commonFilter,
  status: "CUSTOMER_DRAFT",
};

export const allOrderFilter: GetOrdersPaginatedQuery = {
  ...commonFilter,
  status: "SENT",
};

export const searchFilterList: IStatus[] = [
  {
    label: "Todos",
    value: "SENT",
  },
  {
    label: "Aprovados",
    value: "VENDOR_CONFIRMED",
  },
  {
    label: "Reprovados",
    value: "VENDOR_REJECTED",
  },
  {
    label: "Em Análise",
    value: "VENDOR_DRAFT",
  },
];
