import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomButton, {
  CustomButtonVariant,
} from "../CustomButton/CustomButton";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { IconType, StyledIcon } from "../StyledIcon/StyledIcon";

interface ModalOrderDetailsProps {
  componentItems: React.ReactNode;
  titleModal: string;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
  openDialog: boolean;
  handleClose: () => void;
  status?: string;
}

export const Button = styled(MuiButton)({
  padding: "4px 16px",
  fontWeight: "700",
  textTransform: "none",
  widht: "126px",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid shadesOfDark.dark",
  marginBottom: "20px",
  "&#download": {
    background: "#FFFF",
    color: "#shadesOfDark.dark",
  },
  "&#close": {
    background: "#shadesOfDark.dark",
    color: "#FFF",
    marginRight: "31px",
  },
});

const ModalOrderDetails: React.FC<ModalOrderDetailsProps> = ({
  componentItems,
  titleModal,
  buttons,
  openDialog,
  status,
  handleClose,
}) => {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle
          fontSize={24}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {titleModal}
          {status && (
            <StyledIcon
              ml={1}
              iconType={status as IconType}
              sx={{ width: 25, height: 25 }}
            />
          )}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          {componentItems}
        </DialogContent>
        <DialogActions
          sx={{
            borderTopWidth: 1,
            paddingTop: 2.5,
            borderStyle: "solid",
            borderColor: "shadesOfDark.ultraLight",
            paddingBottom: 2.5,
          }}
        >
          {buttons?.map((item, index) => (
            <CustomButton key={index} {...item} />
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalOrderDetails;
