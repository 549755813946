import React, { useEffect } from "react";
import Select from "../Select/Select";
import { getFormatCNPJ } from "../../utils";
import {
  IDistributors,
  useDistributor,
} from "../../contexts/distributor/DistributorContext";

interface SelectDistributorsProps {
  isCutText?: boolean;
  maxLenghtSelected?: number;
  label?: string;
  onChangeValue?: (e?: IDistributors) => void;
  onChange?: (selectedOption: string) => void;
}

const SelectDistributors: React.FC<SelectDistributorsProps> = ({
  isCutText = false,
  label,
  maxLenghtSelected = 18,
  onChangeValue,
}) => {
  const {
    distributors,
    selectedDistributor,
    setSelectedDistributor,
    setFirstLoading,
  } = useDistributor();
  const storedValue = localStorage.getItem("selectedDistributor");

  // TODO: nas proximas sprints retirar a lógica do localStorage e mudar no DistributorContext
  if (selectedDistributor) {
    localStorage.setItem(
      "selectedDistributor",
      selectedDistributor.document ? selectedDistributor.document : ""
    );
  }

  useEffect(() => {
    if (distributors.length === 1) {
      setSelectedDistributor(distributors[0]);
    } else {
      if (storedValue) {
        setSelectedDistributor(
          distributors.find((item) => item.document === storedValue)
        );
      }
      setTimeout(() => {
        setFirstLoading(false);
      }, 1000);
    }
  }, [distributors, setSelectedDistributor]);

  return (
    <Select
      label={label}
      options={distributors.map((option) => ({
        option,
      }))}
      value={selectedDistributor}
      getOptionItemLabel={(value) => getFormatCNPJ(value)}
      getSelectedOptionLabel={(value) =>
        getFormatCNPJ(value, true, isCutText, maxLenghtSelected)
      }
      onChange={(selectedOption) => {
        setSelectedDistributor(selectedOption);
        onChangeValue?.(selectedOption);
        localStorage.setItem(
          "distributors",
          selectedOption.description ? selectedOption.description : ""
        );
      }}
    />
  );
};

export default SelectDistributors;
