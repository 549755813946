import React from "react";
import "./animate.css";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { Box } from "@mui/material";

const DashLoading: React.FC = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
    >
      <div className={"pulse"}>
        <StyledIcon
          iconType="logo-ream"
          sx={{
            width: "14rem",
            height: "14rem",
          }}
        />
      </div>
    </Box>
  );
};

export default DashLoading;
