import React from "react";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import ModalPumpDetailsHeader from "../../PumpRequest/Section/ModalPumpDetailsHeader";
import ModalOrderDetailsContent from "../../QuoteRequest/Section/ModalOrderDetailsContent";
import { Box, Grid, Typography } from "@mui/material";
import ModalDetailsNote from "../../../components/ModalDetailsNote/ModalDetailsNote";

export type ScheduleModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
interface ModalScheduleRequestLoaderProps {
  isOpen: boolean;
  data: ScheduleModalData | any;
  handleClose: (open: boolean) => void;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
}

const ModalPipelineRequestLoader: React.FC<ModalScheduleRequestLoaderProps> = ({
  isOpen,
  data,
  handleClose,
  buttons,
}) => {
  const tabBody: React.ReactNode[] = [];

  const modalOrderHeader = (
    <ModalPumpDetailsHeader
      distributor={data.local ?? ""}
      requestNumber={data.requestNumber ?? ""}
      requestDate={data.requestCreatedDate ?? ""}
      requestUser={data.requestUser ?? ""}
      subsidiaryDescription={data.subsidiaryDescription ?? ""}
    />
  );

  Object.keys(data).map((key) =>
    tabBody.push(
      <ModalOrderDetailsContent
        key={key}
        modality={{
          title: data.modality?.title ?? "",
          value: data.modality?.value ?? "",
        }}
        productName={{
          title: data.productName?.title ?? "",
          value: data.productName?.value ?? "",
        }}
        quantity={{
          title: data.quantity?.title ?? "",
          value: data.quantity?.value ?? "",
        }}
      />
    )
  );

  const PlateCard: React.FC = () => {
    const LabelCard: React.FC<{ label?: string; value?: string }> = ({
      label,
      value,
    }) => {
      return (
        <Typography
          color={"shadesOfDark.dark"}
          paddingRight={3}
          fontWeight={700}
          fontSize={19}
          mb={0.5}
          sx={{ "& span": { fontWeight: 400, fontSize: 16, paddingLeft: 0.5 } }}
        >
          {label} <span>{value}</span>
        </Typography>
      );
    };

    const cardProps = {
      item: true,
      xs: 12,
      md: 12,
      py: 1.8,
      px: 2.5,
    };

    return (
      <Grid container mb={0.5}>
        <Grid
          {...cardProps}
          display={"flex"}
          justifyContent={"left"}
          pl={"none"}
        >
          <LabelCard
            label={data.requestDate?.title ?? ""}
            value={data.requestDate?.value ?? ""}
          />
          <LabelCard
            label={data.requestTime?.title ?? ""}
            value={data.requestTime?.value ?? ""}
          />
          <LabelCard
            label={data.requestPaymentCondition?.title ?? ""}
            value={data.requestPaymentCondition?.value ?? ""}
          />
        </Grid>
      </Grid>
    );
  };

  const modalOrderTabs = (
    <Box mb={3}>
      <ModalOrderDetailsContent
        borderWidth={1}
        modality={{
          title: data.modality?.title ?? "",
          value: data.modality?.value ?? "",
        }}
        productName={{
          title: data.productName?.title ?? "",
          value: data.productName?.value ?? "",
        }}
        quantity={{
          title: data.quantity?.title ?? "",
          value: data.quantity?.value ?? "",
        }}
      />
    </Box>
  );

  const modalOrderNote = (
    <Box mb={3}>
      <ModalDetailsNote
        productNote={{
          title: data.requestNote?.title ?? "",
          value: data.requestNote?.value ?? "",
        }}
      />
    </Box>
  );

  const modalContent = (
    <>
      {modalOrderHeader}
      <PlateCard />
      {modalOrderTabs}
      {modalOrderNote}
    </>
  );

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false)}
      componentItems={modalContent}
      titleModal={"Detalhes do pedido de bombeio"}
      buttons={buttons}
    />
  );
};

export default ModalPipelineRequestLoader;
