import { Avatar, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import { routesConfig } from "../../config/routes";
import { getFormattedTimestamp } from "../../utils/dateFormatter";
import ClearedPipelineDeliveries from "./Sections/ClearedPipelineDeliveries";
import OpenedPipelineDeliveries from "./Sections/OpenedPipelineDeliveries";
import RejectedPipelineDeliveries from "./Sections/RejectedPipelineDeliveries";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { CommonMenuList } from "../ConsultQuotaOrders/models/OptionsMenu";
import { getFirstDayOfCurrentMonth, getTitleAndValue } from "../../utils";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import ModalPipelineRequestLoader from "./Sections/ModalPipelineRequestLoader";
import CalendarRange from "../../components/CalendarRange/CalendarRange";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { TypesPermissions } from "../../components/Routes/TypesPermissions";
import { Roles } from "../../components/SideBar/Content/ListItem";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import MultipleSelectDistributors from "../../components/MultipleSelectDistributors/MultipleSelectDistributors";
import { useDistributor } from "../../contexts/distributor/DistributorContext";

import { sidebar } from "../../utils/featureFlags";
import { useFlags } from "flagsmith/react";
import { TableRow } from "./models/default";
import SelectSubsidiaries from "../../components/SelectSubsidiary/SelectSubsidiary";

const PipelineDeliveriesPage: React.FC = () => {
  const flags = useFlags(sidebar);
  const currentDay = new Date();
  const { selectedDistributorsStoraged, subsidiaries } = useDistributor();
  const { authByPermission, permissionSet, roles } =
    useAuth() as AuthenticationContextData;
  const navigate = useNavigate();
  const [openedCount, setOpenedCount] = useState(0);
  const [clearedCount, setClearedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [dateFilterFrom, setDateFilterFrom] = useState<Date | null>(null);
  const [dateFilterTo, setDateFilterTo] = useState<Date | null>(null);
  const [clearValueCalendar, setClearValueCalendar] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [codeSubsidiary, setCodeSubsidiary] = useState<string | undefined>(
    undefined
  );

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const clearFilters = () => {
    setDateFilterFrom(null);
    setDateFilterTo(null);
    setClearValueCalendar(true);
    setCodeSubsidiary(undefined);
  };

  const selectedDistributors = useMemo(() => {
    return selectedDistributorsStoraged.join(",");
  }, [selectedDistributorsStoraged]);

  const StyledMenu: React.FC<{ rowData: TableRow }> = ({ rowData }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async () => {
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const menuOptions = [
      ...CommonMenuList.map((item) => ({
        ...item,
        onClick: () => {
          setModalData((oldState) => ({
            ...oldState,
            local: {
              title: "Nome da razão social",
              value: rowData.customer ?? "",
            },
            requestNumber: {
              title: "N. do pedido",
              value: rowData.scheduleNumber ?? "",
            },
            requestCreatedDate: getTitleAndValue(
              "Data do pedido",
              rowData.createdAt
            ),
            requestUser: getTitleAndValue(
              "Solicitado por:",
              rowData.customerRequester
            ),
            productName: getTitleAndValue("Nome do produto", rowData.product),
            modality: getTitleAndValue("Modalidade", rowData.mod),
            quantity: getTitleAndValue(
              "Quantidade",
              `${rowData.quantity} ${rowData.unit}`
            ),
            requestDate: getTitleAndValue(
              "Data para bombeio",
              rowData.scheduleDate
            ),
            requestTime: getTitleAndValue("Horário", rowData.scheduleTime),
            requestPaymentCondition: getTitleAndValue(
              "Condição de pagamento",
              rowData.paymentCondition
            ),
            requestNote: getTitleAndValue(
              "Observação",
              rowData.customerRequesterNote
            ),
            subsidiaryDescription: getTitleAndValue(
              "Filial",
              rowData.subsidiary ?? "-"
            ),
          }));
          setIsOpen(true);
        },
      })),
    ];

    return (
      <>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick();
                option.onClick();
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const isAllowed = useMemo(() => {
    return (
      authByPermission(
        permissionSet.SYSTEM_MODULES,
        roles.has(Roles.ADMIN),
        TypesPermissions.PIPELINES_REQUEST
      ) && flags.operational_new_pipelines.enabled
    );
  }, [authByPermission, flags]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Entregas dutoviárias" />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "24px" }}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title="Pedidos de bombeio"
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Novo pedido de bombeio",
                onClick() {
                  navigate(routesConfig.OPERATIONAL().PIPELINES_REQUEST);
                },
                variant: isAllowed
                  ? CustomButtonVariant.CONTAINED
                  : CustomButtonVariant.DISABLED,
                disabled: !isAllowed,
              }}
            />
            <Grid container>
              <Grid item xs={12}>
                <ModalPipelineRequestLoader
                  handleClose={(open) => handleClose(open)}
                  isOpen={isOpen}
                  data={modalData}
                  buttons={[
                    {
                      title: "Fechar",
                      variant: CustomButtonVariant.CONTAINED,
                      onClick: () => {
                        handleClose(false);
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={6} md={3}>
                      <MultipleSelectDistributors
                        maxWidth={1200}
                        label={"Selecione o cliente"}
                      />
                    </Grid>

                    <Grid item xs={10} sm={6} md={3}>
                      <SelectSubsidiaries
                        label="Filial"
                        valueSubsidiary={subsidiaries.find(
                          (item) => item.id === codeSubsidiary
                        )}
                        onChangeValue={(selectedOption) => {
                          if (selectedOption) {
                            setCodeSubsidiary(selectedOption.id);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={10} sm={6} md={3}>
                      <CalendarRange
                        name="date"
                        label="Data de agendamento"
                        firstDayOfMonth={getFirstDayOfCurrentMonth()}
                        lastDayOfMonth={currentDay}
                        clearValueCalendar={clearValueCalendar}
                        onChangeDateStartEnd={(dateBegin, dateEnd) => {
                          setClearValueCalendar(false);
                          // eslint-disable-next-line
                          if (dateBegin && dateEnd) {
                            setDateFilterFrom(dateBegin);
                            setDateFilterTo(dateEnd);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="flex-end"
                  justifyContent={"flex-end"}
                  alignContent={"flex-start"}
                  marginTop={"25px"}
                >
                  <CustomButton
                    title="Limpar filtros"
                    variant={CustomButtonVariant.OUTLINED}
                    onClick={clearFilters}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomTabs
                  tabsHeader={[
                    `Em aberto(${openedCount})`,
                    `Faturado(${clearedCount})`,
                    `Reprovado(${rejectedCount})`,
                  ]}
                  body={[
                    <OpenedPipelineDeliveries
                      key={0}
                      subsidiary={codeSubsidiary}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      customerCompanyIds={selectedDistributors}
                      onChangeCount={(count) => {
                        setOpenedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,
                    <ClearedPipelineDeliveries
                      key={1}
                      subsidiary={codeSubsidiary}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      customerCompanyIds={selectedDistributors}
                      onChangeCount={(count) => {
                        setClearedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,
                    <RejectedPipelineDeliveries
                      key={2}
                      subsidiary={codeSubsidiary}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      customerCompanyIds={selectedDistributors}
                      onChangeCount={(count) => {
                        setRejectedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,
                  ]}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PipelineDeliveriesPage;
