import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";

const List = styled("ul")({
  display: "flex",
  justifyContent: "end",
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const Item = styled("li")((props: { selected: boolean }) => ({
  "& button": {
    outline: "none",
    cursor: "pointer",
    backgroundColor: props.selected ? "#fdeeef" : "#fff",
    border: 0,
    color: props.selected ? "#D91F05" : "#475569",
    width: 32,
    height: 36,
    fontSize: 16,
    fontFamily: "Archivo",
    borderRadius: 4,
    fontWeight: 400,
  },
}));

export interface PaginationProps {
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
  pagesTotal: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  page?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  pagesTotal,
  showFirstButton = false,
  showLastButton = false,
  page,
}) => {
  const { items } = usePagination({
    page,
    count: pagesTotal,
    showFirstButton,
    showLastButton,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    if (onPageChange) onPageChange(event, newPage);
  };

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                disabled={item.disabled}
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
              >
                {page}
              </button>
            );
          } else if (type === "first" || type === "last") {
            children = (
              <IconButton
                disabled={item.disabled}
                type="button"
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
                sx={{
                  px: 0,
                }}
              >
                <StyledIcon
                  iconType={type.includes("first") ? "first-page" : "last-page"}
                  sx={{
                    p: 0,
                    height: "auto",
                    width: "auto",
                  }}
                />
              </IconButton>
            );
          } else {
            const buttonType =
              type === "next" ? (
                <KeyboardArrowRightRounded />
              ) : (
                <KeyboardArrowLeftRounded />
              );

            children = (
              <IconButton
                disabled={item.disabled}
                type="button"
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
              >
                {buttonType}
              </IconButton>
            );
          }

          return (
            <Item key={index} selected={selected}>
              {children}
            </Item>
          );
        })}
      </List>
    </nav>
  );
};

export default Pagination;
