import { Tooltip } from "@material-ui/core";
import { Box, BoxProps, SxProps, Theme } from "@mui/system";
import { generateFilter } from "colorize-filter";

export type IconType =
  | "badge"
  | "chat"
  | "notification"
  | "settings"
  | "billingTypeIcon-blue"
  | "billingTypeIcon-red"
  | "billingTypeIcon-yellow"
  | "billingTypeIcon-green"
  | "finantial"
  | "dashboard"
  | "executive"
  | "operational"
  | "sac"
  | "suitcase"
  | "supplies"
  | "dot"
  | "separator"
  | "product-name"
  | "rocket-woman"
  | "logo-ream"
  | "download-button"
  | "data-not-found"
  | "first-page"
  | "last-page"
  | "left-arrow"
  | "right-arrow"
  | "calendar"
  | "cactus"
  | "info"
  | "arrow-sort"
  | "three-vertical-points"
  | "notification-message-success"
  | "notification-message-error"
  | "notification-message-warning"
  | "check-circle"
  | "clock"
  | "global-error"
  | "requested"
  | "analysis"
  | "approved"
  | "blocked"
  | "progress"
  | "step"
  | "current-step"
  | "list"
  | "search"
  | "home"
  | "trash"
  | "search-empty"
  | "no-check-circle"
  | "upload"
  | "image"
  | "download"
  | "await";

export interface StyledIconProps extends BoxProps<"img"> {
  iconType: IconType;
  /**
   * Hex color
   */
  iconColor?: string;
  tooltip?: {
    title: string;
  };
}

const StyledIcon: React.FC<StyledIconProps> = ({
  iconType,
  iconColor,
  tooltip,
  ...props
}) => {
  const sxProps: SxProps<Theme> = {
    width: "1.8rem",
    height: "1.8rem",
  };

  const selectIcon = () => {
    switch (iconType) {
      case "badge": {
        return { src: "/icons/appBar/badge.svg", alt: "badge" };
      }
      case "chat": {
        return { src: "/icons/appBar/chat.svg", alt: "chat" };
      }
      case "notification": {
        return { src: "/icons/appBar/notification.svg", alt: "notification" };
      }
      case "settings": {
        return { src: "/icons/appBar/settings.svg", alt: "settings" };
      }
      case "billingTypeIcon-blue": {
        return {
          src: "/icons/billingTable/billingTypeIcon-blue.svg",
          alt: "settings",
        };
      }
      case "billingTypeIcon-red": {
        return {
          src: "/icons/billingTable/billingTypeIcon-red.svg",
          alt: "settings",
        };
      }
      case "billingTypeIcon-yellow": {
        return {
          src: "/icons/billingTable/billingTypeIcon-yellow.svg",
          alt: "settings",
        };
      }
      case "billingTypeIcon-green": {
        return {
          src: "/icons/billingTable/billingTypeIcon-green.svg",
          alt: "settings",
        };
      }
      case "finantial": {
        return { src: "/icons/sideBar/finantial.svg", alt: "finantial" };
      }
      case "dashboard": {
        return { src: "/icons/sideBar/dashboard.svg", alt: "dashboard" };
      }
      case "executive": {
        return { src: "/icons/sideBar/executive.svg", alt: "executive" };
      }
      case "operational": {
        return { src: "/icons/sideBar/operational.svg", alt: "operational" };
      }
      case "sac": {
        return { src: "/icons/sideBar/sac.svg", alt: "sac" };
      }
      case "suitcase": {
        return { src: "/icons/sideBar/suitcase.svg", alt: "suitcase" };
      }
      case "supplies": {
        return { src: "/icons/sideBar/supplies.svg", alt: "supplies" };
      }
      case "dot": {
        return { src: "/icons/sideBar/dot.svg", alt: "dot" };
      }
      case "logo-ream": {
        return {
          src: "/icons/sideBar/logo-ream.svg",
          alt: "logo-ream",
        };
      }
      case "separator": {
        return {
          src: "/icons/billingTableHeader/separator.svg",
          alt: "separator",
        };
      }
      case "product-name": {
        return {
          src: "/icons/consumptionProductName/product_name.svg",
          alt: "product-name",
        };
      }
      case "rocket-woman": {
        return {
          src: "/Illustration/rocketwoman.svg",
          alt: "rocketwoman",
        };
      }
      case "download-button": {
        return {
          src: "/icons/customButton/download.svg",
          alt: "download",
        };
      }
      case "download": {
        return {
          src: "/icons/customButton/download-dark.svg",
          alt: "download",
        };
      }
      case "upload": {
        return {
          src: "/icons/customButton/upload.svg",
          alt: "upload",
        };
      }
      case "data-not-found": {
        return {
          src: "/Illustration/datanotfound.svg",
          alt: "data-not-found",
        };
      }
      case "cactus": {
        return {
          src: "/Illustration/cactus.svg",
          alt: "cactus",
        };
      }
      case "list": {
        return {
          src: "/Illustration/list.svg",
          alt: "cactus",
        };
      }
      case "image": {
        return {
          src: "/Illustration/image.svg",
          alt: "cactus",
        };
      }
      case "first-page": {
        return {
          src: "/icons/pagination/first_page.svg",
          alt: "first-page",
        };
      }
      case "last-page": {
        return {
          src: "/icons/pagination/last_page.svg",
          alt: "last-page",
        };
      }
      case "left-arrow": {
        return {
          src: "/icons/calendarRange/arrowLeft.svg",
          alt: "left-arrow",
        };
      }
      case "right-arrow": {
        return {
          src: "/icons/calendarRange/arrowRight.svg",
          alt: "right-arrow",
        };
      }
      case "calendar": {
        return {
          src: "/icons/calendarRange/calendar.svg",
          alt: "calendar",
        };
      }
      case "info": {
        return {
          src: "/icons/tablePaginated/info.svg",
          alt: "info",
        };
      }
      case "arrow-sort": {
        return {
          src: "/icons/tablePaginated/arrow_sort.svg",
          alt: "arrow-sort",
        };
      }
      case "three-vertical-points": {
        return {
          src: "/icons/tablePaginated/three_vertical_points.svg",
          alt: "three-vertical-points",
        };
      }
      case "notification-message-success": {
        return {
          src: "/icons/snackbar/success.svg",
          alt: "notification-message-success",
        };
      }
      case "notification-message-error": {
        return {
          src: "/icons/snackbar/error.svg",
          alt: "notification-message-error",
        };
      }
      case "notification-message-warning": {
        return {
          src: "/icons/snackbar/warning.svg",
          alt: "notification-message-warning",
        };
      }
      case "check-circle": {
        return {
          src: "/icons/tableSupply/check-circle.svg",
          alt: "check-circle",
        };
      }
      case "clock": {
        return {
          src: "/icons/tableSupply/clock.svg",
          alt: "clock",
        };
      }
      case "global-error": {
        return {
          src: "/icons/tableSupply/global-error.svg",
          alt: "global-error",
        };
      }
      case "requested": {
        return {
          src: "/icons/orderStatusProgress/requested.svg",
          alt: "requested",
        };
      }
      case "analysis": {
        return {
          src: "/icons/orderStatusProgress/analysis.svg",
          alt: "Em análise",
        };
      }
      case "approved": {
        return {
          src: "/icons/orderStatusProgress/approved.svg",
          alt: "Faturado",
        };
      }
      case "blocked": {
        return {
          src: "/icons/orderStatusProgress/blocked.svg",
          alt: "Reprovado",
        };
      }
      case "progress": {
        return {
          src: "/icons/orderStatusProgress/progress.svg",
          alt: "Em programação",
        };
      }
      case "await": {
        return {
          src: "/icons/orderStatusProgress/awaiting_confirmation.svg",
          alt: "Em programação",
        };
      }
      case "step": {
        return {
          src: "/icons/orderStatusProgress/step.svg",
          alt: "Etapa",
        };
      }
      case "current-step": {
        return {
          src: "/icons/orderStatusProgress/current_step.svg",
          alt: "Etapa Atual",
        };
      }
      case "search": {
        return {
          src: "/icons/customInput/search.svg",
          alt: "approved",
        };
      }
      case "home": {
        return {
          src: "/icons/breadcrumb/home.svg",
          alt: "home",
        };
      }
      case "trash": {
        return {
          src: "/icons/trash.svg",
          alt: "Apagar",
        };
      }
      case "search-empty": {
        return {
          src: "/Illustration/search-empty.svg",
          alt: "search-empty",
        };
      }
      case "no-check-circle": {
        return {
          src: "/icons/orderStatusProgress/no-check-circle.svg",
          alt: "no-check-circley",
        };
      }
    }
  };

  const icon = selectIcon();

  return (
    <Tooltip title={tooltip?.title ?? ""} arrow>
      <Box
        component="img"
        {...props}
        {...icon}
        sx={{
          ...sxProps,
          ...props.sx,
          filter: iconColor ? generateFilter(iconColor) : undefined,
        }}
      />
    </Tooltip>
  );
};

export { StyledIcon };
