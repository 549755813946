import { Grid } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import { DetailInputsModalProps } from "../../../components/ModalExtractContent/ModalExtractContent";
import TitleAndDetailExtract from "../../../components/ModalExtractTitleAndDetail/ModalExtractTitleAndDetail";

interface ModalIcmsDetailsContentProps {
  petition: DetailInputsModalProps;
  xmlNotes: DetailInputsModalProps;
  refundValues: DetailInputsModalProps;
  color?: string;
  borderWidth?: number;
}

const ModalIcmsDetailsContent: React.FC<ModalIcmsDetailsContentProps> = ({
  petition,
  xmlNotes,
  refundValues,
  color,
  borderWidth = 0,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid
      container
      sx={{
        border: borderWidth,
        borderStyle: "solid",
        borderColor: "shadesOfDark.ultraLight",
        borderRadius: "4px",
      }}
      padding={2}
      paddingTop={1}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <TitleAndDetailExtract
            color={color}
            title={petition.title}
            detail={petition.value}
            textAlign={"left"}
            underline
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={xmlNotes.title}
            detail={xmlNotes.value}
            textAlign={"left"}
            underline
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={6}
          display={"flex"}
          alignItems={"end"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={refundValues.title}
            detail={refundValues.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalIcmsDetailsContent;
