import { Grid } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import { DetailInputsModalProps } from "../../../components/ModalExtractContent/ModalExtractContent";
import TitleAndDetailExtract from "../../../components/ModalExtractTitleAndDetail/ModalExtractTitleAndDetail";

interface ModalOrderDetailsContentProps {
  productName: DetailInputsModalProps;
  modality: DetailInputsModalProps;
  quantity: DetailInputsModalProps;
  payment?: DetailInputsModalProps;
  type?: DetailInputsModalProps;
  color?: string;
  borderWidth?: number;
}

const ModalOrderDetailsContent: React.FC<ModalOrderDetailsContentProps> = ({
  productName,
  modality,
  quantity,
  payment,
  type,
  color,
  borderWidth = 0,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid
      container
      sx={{
        border: borderWidth,
        borderStyle: "solid",
        borderColor: "shadesOfDark.ultraLight",
        borderRadius: "4px",
      }}
      padding={2}
      paddingTop={1}
    >
      <Grid container>
        <Grid
          item
          paddingTop={isMobile ? "20px" : "0px"}
          xs={12}
          sm={6}
          md={4}
          lg={6}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <TitleAndDetailExtract
            color={color}
            title={productName.title}
            detail={productName.value}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          margin={"auto"}
          display={"flex"}
          justifyContent={"left"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={modality.title}
            detail={modality.value}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={quantity.title}
            detail={quantity.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        {payment !== undefined && type !== undefined && (
          <>
            <Grid
              item
              pt={2}
              xs={12}
              sm={6}
              md={4}
              lg={6}
              display={"flex"}
              justifyContent={"left"}
            >
              <TitleAndDetailExtract
                color={color}
                title={payment.title}
                detail={payment.value}
                textAlign={"left"}
              />
            </Grid>
            <Grid
              item
              pt={2}
              xs={12}
              sm={6}
              md={4}
              lg={6}
              display={"flex"}
              justifyContent={"left"}
            >
              <TitleAndDetailExtract
                color={color}
                title={type.title}
                detail={type.value}
                textAlign={"left"}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ModalOrderDetailsContent;
