import * as React from "react";
import { useCallback, useEffect, useState, createContext } from "react";
import { Types } from "../../ioc/types";
import { useIocContext } from "../ioc/IocContext";
import { IDistributorsService } from "../../modules/distributors/models/IDistributorsService";
import useDialogAlert from "../../hooks/DialogAlert";
import { IDistributorContextData } from "./IDistributorContextData";
import { SubsidiaryDTO } from "../../modules/distributors/models/DistributorListDTO";
import { useFlags } from "flagsmith/react";
import { sidebar } from "../../utils/featureFlags";

export interface IDistributors {
  id?: string;
  document?: string;
  externalId?: string;
  description: string;
}

export interface IDistributorContext {
  distributors: IDistributors[];
  setDistributors?: React.Dispatch<React.SetStateAction<IDistributors[]>>;
  selectedDistributor?: IDistributors;
  setSelectedDistributor: React.Dispatch<
    React.SetStateAction<IDistributors | undefined>
  >;
  firstLoading?: boolean;
  setFirstLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDistributorsStoraged: string[];
  setSelectedDistributorsStoraged: React.Dispatch<
    React.SetStateAction<string[] | []>
  >;

  subsidiaries: SubsidiaryDTO[];
  setSubsidiaries?: React.Dispatch<React.SetStateAction<SubsidiaryDTO[]>>;
}

const DistributorContext = createContext<IDistributorContext>({
  distributors: [],
  setSelectedDistributor() {},
  firstLoading: true,
  setFirstLoading() {},
  selectedDistributorsStoraged: [],
  setSelectedDistributorsStoraged() {},
  subsidiaries: [],
});

export const DistributorProvider = ({ children }: React.PropsWithChildren) => {
  const [distributors, setDistributors] = useState<IDistributors[]>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<
    IDistributors | undefined
  >(undefined);
  const [selectedDistributorsStoraged, setSelectedDistributorsStoraged] =
    useState<string[]>([]);
  const [firstLoading, setFirstLoading] = useState(true);

  const [subsidiaries, setSubsidiaries] = useState<SubsidiaryDTO[]>([]);

  const flags = useFlags(sidebar);

  const IsEnabledSubsidiaries = flags.list_all_subsidiaries?.enabled;

  useState<IDistributorContextData>();
  const { snackbar } = useDialogAlert();

  const iocContext = useIocContext();

  const getDistributorService =
    iocContext.serviceContainer.get<IDistributorsService>(
      Types.Distributors.IDistributors
    );

  const getDistributors = useCallback(async () => {
    try {
      const distributors = await getDistributorService.listDistributors();

      setDistributors(distributors);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar as distribuidoras.",
        variant: "error",
      });
    }
  }, [distributors]);

  const getSubsidiaries = useCallback(async () => {
    try {
      const subsidiariesList = await getDistributorService.listSubsidiaries();

      if (IsEnabledSubsidiaries) {
        setSubsidiaries(subsidiariesList);
      } else {
        if (Array.isArray(subsidiariesList) && subsidiariesList.length > 0) {
          setSubsidiaries([subsidiariesList[0]]);
        }
      }
    } catch (error) {
      snackbar({
        message: "Erro ao carregar as filiais.",
        variant: "error",
      });
    }
  }, [subsidiaries]);

  useEffect(() => {
    getDistributors();
    getSubsidiaries();
  }, []);

  useEffect(() => {
    if (selectedDistributor) {
      setFirstLoading(false);
    }
  }, [selectedDistributor]);

  return (
    <DistributorContext.Provider
      value={{
        distributors,
        setDistributors,
        selectedDistributor,
        setSelectedDistributor,
        firstLoading,
        setFirstLoading,
        selectedDistributorsStoraged,
        setSelectedDistributorsStoraged,
        subsidiaries,
        setSubsidiaries,
      }}
    >
      {children}
    </DistributorContext.Provider>
  );
};

export const useDistributor = () => {
  const context = React.useContext(DistributorContext);
  if (context === undefined) {
    throw new Error(
      "useDistributor não pode ser utilizado fora de um AuthProvider"
    );
  }
  return context;
};
