import React, { ReactNode, useState } from "react";
import { RiverModalData } from "../Section/ModalRiverView";
import { RiverRequestFormData } from "../models/customIconRiver";

interface RiverRequestContextData {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formData?: RiverRequestFormData;
  setFormData: React.Dispatch<
    React.SetStateAction<RiverRequestFormData | undefined>
  >;
  modalData?: RiverModalData;
  setModalData: React.Dispatch<React.SetStateAction<RiverModalData>>;
  isCreating?: boolean;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingSchedule?: boolean;
  setIsCreatingSchedule: React.Dispatch<React.SetStateAction<boolean>>;
}

const RiverRequestContext = React.createContext<RiverRequestContextData>({
  step: 0,
  setFormData() {},
  setStep() {},
  setModalData() {},
  setIsCreating() {},
  isCreating: false,
  setIsCreatingSchedule() {},
  isCreatingSchedule: false,
});

const RiverRequestProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<RiverRequestFormData>();
  const [modalData, setModalData] = useState<RiverModalData>({});
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingSchedule, setIsCreatingSchedule] = useState(false);

  return (
    <RiverRequestContext.Provider
      value={{
        step,
        setStep,
        formData,
        setFormData,
        modalData,
        setModalData,
        isCreating,
        setIsCreating,
        isCreatingSchedule,
        setIsCreatingSchedule,
      }}
    >
      {children}
    </RiverRequestContext.Provider>
  );
};

const useRiverRequestContext = () => {
  const context = React.useContext(RiverRequestContext);
  if (context === undefined) {
    throw new Error(
      "useRiverRequestContext não pode ser utilizado fora de um RiverRequestProvider"
    );
  }
  return context;
};
export { useRiverRequestContext, RiverRequestProvider };
