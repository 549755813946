import { Avatar, Box, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "../../components/BreadcrumbCustom/BreadcrumbCustom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import OrderStatusProgress from "../../components/OrderStatusProgress/OrderStatusProgress";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { routesConfig } from "../../config/routes";
import RiverRequestStep1 from "./Section/RiverRequestStep1";
import RiverRequestStep2 from "./Section/RiverRequestStep2";
import {
  RiverRequestProvider,
  useRiverRequestContext,
} from "./context/RiverRequestContext";

const RiverRequestPageContainer: React.FC = () => {
  const navigate = useNavigate();
  const { step } = useRiverRequestContext();

  const breadcrumbs: BreadcrumbChild[] = [
    {
      isIcon: true,
      link: routesConfig.HOME,
      icon: <StyledIcon iconType={"home"} />,
    },
    {
      link: routesConfig.OPERATIONAL().RIVER,
      label: "ENTREGAS FLUVIAIS",
    },
    { label: "NOVO PEDIDO DE AGENDAMENTO" },
  ];

  const StepbyStep = [
    <RiverRequestStep1 key={0} />,
    <RiverRequestStep2 key={1} />,
  ];

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Entregas Fluviais" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title="Novo pedido de agendamento"
              buttonProps={{
                title: "Voltar para agendamentos",
                onClick: () => {
                  navigate(routesConfig.OPERATIONAL().RIVER);
                },
              }}
            />
            <BreadcrumbCustom data={breadcrumbs} />
            <Box mt={2}></Box>
            <Divider />
            <Grid container mt={5}>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <OrderStatusProgress
                  statusList={[
                    {
                      icon: (
                        <StyledIcon
                          iconType={step === 0 ? "current-step" : "step"}
                          sx={{ width: "2rem", height: "2rem" }}
                        />
                      ),
                      description: "Agendamento",
                      isMarked: step === 0,
                    },
                    {
                      icon: (
                        <StyledIcon
                          iconType={step === 1 ? "current-step" : "step"}
                          sx={{ width: "2rem", height: "2rem" }}
                        />
                      ),
                      description: "Pedido",
                      isMarked: step === 1,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={4} />
            </Grid>
            {StepbyStep[step]}
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

const RiverRequestPage: React.FC = () => {
  return (
    <RiverRequestProvider>
      <RiverRequestPageContainer />
    </RiverRequestProvider>
  );
};
export default RiverRequestPage;
