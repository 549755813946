import { Grid, Typography, useTheme } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import React, { useRef } from "react";
import CustomButton, {
  CustomButtonVariant,
} from "../CustomButton/CustomButton";

interface FileInputProps {
  label?: string;
  onChangeFile?: (e: File | null) => void;
  accept?: string;
  helperText?: string;
  value: File | null;
}

const FileInput: React.FC<FileInputProps> = ({
  label,
  accept,
  helperText,
  onChangeFile,
  value,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLDivElement>(null);

  return (
    <Grid item display={"flex"} flexDirection={"column"}>
      {label && (
        <Typography
          fontFamily={"Open Sans"}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          mb={0.5}
        >
          {label}
        </Typography>
      )}
      <Grid item display={"flex"} flexDirection={"row"}>
        <MuiFileInput
          value={value}
          ref={inputRef}
          InputProps={{
            startAdornment: null,
            inputProps: {
              accept: accept ?? "",
              onSubmit: (event) => event.preventDefault(),
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: 40,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              textDecoration: "none",
            },
            "& label": {
              height: 40,
            },
            "& span.MuiFileInput-placeholder": {
              padding: 1,
              color: theme.palette.shadesOfDark.medium,
            },
            "& .MuiOutlinedInput-root:hover": {
              color: theme.palette.shadesOfDark.medium,
              textDecoration: "none",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "& label div span": {
              paddingLeft: "10px",
            },
          }}
          error={false}
          placeholder="Selecione"
          onChange={(e) => {
            onChangeFile && onChangeFile(e);
          }}
          helperText={helperText}
        />
        <Grid>
          <CustomButton
            title={"Selecionar"}
            variant={CustomButtonVariant.CONTAINED}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: 40,
              border: "antiquewhite",
            }}
            onClick={() => {
              const input: any = inputRef.current?.firstChild?.firstChild;
              input && document.getElementById(input.htmlFor)?.click();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileInput;
