import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Grid, IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useMedia } from "use-media";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { goToLogout } from "../../utils";
import { Logout } from "@mui/icons-material";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export interface AppBarProps {
  notification?: {
    onChange?: () => void;
  };
  chat?: {
    onChange?: () => void;
  };
  settings?: {
    onChange?: () => void;
  };
  avatar?: () => React.ReactNode;
  onMenuClick?: () => void;
}

const AppBar: React.FC<AppBarProps> = ({
  notification,
  chat,
  settings,
  onMenuClick,
}) => {
  const theme = useTheme();
  const isMobile = useMedia({ maxWidth: 900 });
  const authContext = useAuth();

  const MenuIconCustom: React.FC = () => {
    return (
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        onClick={onMenuClick}
      >
        <Box
          height={"6.2rem"}
          width={"6.2rem"}
          border={`2px solid ${theme.palette.tagsAndStatus[3]}`}
          borderRadius={"6.2rem"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MenuIcon height={2.4} width={2.4} />
        </Box>
      </IconButton>
    );
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      paddingX={4}
      bgcolor="#fff"
      onClick={preventDefault}
      minHeight={100}
      position={"fixed"}
      sx={{
        width: "100%",
        borderBottom: "1px solid #ECF2F6",
        zIndex: 1,
      }}
    >
      <Grid display={"flex"} alignItems={"left"}>
        <Grid margin={"auto"}>{isMobile && <MenuIconCustom />}</Grid>
        <Grid margin={"auto"}>
          <StyledIcon
            iconType="logo-ream"
            sx={{
              width: "18",
              height: "auto",
              paddingLeft: "20px",
              paddingTop: "10px",
            }}
          />
        </Grid>
      </Grid>

      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{
          "& > img": {
            marginX: 1,
          },
        }}
      >
        {notification && (
          <IconButton size="large" edge="start" color="primary">
            <StyledIcon iconType="notification" />

            <Box
              position={"absolute"}
              right={10}
              top={10}
              margin={0}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
            >
              <StyledIcon
                iconType="badge"
                sx={{
                  width: "1.1rem",
                  height: "1.1rem",
                }}
              />
            </Box>
          </IconButton>
        )}
        {chat && (
          <IconButton size="large" edge="start" color="primary">
            <StyledIcon iconType="chat" />
          </IconButton>
        )}
        {settings && (
          <IconButton size="large" edge="start" color="primary">
            <StyledIcon iconType="settings" />
          </IconButton>
        )}
        <Logout
          onClick={() => goToLogout()}
          sx={{
            color: "#58595B",
            fontSize: "large",
            cursor: "pointer",
            marginRight: 2,
          }}
        />
        <Avatar
          sx={{
            bgcolor: theme.palette.tagsAndStatus[2],
            color: theme.palette.primary.main,
          }}
        >
          {authContext?.name.slice(0, 1)}
        </Avatar>
      </Box>
    </Box>
  );
};

export default AppBar;
