export type StatusInvoiceValueType = "CLOSE" | "OPEN" | "ALL";

interface IStatusInvoice {
  label: string;
  value: StatusInvoiceValueType;
}

export const statusInvoiceList: IStatusInvoice[] = [
  {
    label: "Compensado",
    value: "CLOSE",
  },
  {
    label: "Aberto",
    value: "OPEN",
  },
  {
    label: "Todos",
    value: "ALL",
  },
];
