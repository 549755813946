/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from "react";
import { ModalOrderLoaderProps, tabLabels } from "../models/ModalOrdes";
import ModalOrderDetailsHeader from "../../../components/ModalOrderDetailsHeader/ModalOrderDetailsHeader";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import ModalOrderDetailsContent from "./ModalOrderDetailsContent";
import { Grid, Typography } from "@mui/material";
import useMedia from "use-media";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import { applyVolumeMask } from "../../../utils";
import { orderModalStatus } from "../../ConsultQuotaOrders/models/OptionsMenu";
import { makeStyles } from "@mui/styles";

const ModalOrderLoader: React.FC<ModalOrderLoaderProps> = ({
  withTab,
  isOpen,
  data,
  isView,
  isApproved = false,
  handleClose,
}) => {
  const classes = useStyles();
  const isMobile = useMedia({ maxWidth: 901 });
  const tabBody: React.ReactNode[] = [];
  const tabTotal: React.ReactNode[] = [];

  const buttonsOrderApprovedWithoutCaveat = [
    {
      title: "Fechar",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false, orderModalStatus.CLOSE);
      },
    },
  ];

  const buttonNewOrderRequest = [
    {
      title: "Salvar em rascunho",
      variant: CustomButtonVariant.OUTLINED,
      onClick: () => {
        handleClose(false, orderModalStatus.DRAFT);
      },
    },
    {
      title: "Realizar pedido",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false, orderModalStatus.ORDER);
      },
    },
  ];

  const buttonSeeOrder = [
    {
      title: "Ver pedido completo",
      variant: CustomButtonVariant.OUTLINED,
      onClick: () => {
        handleClose(false, orderModalStatus.SEE_ORDER);
      },
    },
    {
      title: "Fechar",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false, orderModalStatus.CLOSE);
      },
    },
  ];

  const modalTotalOrder = (quantity: string | undefined) => {
    return (
      <Grid
        key={quantity}
        container
        marginTop={2}
        marginBottom={2}
        justifyContent={"end"}
        display={"flex"}
      >
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <Typography className={classes.typographyStyleTitle}>
            Total do pedido
          </Typography>
          <Typography className={classes.typographyStyleVolumeNumber}>
            {applyVolumeMask(quantity || "0")}
          </Typography>
          <Typography className={classes.typographyStyleVolumeMask}>
            {data.measurementUnit || "L"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const tabs = Object.keys(data)
    .filter((ele) => ele !== "header" && ele !== "content")
    .reverse();

  if (tabs.length !== undefined) {
    tabs.map((key) =>
      tabBody.push(
        <ModalOrderDetailsContent
          color={
            key === "approved"
              ? "systemColors.success.main"
              : "shadesOfDark.steel"
          }
          key={key}
          modality={{
            title: data.content.modality.title,
            value: data[key].modality,
          }}
          productName={{
            title: data.content.productName.title,
            value: data[key].productName,
          }}
          quantity={{
            title: data.content.quantity.title,
            value: `${applyVolumeMask(data[key].quantity || "0")} ${
              data[key].measurementUnit
            }`,
          }}
        />
      )
    );
  }

  if (tabs.length !== undefined) {
    tabs.map((key) =>
      tabTotal.push(<div key={key}>{modalTotalOrder(data[key].quantity)}</div>)
    );
  }

  const modalOrderHeader = (
    <ModalOrderDetailsHeader
      icon={
        <StyledIcon
          iconType="list"
          sx={{
            width: "8rem",
            height: "8rem",
          }}
        />
      }
      distributor={data.header.distributor}
      requestNumber={data.header.requestNumber}
      subsidiary={data.header.subsidiaryDescription}
      requestDate={data.header.requestDate}
    />
  );

  const modalOrderTabs = withTab ? (
    <CustomTabs
      headerBorder={0}
      bodyBorder={1}
      tabsHeader={tabLabels}
      body={tabBody}
      total={tabTotal}
      paddingY={0}
    />
  ) : (
    <>
      <ModalOrderDetailsContent
        borderWidth={1}
        modality={{
          title: data.content.modality.title,
          value: data.modality as string,
        }}
        productName={{
          title: data.content.productName.title,
          value: data.productName as string,
        }}
        quantity={{
          title: data.content.quantity.title,
          value: `${applyVolumeMask(data.quantity)} ${
            data.measurementUnit && data.measurementUnit
          }`,
        }}
      />
      {modalTotalOrder(data.quantity)}
    </>
  );

  const modalContent = (
    <>
      {modalOrderHeader}
      {modalOrderTabs}
    </>
  );

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false, orderModalStatus.CLOSE)}
      componentItems={modalContent}
      titleModal={"Detalhes do pedido de cota"}
      buttons={
        isApproved
          ? buttonsOrderApprovedWithoutCaveat
          : isView
          ? buttonSeeOrder
          : buttonNewOrderRequest
      }
    />
  );
};

export default ModalOrderLoader;

const useStyles = makeStyles({
  typographyStyleTitle: {
    fontSize: 19,
    fontWeight: 700,
    color: "shadesOfDark.steel",
    marginRight: 1,
  },
  typographyStyleVolumeNumber: {
    fontSize: 24,
    fontWeight: 700,
    color: "shadesOfDark.dark",
    marginRight: 1,
  },
  typographyStyleVolumeMask: {
    fontSize: 14,
    fontWeight: 400,
    color: "shadesOfDark.dark",
    marginRight: 1,
  },
});
