import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";

export interface TableRow {
  scheduleDate: string;
  scheduleTime: string;
  product: string;
  quantity: string;
  unit: string;
  scheduleNumber: string;
  customer: string;
  subsidiary: string;
  paymentCondition: string;
  mod: string;
  statusText: PipelineScheduleStatus;
  statusIcon?: React.ReactNode;
  menuIcon?: React.ReactNode;
  customerRequester: string;
  createdAt: string;
  customerRequesterNote?: string;
}

export const openedInitQuery: IGetPipelineSchedulesQuery = {
  limit: 10,
  page: 1,
  status: [
    PipelineScheduleStatus.VENDOR_APPROVED,
    PipelineScheduleStatus.VENDOR_PENDING,
    PipelineScheduleStatus.FINANCIAL_BLOCKED,
    PipelineScheduleStatus.INVOICE_PENDING,
    PipelineScheduleStatus.IN_LOADING,
    PipelineScheduleStatus.IN_SCHEDULING,
  ].join(","),
};

export const clearedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.INVOICE_CLEARED].join(","),
};

export const rejectedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.VENDOR_REJECTED].join(","),
};

export interface TabsDeliveriesPipelineProps {
  onChangeCount?: (value: number) => void;
  dateFilterFrom?: Date | null | undefined;
  dateFilterTo?: Date | null | undefined;
  menuIcon?: (item: TableRow) => React.ReactNode;
  customerCompanyIds: string;
  subsidiary?: string;
}
