export enum TypesPermissions {
  /* executive module permissions */
  PRICE = "APP-REAM:EXECUTIVE:PRICE:*",
  MONTHLY_PARAMETERS = "APP-REAM:EXECUTIVE:MONTHLY-PARAMETERS:*",
  OPERATING_CONDITIONS = "APP-REAM:EXECUTIVE:OPERATING-CONDITIONS:*",
  /* financial module permissions */
  INVOICES = "APP-REAM:FINANCIAL:INVOICES:READ",
  MOVEMENT = "APP-REAM:FINANCIAL:MOVEMENT:READ",
  STATEMENT = "APP-REAM:FINANCIAL:STATEMENT:READ",
  TAX_REIMBURSEMENT = "APP-REAM:FINANCIAL:REFUND-ICMS:READ",
  TAX_REIMBURSEMENT_REQUEST = "APP-REAM:FINANCIAL:REFUND-ICMS:WRITE",
  /* suplly module permissions */
  FINANCIAL_MOVEMENT = "APP-REAM:SUPPLY:FINANCIAL-MOVEMENT:READ",
  QUOTE_EXTRACT = "APP-REAM:SUPPLY:QUOTE-EXTRACT:READ",
  QUOTE_BALANCE = "APP-REAM:SUPPLY:QUOTE-BALANCE:READ",
  QUOTE = "APP-REAM:SUPPLY:QUOTE:READ",
  REQUEST = "APP-REAM:SUPPLY:QUOTE:WRITE",
  /* operational module permissions */
  PIPELINES = "APP-REAM:OPERATIONAL:PIPELINES:READ",
  PIPELINES_REQUEST = "APP-REAM:OPERATIONAL:PIPELINES:WRITE",
  ROAD_STATIONS = "APP-REAM:OPERATIONAL:ROAD-STATIONS:READ",
  ROAD_STATIONS_REQUEST = "APP-REAM:OPERATIONAL:ROAD-STATIONS:WRITE",
  RIVER = "APP-REAM:OPERATIONAL:MARINE:READ",
  RIVER_REQUEST = "APP-REAM:OPERATIONAL:MARINE-SCHEDULE:WRITE",
  MARITIME = "APP-REAM:OPERATIONAL:MARITIME:*",
  PROGRAMMING = "APP-REAM:OPERATIONAL:PROGRAMMING:*",
}

export enum WhatPermission {
  WRITE = "*:WRITE",
}
