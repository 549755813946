import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import "./styles.css";

export interface CustomButtonProps
  extends Omit<ButtonProps<"button">, "variant"> {
  variant?: CustomButtonVariant;
  title: string;
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  variant = CustomButtonVariant.CONTAINED,
  onClick,
  ...buttonProps
}) => {
  const { sx: buttonSxProps } = buttonProps;
  const theme = useTheme();

  const getColorText = () => {
    if (
      variant.toLowerCase().includes("contained") ||
      variant.toLowerCase().includes("error")
    ) {
      return "shadesOfDark.white";
    }

    if (variant.toLowerCase().includes("outlined")) {
      return "shadesOfDark.dark";
    }

    if (variant.toLowerCase().includes("enable")) {
      return "error.main";
    }

    if (variant.toLowerCase().includes("disabled")) {
      return "shadesOfDark.medium";
    }
    return "shadesOfDark.dark";
  };

  const getBackgroundButton = () => {
    if (variant.toLowerCase().includes("contained")) {
      return "shadesOfDark.dark";
    }
    if (
      variant.toLowerCase().includes("outlined") ||
      variant.toLowerCase().includes("enable")
    ) {
      return "shadesOfDark.white";
    }
    if (variant.toLowerCase().includes("disabled")) {
      return "shadesOfDark.ultraLight";
    }
    if (variant.toLowerCase().includes("error")) {
      return "error.main";
    }
    return "shadesOfDark.white";
  };

  const getColorDownloadButton = () => {
    if (variant.toLowerCase().includes("outlined")) {
      return theme.palette.shadesOfDark.dark;
    }
    return undefined;
  };

  const getBorder = () => {
    if (variant.toLowerCase().includes("outlined")) {
      return `1px solid ${theme.palette.shadesOfDark.dark}`;
    }

    if (variant.toLowerCase().includes("enable")) {
      return `1px solid ${theme.palette.primary.main}`;
    }

    return `1px solid ${theme.palette.shadesOfDark.white}`;
  };

  const getProgressColor = () => {
    if (variant.toLowerCase().includes("outlined")) {
      return theme.palette.shadesOfDark.dark;
    }
    return theme.palette.shadesOfDark.white;
  };

  const isDownloadButton = variant.toLowerCase().includes("download");

  const isLoading = variant.toLowerCase().includes("loading");

  const isDisabled = variant.toLowerCase().includes("disabled");

  const CustomProgress: React.FC = () => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ opacity: 0 }}
        >
          <Typography fontFamily={"Open Sans"} fontWeight={700}>
            {title}
          </Typography>
          {isDownloadButton && (
            <StyledIcon iconType="download-button" ml={1.5} />
          )}
        </Box>
        <CircularProgress
          size={16}
          color={"primary"}
          sx={{
            position: "absolute",
            "&.MuiCircularProgress-colorPrimary": {
              color: getProgressColor,
            },
          }}
        />
      </Box>
    );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!isLoading && !isDisabled && onClick) {
      onClick();
    } else {
      event.preventDefault();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    if (isLoading && isDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Button
      {...buttonProps}
      sx={{
        cursor: isDisabled ? "not-allowed" : "pointer",
        color: "#fff",
        bgcolor: getBackgroundButton(),
        border: getBorder(),
        px: 2,
        py: 1,
        "&:hover": {
          backgroundColor: getBackgroundButton(),
        },
        ...buttonSxProps,
      }}
      onClick={handleClick}
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <CustomProgress />
      ) : (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography
            fontFamily={"Open Sans"}
            fontWeight={700}
            color={getColorText}
            textAlign={"center"}
          >
            {title}
          </Typography>
          {isDownloadButton && (
            <StyledIcon
              iconType="download-button"
              ml={1.5}
              iconColor={getColorDownloadButton()}
            />
          )}
        </Box>
      )}
    </Button>
  );
};

export default CustomButton;

export enum CustomButtonVariant {
  OUTLINED = "OUTLINED",
  OUTLINED_LOADING = "OUTLINED_LOADING",
  OUTLINED_DOWNLOAD = "OUTLINED_DOWNLOAD",
  OUTLINED_DOWNLOAD_LOADING = "OUTLINED_DOWNLOAD_LOADING",

  CONTAINED = "CONTAINED",
  CONTAINED_LOADING = "CONTAINED_LOADING",
  CONTAINED_DOWNLOAD = "CONTAINED_DOWNLOAD",
  CONTAINED_DOWNLOAD_LOADING = "CONTAINED_DOWNLOAD_LOADING",

  DISABLED = "DISABLED",

  ERROR = "ERROR",
  ERROR_CONTAINED = "ENABLE",
  ERROR_LOADING = "ERROR_LOADING",
}
// eslint-disable-next-line prettier/prettier

