import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";

export const openedInitQuery: IGetPipelineSchedulesQuery = {
  limit: 10,
  page: 1,
  status: [
    PipelineScheduleStatus.VENDOR_APPROVED,
    PipelineScheduleStatus.VENDOR_PENDING,
    PipelineScheduleStatus.FINANCIAL_BLOCKED,
    PipelineScheduleStatus.INVOICE_PENDING,
    PipelineScheduleStatus.IN_LOADING,
    PipelineScheduleStatus.IN_SCHEDULING,
  ].join(","),
};

export const clearedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.INVOICE_CLEARED].join(","),
};

export const rejectedInitQuery: IGetPipelineSchedulesQuery = {
  ...openedInitQuery,
  status: [PipelineScheduleStatus.VENDOR_REJECTED].join(","),
};
