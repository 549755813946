import * as _ from "lodash";
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import Select from "../../components/Select/Select";
import TextFieldCustom from "../../components/TextFieldCustom/TextFieldCustom";
import ModalOrderLoader from "./Section/ModalOrderLoader";
import { DataOrder, content } from "./models/ModalOrdes";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import {
  getFormattedTimestamp,
  referenceMonthLastMap,
  referenceMonthMap,
} from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "../../components/BreadcrumbCustom/BreadcrumbCustom";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { useOrderQuote } from "../../contexts/orderQuote/OrderQuoteContext";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { applyVolumeMask, getFormatCNPJ } from "../../utils";
import {
  ModalityDTO,
  ProductDTO,
} from "../../modules/distributors/models/DistributorListDTO";
import { ICreateOrder } from "../../modules/supply/models/CreateOrder";
import useDialogAlert, { SnackbarProps } from "../../hooks/DialogAlert";
import { GetOrdersPaginatedByIdDTO } from "../../modules/supply/models/GetOrdersDTO";
import { format } from "date-fns";
import { StatusOrderIcon } from "../ConsultQuotaOrders/models/StatusOrderIcon";
import SelectSubsidiaries from "../../components/SelectSubsidiary/SelectSubsidiary";
import { IEditOrder } from "../../modules/supply/models/EditOrder";

interface FormProps {
  distributor: string;
  month: string;
  product: string;
  modality: string;
  quantity: number;
  subsidiary: string;
}
export interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const QuoteRequestPage: React.FC = () => {
  const { distributors, selectedDistributor, subsidiaries } = useDistributor();
  const { snackbar } = useDialogAlert();
  const {
    getModalities,
    getProducts,
    createDraft,
    updateOrder,
    commitOrderDraft,
    itemInView,
    dataItemInView,
    itemUpdate,
  } = useOrderQuote();

  const navigate = useNavigate();
  const [products, setProducts] = useState<ProductDTO[]>([]);
  const [modalities, setModalities] = useState<ModalityDTO[]>([]);
  const [modalityCustom, setModalityCustom] = useState("");
  const [currentOrder, setCurrentOrder] = useState<ICreateOrder>();
  const [modalData, setModalData] = useState<DataOrder>();
  const [rawDraftData, setRawDraftData] = useState<GetOrdersPaginatedByIdDTO>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const currentYear = useRef(new Date().getFullYear());
  const currentMonth = useRef(new Date().getMonth() + 1);

  const months = useMemo(
    () => referenceMonthMap(),
    [currentYear.current, currentMonth.current]
  );

  const monthsLasts = useMemo(
    () => referenceMonthLastMap(),
    [currentYear.current, currentMonth.current]
  );

  const formRef = useRef<FormikProps<FormProps>>(null);

  const handleClose = useCallback(
    async (open: boolean, flag: string) => {
      const snackDraft: SnackbarProps = {
        message: "Pedido de cota salvo em rascunho com sucesso.",
        variant: "success",
      };
      switch (flag) {
        case "draft": {
          if (!isView) {
            snackbar(snackDraft);
            navigate(routesConfig.SUPPLIES().QUOTE, { state: { tab: 1 } });
          }
          break;
        }
        case "order": {
          try {
            if (
              rawDraftData?.id !== undefined ||
              (isView && dataItemInView?.id !== undefined)
            ) {
              const id = isView ? dataItemInView?.id : rawDraftData?.id;
              if (id !== undefined) {
                const response = await commitOrderDraft?.(id);
                if (response === "") {
                  snackbar({
                    message: "Pedido de cota solicitado com sucesso.",
                    variant: "success",
                  });
                  navigate(routesConfig.SUPPLIES().QUOTE, {
                    state: { tab: 0 },
                  });
                }
              }
            }
          } catch (error: any) {
            snackbar({
              message: error.message,
              variant: "error",
            });
            snackbar({
              message: "Erro ao realizar pedido de cota.",
              variant: "error",
            });
          }
          break;
        }
        case "close": {
          if (rawDraftData) snackbar(snackDraft);
        }
      }

      setIsOpen(open);
      setIsTab(false);
    },
    [rawDraftData, dataItemInView]
  );

  const getModalitiesData = useCallback(async () => {
    const response = await getModalities?.();
    if (response === undefined) return;
    if (response?.length) setModalities(response);
  }, [getModalities, setModalities]);

  const getProductsData = useCallback(async () => {
    const response = await getProducts?.();
    if (response === undefined) return;
    if (response?.length) setProducts(response);
  }, [getProducts, setProducts]);

  const findProduct = (id: string) => {
    return products.find((ele) => ele.id === id);
  };

  const findModality = (code: string) => {
    return modalities.find((ele) => ele.code === code);
  };

  const modalityByProduct = (prod: string, setProd?: any) => {
    const modMapped: ModalityDTO[] = [];
    const mods = modalities.filter((item) =>
      item.metadata?.logistics.some((ele) =>
        findProduct(prod)?.metadata.logistics.includes(ele)
      )
    );

    mods.forEach((mod) =>
      mod.metadata.logistics.forEach((log) =>
        modMapped.push({ ...mod, metadata: { logistics: [log] } })
      )
    );
    return modMapped;
  };

  const isView = useMemo(() => {
    return itemInView !== "";
  }, [itemInView]);

  const isUpdate = useMemo(() => {
    return itemUpdate !== "";
  }, [itemInView]);

  useEffect(() => {
    if (!_.isEmpty(dataItemInView)) {
      formRef.current?.setValues({
        distributor: dataItemInView?.customerCompanyId ?? "",
        subsidiary:
          dataItemInView?.contractRequestEntries?.[0].subsidiaryId ?? "",
        month: dataItemInView?.monthReference ?? "",
        product: dataItemInView?.contractRequestEntries?.[0].productId ?? "",
        modality: dataItemInView?.contractRequestEntries?.[0].mod ?? "",
        quantity:
          dataItemInView?.contractRequestEntries?.[0].volumeRequested ?? 0,
      });
    }
  }, [dataItemInView]);

  useEffect(() => {
    getProductsData();
    getModalitiesData();
  }, [getProductsData, getModalitiesData]);

  useEffect(() => {
    const dist = distributors.find(
      (ele) => ele.id === currentOrder?.customerCompanyId
    );

    if (currentOrder !== undefined) {
      const { mod, volumeRequested, productId } =
        currentOrder.contractRequestEntries[0];
      const prod = findProduct(productId);
      const modality = findModality(mod);

      setModalData({
        content: { ...content },
        header: {
          distributor: {
            title: "Nome da distribuidora",
            value: dist?.description,
          },
          requestNumber: {
            title: "N. do pedido",
            value: rawDraftData?.refId
              ? rawDraftData?.refId
              : dataItemInView?.refId,
          },
          requestDate: {
            title: "Data do pedido",
            value: rawDraftData?.createdAt
              ? format(new Date(rawDraftData?.createdAt), "dd/MM/yyyy")
              : dataItemInView?.createdAt
              ? format(new Date(dataItemInView?.createdAt), "dd/MM/yyyy")
              : "",
          },
          subsidiaryDescription: {
            title: "Filial",
            value:
              rawDraftData?.contractRequestEntries?.[0]?.subsidiary
                ?.description ??
              dataItemInView?.contractRequestEntries?.[0]?.subsidiary
                ?.description ??
              "-",
          },
        },
        productName: prod?.description ?? "",
        modality: modality?.code ?? "",
        quantity: volumeRequested.toString(),
        measurementUnit: prod?.measurementUnit ?? "",
      });
    }
  }, [currentOrder, rawDraftData, dataItemInView]);

  const breadcrumbs: BreadcrumbChild[] = [
    {
      isIcon: true,
      link: routesConfig.HOME,
      icon: <StyledIcon iconType={"home"} />,
    },
    { link: routesConfig.SUPPLIES().QUOTE, label: "Pedidos de cota" },
    {
      label: !isView
        ? "NOVO PEDIDO DE COTA"
        : dataItemInView?.step !== "NOT_SENT"
        ? "PEDIDO DE COTA"
        : "PEDIDO DE COTA EM RASCUNHO",
    },
  ];

  const getButtonVariant = (isValid: boolean) => {
    let variant;
    switch (true) {
      case isLoading:
        variant = CustomButtonVariant.CONTAINED_LOADING;
        break;
      case (isView && dataItemInView?.step === "NOT_SENT") ||
        (isValid && !isView):
        variant = CustomButtonVariant.CONTAINED;
        break;
      default:
        variant = CustomButtonVariant.DISABLED;
    }
    return variant;
  };

  const handleQuantityChange = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = value.target.value.replace(/[^\d.-]/g, "");
    const parsedValue = parseFloat(
      inputValue.replace(/\./g, "").replace(",", ".")
    );
    return parsedValue;
  };

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Pedidos de cota" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title={
                !isView
                  ? "Novo pedido de cota"
                  : isView && dataItemInView?.step !== "NOT_SENT"
                  ? "Pedido de cota"
                  : "Pedido de cota em rascunho"
              }
              icon={
                dataItemInView?.status !== undefined
                  ? StatusOrderIcon[
                      dataItemInView?.status as keyof typeof StatusOrderIcon
                    ]
                  : undefined
              }
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Voltar para pedidos de cotas",
                onClick: () => {
                  navigate(routesConfig.SUPPLIES().QUOTE, {
                    state: {
                      tab: dataItemInView?.step === "NOT_SENT" ? 1 : 0,
                    },
                  });
                },
              }}
            />
            <BreadcrumbCustom data={breadcrumbs} />
            <Divider sx={{ marginTop: 2 }} />
            <Formik
              innerRef={formRef}
              initialValues={{
                distributor: selectedDistributor?.id ?? "",
                month: months[0].value,
                product: "",
                modality: "",
                quantity: 0,
                subsidiary: "",
              }}
              isInitialValid={false}
              validationSchema={Yup.object({
                distributor: Yup.string().required(
                  "Selecione uma distribuidora"
                ),
                product: Yup.string().required("Selecione um produto"),
                subsidiary: Yup.string().required("Selecione uma filial"),
                month: Yup.string().required("Selecione um mês"),
                modality: Yup.string().required("Selecione uma modalidade"),
                quantity: Yup.number()
                  .required("Campo obrigatório")
                  .positive("Campo é obrigatório e deve ser positivo")
                  .max(
                    10000000000,
                    "Limite máximo para quantidade de 10 bilhões"
                  ),
              })}
              onSubmit={async function (values: FormProps) {
                try {
                  setLoading(true);
                  const order: ICreateOrder = {
                    monthReference: values.month,
                    contractRequestEntries: [
                      {
                        mod: values.modality,
                        productId: values.product,
                        volumeRequested: values.quantity,
                        subsidiaryId: values.subsidiary,
                      },
                    ],
                    customerCompanyId: values.distributor,
                  };

                  const orderUpdate: IEditOrder = {
                    contractRequestEntries: [
                      {
                        mod: values.modality,
                        productId: values.product,
                        volumeRequested: values.quantity,
                        subsidiaryId: values.subsidiary,
                      },
                    ],
                  };

                  setCurrentOrder(order);

                  if (!isView && !isUpdate) {
                    const response = await createDraft?.(order);
                    if (response !== undefined) {
                      setRawDraftData(response);
                      setIsOpen(true);
                    }
                  } else if (isUpdate && itemUpdate) {
                    await updateOrder?.(itemUpdate, orderUpdate);
                    navigate(routesConfig.SUPPLIES().QUOTE, {
                      state: { tab: 0 },
                    });
                  } else {
                    setIsOpen(true);
                  }
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                }
              }}
            >
              {({
                errors,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                isValid,
              }) => {
                const hasErrorProduct =
                  touched.product && Boolean(errors.product);
                const hasErrorDistributor =
                  touched.distributor && Boolean(errors.distributor);
                const hasErrorMonth = touched.month && Boolean(errors.month);
                const hasErrorModality =
                  touched.modality && Boolean(errors.modality);
                const hasErrorQuantity =
                  touched.quantity && Boolean(errors.quantity);
                const hasErrorSubsidiary =
                  touched.subsidiary && Boolean(errors.subsidiary);

                const measurementUnit =
                  findProduct(values.product)?.measurementUnit ?? "";

                const modalitiesByProduct =
                  values.product !== ""
                    ? modalityByProduct(values.product)
                    : modalities;

                const getModalityValue = () =>
                  modalitiesByProduct.find((item) =>
                    modalityCustom === ""
                      ? item.code === values.modality
                      : `${item.metadata.logistics[0]}-${item.code}` ===
                        modalityCustom
                  );

                const modalityValue = useMemo(getModalityValue, [
                  values.modality,
                  modalitiesByProduct,
                ]);

                const monthsValues = isView ? months : monthsLasts;

                return (
                  <Form>
                    <Grid container spacing={3} mt={2}>
                      <Grid item xs={12}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                        >
                          Informações do pedido
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          label="Distribuidora"
                          options={distributors.map((option) => ({
                            option,
                          }))}
                          value={distributors.find(
                            (item) => item.id === values.distributor
                          )}
                          isDisabled={isView || isUpdate}
                          getOptionItemLabel={(value) => getFormatCNPJ(value)}
                          getSelectedOptionLabel={(value) =>
                            getFormatCNPJ(
                              value,
                              value.id === values.distributor ?? false,
                              true,
                              18
                            )
                          }
                          onBlur={() => {
                            setFieldTouched("distributor", true);
                          }}
                          onChange={(value) =>
                            setFieldValue("distributor", value.id)
                          }
                          hasError={hasErrorDistributor}
                          messageError={errors.distributor}
                        />
                      </Grid>
                      <Grid item xs={0} md={4} />
                      <Grid item xs={0} md={4} />
                      <Grid item xs={12} md={4}>
                        <SelectSubsidiaries
                          label={"Filial"}
                          isDisabledValue={isView || isUpdate}
                          valueSubsidiary={subsidiaries.find(
                            (item) => item.id === values.subsidiary
                          )}
                          onChangeValue={(selectedOption) => {
                            if (selectedOption) {
                              setFieldValue("subsidiary", selectedOption.id);
                            }
                          }}
                          onBlurValue={() => {
                            setFieldTouched("subsidiary", true);
                          }}
                          hasErrorValue={hasErrorSubsidiary}
                          messageErrorValue={errors.subsidiary}
                        />
                      </Grid>
                      <Grid item xs={0} md={4} />
                      <Grid item xs={0} md={4} />
                      <Grid item xs={12} md={4}>
                        <Select
                          label="Mês"
                          value={months.find(
                            (item) => item.value === values.month
                          )}
                          options={monthsValues.map((option) => ({
                            option,
                          }))}
                          isDisabled={isView || isUpdate}
                          getOptionItemLabel={(value) => value.label}
                          getSelectedOptionLabel={(value) => value.label}
                          onChange={(value) =>
                            setFieldValue("month", value.value)
                          }
                          onBlur={() => {
                            setFieldTouched("month", true);
                          }}
                          hasError={hasErrorMonth}
                          messageError={errors.month}
                        />
                      </Grid>
                      <Grid item xs={0} md={4} />
                      <Grid item xs={0} md={4} />
                      <Grid container item spacing={1} mt={5}>
                        <Grid item xs={12}>
                          <Typography
                            fontFamily={"Open Sans"}
                            fontSize={16}
                            fontWeight={700}
                            color={"shadesOfDark.dark"}
                          >
                            Pedido de produtos
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Select
                            label="Produto"
                            value={products.find(
                              (item) => item.id === values.product
                            )}
                            options={products.map((option) => ({
                              option,
                            }))}
                            getOptionItemLabel={(value) => value.description}
                            getSelectedOptionLabel={(value) =>
                              value.description
                            }
                            isDisabled={
                              values.distributor === "" || isView || isUpdate
                            }
                            onChange={(value) =>
                              setFieldValue("product", value.id)
                            }
                            onBlur={() => {
                              setFieldTouched("product", true);
                            }}
                            hasError={hasErrorProduct}
                            messageError={errors.product}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Select
                            label="Modalidade"
                            isDisabled={!values.product || isView || isUpdate}
                            value={modalityValue}
                            options={modalitiesByProduct.map((option) => ({
                              option,
                            }))}
                            getOptionItemLabel={(value) =>
                              `${value.metadata.logistics[0] || ""} - ${
                                value.code || ""
                              }` || ""
                            }
                            getSelectedOptionLabel={(value) =>
                              `${value.metadata.logistics[0] || ""} - ${
                                value.code || ""
                              }` || ""
                            }
                            onChange={(value) => {
                              setFieldValue("modality", value.code);
                              setModalityCustom(
                                `${value.metadata.logistics[0]}-${value.code}`
                              );
                            }}
                            onBlur={() => {
                              setFieldTouched("modality", true);
                            }}
                            hasError={hasErrorModality}
                            messageError={errors.modality}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextFieldCustom
                            label={`Quantidade ${
                              measurementUnit !== ""
                                ? `(${measurementUnit})`
                                : ""
                            }`}
                            type="text"
                            inputProps={{
                              min: 0,
                              max: 10000000000,
                              step: 0.001,
                            }}
                            placeholder="Informe a quantidade"
                            value={applyVolumeMask(values.quantity.toString())}
                            hasError={hasErrorQuantity}
                            messageError={errors.quantity}
                            disabled={!values.modality || isView}
                            onChange={(value) => {
                              const quantity = handleQuantityChange(value);
                              setFieldValue("quantity", quantity);
                            }}
                            onBlur={() => {
                              setFieldTouched("quantity", true);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mt={5}>
                        <Divider />
                        <Box display={"flex"} mt={2}>
                          <Typography
                            color={"shadesOfDark.steel"}
                            fontFamily={"Open Sans"}
                            fontWeight={700}
                            fontSize={24}
                          >
                            Total do pedido:
                          </Typography>
                          <Typography
                            fontFamily={"Open Sans"}
                            fontWeight={700}
                            fontSize={24}
                            color={"shadesOfDark.dark"}
                          >
                            {`${applyVolumeMask(
                              values.quantity.toString()
                            )} ${measurementUnit}`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} mt={10}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-end"}
                      >
                        <Box display={"flex"}>
                          <CustomButton
                            title={"Cancelar"}
                            variant={CustomButtonVariant.OUTLINED}
                            onClick={() =>
                              navigate(routesConfig.SUPPLIES().QUOTE, {
                                state: {
                                  tab:
                                    dataItemInView?.step === "NOT_SENT" ? 1 : 0,
                                },
                              })
                            }
                          />

                          {!isUpdate && (
                            <CustomButton
                              type="submit"
                              title={"Salvar"}
                              disabled={
                                isView && dataItemInView?.step !== "NOT_SENT"
                              }
                              variant={getButtonVariant(isValid)}
                              onClick={() => null}
                              sx={{
                                ml: 1,
                              }}
                            />
                          )}

                          {isUpdate && (
                            <CustomButton
                              type="submit"
                              title={"Editar"}
                              variant={
                                isLoading
                                  ? CustomButtonVariant.CONTAINED_LOADING
                                  : CustomButtonVariant.CONTAINED
                              }
                              onClick={() => null}
                              sx={{
                                ml: 1,
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CustomCard>
          {modalData && (
            <ModalOrderLoader
              handleClose={async (open, flag) => await handleClose(open, flag)}
              isOpen={isOpen}
              withTab={isTab}
              data={modalData}
            ></ModalOrderLoader>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default QuoteRequestPage;
