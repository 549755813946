import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableHeaderBalanceColumns: Column[] = [
  {
    label: "Cliente",
    id: "customer",
    align: "left",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Filial",
    id: "subsidiary",
    align: "left",
    showIcon: false,
    sortable: true,
  },
  {
    label: "CNPJ",
    id: "cnpj",
    align: "left",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Produto ",
    id: "product",
    align: "left",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Modalidade ",
    id: "mod",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Cota Mês ",
    id: "quota",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Cota Atualizada",
    id: "finalQuota",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Adicional ",
    id: "additional",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Antecipação ",
    id: "anticipation",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Corte ",
    id: "cut",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Repactuação ",
    id: "renegotiation",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Suplementação ",
    id: "supplementation",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Transferência ",
    id: "transference",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Transformação ",
    id: "transformation",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Volume faturado ",
    id: "stated",
    align: "right",
    showIcon: false,
    sortable: true,
  },
  {
    label: "Saldo ",
    id: "balance",
    align: "right",
    showIcon: false,
    sortable: true,
  },
];
