import { Box, BoxProps } from "@mui/material";
import * as React from "react";

interface CustomCardProps extends BoxProps {
  children: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({ children, ...boxProps }) => {
  const { sx, ...rest } = boxProps;
  return (
    <Box
      sx={{
        boxShadow:
          "0px 2px 12px -4px rgba(58, 53, 65, 0.1), 0px 2px 12px -4px rgba(58, 53, 65, 0.08), 0px 6px 16px -4px rgba(58, 53, 65, 0.1)",
        borderRadius: 1.5,
        background: "#fff",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CustomCard;
