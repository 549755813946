import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Select from "../../../components/Select/Select";
import { searchFilterList } from "../models/SearchFilterOptions";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import SelectDistributors from "../../../components/SelectDistributors/SelectDistributors";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";

interface IQueryProps {
  customerCompanyId?: string;
  refId?: string;
  subsidiaryId?: string;
  status?: string;
}
interface ButtonSearch {
  dataFilter: (query: IQueryProps) => void;
  enableSelect?: boolean;
}

const ButtonSearchItemTableSection: React.FC<ButtonSearch> = ({
  dataFilter,
  enableSelect = true,
}) => {
  const { selectedDistributor, subsidiaries } = useDistributor();
  const [searchNumber, setSearchNumber] = useState<string | undefined>(
    undefined
  );
  const [codeStatus, setCodeStatus] = useState("SENT");
  const [codeDistributor, setCodeDistributor] = useState<string | undefined>(
    undefined
  );
  const [codeSubsidiary, setCodeSubsidiary] = useState<string | undefined>(
    undefined
  );

  const handleChangeStatus = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement> | any) => {
      setCodeStatus(event.value);
    },
    []
  );

  const handleChangeNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const data = event.target.value.replace(/^-\D/g, "");
      setSearchNumber(data ?? undefined);
    },
    []
  );

  useEffect(() => {
    setCodeDistributor(selectedDistributor?.id ?? undefined);
  }, [selectedDistributor]);

  useEffect(() => {
    dataFilter({
      ...(codeStatus && { status: codeStatus }),
      ...(searchNumber && { refId: searchNumber }),
      ...(codeSubsidiary && {
        subsidiaryId: codeSubsidiary,
      }),
      ...(codeDistributor && {
        customerCompanyId: codeDistributor,
      }),
    });
  }, [codeSubsidiary, codeDistributor, codeStatus, searchNumber]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={3}>
            <SelectDistributors
              label="Selecione o cliente"
              isCutText
              maxLenghtSelected={25}
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <SelectSubsidiaries
              label={"Filial"}
              valueSubsidiary={subsidiaries.find(
                (item) => item.id === codeSubsidiary
              )}
              onChangeValue={(selectedOption) => {
                if (selectedOption) {
                  setCodeSubsidiary(selectedOption.id);
                }
              }}
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <TextFieldCustom
              label="Número da solicitação"
              placeholder="Busque pelo número da solicitação"
              value={searchNumber}
              onChange={handleChangeNumber}
              endComponent={<StyledIcon iconType="search" />}
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            {enableSelect && (
              <Select
                label="Selecione um status"
                options={searchFilterList.map((item) => ({ option: item }))}
                value={searchFilterList.find((ele) => ele.value === codeStatus)}
                getOptionItemLabel={(value) => value.label}
                getSelectedOptionLabel={(value) => value.label}
                onChange={handleChangeStatus}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      ></Grid>
    </Grid>
  );
};

export default ButtonSearchItemTableSection;
