import { FormGroup } from "@material-ui/core";
import { TextField, Typography, styled } from "@mui/material";
import MuiBox from "@mui/material/Box";
import { getMonth, getYear } from "date-fns";
import React, { useEffect, useRef, RefObject } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledIcon } from "../StyledIcon/StyledIcon";
import { dateRangeFormat } from "../../utils/dateFormatter";

export const Box = styled(MuiBox)({
  background: "#FFFFFF",
  boxShadow:
    "0px 6px 10px rgba(0, 0, 0, 0.12), 0px 1px 18px rgba(0, 0, 0, 0.06)",
  borderRadius: "4px",
  "& .react-datepicker": {
    zIndex: 3,
    border: "none",
    padding: "15px",
    position: "absolute",
    boxShadow:
      "0px 6px 10px rgba(0, 0, 0, 0.12), 0px 1px 18px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__header--custom": {
    borderBottom: "0px solid #aeaeae",
    backgroundColor: "#fff",
  },
  "& .react-datepicker__day": {
    fontSize: "13px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    width: "40px",
    height: "30px",
    margin: "4px 0px",
    borderRadius: "0px",
    paddingTop: "4px",
  },

  "& .react-datepicker__day-names": {
    fontWeight: "700",
    fontSize: "12px",
    marginTop: "20px",
  },

  "& .react-datepicker__day-name": {
    color: "#58595B",
    width: "auto",
    padding: "0 6px",
  },

  "& .react-datepicker__day--in-range": {
    background: "#E8E9EB",
    color: "#58595B",
    ":hover": {
      background: "#8C8D8F!important",
      color: "#FFF!important",
    },
  },
  "& .react-datepicker__day--range-start": {
    paddingTop: "4px",
    width: "40px",
    height: "30px",
    background: "#FFFFFF",
    borderRadius: "30px 0px 0px 30px",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__day--range-end": {
    paddingTop: "4px",
    width: "40px",
    height: "30px",
    background: "#FFFFFF",
    borderRadius: "0px 30px 30px 0px",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.06)",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#FFFFFF",
    color: "#58595B",
  },
  "& .react-datepicker__day--in-selecting-range": {
    background: "#8C8D8F!important",
    color: "#FFF!important",
  },
});

const monthsBR = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const daysBR = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

const ptbr: Locale = {
  localize: {
    ordinalNumber: (...args: any[]) => "",
    era: (...args: any[]) => "",
    quarter: (...args: any[]) => "",
    day: (n: any) => daysBR[n],
    month: (n: any) => monthsBR[n],
    dayPeriod: (...args: any[]) => "",
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
    time: (...args: any[]) => "",
    dateTime: (...args: any[]) => "",
  },
};

interface CalendarRangeProps {
  name: string;
  label: string;
  value?: [Date, Date] | [null, null];
  onChangeDateStartEnd?: (dateBegin: Date, dateEnd: Date) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  clearValueCalendar?: boolean;
  disabledTextField?: boolean;
  firstDayOfMonth?: Date | null;
  lastDayOfMonth?: Date | null;
}

const CalendarRange: React.FC<CalendarRangeProps> = ({ ...props }) => {
  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>(
    props.value || [null, null]
  );
  const [startDate, endDate] = dateRange;
  const [isOpen, setIsOpen] = React.useState(false);
  const datePickerRef: RefObject<HTMLDivElement> = useRef(null);

  const handleChange = (date: [Date, Date]) => {
    if (props.onChangeDateStartEnd)
      props.onChangeDateStartEnd(date[0], date[1]);
    setDateRange(date);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  function range(start: number, end: number) {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }

  const years = range(1990, new Date().getFullYear() + 1);

  const getStartOrEnd = (startDate: Date | null, endDate: Date | null) =>
    dateRangeFormat(startDate, endDate) ?? "";

  const handleFirstAndLastDayOfMonth = (firstDate: Date, lastDate: Date) => {
    const dateArray: [Date, Date] = [firstDate, lastDate];
    setDateRange(dateArray);
  };

  useEffect(() => {
    if (props.firstDayOfMonth && props.lastDayOfMonth) {
      handleFirstAndLastDayOfMonth(props.firstDayOfMonth, props.lastDayOfMonth);
    }
  }, []);

  useEffect(() => {
    if (props.firstDayOfMonth && props.lastDayOfMonth) {
      handleFirstAndLastDayOfMonth(props.firstDayOfMonth, props.lastDayOfMonth);
    } else if (props.clearValueCalendar) {
      setDateRange([null, null]);
    }
  }, [props.clearValueCalendar]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <FormGroup ref={datePickerRef}>
      <Typography
        sx={{
          color: "#58595B",
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: "700",
          paddingBottom: "4px",
        }}
      >
        {props.label}
      </Typography>
      <TextField
        disabled={props.disabledTextField}
        type="text"
        placeholder="DD/MM/AAAA até DD/MM/AAAA"
        id="outlined-basic"
        variant="outlined"
        error={startDate !== null && endDate === null}
        helperText={
          startDate !== null && endDate === null
            ? "Selecione a data final para filtrar"
            : ""
        }
        name={props.name}
        onClick={handleClick}
        onBlur={props.onBlur}
        value={getStartOrEnd(startDate, endDate)}
        autoComplete="off"
        InputProps={{
          readOnly: true,
        }}
        sx={{
          height: "60px",
          width: "100%",
          backgroundColor: "#fff",
          "& .Mui-disabled": {
            background: "#E8E9EB",
          },
          "& .datepicker": {
            border: "2px solid #58595B",
          },
          "& .MuiInputLabel-shrink": {
            fontWeight: 700,
            backgroundColor: "#fff",
            borderRadius: "4px",
            color: "#58595B",
            paddingRight: 6,
            fontFamily: "Open Sans",
            fontSize: "20px",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid green",
          },
          "& .MuiOutlinedInput": {
            fontSize: "20px",
          },
          "& .MuiOutlinedInput-input": {
            fontSize: "14px",
            padding: "4px 8px",
            fontFamily: "Open Sans",
            color: "#58595B",
            height: "32px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "4px",
            opacity: "0.6",
          },
        }}
        fullWidth
      />

      <Box>
        {isOpen && !props.disabledTextField && (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <div>
                  <button
                    style={{
                      padding: "0",
                      cursor: "pointer",
                      background: "#FFF",
                      border: "none",
                    }}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <StyledIcon
                      iconType="left-arrow"
                      sx={{
                        height: "12px",
                        width: "14px",
                      }}
                    />
                  </button>

                  <select
                    style={{
                      color: "#58595B",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      border: "none",
                      textAlign: "center",
                      padding: "0 4px 0 4px",
                      cursor: "pointer",
                    }}
                    value={monthsBR[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(monthsBR.indexOf(value))
                    }
                  >
                    {monthsBR.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    style={{
                      backgroundColor: "#fff",
                      color: "#58595B",
                      fontSize: "14px",
                      border: "none",
                      cursor: "pointer",
                      marginRight: "25px",
                    }}
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(parseInt(value))
                    }
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    style={{
                      border: "none",
                      padding: "0",
                      cursor: "pointer",
                      background: "#FFF",
                    }}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <StyledIcon
                      iconType="right-arrow"
                      sx={{
                        height: "12px",
                        width: "14px",
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
            wrapperClassName="datePicker"
            startDate={startDate}
            endDate={endDate}
            locale={ptbr}
            onChange={handleChange}
            selectsRange
            inline
            isClearable
          />
        )}
      </Box>
    </FormGroup>
  );
};

export default CalendarRange;
