import Axios, { AxiosError, AxiosInstance } from "axios";
import { injectable } from "inversify";
import "reflect-metadata";
import config from "../../config/default";
import AppError from "../../utils/appError";
import { IHttpClientService } from "./models/IHttpClientService";
import { goToLogout } from "../../utils";

@injectable()
export default class HttpClientService implements IHttpClientService {
  private readonly httpInstance: AxiosInstance;

  constructor() {
    this.httpInstance = Axios.create({
      timeout: config.api.timeout,
      baseURL: config.api.url,
    });
  }

  setTokenExpirationStrategy(
    tokenExpireStrategy: () => Promise<string | null>
  ) {
    this.httpInstance.interceptors.request.clear();
    this.httpInstance.interceptors.request.use(async (config) => {
      const newTokenProvided = await tokenExpireStrategy();
      if (newTokenProvided) {
        this.setAuthorization(newTokenProvided);
        config.headers.Authorization = newTokenProvided;
      }
      return config;
    });
  }

  setAuthorization(token: string): void {
    this.httpInstance.defaults.headers.common.Authorization = token;
  }

  getAuthorization() {
    return this.httpInstance.defaults.headers.common.Authorization as string;
  }

  public async get<T>(path: string, params?: any): Promise<T> {
    return await this.httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
          throw new AppError("erro 403", "error");
        }
        if (error.response && error.response.data.message) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Erro ao realizar operação", "error");
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  public async post<T = any>(
    path: string,
    body: any,
    params?: any
  ): Promise<T> {
    return await this.httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        // NOTE:  Não habilitar o goToLogout() para o status 403 no post.
        if (error.response && error.response.data.message) {
          throw new AppError(
            error.response.data.message,
            "error",
            error.response.status
          );
        } else {
          throw new AppError(
            "Erro ao realizar operação",
            "error",
            error.response?.status
          );
        }
      });
  }

  public async patch<T = any>(
    path: string,
    body?: any,
    params?: any
  ): Promise<T> {
    return await this.httpInstance
      .patch<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        // NOTE:  Não habilitar o goToLogout() para o status 403 no patch.
        if (error.response && error.response.data.message) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Erro ao realizar operação", "error");
        }
      });
  }

  public async put<T = any>(
    path: string,
    body?: any,
    params?: any
  ): Promise<T> {
    return await this.httpInstance
      .put<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
          throw new AppError("erro 403", "error");
        }
        if (error.response && error.response.data.message) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Erro ao realizar operação", "error");
        }
      });
  }

  public async delete<T = any>(path: string, params?: any): Promise<T> {
    return await this.httpInstance
      .delete<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
          throw new AppError("erro 403", "error");
        }
        if (error.response && error.response.data.message) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Erro ao realizar operação", "error");
        }
      });
  }
}
