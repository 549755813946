import { ExecutiveTypes } from "../modules/executive/container/types";
import { FinancialTypes } from "../modules/financial/container/types";
import { DistributorsTypes } from "../modules/distributors/containers/types";
import { SupplyTypes } from "../modules/supply/container/types";
import { OperationalTypes } from "../modules/operational/container/type";
import { InformationTypes } from "../modules/information/container/type";
import { HomeTypes } from "../modules/home/container/type";

export const Types = {
  IHttpService: Symbol("IHttpService"),
  Executive: { ...ExecutiveTypes },
  Financial: { ...FinancialTypes },
  Distributors: { ...DistributorsTypes },
  Supply: { ...SupplyTypes },
  Operational: { ...OperationalTypes },
  Information: { ...InformationTypes },
  Home: { ...HomeTypes },
};
