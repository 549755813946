/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from "react";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import { applyVolumeMask } from "../../../utils";
import ModalIcmsDetailsContent from "./ModalIcmsDetailsContent";
import { ModalIcmsLoaderProps, tabLabels } from "../models/ModalOrdes";
import ModalIcmsDetailsHeader from "./ModalIcmsDetailsHeader";
import { formatCurrency } from "../../../utils/currencyFormatter";
import ModalDetailsNote from "../../../components/ModalDetailsNote/ModalDetailsNote";
import { Box } from "@mui/material";
import { RefundIcmsStatusIcons } from "../models/Filters";

const ModalIcmsLoader: React.FC<ModalIcmsLoaderProps> = ({
  withTab,
  isOpen,
  data,
  isView,
  isApproved = false,
  handleClose,
}) => {
  const tabBody: React.ReactNode[] = [];
  const buttonsOrderApprovedWithoutCaveat = [
    {
      title: "Fechar",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false, "close");
      },
    },
  ];

  const buttonNewIcmsRequest = [
    {
      title: "Cancelar",
      variant: CustomButtonVariant.OUTLINED,
      onClick: () => {
        handleClose(false, "close");
      },
    },
    {
      title: "Confirmar Solicitação",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false, "refund");
      },
    },
  ];

  const buttonSeeIcms = [
    {
      title: "Fechar",
      variant: CustomButtonVariant.OUTLINED,
      onClick: () => {
        handleClose(false, "close");
      },
    },
  ];

  const tabs = Object.keys(data)
    .filter((ele) => ele !== "header" && ele !== "content")
    .reverse();

  if (tabs.length !== undefined) {
    tabs.map((key) =>
      tabBody.push(
        <ModalIcmsDetailsContent
          color={
            key === "approved"
              ? "systemColors.success.main"
              : "shadesOfDark.steel"
          }
          key={key}
          xmlNotes={{
            title: data.content.xmlNotes.title,
            value: data[key].xmlNotes,
          }}
          petition={{
            title: data.content.petition.title,
            value: data[key].petition,
          }}
          refundValues={{
            title: data.content.refundValues.title,
            value: `R$ ${applyVolumeMask(data[key].refundValues)}`,
          }}
        />
      )
    );
  }

  const modalIcmsHeader = (
    <ModalIcmsDetailsHeader
      icon={
        <StyledIcon
          iconType="list"
          sx={{
            width: "8rem",
            height: "8rem",
          }}
        />
      }
      distributor={data.header.distributor}
      requestNumber={data.header.requestNumber}
      requestDate={data.header.requestDate}
      requester={data.header.requester}
    />
  );

  const modalIcmsTabs = withTab ? (
    <CustomTabs
      headerBorder={0}
      bodyBorder={1}
      tabsHeader={tabLabels}
      body={tabBody}
      paddingY={0}
    />
  ) : (
    <ModalIcmsDetailsContent
      borderWidth={1}
      xmlNotes={{
        title: data.content.xmlNotes.title,
        value: data.xmlNotes,
      }}
      petition={{
        title: data.content.petition.title,
        value: data.petition,
      }}
      refundValues={{
        title: data.content.refundValues.title,
        value: `${formatCurrency(data.refundValues)}`,
      }}
    />
  );

  const modalIcmsNote = (
    <Box mb={2} mt={3}>
      <ModalDetailsNote
        borderWidth={1}
        productNote={{
          title: data.content.requestNote?.title ?? "",
          value: data.requestNote ?? "",
        }}
      />
    </Box>
  );

  const modalIcmsAnalistNote = (
    <Box mb={2} mt={2}>
      <ModalDetailsNote
        borderWidth={1}
        productNote={{
          title: data.content.analistNote?.title ?? "",
          value: data.analistNote ?? "",
        }}
      />
    </Box>
  );

  const modalContent = (
    <>
      {modalIcmsHeader}
      {modalIcmsTabs}
      {data.requestNote && modalIcmsNote}
      {data.analistNote && modalIcmsAnalistNote}
    </>
  );

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false, "close")}
      componentItems={modalContent}
      titleModal={"Detalhes da solicitação de ressarcimento"}
      status={
        RefundIcmsStatusIcons[data.status as keyof typeof RefundIcmsStatusIcons]
      }
      buttons={
        isApproved
          ? buttonsOrderApprovedWithoutCaveat
          : isView
          ? buttonSeeIcms
          : buttonNewIcmsRequest
      }
    />
  );
};

export default ModalIcmsLoader;
