import { Avatar, Grid } from "@mui/material";
import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import CustomCard from "../../components/CustomCard/CustomCard";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import BalanceTableSection from "./Sections/BalanceTableSection";
import BalanceFilterTableSection from "./Sections/BalanceFilterTableSection";
import CardDataNotFound from "../../components/CardDataNotFound/CardDataNotFound";

const ConsultQuoteBalancePage: React.FC = () => {
  const [filter, setFilter] = useState<any | null>(null);
  const [isClear, setClear] = useState<boolean>(false);
  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid item xs={12}>
        <QueryPageTitleCard title="Saldo de Cota" />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <CustomCard
          sx={{
            boxShadow: "none",
            border: "1px solid #DFE0EB",
            height: "100%",
            padding: 2.4,
          }}
        >
          <CardTitleWithSubtitle
            title="Consultar Saldo de Cota"
            subtitle="Informe os parâmetros de pesquisa para consultar o saldo. As informações estão disponíveis para um período máximo de 5 anos."
          />

          <BalanceFilterTableSection
            onButtonChange={(value) => {
              setFilter(value);
            }}
            onButtonClearFilter={(value) => {
              setClear(value);
              setFilter(null);
            }}
          />

          {!filter ? (
            <Grid item xs={12} mt={2}>
              <CardDataNotFound
                icon="search-empty"
                title="Selecione o CNPJ, período e contrato para começar"
                subtitle="Informe outros critérios de busca e comece a consultar!"
              />
            </Grid>
          ) : (
            <Grid item xs={12} mt={2}>
              <BalanceTableSection dataFilter={filter} isClear={isClear} />
            </Grid>
          )}
        </CustomCard>
      </Grid>
    </Layout>
  );
};

export default ConsultQuoteBalancePage;
