import { Column } from "../../../components/TablePaginated/TablePaginated";

export const InitialTableSacCommonColumns: Column[] = [
  {
    label: "ID do processo",
    id: "refId",
    align: "left",
  },
  {
    label: "Filial",
    id: "subsidiaryDescription",
    align: "left",
  },
  {
    label: "Razão social",
    id: "cnpjDescription",
    align: "left",
  },
  {
    label: "CNPJ",
    id: "cnpj",
    align: "left",
  },
  {
    label: "Solicitante",
    id: "customerRequester",
    align: "left",
  },
  { label: "Tipo", id: "type", align: "left", showIcon: true },
  {
    label: "Solicitado em",
    id: "requestDate",
    align: "left",
  },
];

export const finallTableSacCommonColumns: Column[] = [
  {
    label: "",
    id: "status",
    align: "center",
  },
  {
    label: "",
    id: "icon",
    align: "center",
  },
];

export const TableSacColumns: Column[] = [
  ...InitialTableSacCommonColumns,
  ...finallTableSacCommonColumns,
];

export const TableWaitingSacColumns: Column[] = [
  ...InitialTableSacCommonColumns,
  {
    label: "Status",
    id: "waiting",
    align: "left",
  },
  ...finallTableSacCommonColumns,
];
