import { Types } from "../../ioc/types";
import { useIocContext } from "../ioc/IocContext";
import { IInformationService } from "../../modules/information/models/IInformationService";
import {
  IPropsDownloadFile,
  NewSacRegister,
  SacRegisterQuery,
} from "../../modules/information/models/SacRegisterQuery";
import { SacRegisterStatus } from "../../pages/SacPage/models/SearchFilterOptions";
import { TotalResgisters } from "./models/sacModels";
import { CustomerServiceDepartment } from "../../modules/information/models/GetDepartments";
import useDialogAlert from "../../hooks/DialogAlert";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Subjects } from "../../modules/information/models/GetSubjects";
import {
  FollowUp,
  GetSacRegisterDTO,
  SacRegister,
} from "../../modules/information/models/GetSacRegisterDTO";
import {
  FileType,
  downloadExcel,
  downloadJPEG,
  downloadZIP,
} from "../../utils/download";
import { isEqual } from "lodash";

interface SacContextData {
  setDataItemInView: (data: any) => void;
  setItemInView: (data: any) => void;
  registersTotal: TotalResgisters;
  itemInView?: any;
  departments: CustomerServiceDepartment[];
  subjects: Subjects[];
  dataItemInView: SacRegister | null;
  productSelected?: string;
  getAllRegisters?: (query: SacRegisterQuery) => Promise<GetSacRegisterDTO>;
  createNewSacResgister?: (
    register: NewSacRegister
  ) => Promise<SacRegister | undefined>;
  getAllSubjects?: (department: string) => Promise<any>;
  deleteRegister?: (id: string) => any;
  followUpSacResgister?: (
    id: string,
    message: string
  ) => Promise<FollowUp | undefined>;
  finishSacResgister?: (id: string) => Promise<string | undefined>;
  handleDownloadFiles?: (item: IPropsDownloadFile) => Promise<void>;
}

const totalResgister: TotalResgisters = {
  waiting_confirmation: 0,
  open: 0,
  closed: 0,
};

const SacContext = createContext<SacContextData>({
  registersTotal: totalResgister,
  departments: [],
  subjects: [],
  setDataItemInView() {},
  dataItemInView: null,
  setItemInView() {},
});

export const SacProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: React.PropsWithChildren) => {
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();
  const informationService = serviceContainer.get<IInformationService>(
    Types.Information.IInformationService
  );
  const [registersTotal, setRegistersTotal] = useState(totalResgister);
  const [itemInView, setItemInView] = useState<any | null>(null);
  const [departments, setDepartments] = useState<CustomerServiceDepartment[]>(
    []
  );
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const [productSelected, setProductSelected] = useState("");
  const [dataItemInView, setDataItemInView] = useState<SacRegister | null>(
    null
  );

  const classifyByStatus = useCallback(
    (status: string, total: number) => {
      setRegistersTotal((old) => {
        return {
          ...old,
          [status.toLocaleLowerCase()]: total,
        };
      });
    },
    [setRegistersTotal]
  );

  const getAllRegisters = useCallback(
    async (filters: SacRegisterQuery) => {
      const { status } = filters;
      const response = await informationService.getRegisters(filters);
      switch (status) {
        case SacRegisterStatus.WAITING_CONFIRMATION: {
          classifyByStatus(status, response.total);
          break;
        }
        case `${SacRegisterStatus.WAITING_CONFIRMATION},${SacRegisterStatus.WAITING_VENDOR}`: {
          classifyByStatus(
            SacRegisterStatus.WAITING_CONFIRMATION,
            response.total
          );
          break;
        }
        case SacRegisterStatus.OPEN: {
          classifyByStatus(status, response.total);
          break;
        }
        case SacRegisterStatus.CLOSED: {
          classifyByStatus(status, response.total);
          break;
        }
        default: {
          setRegistersTotal(totalResgister);
        }
      }
      return response;
    },
    [setRegistersTotal]
  );

  const getAllDepartments = useCallback(async () => {
    try {
      const response = await informationService.getDepartments();
      response.length !== 0 && setDepartments(response);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar lista de departamentos. Tentar novamente",
        variant: "error",
      });
    }
  }, [setDepartments]);

  const getAllSubjects = useCallback(
    async (departament: string) => {
      try {
        const response = await informationService.getSubjects(departament);
        response.length !== 0 && setSubjects(response);
      } catch (error) {
        snackbar({
          message: "Erro ao carregar lista de assuntos. Tentar novamente",
          variant: "error",
        });
      }
    },
    [setSubjects]
  );

  const createNewSacResgister = useCallback(
    async (resister: NewSacRegister) => {
      try {
        const response = await informationService.createSacRegister(resister);
        snackbar({
          message: "Atendimento enviado com sucesso.",
          variant: "success",
        });
        return response;
      } catch (error) {
        snackbar({
          message: "Erro ao enviar atendimento.",
          variant: "error",
        });
      }
    },
    []
  );

  const deleteRegister = useCallback(async (id: string) => {
    try {
      const response = await informationService.deleteSacRegister(id);
      snackbar({
        message: "Atendimento excluído",
        variant: "success",
      });
      return response;
    } catch (error) {
      snackbar({
        message: "Erro ao deletar atendimento. Tentar novamente",
        variant: "error",
      });
    }
  }, []);

  const followUpSacResgister = useCallback(
    async (id: string, message: string) => {
      try {
        const response = await informationService.followUpRegister(id, {
          message,
        });
        snackbar({
          message: "Atendimento enviado com sucesso.",
          variant: "success",
        });
        return response;
      } catch (error) {
        snackbar({
          message: "Erro ao enviar atendimento.",
          variant: "error",
        });
      }
    },
    []
  );

  const finishSacResgister = useCallback(async (id: string) => {
    try {
      const response = await informationService.commitRegister(id);
      snackbar({
        message: "Atendimento finalizado.",
        variant: "success",
      });
      return response;
    } catch (error) {
      snackbar({
        message: "Erro ao finalizar atendimento.",
        variant: "error",
      });
    }
  }, []);

  const handleDownloadFiles = useCallback(async (item: IPropsDownloadFile) => {
    try {
      const response = await informationService.getSacReportFiles(
        item.customerServiceId,
        item.followUpId,
        item.attachmentId
      );
      switch (item.fileType) {
        case FileType.JPEG:
        case FileType.PNG: {
          downloadJPEG(response, item.fileName, item.fileType);
          break;
        }
        case FileType.XLSX: {
          downloadExcel(response);
          break;
        }
        case FileType.ZIP: {
          downloadZIP(response);
          break;
        }
        default: {
          snackbar({
            message: `Erro ao realizar download. FileType não mapeado: ${String(
              item.fileType
            )}`,
            variant: "error",
          });
          return;
        }
      }

      snackbar({
        message: "Download  realizado com sucesso",
        variant: "success",
      });
    } catch (error) {
      snackbar({
        message: "Erro ao realizar download de arquivos. Tentar novamente",
        variant: "error",
      });
    }
  }, []);

  useEffect(() => {
    getAllDepartments();
  }, []);

  useEffect(() => {
    if (itemInView) {
      getAllRegisters({ refIds: itemInView }).then((ele) => {
        if (ele.content.length !== 0) {
          if (!isEqual(dataItemInView, ele.content[0])) {
            setDataItemInView(ele.content[0]);
          }
        }
      });
    }
  }, [itemInView, setDataItemInView]);

  const SacProviderData = useMemo(
    () => ({
      createNewSacResgister,
      getAllRegisters,
      getAllSubjects,
      deleteRegister,
      followUpSacResgister,
      finishSacResgister,
      handleDownloadFiles,
      registersTotal,
      departments,
      subjects,
      setItemInView,
      setDataItemInView,
      itemInView,
      dataItemInView,
      productSelected,
      setProductSelected,
    }),
    [
      createNewSacResgister,
      getAllRegisters,
      getAllSubjects,
      deleteRegister,
      followUpSacResgister,
      finishSacResgister,
      handleDownloadFiles,
      registersTotal,
      departments,
      subjects,
      setItemInView,
      setDataItemInView,
      itemInView,
      dataItemInView,
      productSelected,
      setProductSelected,
    ]
  );

  return (
    <SacContext.Provider value={SacProviderData}>
      {children}
    </SacContext.Provider>
  );
};

export const useSac = () => {
  const context = useContext(SacContext);
  if (context === undefined) {
    throw new Error("useSac não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
