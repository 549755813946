import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import TitleAndDetailExtract from "../ModalTitleAndDetail/ModalTitleAndDetail";
import { useMedia } from "use-media";
import StatusTag, { StatusTagType } from "../StatusTag/StatusTag";
import { STATUS_NOTE_CANCELLED } from "../../utils/enumStatusNote";

interface ModalDetailsContentProps {
  icon: React.ReactNode;
  quantity: DetailInputsModalProps;
  invoiceTotal: DetailInputsModalProps;
  documentDate: DetailInputsModalProps;
  dueDate: DetailInputsModalProps;
  CFOP: DetailInputsModalProps;
  operationNature: DetailInputsModalProps;
  noteNumber: DetailInputsModalProps;
  statusNote: DetailInputsModalProps;
}

export interface DetailInputsModalProps {
  title: string;
  value: string;
}

const ModalDetailsContent: React.FC<ModalDetailsContentProps> = ({
  icon,
  quantity,
  invoiceTotal,
  documentDate,
  dueDate,
  CFOP,
  operationNature,
  noteNumber,
  statusNote,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });

  const isCancelledNote = (isCancelled: string) => {
    return isCancelled === STATUS_NOTE_CANCELLED
      ? StatusTagType.LATE
      : StatusTagType.ON_TIME;
  };

  const statusTagComponent = (
    <Box>
      <Typography fontWeight={400} textAlign={"left"} fontFamily={"Open Sans"}>
        {noteNumber.value}
        <StatusTag
          text={statusNote.value}
          type={isCancelledNote(statusNote.value)}
          marginLeft={"10px"}
        />
      </Typography>
    </Box>
  );

  return (
    <Grid
      container
      sx={{ border: "1px solid #E8E9EB", borderRadius: "4px" }}
      padding={3}
      marginTop={2}
    >
      <Grid container>
        <Grid
          item
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
          xs={6}
          sm={4}
          md={2}
          lg={1}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
        >
          {icon}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          margin={"auto"}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
            paddingLeft: {
              lg: "30px",
            },
          }}
        >
          <TitleAndDetailExtract
            title={noteNumber.title}
            detail={statusTagComponent}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
        >
          <TitleAndDetailExtract
            title={CFOP.title}
            detail={CFOP.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={3}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
        >
          <TitleAndDetailExtract
            title={quantity.title}
            detail={quantity.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={invoiceTotal.title}
            detail={invoiceTotal.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={9}
          lg={9}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "30px" : "20px"}
        >
          <TitleAndDetailExtract
            title={documentDate.title}
            detail={documentDate.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={3}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={2}
        >
          <TitleAndDetailExtract
            title={dueDate.title}
            detail={dueDate.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          margin={"auto"}
          display={"flex"}
          justifyContent={"left"}
          paddingTop={5}
        >
          <TitleAndDetailExtract
            title={operationNature.title}
            detail={operationNature.value}
            textAlign={"left"}
            paddingBottom={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalDetailsContent;
