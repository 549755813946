import { Avatar, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import CustomCard from "../../components/CustomCard/CustomCard";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import { routesConfig } from "../../config/routes";
import { getFormattedTimestamp } from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { Roles } from "../../components/SideBar/Content/ListItem";
import { TypesPermissions } from "../../components/Routes/TypesPermissions";
import { useFlags } from "flagsmith/react";
import { sidebar } from "../../utils/featureFlags";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import MultipleSelectDistributors from "../../components/MultipleSelectDistributors/MultipleSelectDistributors";
import SelectSubsidiaries from "../../components/SelectSubsidiary/SelectSubsidiary";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import CalendarRange from "../../components/CalendarRange/CalendarRange";
import { getFirstDayOfCurrentMonth, getTitleAndValue } from "../../utils";
import OpenedRiverDeliveries from "./Sections/OpenedRiverDeliveries";
import ClearedRiverDeliveries from "./Sections/ClearedRiverDeliveries";
import RejectedRiverDeliveries from "./Sections/RejectedRiverDeliveries";
import ModalRiverView from "../RiverRequest/Section/ModalRiverView";
import { CommonMenuList } from "./models/OptionsMenu";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { TableRow } from "./models/defaut";
import { PipelineScheduleStatus } from "../../modules/operational/models/GetPipelineSchedulesDTO";
import ModalRiverUpdate from "../RiverRequest/Section/ModalRiverUpdate";

const RiverDeliveriesPage: React.FC = () => {
  const navigate = useNavigate();
  const flags = useFlags(sidebar);
  const currentDay = new Date();
  const { authByPermission, permissionSet, roles } =
    useAuth() as AuthenticationContextData;

  const { selectedDistributorsStoraged, subsidiaries } = useDistributor();
  const [modalData, setModalData] = useState({});
  const [statusData, setStatusData] = useState<PipelineScheduleStatus | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [openedCount, setOpenedCount] = useState(0);
  const [clearedCount] = useState(0);
  const [rejectedCount] = useState(0);
  const [codeSubsidiary, setCodeSubsidiary] = useState<string | undefined>(
    undefined
  );
  const [dateFilterFrom, setDateFilterFrom] = useState<Date | null>(null);
  const [dateFilterTo, setDateFilterTo] = useState<Date | null>(null);

  const [clearValueCalendar, setClearValueCalendar] = useState<boolean>(false);

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const handleCloseUpdate = (open: boolean) => {
    setIsOpenUpdate(open);
  };

  const clearFilters = () => {
    setClearValueCalendar(true);
    setCodeSubsidiary(undefined);
    setDateFilterFrom(null);
    setDateFilterTo(null);
  };

  const selectedDistributors = useMemo(() => {
    return selectedDistributorsStoraged.join(",");
  }, [selectedDistributorsStoraged]);

  const isEdit = useMemo(() => {
    const editableStatus = [
      PipelineScheduleStatus.INVOICE_PENDING,
      PipelineScheduleStatus.IN_SCHEDULING,
      PipelineScheduleStatus.IN_LOADING,
    ];

    return statusData !== null && editableStatus.includes(statusData);
  }, [statusData]);

  const buttonsModalFechar = [
    {
      title: "Fechar",
      onClick: () => {
        handleClose(false);
      },
      variant: CustomButtonVariant.CONTAINED,
    },
  ];

  const buttonsModalEditar = [
    {
      title: "Editar",
      onClick: () => {
        handleClose(false);
        handleCloseUpdate(true);
      },
      variant: CustomButtonVariant.DISABLED, // TODO: modal de editar temporariamente desabilitado
    },
    ...buttonsModalFechar,
  ];

  const isAllowed = useMemo(() => {
    return (
      authByPermission(
        permissionSet.SYSTEM_MODULES,
        roles.has(Roles.ADMIN),
        TypesPermissions.RIVER_REQUEST
      ) && flags.operational_new_river.enabled
    );
  }, [authByPermission, flags]);

  const StyledMenu: React.FC<{ rowData: TableRow }> = ({ rowData }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async () => {
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const menuOptions = [
      ...CommonMenuList.map((item) => ({
        ...item,
        onClick: () => {
          setStatusData(rowData.statusText);
          setModalData((oldState) => ({
            ...oldState,
            issuingCustomer: getTitleAndValue(
              "Nome da distribuidora",
              rowData.customer ?? "-"
            ),
            requestNumber: {
              title: "N. do pedido",
              value: rowData.refId ?? "",
            },
            requestUser: getTitleAndValue(
              "Solicitado por:",
              rowData.customerRequester ?? "-"
            ),
            type: getTitleAndValue(
              "Data para carregamento",
              rowData.scheduleDate ?? "-"
            ),
            requestDate: getTitleAndValue(
              "Data do Pedido",
              rowData.createdAt ?? "-"
            ),
            subsidiaryDescription: getTitleAndValue(
              "Filial",
              rowData.subsidiary ?? "-"
            ),
            receiver: getTitleAndValue("Recebedor", rowData.receiver ?? "-"),
            shippingCompany: getTitleAndValue(
              "Transportadora",
              rowData.shippingCompany ?? "-"
            ),
            productName: getTitleAndValue(
              "Nome do produto",
              rowData.product ?? "-"
            ),
            modality: getTitleAndValue("Modalidade", rowData.mod ?? "-"),
            quantity: getTitleAndValue("Quantidade", rowData.quantity ?? "-"),
            paymentCondition: getTitleAndValue(
              "Condição de pagamento",
              rowData.paymentCondition ?? "-"
            ),
            requestNote: getTitleAndValue(
              "Observação",
              rowData.customerRequesterNote ?? "-"
            ),
          }));
          setIsOpen(true);
        },
      })),
    ];

    return (
      <>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick();
                option.onClick();
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Entregas fluviais" />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "24px" }}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title="Pedidos de agendamento"
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Novo pedido Fluvial",
                onClick() {
                  navigate(routesConfig.OPERATIONAL().RIVER_REQUEST);
                },
                variant: isAllowed
                  ? CustomButtonVariant.CONTAINED
                  : CustomButtonVariant.DISABLED,
                disabled: !isAllowed,
              }}
            />
            <Grid container>
              <Grid item xs={12}>
                <ModalRiverUpdate
                  handleClose={(open) => handleCloseUpdate(open)}
                  cleanFilter={clearFilters}
                  isOpen={isOpenUpdate}
                  data={modalData}
                />
                <ModalRiverView
                  isEdit={false}
                  handleClose={(open) => handleClose(open)}
                  isOpen={isOpen}
                  data={modalData}
                  buttons={isEdit ? buttonsModalEditar : buttonsModalFechar}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={6} md={3}>
                      <MultipleSelectDistributors
                        maxWidth={1200}
                        label={"Selecione o cliente"}
                      />
                    </Grid>

                    <Grid item xs={10} sm={6} md={3}>
                      <SelectSubsidiaries
                        label="Filial"
                        valueSubsidiary={subsidiaries.find(
                          (item) => item.id === codeSubsidiary
                        )}
                        onChangeValue={(selectedOption) => {
                          if (selectedOption) {
                            setCodeSubsidiary(selectedOption.id);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={10} sm={6} md={3}>
                      <CalendarRange
                        name="date"
                        label="Data de agendamento"
                        clearValueCalendar={clearValueCalendar}
                        firstDayOfMonth={getFirstDayOfCurrentMonth()}
                        lastDayOfMonth={currentDay}
                        onChangeDateStartEnd={(dateBegin, dateEnd) => {
                          setClearValueCalendar(false);
                          // eslint-disable-next-line
                          if (dateBegin && dateEnd) {
                            setDateFilterFrom(dateBegin);
                            setDateFilterTo(dateEnd);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={3}
                  container
                  alignItems="flex-end"
                  justifyContent={"flex-end"}
                  alignContent={"flex-start"}
                  marginTop={"25px"}
                >
                  <CustomButton
                    title="Limpar filtros"
                    variant={CustomButtonVariant.OUTLINED}
                    onClick={clearFilters}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <CustomTabs
                  tabsHeader={[
                    `Em aberto(${openedCount})`,
                    `Faturado(${clearedCount})`,
                    `Reprovado(${rejectedCount})`,
                  ]}
                  body={[
                    <OpenedRiverDeliveries
                      key={0}
                      subsidiary={codeSubsidiary}
                      customerCompanyIds={selectedDistributors}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      onChangeCount={(count) => {
                        setOpenedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,

                    <ClearedRiverDeliveries
                      key={1}
                      subsidiary={codeSubsidiary}
                      customerCompanyIds={selectedDistributors}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      onChangeCount={(count) => {
                        setOpenedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,
                    <RejectedRiverDeliveries
                      key={2}
                      subsidiary={codeSubsidiary}
                      customerCompanyIds={selectedDistributors}
                      dateFilterFrom={
                        dateFilterFrom || getFirstDayOfCurrentMonth()
                      }
                      dateFilterTo={dateFilterTo || currentDay}
                      onChangeCount={(count) => {
                        setOpenedCount(count);
                      }}
                      menuIcon={(item) => <StyledMenu rowData={item} />}
                    />,
                  ]}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RiverDeliveriesPage;
