import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { Row } from "../../../modules/operational/models/GetRiverSchedulesDTO";
import { formatCNPJ, getThousand } from "../../../utils";
import { formatCurrency } from "../../../utils/currencyFormatter";
import {
  DateAndHoursToFormat,
  DateToFormat,
} from "../../../utils/dateFormatter";

export const getRiverResponseToModel = (item: Row) => ({
  id: item.id ?? "",
  refId: item.refId ?? "",
  scheduleDate: DateToFormat(item.scheduleDate),
  createdAt: DateAndHoursToFormat(item.createdAt),
  quantity: getThousand(item.volumeRequested ?? 0)?.toString(),
  product: item.contract?.product?.description ?? "",
  unit: item.contract?.product?.measurementUnit ?? "",
  receiver: item.receiverCustomerCompany?.description ?? "-",
  shippingCompany: item.shippingCompany?.description ?? "-",
  customer: item.contract?.customerCompany?.description ?? "",
  customerRequester: item.customerRequester ?? "",
  customerRequesterNote: item.customerRequesterNote ?? "",
  subsidiary: item.contract?.subsidiary?.description ?? "",
  paymentCondition: item.paymentConditionDescription ?? "",
  statusText: item.status ?? PipelineScheduleStatus.VENDOR_PENDING,
  mod: item.contract?.mod ?? "",
  price: formatCurrency(item.billedPrice) ?? "-",
  cnpj: formatCNPJ(item.contract?.customerCompany?.document) ?? "",
});
