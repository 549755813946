import { Avatar, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import WelcomeCard from "../../components/WelcomeCard/WelcomeCard";
import CarrouselSection from "./Sections/CarrouselSection";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { getFirstNameUser } from "../../utils";
import DeliveryTableSection from "./Sections/DeliveryTableSection";
import { useHome } from "../../contexts/home/HomeContext";
import ConsumptionByProductCard from "../../components/ConsumptionByProductCard/ConsumptionByProductCard";
import {
  clearedInitQuery,
  openedInitQuery,
  rejectedInitQuery,
} from "./models/default";
import { PipelineScheduleStatus } from "../../modules/operational/models/GetPipelineSchedulesDTO";
import { useMedia } from "use-media";

const HomePage: React.FC = () => {
  const authContext = useAuth();
  const [isValidPipeline, setIsValidPipeline] = useState(false);
  const [isValidRoad, setIsValidRoad] = useState(false);
  const isMobile = useMedia({ maxWidth: 900 });

  const {
    dataPipelineSchedules,
    dataRoadlineSchedules,
    totalPipelineSchedules,
    totalRoadlineSchedules,
    isLoadingPipelineSchedules,
    isLoadingRoadlineSchedules,
    queryPipelineSchedules,
    queryRoadlineSchedules,
    setQueryPipelineSchedules,
    setQueryRoadlineSchedules,
    companyDescription,
    dataAnalytics,
    isLoadingDataAnalytics,
  } = useHome();

  useEffect(() => {
    const statusString = `${openedInitQuery.status ?? ""},${
      clearedInitQuery.status ?? ""
    },${rejectedInitQuery.status ?? ""}`;

    if (setQueryPipelineSchedules && setQueryRoadlineSchedules) {
      setQueryPipelineSchedules((prevQuery) => ({
        ...prevQuery,
        status: statusString,
        page: 1,
      }));

      setQueryRoadlineSchedules((prevQuery) => ({
        ...prevQuery,
        status: statusString,
        page: 1,
      }));
    }
  }, []);

  useEffect(() => {
    const isNotOpenPipe =
      !queryPipelineSchedules?.status?.includes(
        PipelineScheduleStatus.VENDOR_APPROVED
      ) ?? false;

    const isNotOpensRoad =
      !queryRoadlineSchedules?.status?.includes(
        PipelineScheduleStatus.VENDOR_APPROVED
      ) ?? false;

    setIsValidPipeline(isNotOpenPipe || dataPipelineSchedules.length > 0);
    setIsValidRoad(isNotOpensRoad || (dataRoadlineSchedules ?? []).length > 0);
  }, [
    queryPipelineSchedules,
    dataPipelineSchedules,
    queryRoadlineSchedules,
    dataRoadlineSchedules,
  ]);

  const DeliveryTableSectionPipe = () => {
    return (
      <DeliveryTableSection
        title={"Entregas Dutoviárias"}
        subTitle={companyDescription ?? ""}
        data={dataPipelineSchedules ?? []}
        count={totalPipelineSchedules ?? 0}
        isLoading={isLoadingPipelineSchedules ?? false}
        currentPageTable={queryPipelineSchedules?.page ?? 0}
        query={queryPipelineSchedules ?? undefined}
        setQuery={setQueryPipelineSchedules ?? (() => {})}
      />
    );
  };

  const DeliveryTableSectionRoad = () => {
    return (
      <DeliveryTableSection
        title={"Entregas Rodoviárias"}
        subTitle={companyDescription ?? ""}
        data={dataRoadlineSchedules ?? []}
        count={totalRoadlineSchedules ?? 0}
        isLoading={isLoadingRoadlineSchedules ?? false}
        currentPageTable={queryRoadlineSchedules?.page ?? 0}
        query={queryRoadlineSchedules ?? undefined}
        setQuery={setQueryRoadlineSchedules ?? (() => {})}
      />
    );
  };

  const DataAnalytics = () => {
    return (
      <>
        {dataAnalytics && dataAnalytics?.dataSubsidiary.length > 0 ? (
          dataAnalytics?.dataSubsidiary.map((item, index) => (
            <div key={index}>
              <ConsumptionByProductCard
                firstSubTitle={item?.companyDescription}
                secondSubTitle={item?.subsidiaryDescription}
                totalQuota={item?.totalSubsidiaryVolume ?? 0}
                data={item.entries}
                isLoadingData={isLoadingDataAnalytics ?? false}
              />
            </div>
          ))
        ) : (
          <ConsumptionByProductCard totalQuota={0} isLoadingData={false} />
        )}
      </>
    );
  };

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid
        container
        spacing={3}
        mb={5}
        bgcolor={"#f3f4f6"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <WelcomeCard
            userName={getFirstNameUser(authContext?.name)}
            gender="o"
          />
        </Grid>

        <Grid item xs={12}>
          <CarrouselSection />
        </Grid>

        {/* <Grid */}
        {/*  item */}
        {/*  xs={12} */}
        {/*  md={6} */}
        {/*  display={"flex"} */}
        {/*  justifyContent={"space-between"} */}
        {/* > */}
        {/*  <Grid container spacing={3}> */}
        {/*    <Grid item xs={12} overflow={"scroll"}> */}
        {/*      <Box */}
        {/*        display={"flex"} */}
        {/*        justifyContent={"space-between"} */}
        {/*        sx={{ */}
        {/*          "& div": { */}
        {/*            marginX: 1, */}
        {/*          }, */}
        {/*          "& div:first-child": { */}
        {/*            marginLeft: 0, */}
        {/*          }, */}
        {/*          "& div:last-child": { */}
        {/*            marginRight: 0, */}
        {/*          }, */}
        {/*        }} */}
        {/*      > */}
        {/*        {Array.from(Array(4)).map((item) => ( */}
        {/*          <StatusCard */}
        {/*            key={item} */}
        {/*            iconType="billingTypeIcon-blue" */}
        {/*            title="Notas emitidas" */}
        {/*            subTitle="Notas emitidas" */}
        {/*            value="100" */}
        {/*          /> */}
        {/*        ))} */}
        {/*      </Box> */}
        {/*    </Grid> */}
        {/*    <Grid item xs={12} display={"flex"}> */}
        {/*      <BillingTableSection /> */}
        {/*    </Grid> */}
        {/*  </Grid> */}
        {/* </Grid> */}
      </Grid>

      <Grid container spacing={2}>
        {/* INFO: Primeira Coluna */}
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DataAnalytics />
            </Grid>
          </Grid>
        </Grid>
        {/* INFO: Segunda Coluna */}
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container direction="column" spacing={2}>
            {isValidPipeline && (
              <Grid item>
                <DeliveryTableSectionPipe />
              </Grid>
            )}
            {isValidRoad && (
              <Grid item>
                <DeliveryTableSectionRoad />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default HomePage;
