import React from "react";
import Carousel from "react-material-ui-carousel";

interface CardSliderProps {
  componentItems: React.ReactNode;
}

const CardSlider: React.FC<CardSliderProps> = ({ componentItems }) => {
  const carouselSettings = {
    children: componentItems,
    indicatorIconButtonProps: {
      style: {
        color: "#FFFFFF",
        margin: "3px",
        border: "1px solid #E62B16",
      },
    },
    indicatorContainerProps: {
      style: {
        paddingBottom: "20px",
        bottom: "0",
      },
    },
    activeIndicatorIconButtonProps: {
      style: {
        color: "#E62B16",
        background: "#E62B16",
        border: "3px solid #E62B16",
      },
    },
  };

  return (
    <Carousel
      interval={8000}
      animation="slide"
      autoPlay
      {...carouselSettings}
      sx={{
        background: "#FFFFFF",
      }}
    >
      {componentItems}
    </Carousel>
  );
};

export default CardSlider;
