/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Divider, Grid, Typography } from "@mui/material";
import { format, isSameDay } from "date-fns";
import * as _ from "lodash";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AnimatedBox from "../../../components/AnimatedBox/AnimatedBox";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import DatePickerDefault from "../../../components/DatePickerDefault/DatePickerDefault";
import Select, { SelectVariant } from "../../../components/Select/Select";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { routesConfig } from "../../../config/routes";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import useDialogAlert from "../../../hooks/DialogAlert";
import { useFetchData } from "../../../hooks/FetchData";
import { Types } from "../../../ioc/types";
import {
  ContractModel,
  GetContractsDTO,
} from "../../../modules/operational/models/GetContractsDTO";
import { GetMonthDetailsDTO } from "../../../modules/operational/models/GetMonthDetailsDTO";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import {
  getCurrentMonthYear,
  getNextMonthYear,
  getThousand,
  getTitleAndValue,
  isLastBusinessDayOfMonth,
  removeSpecialCharactersAndLetters,
} from "../../../utils";
import AppError from "../../../utils/appError";
import { DateToFormat, isValidDate } from "../../../utils/dateFormatter";
import { useRoadScheduleRequestContext } from "../context/RoadScheduleRequestContext";
import ModalScheduleRequestLoader from "./ModalScheduleRequestLoader";

interface FormProps {
  contractId: string;
  scheduleDate: Date | null;
  scheduleTime: string;
  productId: string;
  mod: string;
  suggestedQuantity: number;
  volumeRequested: string;
  paymentCondition: string;
  note: string;
}

const CURRENT_DATE = new Date();

const RoadScheduleRequestStep3: React.FC = () => {
  const [timeList, setTimeList] = useState<string[]>([]);
  const [isLoadingTimes, setIsLoadingTimes] = useState(false);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [isLoadingEngageVacancy, setIsLoadingEngageVacancy] = useState(false);
  const [monthReference, setMonthReference] = useState<string>(
    format(CURRENT_DATE, "MM-yyyy")
  );
  const [monthDetails, setMonthDetails] = useState<GetMonthDetailsDTO | null>(
    null
  );
  const [contractList, setContractList] = useState<GetContractsDTO>([]);

  const {
    formData,
    modalData,
    isCreatingSchedule,
    setStep,
    setFormData,
    setModalData,
    setIsCreatingSchedule,
  } = useRoadScheduleRequestContext();

  const { snackbar } = useDialogAlert();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const handleClose = (open: boolean) => {
    setIsOpen(open);
  };

  const getMonthDetails = useCallback(async () => {
    try {
      setIsLoadingTimes(true);
      const response = await operationalService.getMonthDetails(monthReference);

      setMonthDetails(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    } finally {
      setIsLoadingTimes(false);
    }
  }, [monthReference]);

  const setNewAvailableTimes = (selectedDay?: string) => {
    if (!monthDetails) return;
    if (!selectedDay) selectedDay = format(new Date(), "dd");

    const dayStringToNumber = parseInt(selectedDay).toString();

    if (monthDetails[dayStringToNumber] === undefined) return;

    const vacancies = monthDetails[dayStringToNumber].vacancies;
    const availableTimes = vacancies
      ? Object.keys(vacancies ?? {}).filter((key) => vacancies[key] > 0)
      : [];

    if (vacancies && availableTimes.length) {
      setTimeList(availableTimes);
    }
  };

  const getContracts = useCallback(async () => {
    try {
      setIsLoadingContract(true);
      if (!formData?.customerCompanyId?.length) {
        throw new AppError("ID do cliente não encontrato", "error");
      }
      if (!formData?.subsidiaryId?.length) {
        throw new AppError("ID da filial não encontrato", "error");
      }

      const currentDate = new Date();
      let payload: ContractModel = {};

      if (isLastBusinessDayOfMonth(currentDate)) {
        payload = {
          monthReferenceFrom: getCurrentMonthYear(),
          monthReferenceTo: getNextMonthYear(),
        };
      } else {
        payload = {
          monthReference: getCurrentMonthYear(),
        };
      }

      const response = await operationalService.getContracts(
        formData?.customerCompanyId,
        formData?.subsidiaryId,
        "RODO",
        payload
      );
      setContractList(response);
      if (response.length === 0) {
        snackbar({
          message: "Nenhum contrato encontrado",
          variant: "error",
        });
      }
    } catch (error) {
      snackbar({
        message: "Erro ao carregar contratos",
        variant: "error",
      });
    } finally {
      setIsLoadingContract(false);
    }
  }, []);

  const paymentConditions = useFetchData(
    async () => await operationalService.getPaymentConditions()
  );

  const getEngageVacancy = useCallback(async (dateTimeReference: string) => {
    try {
      setIsLoadingEngageVacancy(true);
      const response = await operationalService.engageVacancy(
        dateTimeReference
      );
      setFormData((oldState) => ({
        ...oldState,
        scheduleToken: response.token,
      }));
      snackbar({
        message: response.message ?? "Sucesso ao reservar vaga!",
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      snackbar({
        message: "Erro ao reservar vaga",
        variant: "error",
      });
    } finally {
      setIsLoadingEngageVacancy(false);
    }
  }, []);

  const creatingSchedule = useCallback(async () => {
    try {
      setIsCreatingSchedule(true);

      const filteredValues = _.omit(formData, ["mod", "productId", "contract"]);
      await operationalService.createRoadSchedule({
        ...filteredValues,
      });
      snackbar({
        message: "Agendamento solicitado com sucesso.",
        variant: "success",
      });
      navigate(routesConfig.OPERATIONAL().ROAD_STATIONS);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
        autoHideDuration: 7000,
      });
    } finally {
      setIsCreatingSchedule(false);
    }
  }, [formData]);

  const shouldDisableDate = (date: Date) => {
    const monthDetailsKey = Object.keys(monthDetails ?? {});

    const datesWithVacancy = monthDetailsKey.filter(
      (item) => monthDetails?.[item].hasVacancy ?? false
    );

    const disabledDates = datesWithVacancy.map((day) => {
      const monthReferenceSplit = monthReference.split("-");
      const getMonthReference = monthReferenceSplit[0];
      const getYearReference = monthReferenceSplit[1];

      const date = `${getYearReference}-${getMonthReference}-${day.padStart(
        2,
        "0"
      )}`;

      return new Date(`${date}T04:00:00Z`);
    });

    return !disabledDates.some((disabledDate) => isSameDay(date, disabledDate));
  };

  useEffect(() => {
    getMonthDetails();
  }, [monthReference]);

  useEffect(() => {
    if (monthDetails) {
      setNewAvailableTimes();
    }
  }, [monthDetails]);

  useEffect(() => {
    getContracts();
    if (!formData?.scheduleLikeDate) {
      setFormData((oldState) => ({
        ...oldState,
        scheduleLikeDate: new Date(),
      }));
    }
  }, []);

  return (
    <AnimatedBox>
      <Formik
        initialValues={{
          contractId: formData?.contractId ?? "",
          scheduleDate: formData?.scheduleLikeDate ?? CURRENT_DATE,
          scheduleTime: formData?.scheduleTime ?? "",
          productId: formData?.productId ?? "",
          mod: formData?.mod ?? "",
          suggestedQuantity: formData?.suggestedVolume ?? 0,
          volumeRequested: getThousand(formData?.volumeRequested ?? 0),
          paymentCondition: formData?.paymentCondition ?? "",
          note: formData?.note ?? "",
        }}
        validationSchema={Yup.object({
          contractId: Yup.string().required("Selecione um contrato"),
          scheduleDate: Yup.date().required(
            "Selecione uma data para agendamento"
          ),
          scheduleTime: Yup.string().required("Selecione um horário"),
          productId: Yup.string(),
          volumeRequested: Yup.string().required("Informe quantidade"),
          paymentCondition: Yup.string().required(
            "Selecione uma condição de pagamento"
          ),
        })}
        onSubmit={function (
          values: FormProps,
          formikHelpers: FormikHelpers<FormProps>
        ) {
          setIsOpen(true);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const hasErrorContract =
            touched.contractId && Boolean(errors.contractId);
          const hasErrorScheduleTime =
            touched.scheduleTime && Boolean(errors.scheduleTime);
          const hasErrorVolumeRequested =
            touched.volumeRequested && Boolean(errors.volumeRequested);
          const hasErrorPaymentMethod =
            touched.paymentCondition && Boolean(errors.paymentCondition);
          const hasErrorObservation = touched.note && Boolean(errors.note);

          return (
            <Form>
              <Grid container spacing={1} mt={2}>
                <Grid item>
                  <ModalScheduleRequestLoader
                    isEdit={false}
                    handleClose={(open) => handleClose(open)}
                    isOpen={isOpen}
                    data={modalData}
                    buttons={[
                      {
                        title: "Cancelar",
                        variant: CustomButtonVariant.OUTLINED,
                        onClick: () => {
                          handleClose(false);
                        },
                      },
                      {
                        title: "Confirmar agendamento",
                        variant: isCreatingSchedule
                          ? CustomButtonVariant.CONTAINED_LOADING
                          : CustomButtonVariant.CONTAINED,
                        onClick: async () => await creatingSchedule(),
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={"Open Sans"}
                    fontSize={16}
                    fontWeight={700}
                    color={"shadesOfDark.dark"}
                  >
                    Pedido de produto
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} mt={2.5}>
                  <Select
                    variant={
                      isLoadingContract
                        ? SelectVariant.LOADING
                        : SelectVariant.STANDARD
                    }
                    label="Contrato"
                    placeholder={
                      isLoadingContract ? "Carregando..." : "Selecione"
                    }
                    options={contractList.map((option) => ({
                      option,
                    }))}
                    value={
                      contractList.find(
                        (item) => item.id === values.contractId
                      ) ?? {}
                    }
                    getOptionItemLabel={(value: ContractModel) =>
                      value.label ?? ""
                    }
                    getSelectedOptionLabel={(value: ContractModel) =>
                      value.label ?? ""
                    }
                    onChange={(value) => {
                      setFieldValue("contractId", value.id ?? "");
                      setFieldValue("productId", value.productId ?? "");
                      setFieldValue("mod", value.mod ?? "");
                      setFieldValue(
                        "suggestedQuantity",
                        value.suggestedVolume ?? ""
                      );

                      setFormData((oldState) => ({
                        ...oldState,
                        contractId: value.id,
                        productId: value.productId,
                        mod: value.mod,
                        suggestedVolume: value.suggestedVolume,
                      }));

                      setModalData((oldState) => ({
                        ...oldState,
                        productName: getTitleAndValue(
                          "Nome do produto",
                          value.product
                        ),
                        modality: getTitleAndValue("Modalidade", value.mod),
                        quantity: getTitleAndValue(
                          "Quantidade",
                          `${values.volumeRequested?.toString()} ${
                            value.productMeasurementUnit ?? ""
                          }`
                        ),
                        unit: getTitleAndValue(
                          "Unidade",
                          value.productMeasurementUnit
                        ),
                      }));
                      if (value.monthReference !== undefined) {
                        setMonthReference(value.monthReference);
                      }
                    }}
                    onBlur={() => setFieldTouched("contractId", true)}
                    hasError={hasErrorContract}
                    messageError={errors.contractId}
                  />
                </Grid>

                <Grid item xs={12} md={0} />
                <Grid container item xs={12} md={0} spacing={1}>
                  <Grid item xs={12} md={4}>
                    <DatePickerDefault
                      label={"Data para agendamento"}
                      disablePast
                      value={values.scheduleDate}
                      shouldDisableDate={shouldDisableDate}
                      onChange={(value) => {
                        if (isValidDate(value as Date)) {
                          setTimeList([]);
                          setFieldValue("scheduleDate", value);
                          setFieldValue("scheduleTime", "");
                          setNewAvailableTimes(format(value as Date, "dd"));
                          setFormData((oldState) => ({
                            ...oldState,
                            scheduleLikeDate: value as Date,
                          }));
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("pumpDate", true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Select
                      variant={
                        isLoadingTimes || isLoadingEngageVacancy
                          ? SelectVariant.LOADING
                          : SelectVariant.STANDARD
                      }
                      isDisabled={isLoadingTimes || isLoadingEngageVacancy}
                      label="Grade de horário"
                      emptyOptionsText="Horários indisponíveis para este dia"
                      value={values.scheduleTime}
                      options={timeList.map((option) => ({
                        option,
                      }))}
                      getOptionItemLabel={(value) => value}
                      getSelectedOptionLabel={(value) => value}
                      onChange={(value) => {
                        const dateTimeReference = `${DateToFormat(
                          formData?.scheduleLikeDate
                        )} ${value}`;
                        getEngageVacancy(dateTimeReference);
                        setFieldValue("scheduleTime", value);
                        setFormData((oldState) => ({
                          ...oldState,
                          scheduleTime: value,
                          scheduleDate: dateTimeReference,
                        }));
                      }}
                      onBlur={() => {
                        setFieldTouched("scheduleTime", true);
                      }}
                      hasError={hasErrorScheduleTime}
                      messageError={errors.scheduleTime}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={0} />
                <Grid container item spacing={1} mt={3}>
                  <Grid item xs={12} md={2.4}>
                    <Select
                      isDisabled
                      label="Produto"
                      value={contractList.find(
                        (item) => item.productId === values.productId
                      )}
                      options={contractList.map((option) => ({
                        option,
                      }))}
                      getOptionItemLabel={(value) => value.product ?? ""}
                      getSelectedOptionLabel={(value) => value.product ?? ""}
                      placeholder={
                        values.productId.length ? values.productId : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Select
                      isDisabled
                      label="Modalidade"
                      value={contractList.find(
                        (item) => item.mod === values.mod
                      )}
                      options={contractList.map((option) => ({
                        option,
                      }))}
                      getOptionItemLabel={(value) => value.mod ?? ""}
                      getSelectedOptionLabel={(value) => value.mod ?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <TextFieldCustom
                      disabled
                      label={`Quantidade sugerida ${
                        modalData?.unit?.value ?? ""
                      }`}
                      placeholder="Informe a quantidade"
                      value={getThousand(values.suggestedQuantity)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <TextFieldCustom
                      label={`Quantidade ${modalData?.unit?.value ?? ""}`}
                      placeholder="Informe a quantidade"
                      value={values.volumeRequested}
                      hasError={hasErrorVolumeRequested}
                      messageError={errors.volumeRequested}
                      onChange={(value) => {
                        const numberWithoutCharactersAndLetters = Number(
                          removeSpecialCharactersAndLetters(value.target.value)
                        );

                        const formattedValue = getThousand(
                          numberWithoutCharactersAndLetters
                        );
                        setFieldValue("volumeRequested", formattedValue);
                        setFormData((oldState) => ({
                          ...oldState,
                          volumeRequested: Number(
                            formattedValue.replaceAll(".", "")
                          ),
                        }));
                        setModalData((oldState) => ({
                          ...oldState,
                          quantity: getTitleAndValue(
                            "Quantidade",
                            `${formattedValue} ${oldState.unit?.value ?? ""} `
                          ),
                        }));
                      }}
                      onBlur={() => setFieldTouched("volumeRequested", true)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.4}>
                    <Select
                      isDisabled={paymentConditions.errorLoading}
                      label="Condição de pagamento"
                      variant={
                        paymentConditions.loading
                          ? SelectVariant.LOADING
                          : SelectVariant.STANDARD
                      }
                      value={paymentConditions.value?.find(
                        (item) => item.code === values.paymentCondition
                      )}
                      options={
                        paymentConditions.value?.map((option) => ({
                          option,
                        })) ?? []
                      }
                      getOptionItemLabel={(value) => value.description ?? ""}
                      getSelectedOptionLabel={(value) =>
                        value.description ?? ""
                      }
                      onChange={(value) => {
                        setFieldValue("paymentCondition", value.code);
                        setFormData((oldState) => ({
                          ...oldState,
                          paymentCondition: value.code,
                        }));
                      }}
                      onBlur={() => setFieldTouched("paymentCondition", true)}
                      hasError={hasErrorPaymentMethod}
                      messageError={errors.paymentCondition}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCustom
                    fullWidth
                    label={"Observação"}
                    multiline
                    rows={4}
                    placeholder="Deixa sua observação"
                    value={values.note}
                    hasError={hasErrorObservation}
                    messageError={errors.note}
                    onChange={(value) => {
                      setFieldValue("note", value.target.value);
                      setFormData((oldState) => ({
                        ...oldState,
                        note: value.target.value,
                      }));
                    }}
                    onBlur={() => setFieldTouched("note", true)}
                  />
                </Grid>

                <Grid item xs={12} mt={10}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Box display={"flex"}>
                    <CustomButton
                      title={"Voltar"}
                      variant={CustomButtonVariant.OUTLINED}
                      onClick={() => {
                        setStep(1);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      title={"Agendar"}
                      variant={CustomButtonVariant.CONTAINED}
                      onClick={() => {}}
                      onSubmit={() => handleSubmit}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </AnimatedBox>
  );
};

export default RoadScheduleRequestStep3;
