import { inject, injectable } from "inversify";
import {
  CustomerCompany,
  GetPipelineSchedulesDTO,
} from "./models/GetPipelineSchedulesDTO";
import { IGetPipelineSchedulesQuery } from "./models/GetPipelineSchedulesQuery";
import { IOperationalService } from "./models/IOperationalService";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import { ContractModel, GetContractsDTO } from "./models/GetContractsDTO";
import { GetPaymentConditionsDTO } from "./models/GetPaymentConditionsDTO";
import { PumpResquestFormProps } from "../../pages/PumpRequest/PumpRequestPage";
import { GetMonthDetailsDTO } from "./models/GetMonthDetailsDTO";
import { GetEngageVacancyDTO } from "./models/GetEngageVacancy";
import { GetShippingCompaniesDTO } from "./models/GetShippingCompaniesDTO";
import { GetDriversDTO } from "./models/GetDriversDTO";
import { RoadScheduleRequestFormData } from "../../pages/RoadScheduleRequest/context/RoadScheduleRequestContext";
import { GetPlatesDTO } from "./models/GetPlatesDTO";
import { IFileUploadDTO } from "./models/IFileUpload";
import { IUpdateScheduleDTO } from "./models/UpdateScheduleDTO";
import { IGetRiverSchedulesQuery } from "./models/GetRiverSchedulesQuery";
import { GetRiverSchedulesDTO } from "./models/GetRiverSchedulesDTO";
import { RiverRequestFormData } from "../../pages/RiverRequest/models/customIconRiver";

@injectable()
export class OperationalService implements IOperationalService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async getPipelineSchedules(
    query: IGetPipelineSchedulesQuery
  ): Promise<GetPipelineSchedulesDTO> {
    return await this.httpInstance.get("/ms-contracts/pipe-schedule", {
      params: query,
    });
  }

  public async getRoadlineSchedules(
    query: IGetPipelineSchedulesQuery
  ): Promise<GetPipelineSchedulesDTO> {
    return await this.httpInstance.get("/ms-contracts/road-schedule", {
      params: query,
    });
  }

  public async getRiverSchedules(
    query: IGetRiverSchedulesQuery
  ): Promise<GetRiverSchedulesDTO> {
    return await this.httpInstance.get("/ms-contracts/marine-schedule", {
      params: query,
    });
  }

  public async getContracts(
    customerCompanyId: string,
    subsidiaryId: string,
    scheduleType?: string,
    data?: ContractModel
  ): Promise<GetContractsDTO> {
    return await this.httpInstance.get("ms-contracts/contract", {
      params: {
        customerCompanyId,
        subsidiaryId,
        scheduleType,
        ...data,
      },
    });
  }

  public async getPaymentConditions(): Promise<GetPaymentConditionsDTO> {
    return await this.httpInstance.get(
      "/ms-data-guard/static-entity?group=PAYMENT_CONDITION"
    );
  }

  public async createPipelineSchedule(
    body: PumpResquestFormProps
  ): Promise<void> {
    return await this.httpInstance.post("/ms-contracts/pipe-schedule", body);
  }

  public async createRoadSchedule(
    body: RoadScheduleRequestFormData
  ): Promise<void> {
    return await this.httpInstance.post("/ms-contracts/road-schedule", body);
  }

  public async createRiverSchedule(body: RiverRequestFormData): Promise<void> {
    return await this.httpInstance.post("/ms-contracts/marine-schedule", body);
  }

  public async getPipeMonthDetails(
    monthReference: string
  ): Promise<GetMonthDetailsDTO> {
    return await this.httpInstance.get(
      "/ms-contracts/pipe-schedule/month-details",
      {
        params: {
          monthReference,
        },
      }
    );
  }

  public async getMonthDetails(
    monthReference: string
  ): Promise<GetMonthDetailsDTO> {
    return await this.httpInstance.get(
      "/ms-contracts/road-schedule/month-details",
      {
        params: {
          monthReference,
        },
      }
    );
  }

  public async engageVacancy(
    dateTimeReference: string
  ): Promise<GetEngageVacancyDTO> {
    return await this.httpInstance.get(
      "/ms-contracts/road-schedule/engage-vacancy",
      {
        params: {
          dateTimeReference,
        },
      }
    );
  }

  public async getReceiversCompanies(): Promise<CustomerCompany[]> {
    return await this.httpInstance.get("/ms-sap/customer-receiver");
  }

  public async getShippingCompanies(): Promise<GetShippingCompaniesDTO> {
    return await this.httpInstance.get("/ms-sap/shipping-company");
  }

  public async getDrivers(): Promise<GetDriversDTO> {
    return await this.httpInstance.get("/ms-data-guard/driver?query=");
  }

  public async getPlates(): Promise<GetPlatesDTO> {
    return await this.httpInstance.get("/ms-data-guard/vehicle");
  }

  public async uploadRoadScheduleFile(
    file: FormData
  ): Promise<IFileUploadDTO[]> {
    return await this.httpInstance.post(
      `ms-contracts/road-schedule/upload`,
      file
    );
  }

  public async updateRoadScheduleRequest(
    id: string,
    data: IUpdateScheduleDTO
  ): Promise<void> {
    return await this.httpInstance.patch(
      `/ms-contracts/road-schedule/${id}`,
      data
    );
  }
}
