import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableHeaderColumns: Column[] = [
  {
    label: "Número da nota",
    id: "document",
    align: "left",
  },
  {
    label: "Data de emissão",
    id: "dateOfIssue",
    align: "left",
    showIcon: true,
  },
  {
    label: "Cliente",
    id: "customerName",
    align: "left",
  },
  {
    label: "CNPJ",
    id: "CNPJ",
    align: "left",
  },
  {
    label: "Data de vencimento",
    id: "dueDate",
    align: "left",
    showIcon: true,
  },
  {
    label: "Status financeiro",
    id: "financialStatus",
    align: "left",
    showIcon: true,
  },
  {
    label: "Filial",
    id: "branch",
    align: "left",
    showIcon: true,
  },
  {
    label: "Valor",
    id: "value",
    align: "right",
    showIcon: true,
  },
  {
    label: "",
    id: "icon",
    align: "center",
  },
];
