import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CustomButton, {
  CustomButtonVariant,
} from "../CustomButton/CustomButton";
import { IconType, StyledIcon } from "../StyledIcon/StyledIcon";

interface ModalCustomProps {
  componentItems: React.ReactNode;
  titleModal: string;
  withIcon?: boolean;
  icon?: React.ReactNode;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs";
  buttons?: Array<{
    title: string;
    onclick?: () => void;
    variant: CustomButtonVariant;
    isClose?: boolean;
  }>;
  onClose?: (e?: string) => void;
  isOpen?: boolean;
  externalController?: boolean;
}

export const Button = styled(MuiButton)({
  padding: "4px 16px",
  fontWeight: "700",
  textTransform: "none",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid #58595B",
  marginBottom: "20px",
  "&#download": {
    background: "#FFFF",
    color: "#58595B",
  },
  "&#close": {
    background: "#58595B",
    color: "#FFF",
    marginRight: "31px",
  },
});

const ModalCustom: React.FC<ModalCustomProps> = ({
  componentItems,
  titleModal,
  buttons,
  maxWidth = "lg",
  withIcon = false,
  icon = "",
  onClose,
  isOpen = false,
  externalController = false,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, setOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose && onClose("close");
    setOpen(false);
  };

  const TitleDialog: React.FC<{ titleModal: string }> = ({ titleModal }) => {
    return (
      <DialogTitle fontSize={24} fontWeight={700} color={"shadesOfDark.dark"}>
        {titleModal}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      {!externalController ? (
        withIcon ? (
          <StyledIcon
            iconType={icon as IconType}
            sx={{ width: "auto", height: "auto", cursor: "pointer" }}
            onClick={handleClickOpen}
          />
        ) : (
          <Button variant="outlined" onClick={handleClickOpen}>
            {titleModal}
          </Button>
        )
      ) : (
        <></>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <TitleDialog titleModal={titleModal} />
        <DialogContent>{componentItems}</DialogContent>
        <DialogActions>
          {buttons?.map((item, index) => (
            <CustomButton
              key={index}
              title={item.title}
              variant={item.variant}
              onClick={() => {
                item.isClose ? handleClose() : item.onclick?.();
              }}
            />
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalCustom;
