import Axios from "axios";
import jwtDecode from "jwt-decode";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import DashLoading from "../../components/DashLoading/DashLoading";
import appConfig from "../../config/default";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../../modules/http/models/IHttpClientService";
import { goToLogout, toReplaceAsterisk } from "../../utils";
import { useIocContext } from "../ioc/IocContext";
import {
  AuthCogCookieKeys,
  AuthenticationContextData,
  AuthenticationResponse,
  CognitoParsedToken,
} from "./domain";
import { datadogRum } from "@datadog/browser-rum";

const AuthenticationContext =
  React.createContext<AuthenticationContextData | null>(null);

const standaloneAuthService = Axios.create({
  baseURL: appConfig.auth.url,
  timeout: appConfig.auth.timeout,
});

export const AuthenticationProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [currentState, setCurrentState] =
    useState<AuthenticationContextData | null>(null);
  const iocContext = useIocContext();

  const globalHttpService = iocContext.serviceContainer.get<IHttpClientService>(
    Types.IHttpService
  );

  const [isTokenReady, setIsTokenReady] = useState(false);

  const performTokenRefresh = useCallback(async (onlyAfter?: number) => {
    const cookies = new Cookies();
    const currentRefreshToken = cookies.get(AuthCogCookieKeys.refreshToken);
    if (!currentRefreshToken) {
      throw new Error("Refresh token not found");
    }
    if (onlyAfter && +new Date() <= onlyAfter) {
      return null;
    }
    const { data } = await standaloneAuthService.post<AuthenticationResponse>(
      "/auth/refresh",
      {
        token: currentRefreshToken,
      }
    );

    const nextTokenUpdate =
      jwtDecode<CognitoParsedToken>(data.AccessToken).exp * 1e3 - 59 * 60 * 1e3;

    globalHttpService.setTokenExpirationStrategy(async () => {
      const data = await performTokenRefresh(nextTokenUpdate - 30e3);
      if (data) {
        const builtToken = `${data.TokenType} ${data.AccessToken}`;
        globalHttpService.setAuthorization(builtToken);
        return builtToken;
      }
      return null;
    });

    return data;
  }, []);

  const logout = useCallback(async () => {
    goToLogout();
  }, []);

  const authByPermission = useCallback(
    (
      userPermission: string[],
      isAdmin: boolean,
      checkPermission: string
    ): boolean => {
      const replacedAsterik = toReplaceAsterisk(checkPermission);
      const checkHasPermission = userPermission
        .filter((check) => replacedAsterik.includes(check))
        .map((r) => r.endsWith("WRITE"));
      const [checked] = checkHasPermission;
      return isAdmin || checked;
    },
    []
  );

  const performBootstrap = useCallback(async () => {
    try {
      const data = await performTokenRefresh();

      if (!data) {
        await logout();
        return;
      }
      const roles = ["admin", "vendor", "customer"];
      const isAuthorized = data.meta.roles.some((ele) => roles.includes(ele));

      if (!isAuthorized) {
        await logout();
      }

      globalHttpService.setAuthorization(
        `${data.TokenType} ${data.AccessToken}`
      );

      setIsTokenReady(globalHttpService.getAuthorization().length > 0);

      setCurrentState({
        email: data.meta.email,
        name: data.meta.name,
        permissionSet: {
          EXTERNAL_SUBSIDIARIES: data.meta.permissionSet.EXTERNAL_SUBSIDIARIES,
          INTERNAL_SUBSIDIARIES: data.meta.permissionSet.INTERNAL_SUBSIDIARIES,
          PRODUCTS: data.meta.permissionSet.PRODUCTS,
          SYSTEM_MODULES: data.meta.permissionSet.SYSTEM_MODULES,
        },
        roles: new Set(data.meta.roles),
        refreshToken: data.RefreshToken,
        subject: data.meta.id,
        token: data.AccessToken,
        logout,
        authByPermission,
      });
    } catch (error) {
      await logout();
    }
  }, []);

  useEffect(() => {
    performBootstrap();
  }, []);

  useEffect(() => {
    if (isTokenReady && window.location.hostname !== "localhost") {
      datadogRum.init({
        applicationId: "c6c6a302-48d0-4a10-8f84-b277ccfa2a41",
        clientToken: "pubfcca41922d6e5f01ff0aedf5aa7eb77d",
        site: "datadoghq.com",
        service: "app-cliente-ream",
        env: "prod-ream",
        version: "1.0.0",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });
      datadogRum.setUser({
        id: currentState?.subject,
        name: currentState?.name,
        email: currentState?.email,
      });
      datadogRum.startSessionReplayRecording();
    }
  }, [isTokenReady]);

  return (
    <AuthenticationContext.Provider value={currentState}>
      {isTokenReady ? children : <DashLoading />}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error("useAuth não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
