import { Box, Typography } from "@mui/material";
import React from "react";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import ModalPumpDetailsHeader from "./ModalPumpDetailsHeader";
import ModalPumpDetailsContent from "./ModalPumpDetailsContent";

export type PumpModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
interface ModalPumpRequestLoaderProps {
  isOpen: boolean;
  data: PumpModalData;
  handleClose: (open: boolean) => void;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
}

const ModalPumpRequestLoader: React.FC<ModalPumpRequestLoaderProps> = ({
  isOpen,
  data,
  handleClose,
  buttons,
}) => {
  const tabBody: React.ReactNode[] = [];

  const hasData = Object.keys(data).map((i) => i).length > 0;

  Object.keys(data).map((key) =>
    tabBody.push(
      <ModalPumpDetailsContent
        key={key}
        modality={{
          title: data.modality?.title,
          value: data.modality?.value,
        }}
        productName={{
          title: data.productName?.title,
          value: data.productName?.value,
        }}
        quantity={{
          title: data.quantity?.title,
          value: data.quantity?.value,
        }}
      />
    )
  );

  const modalOrderHeader = (
    <ModalPumpDetailsHeader
      distributor={data.distributor}
      requestDate={data.requestDate}
      requestUser={data.requestUser}
      subsidiaryDescription={data.subsidiaryDescription}
    />
  );

  const modalTimeDetails = (
    <Box display={"flex"} alignItems={"center"} mb={3} mt={7}>
      <Typography
        fontSize={19}
        fontWeight={700}
        color={"shadesOfDark.dark"}
        mr={1}
      >
        {data.pumpDate?.title ?? ""}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        fontFamily={"Open Sans"}
        color={"shadesOfDark.dark"}
        mr={3}
      >
        {data.pumpDate?.value}
      </Typography>

      <Typography
        fontSize={19}
        fontWeight={700}
        color={"shadesOfDark.dark"}
        mr={1}
      >
        {data.pumpTime?.title}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        fontFamily={"Open Sans"}
        color={"shadesOfDark.dark"}
        mr={3}
      >
        {data.pumpTime?.value}
      </Typography>

      <Typography
        fontSize={19}
        fontWeight={700}
        color={"shadesOfDark.dark"}
        mr={1}
      >
        {data.paymentMethod?.title}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        fontFamily={"Open Sans"}
        color={"shadesOfDark.dark"}
        mr={3}
      >
        {data.paymentMethod?.value}
      </Typography>
    </Box>
  );

  const modalOrderTabs = (
    <Box mb={12}>
      <ModalPumpDetailsContent
        borderWidth={1}
        modality={{
          title: data.modality?.title,
          value: data.modality?.value,
        }}
        productName={{
          title: data.productName?.title,
          value: data.productName?.value,
        }}
        quantity={{
          title: data.quantity?.title,
          value: data.quantity?.value,
        }}
      />
    </Box>
  );

  const modalContent = hasData && (
    <>
      {modalOrderHeader}
      {modalTimeDetails}
      {modalOrderTabs}
    </>
  );
  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false)}
      componentItems={modalContent}
      titleModal={"Detalhes do pedido de bombeio"}
      buttons={buttons}
    />
  );
};

export default ModalPumpRequestLoader;
