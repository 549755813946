import { OutlinedTextFieldProps, TextField, Typography } from "@mui/material";
import React from "react";

interface TextFieldCustomProps extends Omit<OutlinedTextFieldProps, "variant"> {
  label?: string;
  hasError?: boolean;
  messageError?: string;
  endComponent?: React.ReactNode;
  isDisabled?: boolean;
}

const TextFieldCustom: React.FC<TextFieldCustomProps> = ({
  label,
  hasError,
  messageError,
  endComponent,
  isDisabled,
  ...props
}) => {
  const { sx: sxProps } = props;
  return (
    <>
      {label && (
        <Typography
          fontSize={14}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          mb={0.5}
          fontFamily={"Open Sans"}
          noWrap
        >
          {label}
        </Typography>
      )}
      <TextField
        disabled={isDisabled}
        fullWidth
        variant="outlined"
        size="small"
        autoComplete="off"
        sx={{
          ...sxProps,
          width: "100%",
          bgcolor: "background.paper",
          p: 0,
          fontSize: 14,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          "& .MuiOutlinedInput-root.Mui-disabled": {
            backgroundColor: "shadesOfDark.ultraLight",
            "& > input": {
              WebkitTextFillColor: "shadesOfDark.steel",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "shadesOfDark.light",
            },
          },
          "& .MuiOutlinedInput-root": {
            background: "#FFF",
            borderRadius: 1,
            color: "shadesOfDark.dark",
            "& fieldset": {
              borderColor: hasError
                ? "systemColors.error.main"
                : "shadesOfDark.light",
            },
            "& > input": {
              padding: 1.09,
            },
            "&.Mui-focused fieldset": {
              borderColor: hasError
                ? "systemColors.error.main"
                : "shadesOfDark.steel",
            },
          },
        }}
        InputProps={{
          endAdornment: <>{endComponent}</>,
        }}
        // removi temporariamente para o input aceitar Nun negativos, voltará em breve!
        // onKeyPress={(event) => {
        //   if (event?.key === "-" || event?.key === "+") {
        //     event.preventDefault();
        //   }
        // }}
        {...props}
      />
      {hasError && (
        <Typography
          mt={0.5}
          fontFamily={"Open Sans"}
          fontSize={10}
          color={"systemColors.error.main"}
        >
          {messageError}
        </Typography>
      )}
    </>
  );
};

export default TextFieldCustom;
