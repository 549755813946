import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Roles } from "../SideBar/Content/ListItem";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import { sidebar } from "../../utils/featureFlags";
import { useFlags } from "flagsmith/react";

interface RouteGuardProps extends PropsWithChildren {
  children: any;
  rules: (_: string) => boolean;
  flag?: string;
}

const RouteGuard: React.FC<RouteGuardProps> = ({ children, rules, flag }) => {
  const flags = useFlags(sidebar);

  const { token, roles, permissionSet } =
    useAuth() as AuthenticationContextData;
  const isAdmin = roles.has(Roles.ADMIN);
  const isAuthorized =
    permissionSet.SYSTEM_MODULES.some((authPermission) =>
      rules(authPermission)
    ) && (flag ? flags[flag].enabled : true);

  return (token && isAdmin) || isAuthorized ? children : <Navigate to="/" />;
};

export default RouteGuard;
