import { Grid } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import { DetailInputsModalProps } from "../../../components/ModalExtractContent/ModalExtractContent";
import TitleAndDetailExtract from "../../../components/ModalExtractTitleAndDetail/ModalExtractTitleAndDetail";

interface ModalPumpDetailsContentProps {
  productName: DetailInputsModalProps;
  modality: DetailInputsModalProps;
  quantity: DetailInputsModalProps;
  color?: string;
  borderWidth?: number;
}

const ModalPumpDetailsContent: React.FC<ModalPumpDetailsContentProps> = ({
  productName,
  modality,
  quantity,
  color,
  borderWidth = 0,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid
      container
      sx={{
        border: borderWidth,
        borderStyle: "solid",
        borderColor: "shadesOfDark.ultraLight",
        borderRadius: "4px",
      }}
      padding={2}
      paddingTop={1}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={5}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <TitleAndDetailExtract
            color={color}
            title={productName.title}
            detail={productName.value}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={modality.title}
            detail={modality.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            color={color}
            title={quantity.title}
            detail={quantity.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalPumpDetailsContent;
