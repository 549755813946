import { useCallback, useEffect, useState } from "react";

export interface FetchData<T> {
  loading: boolean;
  errorLoading: boolean;
  firstLoading: boolean;
  value: T | null;
  fetch: () => Promise<void>;
}

export function useFetchData<T>(
  callback: () => Promise<T>,
  options?: {
    useEffectDeps?: any[];
    useCallbackDeps?: any[];
    simulateError?: {
      isValueNull?: boolean;
      isError: boolean;
    };
  }
): FetchData<T> {
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [firstLoading, setFirstLoading] = useState(true);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const valueFetched = await Promise.resolve(callback());
      if (options?.simulateError && options?.simulateError.isValueNull) {
        setValue(null);
      } else {
        setValue(valueFetched);
      }

      setErrorLoading(
        (options?.simulateError && options.simulateError.isError) ?? false
      );
    } catch (error) {
      setErrorLoading(true);
    } finally {
      setLoading(false);
      setFirstLoading(false);
    }
  }, [...(options && options.useCallbackDeps ? options.useCallbackDeps : [])]);

  if (!options) {
    useEffect(() => {
      fetch();
    }, []);
  } else {
    useEffect(() => {
      fetch();
    }, [...(options.useEffectDeps ? options.useEffectDeps : [])]);
  }

  return { loading, errorLoading, value, firstLoading, fetch };
}
