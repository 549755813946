import React, { ReactNode, useState } from "react";
import { OperationModeType } from "../Section/RoadScheduleRequestStep1";
import { VehicleBundle } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { ScheduleModalData } from "../Section/ModalScheduleRequestLoader";

export interface RoadScheduleRequestFormData {
  type?: OperationModeType;
  receiverCustomerId?: string;
  customerCompanyId?: string;
  driverId?: string;
  shippingCompanyId?: string;
  vehicleBundle?: VehicleBundle;
  horsePlates?: string[];
  productId?: string;
  contractId?: string;
  mod?: string;
  volumeRequested?: number;
  suggestedVolume?: number;
  reason?: string;
  paymentCondition?: string;
  scheduleDate?: string;
  scheduleLikeDate?: Date;
  scheduleTime?: string;
  scheduleToken?: string;
  note?: string;
  subsidiaryId?: string;
}

interface RoadScheduleRequestContextData {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formData?: RoadScheduleRequestFormData;
  setFormData: React.Dispatch<
    React.SetStateAction<RoadScheduleRequestFormData | undefined>
  >;
  modalData?: ScheduleModalData;
  setModalData: React.Dispatch<React.SetStateAction<ScheduleModalData>>;
  isCreatingSchedule?: boolean;
  setIsCreatingSchedule: React.Dispatch<React.SetStateAction<boolean>>;
}

const RoadScheduleRequestContext =
  React.createContext<RoadScheduleRequestContextData>({
    step: 0,
    setFormData() {},
    setStep() {},
    setModalData() {},
    setIsCreatingSchedule() {},
    isCreatingSchedule: false,
  });

const RoadScheduleRequestProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<RoadScheduleRequestFormData>();
  const [modalData, setModalData] = useState<ScheduleModalData>({});
  const [isCreatingSchedule, setIsCreatingSchedule] = useState(false);

  return (
    <RoadScheduleRequestContext.Provider
      value={{
        step,
        setStep,
        formData,
        setFormData,
        modalData,
        setModalData,
        isCreatingSchedule,
        setIsCreatingSchedule,
      }}
    >
      {children}
    </RoadScheduleRequestContext.Provider>
  );
};

const useRoadScheduleRequestContext = () => {
  const context = React.useContext(RoadScheduleRequestContext);
  if (context === undefined) {
    throw new Error(
      "useRoadScheduleRequestContext não pode ser utilizado fora de um RoadScheduleRequestProvider"
    );
  }
  return context;
};
export { useRoadScheduleRequestContext, RoadScheduleRequestProvider };
