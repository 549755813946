export type DownloadType = "tax" | "pdf" | "xml" | "detailNote";

interface IDownloadItem {
  label: string;
  type: DownloadType;
  openModal?: boolean;
}

export const DownloadList: IDownloadItem[] = [
  {
    label: "Ver detalhes da nota",
    type: "detailNote",
    openModal: true,
  },
  {
    label: "Download em pdf",
    type: "pdf",
  },
  {
    label: "Download em xml",
    type: "xml",
  },
];
