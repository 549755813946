import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "../../../components/Select/Select";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import SelectDistributors from "../../../components/SelectDistributors/SelectDistributors";
import {
  ModalityDTO,
  ProductDTO,
} from "../../../modules/distributors/models/DistributorListDTO";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";
import { ISupplyService } from "../../../modules/supply/models/ISupplyService";
import { IGetPeriodByCompanyDTO } from "../../../modules/supply/models/IGetPeriodByCompanyDTO";
import useDialogAlert from "../../../hooks/DialogAlert";
import AppError from "../../../utils/appError";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";

interface IQueryStatementProps {
  customerCompanyId?: string;
  monthReference?: string;
  productId?: string;
  mod?: string;
  subsidiaryId?: string;
}
interface IButtonFilterTableProps {
  onButtonChange: (query: IQueryStatementProps) => void;
  onButtonClearFilter: (isClear: boolean) => void;
}

const ButtonFilterTableSection: React.FC<IButtonFilterTableProps> = ({
  onButtonChange,
  onButtonClearFilter,
}) => {
  const [products, setProducts] = useState<ProductDTO[]>([]);
  const [modalities, setModalities] = useState<ModalityDTO[]>([]);
  const [period, setPeriod] = useState<IGetPeriodByCompanyDTO[]>([]);
  const [codePeriod, setCodePeriod] = useState<string | null>(null);
  const { selectedDistributor, subsidiaries } = useDistributor();
  const [codeProduct, setCodeProduct] = useState<string | null>(null);
  const [codeModality, setCodeModality] = useState<string | null>(null);
  const [codeDistributor, setCodeDistributor] = useState<string | null>(null);
  const [codeSubsidiary, setCodeSubsidiary] = useState<string | null>(null);
  const { getModalities, getProducts, setProductSelected } = useOrderQuote();
  const { serviceContainer } = useIocContext();
  const { snackbar } = useDialogAlert();
  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );

  const getModalitiesData = useCallback(async () => {
    const response = await getModalities?.();
    if (response !== undefined) {
      setModalities(response);
    }
  }, [getModalities, setModalities]);

  useEffect(() => {
    if (selectedDistributor?.id !== undefined) {
      setCodeDistributor(selectedDistributor.id);
    }
  }, [selectedDistributor]);

  const getPeriodData = useCallback(async (id: string) => {
    try {
      const response = await supplyService.getAllPeriodByCompany?.(id);
      setPeriod(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    }
  }, []);

  useEffect(() => {
    if (selectedDistributor?.id) {
      getPeriodData(selectedDistributor.id);
    }
  }, [selectedDistributor]);

  const getProductsData = useCallback(async () => {
    const response = await getProducts?.();
    if (response?.length) {
      setProducts(response);
    }
  }, [getProducts, setProducts]);

  const handleChange = useCallback(() => {
    onButtonClearFilter(false);
    const query = {
      ...(codeModality !== null && { mod: codeModality }),
      ...(codeProduct !== null && { productId: codeProduct }),
      ...(codePeriod !== null && { monthReference: codePeriod }),
      ...(codeSubsidiary !== null && {
        subsidiaryId: codeSubsidiary,
      }),
      ...(codeDistributor !== null && {
        customerCompanyId: codeDistributor,
      }),
    };

    const currentProduct = products.find(
      (product) => codeProduct === product.id
    );
    onButtonChange(query);
    if (currentProduct?.measurementUnit && setProductSelected) {
      setProductSelected(currentProduct.measurementUnit);
    }
  }, [codeModality, codeProduct, codeDistributor, codeSubsidiary, codePeriod]);

  useEffect(() => {
    getProductsData();
    getModalitiesData();
  }, [getProductsData, getModalitiesData]);

  const handleClearFilter = useCallback(() => {
    setCodePeriod(null);
    setCodeProduct(null);
    setCodeModality(null);
    setCodeSubsidiary(null);
    onButtonClearFilter(true);
  }, [onButtonClearFilter]);

  const isAllSelected = useMemo(
    () =>
      codePeriod !== null &&
      codeModality !== null &&
      codeProduct !== null &&
      codeSubsidiary !== null &&
      codeDistributor !== null,
    [codePeriod, codeModality, codeProduct, codeDistributor, codeSubsidiary]
  );

  return (
    <Grid container>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={3}>
            <SelectDistributors
              isCutText
              label={"Selecione o cliente"}
              maxLenghtSelected={19}
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <SelectSubsidiaries
              label={"Filial"}
              valueSubsidiary={subsidiaries.find(
                (item) => item.id === codeSubsidiary
              )}
              onChangeValue={(selectedOption) => {
                if (selectedOption) {
                  setCodeSubsidiary(selectedOption.id);
                }
              }}
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <Select
              label="Período"
              emptyOptionsText={`Não há períodos disponíveis para o cliente ${
                selectedDistributor?.description ?? ""
              }`}
              options={period.map((option) => ({
                option,
              }))}
              getOptionItemLabel={(value) => value?.month ?? ""}
              getSelectedOptionLabel={(value) => value?.month ?? ""}
              onChange={(value) => {
                setCodePeriod(value?.monthReference ?? "");
              }}
              value={
                codePeriod !== null
                  ? period.find((item) => item.monthReference === codePeriod)
                  : null
              }
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <Select
              label="Produto"
              options={products.map((option) => ({
                option,
              }))}
              getOptionItemLabel={(value) =>
                value !== null ? value.description : ""
              }
              getSelectedOptionLabel={(value) =>
                value !== null ? value.description : ""
              }
              onChange={(value) => {
                setCodeProduct(value !== null ? value.id : null);
              }}
              value={
                codeProduct !== null
                  ? products.find((item) => item.id === codeProduct)
                  : null
              }
            />
          </Grid>

          <Grid item xs={10} sm={6} md={3}>
            <Select
              label="Modalidade"
              options={modalities.map((option) => ({
                option,
              }))}
              getOptionItemLabel={(value) =>
                `${value?.metadata.logistics[0] ?? ""} - ${
                  value?.code ?? ""
                }` || ""
              }
              getSelectedOptionLabel={(value) =>
                `${value?.metadata.logistics[0] ?? ""} - ${
                  value?.code ?? ""
                }` || ""
              }
              onChange={(value) => {
                if (value) {
                  setCodeModality(value.code);
                }
              }}
              value={
                codeModality !== null
                  ? modalities.find((item) => item.code === codeModality)
                  : null
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      >
        <CustomButton
          title="Limpar filtros"
          variant={CustomButtonVariant.OUTLINED}
          onClick={handleClearFilter}
        />

        <CustomButton
          sx={{ marginLeft: "16px" }}
          title="Consultar"
          variant={
            isAllSelected
              ? CustomButtonVariant.CONTAINED
              : CustomButtonVariant.DISABLED
          }
          onClick={handleChange}
          disabled={!isAllSelected}
        />
      </Grid>
    </Grid>
  );
};

export default ButtonFilterTableSection;
