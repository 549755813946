import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableHeaderColumns: Column[] = [
  {
    label: "Número da nota",
    id: "noteNumber",
    align: "left",
  },
  {
    label: "Produto",
    id: "product",
    align: "left",
  },
  {
    label: "Emissão",
    id: "dateOfIssue",
    align: "left",
  },
  {
    label: "Vencimento",
    id: "dueDate",
    align: "left",
  },
  {
    label: "Quantidade",
    id: "quantity",
    align: "right",
  },
  {
    label: "Unidade de medida",
    id: "unit",
    align: "left",
  },
  {
    label: "Status da nota",
    id: "cancelled",
    align: "left",
  },
  {
    label: "Valor",
    id: "price",
    align: "right",
  },
  {
    label: "",
    id: "icon",
    align: "center",
  },
];
