import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { PipelineScheduleStatus } from "../../modules/operational/models/GetPipelineSchedulesDTO";
import { StatusTypeEnum } from "../../utils";

interface StatusTagProps {
  value: PipelineScheduleStatus;
  marginLeft?: string;
}

const StatusTextTag: React.FC<StatusTagProps> = ({ value, marginLeft }) => {
  const theme = useTheme();

  const getStatusLabel = (value: string) => {
    const status = StatusTypeEnum.find((status) => status.value === value);
    return status ? status.label : "";
  };

  const selectColor = (): 1 | 2 | 0 | 6 | 3 => {
    switch (value) {
      case PipelineScheduleStatus.VENDOR_PENDING:
      case PipelineScheduleStatus.VENDOR_APPROVED:
      case PipelineScheduleStatus.IN_LOADING:
        return 1;
      case PipelineScheduleStatus.VENDOR_REJECTED:
      case PipelineScheduleStatus.INVOICE_BLOCKED:
      case PipelineScheduleStatus.FINANCIAL_BLOCKED:
        return 2;
      case PipelineScheduleStatus.INVOICE_CLEARED:
        return 0;
      case PipelineScheduleStatus.INVOICE_PENDING:
      case PipelineScheduleStatus.IN_SCHEDULING:
        return 6;
      case PipelineScheduleStatus.INVOICE_CANCELED:
        return 3;
      default:
        return 0;
    }
  };

  const statusLabel = getStatusLabel(value ?? "");

  return (
    <Box
      display={"inline-block"}
      width={104}
      height={"fit-content"}
      paddingY={0.5}
      paddingX={1}
      bgcolor={theme.palette.tagsAndStatus[selectColor()]}
      color={theme.palette.tagAndStatusText[selectColor()]}
      borderRadius={4}
      textAlign={"center"}
      marginLeft={marginLeft}
    >
      <Typography textTransform={"capitalize"} fontSize={13}>
        {statusLabel}
      </Typography>
    </Box>
  );
};

export default StatusTextTag;
