import React, { useCallback, useState, Dispatch, useEffect } from "react";
import * as _ from "lodash";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import ModalOrderDetails from "../../../components/ModalOrderDetails/ModalOrderDetails";
import { Grid } from "@mui/material";
import { IFileUploadDTO } from "../../../modules/operational/models/IFileUpload";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { removeSpecialCharactersAndLetters } from "../../../utils";
import FileInput from "../../../components/FileInput";
import useDialogAlert from "../../../hooks/DialogAlert";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import { Types } from "../../../ioc/types";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { IUpdateScheduleDTO } from "../../../modules/operational/models/UpdateScheduleDTO";

export type ScheduleModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
interface ModalScheduleUpdateProps {
  isOpen: boolean;
  data: ScheduleModalData | any;
  cleanFilter?: () => void;
  handleClose: (open: boolean) => void;
  buttons?: Array<{
    title: string;
    onClick?: () => void;
    variant: CustomButtonVariant;
  }>;
}

const ModalScheduleUpdate: React.FC<ModalScheduleUpdateProps> = ({
  isOpen,
  data,
  handleClose,
  cleanFilter,
}) => {
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();
  const [pdfFile, setPdfFile] = React.useState<File | null>(null);
  const [idSchedule, setIdSchedule] = useState("");
  const [invoice, setInvoice] = React.useState<string>("");
  const [files, setFiles] = React.useState<IFileUploadDTO[] | null>(null);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  useEffect(() => {
    data.invoiceReferenceNumber?.value && setIsValid(true);
    setIdSchedule(data.idSchedule?.value);
  }, [data]);

  useEffect(() => {
    invoice.length > 10 || invoice.length === 0 || pdfFile === null
      ? setIsValid(false)
      : setIsValid(true);
  }, [invoice, pdfFile]);

  const UpdateRoadSchedule = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload: IUpdateScheduleDTO = {
        customerInvoiceReference: invoice,
        attachments: files ?? [],
      };

      await operationalService.updateRoadScheduleRequest(idSchedule, payload);
      snackbar({
        message: "Nota Fiscal atualizada com sucesso",
        variant: "success",
      });
    } catch (error) {
      snackbar({
        message: "Erro ao atualizar Nota Fiscal.",
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      handleClose(false);
      cleanFilter && cleanFilter();
    }
  }, [idSchedule, invoice, files]);

  const handleUploadFile = useCallback(
    async (
      file: File | null,
      setFile: Dispatch<React.SetStateAction<File | null>>
    ) => {
      if (_.isNull(file)) return;
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await operationalService.uploadRoadScheduleFile(
          formData
        );

        setFiles((old) => {
          return old ? [...old, ...response] : response;
        });
      } catch (error) {
        setFile(null);
        snackbar({
          message: "Erro ao realizar upload, tente novamente.",
          variant: "error",
        });
      }
    },
    [setFiles]
  );

  const modalUpdate = (
    <Grid container spacing={4} pb={3}>
      <Grid item xs={12} md={6}>
        <TextFieldCustom
          disabled={data.invoiceReferenceNumber?.value}
          label={"Número da Nota fiscal"}
          placeholder="Informe o valor"
          value={invoice}
          hasError={error !== ""}
          messageError={error}
          onChange={(value) => {
            const numberWithoutCharactersAndLetters =
              removeSpecialCharactersAndLetters(value.target.value);

            numberWithoutCharactersAndLetters.length <= 10
              ? setError("")
              : setError("O valor deve conter no máximo 10 dígitos.");

            setInvoice(numberWithoutCharactersAndLetters);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} display={"flex"} flexDirection={"row"}>
        <FileInput
          value={pdfFile}
          label="PDF"
          accept=".pdf"
          helperText="Formato aceito (PDF)"
          onChangeFile={(file) => {
            setPdfFile(file);
            handleUploadFile(file, setPdfFile);
          }}
        />
      </Grid>
    </Grid>
  );

  const updatedButtons = [
    {
      title: "Salvar",
      variant: isLoading
        ? CustomButtonVariant.CONTAINED_LOADING
        : isValid
        ? CustomButtonVariant.CONTAINED
        : CustomButtonVariant.DISABLED,
      onClick: () => {
        UpdateRoadSchedule();
      },
    },
    {
      title: "Fechar",
      variant: CustomButtonVariant.CONTAINED,
      onClick: () => {
        handleClose(false);
      },
    },
  ];

  return (
    <ModalOrderDetails
      openDialog={isOpen}
      handleClose={() => handleClose(false)}
      componentItems={modalUpdate}
      titleModal={"Editar detalhes do pedido de agendamento"}
      buttons={updatedButtons}
    />
  );
};

export default ModalScheduleUpdate;
