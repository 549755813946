import { Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedBox from "../../../components/AnimatedBox/AnimatedBox";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import Select from "../../../components/Select/Select";
import { routesConfig } from "../../../config/routes";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { DateToFormat } from "../../../utils/dateFormatter";
import { getTitleAndValue, formatInfoCompany } from "../../../utils";
import { useAuth } from "../../../contexts/authentication/AuthenticationContext";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";
import AutoComplete, {
  AutoCompleteVariant,
} from "../../../components/AutoComplete/AutoComplete";
import { Clear } from "@mui/icons-material";
import { Types } from "../../../ioc/types";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import { useFetchData } from "../../../hooks/FetchData";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import AppError from "../../../utils/appError";
import useDialogAlert from "../../../hooks/DialogAlert";
import { useRiverRequestContext } from "../context/RiverRequestContext";
import { CustomerCompany } from "../../../modules/operational/models/GetRiverDTO";
import { RiverRequestFormData, styleButton } from "../models/customIconRiver";
import {
  InitialValuesStep1,
  initialValidationSchemaStep1,
} from "../form/schema";

const RiverRequestStep1: React.FC = () => {
  const { formData, setStep, setFormData, setModalData } =
    useRiverRequestContext();
  const [isLoadingReceiversCompanies, setIsLoadingReceiversCompanies] =
    useState(false);
  const [receivingCustomerList, setReceivingCustomerList] = useState<
    CustomerCompany[]
  >([]);

  const navigate = useNavigate();
  const { snackbar } = useDialogAlert();
  const { distributors, subsidiaries } = useDistributor();

  const auth = useAuth();

  const { serviceContainer } = useIocContext();

  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const shippingCompaniesList = useFetchData(
    async () => await operationalService.getShippingCompanies()
  );

  const getReceiversCompanies = async () => {
    try {
      setIsLoadingReceiversCompanies(true);
      const response = await operationalService.getReceiversCompanies();
      setReceivingCustomerList(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    } finally {
      setIsLoadingReceiversCompanies(false);
    }
  };

  useEffect(() => {
    getReceiversCompanies();
    setModalData((oldState) => ({
      ...oldState,
      requestDate: getTitleAndValue("Data do pedido", DateToFormat(new Date())),
      requestUser: getTitleAndValue("Solicitado por", auth?.name),
    }));
  }, []);

  function getInitialValues(
    formData: RiverRequestFormData
  ): InitialValuesStep1 {
    return {
      customerCompanyId: formData?.customerCompanyId ?? "",
      subsidiaryId: formData?.subsidiaryId ?? "",
      receiverCustomerId: formData?.receiverCustomerId ?? "",
      shippingCompanyId: formData?.shippingCompanyId ?? "",
    };
  }

  return (
    <AnimatedBox>
      <Formik
        initialValues={getInitialValues(formData ?? {})}
        validationSchema={initialValidationSchemaStep1}
        onSubmit={function (values: InitialValuesStep1) {
          setFormData((oldState) => ({
            ...oldState,
            subsidiaryId: values.subsidiaryId,
            customerCompanyId: values.customerCompanyId,
            receiverCustomerId: values.receiverCustomerId,
            shippingCompanyId: values.shippingCompanyId,
          }));
          setStep(1);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const hasErrorCustomerName =
            touched.customerCompanyId && Boolean(errors.customerCompanyId);
          const hasErrorLocal =
            touched.subsidiaryId && Boolean(errors.subsidiaryId);
          const hasErrorShippingCompanyId =
            touched.shippingCompanyId && Boolean(errors.shippingCompanyId);
          const hasErrorReceivingCustomer =
            touched.receiverCustomerId && Boolean(errors.receiverCustomerId);

          return (
            <Form>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={"Open Sans"}
                    fontSize={16}
                    fontWeight={700}
                    color={"shadesOfDark.dark"}
                  >
                    Agendamento
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} mt={2.5}>
                  <Select
                    label="Nome do cliente"
                    options={distributors.map((option) => ({
                      option,
                    }))}
                    value={distributors.find(
                      (item) => item.description === values.customerCompanyId
                    )}
                    getOptionItemLabel={(value) =>
                      formatInfoCompany(
                        value.description ?? "",
                        value.document ?? ""
                      )
                    }
                    getSelectedOptionLabel={(value) => value.description ?? ""}
                    onChange={(value) => {
                      setFieldValue("customerCompanyId", value.id);
                      setModalData((oldState) => ({
                        ...oldState,
                        issuingCustomer: {
                          title: "Cliente",
                          value: value.description ?? "",
                        },
                      }));
                    }}
                    hasError={hasErrorCustomerName}
                    messageError={errors.customerCompanyId}
                    onBlur={() => {
                      setFieldTouched("customerCompanyId", true);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={6}>
                  <SelectSubsidiaries
                    label={"Filial"}
                    valueSubsidiary={subsidiaries.find(
                      (item) => item.id === values.subsidiaryId
                    )}
                    onChangeValue={(selectedOption) => {
                      if (selectedOption) {
                        setFieldValue("subsidiaryId", selectedOption.id);
                        setModalData((oldState) => ({
                          ...oldState,
                          subsidiaryDescription: {
                            title: "Filial",
                            value: selectedOption.description ?? "",
                          },
                        }));
                      }
                    }}
                    onBlurValue={() => {
                      setFieldTouched("subsidiaryId", true);
                    }}
                    hasErrorValue={hasErrorLocal}
                    messageErrorValue={errors.subsidiaryId}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={6}>
                  <AutoComplete
                    variant={
                      isLoadingReceiversCompanies
                        ? AutoCompleteVariant.LOADING
                        : AutoCompleteVariant.STANDARD
                    }
                    label="Recebedor da Mercadoria"
                    iconButton={
                      values.receiverCustomerId !== "" && (
                        <Clear
                          fontSize="small"
                          sx={styleButton}
                          onClick={() => {
                            setFieldValue("receiverName", "");
                          }}
                        />
                      )
                    }
                    options={receivingCustomerList}
                    getOptionItemLabel={(value) =>
                      formatInfoCompany(
                        value.description ?? "",
                        value.document ?? ""
                      )
                    }
                    value={
                      receivingCustomerList.find(
                        (item) => item.id === values.receiverCustomerId
                      ) ?? {}
                    }
                    onChange={(value) => {
                      setFieldValue("receiverCustomerId", value.id);
                      setModalData((oldState) => ({
                        ...oldState,
                        receiver: getTitleAndValue(
                          "Recebedor da Mercadoria",
                          value.description
                        ),
                      }));
                    }}
                    onBlur={() => setFieldTouched("receiverName", true)}
                    hasError={hasErrorReceivingCustomer}
                    messageError={errors.receiverCustomerId}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={6}>
                  <AutoComplete
                    iconButton={
                      values.shippingCompanyId !== "" && (
                        <Clear
                          fontSize="small"
                          sx={styleButton}
                          onClick={() => {
                            setFieldValue("shippingCompanyId", "");
                          }}
                        />
                      )
                    }
                    variant={
                      shippingCompaniesList?.loading
                        ? AutoCompleteVariant.LOADING
                        : AutoCompleteVariant.STANDARD
                    }
                    isDisabled={shippingCompaniesList?.loading}
                    label="Transportadora"
                    value={
                      shippingCompaniesList?.value?.find(
                        (item) => item.id === values.shippingCompanyId
                      ) ?? {}
                    }
                    options={shippingCompaniesList?.value ?? []}
                    getOptionItemLabel={(value) => value.description ?? ""}
                    onChange={(value) => {
                      setFieldValue("shippingCompanyId", value.id);
                      setModalData((oldState) => ({
                        ...oldState,
                        shippingCompany: getTitleAndValue(
                          "Transportadora",
                          value.description
                        ),
                      }));
                    }}
                    onBlur={() => {
                      setFieldTouched("shippingCompanyName", true);
                    }}
                    hasError={hasErrorShippingCompanyId}
                    messageError={errors.shippingCompanyId}
                  />
                </Grid>

                <Grid item xs={12} sx={{ display: "inline-flex" }}>
                  <div style={{ flexGrow: 1 }} />
                </Grid>
                <Grid item xs={12} mt={10}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Box display={"flex"}>
                    <CustomButton
                      title={"Cancelar"}
                      variant={CustomButtonVariant.OUTLINED}
                      onClick={() => {
                        navigate(routesConfig.OPERATIONAL().RIVER);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      title={"Avançar"}
                      variant={CustomButtonVariant.CONTAINED}
                      onClick={() => {}}
                      onSubmit={() => handleSubmit}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </AnimatedBox>
  );
};

export default RiverRequestStep1;
