import React, { useCallback, useEffect, useState } from "react";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import { GetOrdersPaginatedQuery } from "../../../modules/supply/models/GetOrdersPaginatedQuery";
import useDialogAlert from "../../../hooks/DialogAlert";
import { TableDraftHeaderColumns } from "../models/TableHeaderColumns";
import { ISODateToFormat, getMonthName } from "../../../utils/dateFormatter";
import { CommonMenuList, RedirectPage } from "../models/OptionsMenu";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import { StyledMenuProps, TableRow } from "../models/OrderSectionsTableProps";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { ROW_PER_PAGE } from "../models/SearchFilterOptions";
import { calcPagesQuantity } from "../../../utils";
interface DraftOrdersSectionProps {
  dataFilter?: GetOrdersPaginatedQuery | null;
}

const DraftOrdersSection: React.FC<DraftOrdersSectionProps> = ({
  dataFilter,
}) => {
  const [data, setData] = useState<TableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { snackbar } = useDialogAlert();
  const { getOrders, setItemInView } = useOrderQuote();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const StyledMenu: React.FC<StyledMenuProps> = (data) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async (type: RedirectPage) => {
      setAnchorEl(null);
      switch (type) {
        case "viewOrder":
          navigate(routesConfig.SUPPLIES().REQUEST);
          if (setItemInView) {
            setItemInView(data?.data?.id || "");
          }
          break;
        case "deleteOrder":
          break;
        default:
          break;
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {CommonMenuList.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(option.type);
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const getOrdersDraft = useCallback(async (query: GetOrdersPaginatedQuery) => {
    try {
      setErrorData(false);
      setIsLoadingData(true);
      const response = await getOrders?.(query);
      const newArray = response?.content;
      setTotal(response?.total ?? 0);
      if (newArray?.length === 0) {
        setErrorData(true);
      }

      const newDate: TableRow[] =
        newArray?.map(
          (item): TableRow => ({
            id: item.id,
            refId: item.refId,
            vendorAuthorityId: item.vendorAuthorityId,
            monthReference: getMonthName(item.monthReference ?? ""),
            customerCompany: item?.customerRequester,
            subsidiaryDescription:
              item?.contractRequestEntry?.subsidiary?.description ?? "",
            productName:
              item?.contractRequestEntry?.product?.description?.toUpperCase() ??
              "",
            mod: item?.contractRequestEntry?.mod,
            subsidiary: item?.customerCompany?.description,
            lastUpdate: ISODateToFormat(item.updatedAt ?? ""),
            accessKey: item?.id,
          })
        ) ?? [];
      setData(newDate);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar tabela de rascunho. Tentar novamente",
        variant: "error",
      });
      setErrorData(true);
    } finally {
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    if (dataFilter !== null) {
      getOrdersDraft(dataFilter as GetOrdersPaginatedQuery);
      setCurrentPage(dataFilter?.page ?? 1);
    }
  }, [dataFilter]);

  const getbyPage = useCallback(
    (page: number) => {
      if (dataFilter !== null && page) {
        const pageFilter = { ...dataFilter, page: page };
        setCurrentPage(page);
        getOrdersDraft(pageFilter as GetOrdersPaginatedQuery);
      }
    },
    [dataFilter, setCurrentPage]
  );

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="data-not-found"
          title="Não foi encontrado resultado"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <TablePaginated
          columns={TableDraftHeaderColumns}
          data={data.map((item) => ({
            ...item,
            icon: item.isLoadingMenuTable ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <StyledMenu data={item} />
            ),
          }))}
          isLoadingData={isLoadingData}
          loadFromServer
          onChangePage={(newPage) => {
            getbyPage(newPage);
          }}
          rowsPerPage={10}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: currentPage,
          }}
          resultsTotal={total}
        />
      )}
    </>
  );
};

export default DraftOrdersSection;
