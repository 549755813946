import { Box, Divider, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AnimatedBox from "../../../components/AnimatedBox/AnimatedBox";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import Select from "../../../components/Select/Select";
import { routesConfig } from "../../../config/routes";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import useDialogAlert from "../../../hooks/DialogAlert";
import { Types } from "../../../ioc/types";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import { CustomerCompany } from "../../../modules/supply/models/GetOrdersDTO";
import AppError from "../../../utils/appError";
import { useRoadScheduleRequestContext } from "../context/RoadScheduleRequestContext";
import { DateToFormat } from "../../../utils/dateFormatter";
import { getTitleAndValue, formatInfoCompany } from "../../../utils";
import { useAuth } from "../../../contexts/authentication/AuthenticationContext";
import AutoComplete, {
  AutoCompleteVariant,
} from "../../../components/AutoComplete/AutoComplete";
import { Clear } from "@mui/icons-material";
import { styleButton } from "../models/customIconRoadSchedule";
import SelectSubsidiaries from "../../../components/SelectSubsidiary/SelectSubsidiary";

export type OperationModeType =
  | "INTERNAL_MARKET_SALE"
  | "SHIPMENT_ON_ACCOUNT_AND_ORDER";

export const operationModeList: Array<{
  id: OperationModeType;
  label: string;
}> = [
  {
    id: "INTERNAL_MARKET_SALE",
    label: "Venda de mercado interno",
  },
  {
    id: "SHIPMENT_ON_ACCOUNT_AND_ORDER",
    label: "Remessa por conta e ordem",
  },
];

interface FormProps {
  local: string;
  operationMode?: OperationModeType;
  issuingCustomer: string;
  receivingCustomer: string;
}

const RoadScheduleRequestStep1: React.FC = () => {
  const navigate = useNavigate();
  const { distributors, subsidiaries } = useDistributor();
  const { formData, setStep, setFormData, setModalData } =
    useRoadScheduleRequestContext();

  const [isLoadingReceiversCompanies, setIsLoadingReceiversCompanies] =
    useState(false);
  const [receivingCustomerList, setReceivingCustomerList] = useState<
    CustomerCompany[]
  >([]);
  const auth = useAuth();
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();

  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const getReceiversCompanies = async () => {
    try {
      setIsLoadingReceiversCompanies(true);
      const response = await operationalService.getReceiversCompanies();
      setReceivingCustomerList(response);
    } catch (error) {
      snackbar({
        message: (error as AppError).message,
        variant: "error",
      });
    } finally {
      setIsLoadingReceiversCompanies(false);
    }
  };

  useEffect(() => {
    getReceiversCompanies();
    setModalData((oldState) => ({
      ...oldState,
      requestDate: getTitleAndValue("Data do pedido", DateToFormat(new Date())),
      requestUser: getTitleAndValue("Solicitado por", auth?.name),
    }));
  }, []);

  return (
    <AnimatedBox>
      <Formik
        initialValues={{
          local: "",
          operationMode: formData?.type,
          issuingCustomer: formData?.customerCompanyId ?? "",
          receivingCustomer: formData?.receiverCustomerId ?? "",
        }}
        validationSchema={Yup.object({
          local: Yup.string(),
          operationMode: Yup.string().required("Selecione um modo de operação"),
          issuingCustomer: Yup.string().required(
            "Selecione um cliente emissor"
          ),
          receivingCustomer: Yup.string(),
        })}
        onSubmit={function (
          values: FormProps,
          formikHelpers: FormikHelpers<FormProps>
        ) {
          setFormData((oldState) => ({
            ...oldState,
            subsidiaryId: values.local,
            type: values.operationMode,
            customerCompanyId: values.issuingCustomer,
            receiverCustomerId:
              values.receivingCustomer.length > 0 &&
              values.operationMode === "SHIPMENT_ON_ACCOUNT_AND_ORDER"
                ? values.receivingCustomer
                : undefined,
          }));
          setStep(1);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const hasErrorLocal = touched.local && Boolean(errors.local);
          const hasErrorOperationMode =
            touched.operationMode && Boolean(errors.operationMode);

          const hasErrorIssuingCustomer =
            touched.issuingCustomer && Boolean(errors.issuingCustomer);
          const hasErrorReceivingCustomer =
            touched.receivingCustomer && Boolean(errors.receivingCustomer);

          const isMarketSaleIntern = (operationMode?: string) =>
            operationMode === "INTERNAL_MARKET_SALE";

          return (
            <Form>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={"Open Sans"}
                    fontSize={16}
                    fontWeight={700}
                    color={"shadesOfDark.dark"}
                  >
                    Agendamento
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} mt={2.5}>
                  <SelectSubsidiaries
                    label={"Filial"}
                    valueSubsidiary={subsidiaries.find(
                      (item) => item.id === values.local
                    )}
                    onChangeValue={(selectedOption) => {
                      if (selectedOption) {
                        setFieldValue("local", selectedOption.id);
                        setModalData((oldState) => ({
                          ...oldState,
                          subsidiaryDescription: {
                            title: "Filial",
                            value: selectedOption.description ?? "",
                          },
                        }));
                      }
                    }}
                    onBlurValue={() => {
                      setFieldTouched("local", true);
                    }}
                    hasErrorValue={hasErrorLocal}
                    messageErrorValue={errors.local}
                  />
                </Grid>
                <Grid item xs={12} md={0} />
                <Grid item xs={12} md={6}>
                  <Select
                    label="Modo de operação"
                    value={operationModeList.find(
                      (item) => item.id === values.operationMode
                    )}
                    options={operationModeList.map((option) => ({
                      option,
                    }))}
                    getOptionItemLabel={(value) => value.label}
                    getSelectedOptionLabel={(value) => value.label}
                    onChange={(value) => {
                      setFieldValue("operationMode", value.id);
                    }}
                    onBlur={() => {
                      setFieldTouched("operationMode", true);
                    }}
                    hasError={hasErrorOperationMode}
                    messageError={errors.operationMode}
                  />
                </Grid>

                <Grid item xs={12} md={0} />

                <Grid container item md={6} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Select
                      label="Cliente emissor"
                      options={distributors.map((option) => ({
                        option,
                      }))}
                      value={distributors.find(
                        (item) => item.id === values.issuingCustomer
                      )}
                      getOptionItemLabel={(value) =>
                        formatInfoCompany(
                          value.description ?? "",
                          value.document ?? ""
                        )
                      }
                      getSelectedOptionLabel={(value) =>
                        value.description ?? ""
                      }
                      onChange={(value) => {
                        setFieldValue("issuingCustomer", value.id);
                        setModalData((oldState) => ({
                          ...oldState,
                          issuingCustomer: {
                            title: "Nome da razão social",
                            value: value.description ?? "",
                          },
                        }));
                      }}
                      hasError={hasErrorIssuingCustomer}
                      messageError={errors.issuingCustomer}
                      onBlur={() => {
                        setFieldTouched("issuingCustomer", true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoComplete
                      variant={
                        isLoadingReceiversCompanies
                          ? AutoCompleteVariant.LOADING
                          : AutoCompleteVariant.STANDARD
                      }
                      label="Cliente recebedor"
                      iconButton={
                        values.receivingCustomer !== "" && (
                          <Clear
                            fontSize="small"
                            sx={styleButton}
                            onClick={() => {
                              setFieldValue("receivingCustomer", "");
                            }}
                          />
                        )
                      }
                      options={receivingCustomerList}
                      getOptionItemLabel={(value) =>
                        formatInfoCompany(
                          value.description ?? "",
                          value.document ?? ""
                        )
                      }
                      isDisabled={isMarketSaleIntern(values?.operationMode)}
                      value={
                        !isMarketSaleIntern(values?.operationMode)
                          ? receivingCustomerList.find(
                              (item) => item.id === values.receivingCustomer
                            ) ?? {}
                          : {}
                      }
                      onChange={(value) => {
                        setFieldValue("receivingCustomer", value.id);
                      }}
                      onBlur={() => setFieldTouched("receivingCustomer", true)}
                      hasError={hasErrorReceivingCustomer}
                      messageError={errors.receivingCustomer}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: "inline-flex" }}>
                  <div style={{ flexGrow: 1 }} />
                </Grid>
                <Grid item xs={12} mt={10}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                >
                  <Box display={"flex"}>
                    <CustomButton
                      title={"Cancelar"}
                      variant={CustomButtonVariant.OUTLINED}
                      onClick={() => {
                        navigate(routesConfig.OPERATIONAL().ROAD_STATIONS);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      title={"Avançar"}
                      variant={CustomButtonVariant.CONTAINED}
                      onClick={() => {}}
                      onSubmit={() => handleSubmit}
                      sx={{
                        ml: 1,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </AnimatedBox>
  );
};

export default RoadScheduleRequestStep1;
