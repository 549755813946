import { Box, Typography } from "@mui/material";
import React from "react";
import { StyledIcon } from "../StyledIcon/StyledIcon";

interface QueryProductCellProps {
  title: string;
}

const QueryProductCell: React.FC<QueryProductCellProps> = ({ title }) => {
  return (
    <Box display={"flex"} alignItems={"center"} my={2.75}>
      <StyledIcon
        iconType="product-name"
        sx={{
          width: "4.6rem",
          height: "4.4rem",
        }}
      />
      <Box ml={2.5}>
        <Typography>{title}</Typography>
      </Box>
    </Box>
  );
};

export default QueryProductCell;
