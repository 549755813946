import { Grid } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import TitleAndDetailExtract from "../ModalTitleAndDetail/ModalTitleAndDetail";
import { DetailInputsModalProps } from "../ModalExtractContent/ModalExtractContent";

interface ModalOrderDetailsHeaderProps {
  icon: React.ReactNode;
  distributor: DetailInputsModalProps;
  requestNumber: DetailInputsModalProps;
  requestDate: DetailInputsModalProps;
  subsidiary: DetailInputsModalProps;
}

const ModalOrderDetailsHeader: React.FC<ModalOrderDetailsHeaderProps> = ({
  icon,
  distributor,
  requestDate,
  subsidiary,
  requestNumber,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid container padding={3} paddingLeft={0} paddingTop={1}>
      <Grid container>
        <Grid
          item
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
          xs={6}
          sm={4}
          md={2}
          lg={1}
          display={"flex"}
          justifyContent={"center"}
        >
          {icon}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={5}
          margin={"auto"}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <TitleAndDetailExtract
            title={distributor.title}
            detail={distributor.value}
            textAlign={"left"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={subsidiary.title}
            detail={subsidiary.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={requestNumber.title}
            detail={requestNumber.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <TitleAndDetailExtract
            title={requestDate.title}
            detail={requestDate.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalOrderDetailsHeader;
