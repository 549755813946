export interface GetAllFinancialDTO {
  content: Content[];
  total: number;
  page: number;
}

export type StatusInvoice = "OPEN" | "CLOSE";

export enum Status {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}

export enum StatusDoc {
  CLOSE = "CLOSE",
  EXPIRED = "EXPIRED",
  NOT_EXPIRED = "NOT_EXPIRED",
}

export enum StatusPayment {
  AT_MONTH = "AT_MONTH",
  ADVANCE = "ADVANCE",
  RECOVERED = "RECOVERED",
}

export enum PaymentCondition {
  Advance = "ANT0",
  Cash = "0000",
}

export enum CreditDebitIndicator {
  Credit = "H",
  Debit = "S",
}

export enum DocumentType {
  CreditNote = "DG",
  DebitNote = "ZD",
}

export enum CategoryType {
  AdvancePayment = "AdvancePayment",
  Cash = "Cash",
  CreditNote = "CreditNote",
  DebitNote = "DebitNote",
  DeferredPayment = "DeferredPayment",
  NotInvoice = "NotInvoice",
}

export enum CategoryTypeTranslation {
  AdvancePayment = "Antecipado",
  Cash = "À Vista",
  CreditNote = "Nota de Crédito",
  DebitNote = "Nota de Débito",
  DeferredPayment = "A Prazo",
  NotInvoice = "Não é uma Fatura",
}
export interface Content {
  documentNumber: string;
  companyCode: string;
  fiscalYear: string;
  documentItem: string;
  customerNumber: string;
  documentType: DocumentType;
  documentTypeDescription: string;
  documentReference: string;
  assignment: string;
  quittanceDate: string;
  dueDate: string;
  documentDate: string;
  fiscalYearMonth: string;
  creditDebitIndicator: CreditDebitIndicator;
  specialReasonIndicator: string;
  value: number;
  accountNumber: string;
  divisionId: string;
  divisionDescription: string;
  documentItemDescription: string;
  bankCode: string;
  bankName: string;
  account: string;
  fcRequest: string;
  societyId: string;
  movementTypeId: string;
  billingDocument: string;
  paymentMethodCode: string;
  paymentMethod: string;
  paymentType: PaymentCondition;
  paymentReference: string;
  referenceKey: string;
  reference1: string;
  reference2: string;
  reference3: string;
  warningBlock: string;
  invoiceNumber: string;
  orderSaleNumber: string;
  customerName: string;
  CNPJ: string;
  stateRegistration: string;
  segment: string;
  segmentDescription: string;
  street: string;
  city: string;
  state: string;
  category: CategoryType;
  categoryTranslation: CategoryTypeTranslation;
  statusInvoice: StatusInvoice;
  statusDoc: StatusDoc;
  statusPayment: StatusPayment;
  salesDocumentNumber: string;
  lastUpdate: Date;
}
