import { Column } from "../../../components/TablePaginated/TablePaginated";

export const labelToNotShowUnity = [
  "day",
  "consumptionPercentage",
  "positionWithColor",
  "operationType",
  "associatedContract",
];

export const TableHeaderDetailsStatementColumns: Column[] = [
  {
    label: "Dia",
    id: "day",
    align: "left",
    showIcon: true,
    sortable: true,
  },
  {
    label: "Cota dia",
    id: "dailyQuota",
    align: "left",
    showIcon: true,
  },
  {
    label: "Cota dia acumulada",
    id: "dailyQuotaAccumulated",
    align: "right",
    showIcon: true,
  },
  {
    label: "Retiradas faturadas",
    id: "invoicedWithdrawals",
    align: "right",
    showIcon: true,
  },
  {
    label: "Retiradas acumuladas",
    id: "invoicedAccumulated",
    align: "right",
    showIcon: true,
  },
  {
    label: "Saldo",
    id: "balance",
    align: "right",
    showIcon: true,
  },
  {
    label: "Percentual de consumo (%)",
    id: "consumptionPercentage",
    align: "right",
    showIcon: true,
  },
  {
    label: "Posição",
    id: "positionWithColor",
    align: "right",
    showIcon: true,
  },
];

export const TableHeaderSummaryStatementColumns: Column[] = [
  {
    label: "Dia",
    id: "day",
    align: "left",
    showIcon: true,
    sortable: true,
  },
  {
    label: "Movimentação",
    id: "operationType",
    align: "left",
    showIcon: true,
  },
  {
    label: "Contrato",
    id: "associatedContract",
    align: "left",
    showIcon: true,
  },
  {
    label: "Volume",
    id: "volumeWithColor",
    align: "right",
    showIcon: true,
  },
];
