import { Grid, Typography } from "@mui/material";
import TableDropdown, {
  TableDropdownProps,
} from "../TableDropdown/TableDropdown";

interface CardHeaderProps<T> {
  title: string;
  subTitle?: string;
  secondSubTitle?: string;
  tableDropDownProps?: TableDropdownProps<T>;
}

function CardHeader<T>({
  title,
  subTitle,
  secondSubTitle,
  tableDropDownProps,
}: CardHeaderProps<T>) {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        background: "#FFFFFF",
        alignItems: "center",
        padding: 2.4,
      }}
    >
      <Grid item xs={tableDropDownProps ? 8 : 12}>
        <Typography fontSize={20} fontWeight={600} color={"shadesOfDark.dark"}>
          {title}
        </Typography>
        <Typography fontWeight={400} color={"shadesOfDark.steel"}>
          {subTitle ?? ""}
        </Typography>
        <Typography fontWeight={400} color={"shadesOfDark.steel"}>
          {secondSubTitle ?? ""}
        </Typography>
      </Grid>

      {tableDropDownProps && (
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "end",
            textAlignItems: "end",
          }}
        >
          <TableDropdown {...tableDropDownProps} />
        </Grid>
      )}
    </Grid>
  );
}

export default CardHeader;
