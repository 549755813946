import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import StatusTextTag from "../../../components/StatusTextTag/StatusTextTag";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import useDialogAlert from "../../../hooks/DialogAlert";
import { Types } from "../../../ioc/types";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";
import { IOperationalService } from "../../../modules/operational/models/IOperationalService";
import { calcPagesQuantity } from "../../../utils";
import { convertToISO } from "../../../utils/dateFormatter";
import { rejectedInitQuery } from "../../PipelineDeliveries/models/default";
import { TableHeaderColumns } from "../models/TableHeaderColumns";
import {
  ROW_PER_PAGE,
  TableRow,
  TabsDeliveriesRoadProps,
} from "../models/default";
import { getRoadResponseToModel } from "../models/parseResponse";

const RejectedRoadDeliveries: React.FC<TabsDeliveriesRoadProps> = ({
  onChangeCount,
  subsidiary,
  dateFilterTo,
  dateFilterFrom,
  menuIcon,
  customerCompanyIds,
}) => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TableRow[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [query, setQuery] = useState<IGetPipelineSchedulesQuery>({
    ...rejectedInitQuery,
    subsidiaryId: undefined,
    scheduleDateFrom: dateFilterFrom ? convertToISO(dateFilterFrom) : undefined,
    scheduleDateTo: dateFilterTo ? convertToISO(dateFilterTo) : undefined,
    ...(customerCompanyIds !== "" && { customerCompanyId: customerCompanyIds }),
  });
  const { snackbar } = useDialogAlert();
  const { serviceContainer } = useIocContext();
  const operationalService = serviceContainer.get<IOperationalService>(
    Types.Operational.IOperationalService
  );

  const getRoadShedules = useCallback(async () => {
    try {
      setErrorData(false);
      setIsLoadingData(true);
      const response = await operationalService.getRoadlineSchedules(query);
      const newData = response.rows?.map(getRoadResponseToModel) ?? [];
      const count = response.count ?? 0;

      setTotal(count);
      if (onChangeCount) onChangeCount(count);
      setData(newData);
      if (newData.length === 0) {
        setErrorData(true);
      }
    } catch (error) {
      setErrorData(true);
      snackbar({
        message: "Erro ao carregar entregas rodoviárias",
        variant: "error",
      });
    } finally {
      setIsLoadingData(false);
    }
  }, [query]);

  useEffect(() => {
    getRoadShedules();
  }, [query]);

  useEffect(() => {
    setQuery((oldState) => ({
      ...oldState,
      subsidiaryId: subsidiary,
      ...(dateFilterFrom &&
        dateFilterTo && { scheduleDateFrom: convertToISO(dateFilterFrom) }),
      ...(dateFilterFrom &&
        dateFilterTo && { scheduleDateTo: convertToISO(dateFilterTo) }),
      ...(customerCompanyIds !== "" && {
        customerCompanyId: customerCompanyIds,
      }),
    }));
  }, [dateFilterFrom, dateFilterTo, customerCompanyIds, setQuery, subsidiary]);

  return (
    <>
      {errorData ? (
        <Box alignSelf={"center"}>
          <CardDataNotFound
            icon="data-not-found"
            title="Não há pedidos reprovados"
          />
        </Box>
      ) : (
        <TablePaginated
          loadFromServer
          isLoadingData={isLoadingData}
          columns={TableHeaderColumns}
          data={data.map((item) => ({
            ...item,
            statusIcon: <StatusTextTag value={item.statusText} />,
            menuIcon: menuIcon?.(item),
          }))}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: query.page,
          }}
          resultsTotal={total}
          rowsPerPage={ROW_PER_PAGE}
          onChangePage={(page) => {
            setQuery((oldState) => ({
              ...oldState,
              page,
            }));
          }}
        />
      )}
    </>
  );
};

export default RejectedRoadDeliveries;
