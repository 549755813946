import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress, InputAdornment, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import { useState } from "react";

interface AutoCompleteProps<T> {
  variant?: AutoCompleteVariant;
  options: T[];
  label?: string;
  value?: T;
  onChange?: (value: T) => void;
  isDisabled?: boolean;
  getOptionItemLabel: (value: T) => string;
  hasError?: boolean;
  messageError?: string;
  onBlur?: () => void;
  iconButton?: React.ReactNode;
}

export default function AutoComplete<T>({
  variant = AutoCompleteVariant.STANDARD,
  options,
  label,
  value,
  isDisabled,
  hasError,
  messageError,
  getOptionItemLabel,
  onChange,
  onBlur,
  iconButton,
}: AutoCompleteProps<T>) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (!isDisabled) {
      setOpen(!open);
    }
  };

  return (
    <>
      {label && (
        <Typography
          fontFamily={"Open Sans"}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          mb={0.5}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        open={open}
        onClose={handleToggle}
        disablePortal
        value={value}
        disabled={isDisabled}
        placeholder="Pesquisar"
        options={options}
        sx={{
          maxWidth: "100%",
          width: "100%",
          padding: 0,
          "& .MuiInputBase-root": {
            color: "shadesOfDark.steel",
            fontSize: 18,
            padding: 0.1,
          },
          "& .MuiInputBase-input": {
            fontSize: 16,
            fontWeight: 500,
          },
        }}
        onChange={(_, value) => {
          if (value !== null && onChange) onChange(value);
        }}
        onBlur={onBlur}
        renderOption={(props, option, state) => (
          <Box
            key={`${String(state.index)}box`}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
            }}
          >
            <li
              id={String(state.index)}
              style={{
                fontSize: 14,
                fontFamily: "Open Sans",
                backgroundColor: state.selected ? "#E8E9EB" : "",
                fontWeight: state.selected ? 700 : 400,
              }}
              {...props}
            >
              {getOptionItemLabel(option)}
            </li>
          </Box>
        )}
        selectOnFocus
        clearOnBlur
        getOptionLabel={getOptionItemLabel}
        noOptionsText={"Nada encontrado!"}
        renderInput={(params) => (
          <TextFieldCustom
            {...params}
            placeholder="Pesquisar"
            hasError={hasError}
            messageError={messageError}
            onClick={() => handleToggle()}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment:
                variant === AutoCompleteVariant.LOADING ? (
                  <InputAdornment
                    position="end"
                    sx={{ marginRight: isDisabled ? -4 : -7 }}
                  >
                    <CircularProgress
                      size={20}
                      sx={{ color: "shadesOfDark.steel" }}
                    />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end" sx={{ marginRight: -8 }}>
                    {!isDisabled && iconButton !== undefined && iconButton}
                    {!isDisabled && (
                      <KeyboardArrowDownIcon
                        onClick={handleToggle}
                        sx={{
                          color: "shadesOfDark.steel",
                          cursor: "pointer",
                          transform: open ? "rotate(180deg)" : "rotate(0deg)",
                          marginRight: 0.8,
                          ":hover": {
                            backgroundColor: "shadesOfDark.whiteLight",
                            borderRadius: 8,
                          },
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
            }}
          />
        )}
      />
    </>
  );
}

export enum AutoCompleteVariant {
  STANDARD,
  LOADING,
}
