import { inject, injectable } from "inversify";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import { GetOrdersPaginatedQuery } from "./models/GetOrdersPaginatedQuery";
import { ISupplyService } from "./models/ISupplyService";
import { GetOrdersPaginatedByIdDTO, GetOrdersDTO } from "./models/GetOrdersDTO";
import { IGetDetailedStatementDTO } from "./models/GetDetailedStatementDTO";
import { GetDetailedStatementQuery } from "./models/GetDetailedStatementQuery";
import { GetSummaryStatementQuery } from "./models/GetSummaryStatementQuery";
import { IGetSummaryStatementDTO } from "./models/GetSummaryStatementDTO";
import { IDownloadReportStatementQuery } from "./models/IDownloadReportStatementQuery";
import { IGetPeriodByCompanyDTO } from "./models/IGetPeriodByCompanyDTO";
import {
  BalanceContractDTO,
  ContractDTO,
  IQueryBalanceProps,
  IQueryPeriodInterval,
} from "./models/ContractDTO";
import { IEditOrder } from "./models/EditOrder";
import { ICreateOrder } from "./models/CreateOrder";

@injectable()
export class SupplyService implements ISupplyService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async getOrdersPaginated(
    query: GetOrdersPaginatedQuery
  ): Promise<GetOrdersDTO> {
    return await this.httpInstance.get("/ms-contracts/contract-request", {
      params: query,
    });
  }

  public async getOrdersPaginatedById(
    id: string
  ): Promise<GetOrdersPaginatedByIdDTO> {
    return await this.httpInstance.get(`/ms-contracts/contract-request/${id}`);
  }

  public async getAllPeriodByCompany(
    id: string
  ): Promise<IGetPeriodByCompanyDTO[]> {
    return await this.httpInstance.get(
      "/ms-contracts/contract/month-references",
      {
        params: {
          customerCompanyId: id,
        },
      }
    );
  }

  public async getAllContractsInInterval(
    query: IQueryPeriodInterval
  ): Promise<ContractDTO[]> {
    return await this.httpInstance.get("/ms-contracts/contract", {
      params: {
        customerCompanyId: query.id,
        monthReferenceFrom: query.from,
        monthReferenceTo: query.to,
      },
    });
  }

  public async getBalanceOperations(
    query: IQueryBalanceProps
  ): Promise<BalanceContractDTO[]> {
    return await this.httpInstance.get(
      "/ms-contracts/contract/quota-balance-operations",
      {
        params: {
          ...query,
        },
      }
    );
  }

  public async createOrderDraft(
    body: ICreateOrder
  ): Promise<GetOrdersPaginatedByIdDTO> {
    return await this.httpInstance.post("/ms-contracts/contract-request", body);
  }

  public async editOrder(idOrder: string, data: IEditOrder): Promise<void> {
    return await this.httpInstance.patch(
      `/ms-contracts/contract-request/${idOrder}`,
      data
    );
  }

  public async getAllDetailedStatement(
    query: GetDetailedStatementQuery
  ): Promise<IGetDetailedStatementDTO[]> {
    return await this.httpInstance.get(
      "/ms-contracts/contract/quota-statement/detailed",
      {
        params: query,
      }
    );
  }

  public async getAllSummaryStatement(
    query: GetSummaryStatementQuery
  ): Promise<IGetSummaryStatementDTO> {
    return await this.httpInstance.get(
      "/ms-contracts/contract/quota-statement/abstract",
      {
        params: query,
      }
    );
  }

  public async commitOrder(orderId: string): Promise<any> {
    return await this.httpInstance.patch(
      `/ms-contracts/contract-request/${orderId}/commit`
    );
  }

  public async downloadReportDetailed(
    query: IDownloadReportStatementQuery
  ): Promise<Blob> {
    return await this.httpInstance.get(
      `/ms-contracts/contract/quota-statement/detailed/report`,
      {
        responseType: "blob",
        params: { ...query },
      }
    );
  }

  public async downloadReportSummary(
    query: IDownloadReportStatementQuery
  ): Promise<Blob> {
    return await this.httpInstance.get(
      `/ms-contracts/contract/quota-statement/abstract/report`,
      {
        responseType: "blob",
        params: { ...query },
      }
    );
  }

  public async downloadBalanceReport(query: IQueryBalanceProps): Promise<Blob> {
    return await this.httpInstance.get(
      `/ms-contracts/contract/quota-balance-operations/report`,
      {
        responseType: "blob",
        params: { ...query },
      }
    );
  }
}
