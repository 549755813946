import React, { useCallback, useEffect, useState } from "react";
import DeliveryTable, {
  IRowContent,
} from "../../../components/DeliveryTable/DeliveryTable";
import DeliveryTableCell from "../../../components/DeliveryTableCell/DeliveryTableCell";
import StatusTextTag from "../../../components/StatusTextTag/StatusTextTag";
import { ITableHome } from "../../../contexts/home/models/homeModels";
import {
  IOption,
  PropsTable,
  rejectedInitQuery,
  clearedInitQuery,
  openedInitQuery,
  optionsMenu,
  InitialTableStatus,
} from "../models/default";
import { transformPhrase } from "../../../utils";
import { TableHeaderColumns } from "../models/TableHeaderColumns";
import { IGetPipelineSchedulesQuery } from "../../../modules/operational/models/GetPipelineSchedulesQuery";

const DeliveryTableSection: React.FC<PropsTable> = ({
  data,
  title,
  subTitle,
  count,
  isLoading,
  currentPageTable,
  query,
  setQuery,
}) => {
  const [linesMap, setLinesMap] = useState<IRowContent[]>([]);
  const [menuOptions, setMenuOptions] = useState(optionsMenu);

  const mapLinesToComponents = useCallback(
    (dataLines: ITableHome[]) => {
      return dataLines?.map((line) => ({
        id: parseInt(line.id || "0"),
        refId: (
          <DeliveryTableCell
            title={line.refId}
            subTitle={"ID"}
            widthValue={"50px"}
          />
        ),
        local: <DeliveryTableCell title={line.local} subTitle={"Local"} />,
        product: (
          <DeliveryTableCell
            title={transformPhrase(line.product)}
            subTitle={"Produto"}
          />
        ),
        status: <StatusTextTag value={line.status} />,
        dueDate: (
          <DeliveryTableCell title={line.dueDate} subTitle={"Agendado para"} />
        ),
        quantidade: (
          <DeliveryTableCell title={line.quantidade} subTitle={"Quantidade"} />
        ),
      }));
    },
    [data]
  );

  useEffect(() => {
    setMenuOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        isChecked: false,
      }))
    );
  }, []);

  useEffect(() => {
    const newData = mapLinesToComponents(data);
    setLinesMap(newData);
  }, [data]);

  const getStatusByOption = (option: InitialTableStatus) => {
    switch (option) {
      case InitialTableStatus.STATUS_OPEN:
        return openedInitQuery.status;
      case InitialTableStatus.STATUS_CLEARED:
        return clearedInitQuery.status;
      case InitialTableStatus.STATUS_REJECTED:
        return rejectedInitQuery.status;
      default:
        return "";
    }
  };

  const onChangeStatus = (options: IOption[]) => {
    let selectedStatus = "";
    const checkedOptions = options.filter((option) => option.isChecked);

    if (checkedOptions.length === 0) {
      const defaultStatuses = [
        openedInitQuery.status,
        clearedInitQuery.status,
        rejectedInitQuery.status,
      ];
      selectedStatus = defaultStatuses.join(",");
    } else {
      selectedStatus = checkedOptions
        .map((option) => getStatusByOption(option.option))
        .join(",");
    }

    if (query) {
      setQuery((query: IGetPipelineSchedulesQuery) => ({
        ...query,
        status: selectedStatus,
        page: 1,
      }));
    }
  };

  const handleChangePage = (page: number) => {
    if (query) {
      setQuery((query: IGetPipelineSchedulesQuery) => ({
        ...query,
        page,
      }));
    }
  };

  return (
    <DeliveryTable
      options={menuOptions}
      handleOptions={onChangeStatus}
      isLoadingData={isLoading ?? false}
      columns={TableHeaderColumns}
      data={linesMap}
      titleTable={title}
      subTitleTable={subTitle}
      currentPage={currentPageTable}
      totalTable={count}
      totalLine={linesMap.length ?? 0}
      onChangePage={handleChangePage}
    />
  );
};

export default DeliveryTableSection;
