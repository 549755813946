import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";
import { OperationModeType } from "../../RoadScheduleRequest/Section/RoadScheduleRequestStep1";

export interface TableRow {
  id: string;
  scheduleDate: string;
  product: string;
  quantity: string;
  unit: string;
  scheduleNumber: string;
  customer: string;
  paymentCondition: string;
  mod: string;
  shippingCompany?: string;
  driver?: string;
  vehicle_plate?: string;
  horse_plate?: string;
  statusText: PipelineScheduleStatus;
  statusIcon?: React.ReactNode;
  menuIcon?: React.ReactNode;
  customerRequester?: string;
  customerRequesterNote?: string;
  createdAt?: string;
  scheduleTime?: string;
  attachments?: File[];
  customerInvoiceReference?: string;
  type?: OperationModeType;
  subsidiary?: string;
}

export interface File {
  fileKey: string;
  fileName: string;
  fileType: string;
}

export interface TabsDeliveriesRoadProps {
  onChangeCount?: (value: number) => void;
  dateFilterFrom?: Date | null | undefined;
  dateFilterTo?: Date | null | undefined;
  menuIcon?: (item: TableRow) => React.ReactNode;
  customerCompanyIds: string;
  subsidiary?: string;
}

export const ROW_PER_PAGE = 10;
