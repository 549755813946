import React, { useCallback, useEffect, useState } from "react";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import { Grid, Typography } from "@mui/material";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import AppError from "../../../utils/appError";
import { downloadExcel } from "../../../utils/download";
import { GetSummaryStatementQuery } from "../../../modules/supply/models/GetSummaryStatementQuery";
import { ISupplyService } from "../../../modules/supply/models/ISupplyService";
import useDialogAlert from "../../../hooks/DialogAlert";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";
import { TableHeaderBalanceColumns } from "../models/TableHeaderBalanceColumns";
import { BalanceContractTableDTO } from "../../../modules/supply/models/ContractDTO";
import {
  applyVolumeMask,
  formatCNPJ,
  getColorIfGreaterThanZero,
} from "../../../utils";

interface IBalanceTableProps {
  dataFilter: GetSummaryStatementQuery | null;
  isClear: boolean;
}
const QUERY_LENGTH = 5;

const BalanceTableSection: React.FC<IBalanceTableProps> = ({
  dataFilter,
  isClear = false,
}) => {
  const [data, setData] = useState<BalanceContractTableDTO[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { serviceContainer } = useIocContext();
  const { snackbar } = useDialogAlert();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const { selectedDistributor } = useDistributor();
  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );

  useEffect(() => {
    if (isClear) {
      setData([]);
    }
  }, [isClear, setData]);

  const setCellTableData = (value: number) => (
    <Typography color={getColorIfGreaterThanZero(Number(value))}>
      {applyVolumeMask(String(value))}
    </Typography>
  );

  const getSummaryStatementData = useCallback(
    async (query: GetSummaryStatementQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);
        const response = await supplyService.getBalanceOperations?.(query);
        if (!response?.length) {
          setErrorData(true);
        }
        const newData: BalanceContractTableDTO[] =
          response.map((item) => ({
            ...item,
            quota: applyVolumeMask(String(item.quota)),
            finalQuota: applyVolumeMask(String(item.finalQuota)),
            balance: applyVolumeMask(String(item.balance)),
            additional: setCellTableData(item.additional),
            anticipation: setCellTableData(item.anticipation),
            cut: setCellTableData(item.cut),
            renegotiation: setCellTableData(item.renegotiation),
            stated: setCellTableData(item.stated),
            supplementation: setCellTableData(item.supplementation),
            transference: setCellTableData(item.transference),
            transformation: setCellTableData(item.transformation),
            customer: item.customerCompany.description,
            cnpj: formatCNPJ(item.customerCompany.document),
            subsidiary: item.subsidiary?.description,
          })) ?? [];
        setData(newData);
      } catch (error) {
        setErrorData(true);
        snackbar({
          message: (error as AppError).message,
          variant: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData]
  );

  useEffect(() => {
    if (
      dataFilter !== null &&
      Object.keys(dataFilter).length === QUERY_LENGTH
    ) {
      getSummaryStatementData(dataFilter);
    }
  }, [dataFilter]);

  const downloadQuoteBalanceReport = useCallback(async () => {
    try {
      if (dataFilter !== null) {
        setIsLoadingDownload(true);
        const response = await supplyService.downloadBalanceReport(dataFilter);
        downloadExcel(response);
        snackbar({
          message: "Download feito com sucesso",
          variant: "success",
        });
      }
    } catch (error) {
      snackbar({
        message: "Erro ao fazer download. Tentar novamente",
        variant: "error",
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [dataFilter]);

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <>
          <Grid
            item
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            style={{ paddingBottom: "2px" }}
          >
            <CustomButton
              title="Baixar relatório"
              variant={
                isLoadingDownload
                  ? CustomButtonVariant.CONTAINED_DOWNLOAD_LOADING
                  : CustomButtonVariant.CONTAINED_DOWNLOAD
              }
              onClick={() => {
                !selectedDistributor?.document?.length
                  ? snackbar({
                      message: "Selecione um cliente para baixar relatório.",
                      variant: "warning",
                    })
                  : downloadQuoteBalanceReport();
              }}
            />
          </Grid>
          <TablePaginated
            columns={TableHeaderBalanceColumns}
            data={data}
            rowsPerPage={10}
            isLoadingData={isLoadingData}
          />
        </>
      )}
    </>
  );
};

export default BalanceTableSection;
