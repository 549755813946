import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";

export interface HeadCellProps {
  label: string;
  textAlign?: "left" | "center" | "right";
  flex?: number;
  boxProps?: BoxProps;
}

interface QueryTableHeaderProps {
  headCells: HeadCellProps[];
}

const QueryTableHeader: React.FC<QueryTableHeaderProps> = ({ headCells }) => {
  const HeadCell: React.FC<HeadCellProps> = ({
    label,
    textAlign = "right",
    flex = 1,
    boxProps,
  }) => (
    <Box display="flex" p={2} flex={flex} alignItems="center" {...boxProps}>
      <Typography
        fontWeight={700}
        letterSpacing={0.2}
        textAlign={textAlign}
        flex={1}
        color={"shadesOfDark.steel"}
      >
        {label}
      </Typography>
    </Box>
  );
  return (
    <Box
      display={"flex"}
      color={"rgba(58, 53, 65, 0.87)"}
      justifyContent={"center"}
      alignItems={"center"}
      borderBottom={"1.5px solid #DFE0EB"}
    >
      {headCells.map((item, index) => (
        <HeadCell key={index} {...item} />
      ))}
    </Box>
  );
};

export default QueryTableHeader;
