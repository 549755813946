type OrderType = Record<string, any>;

export enum StatusOrderIcon {
  SENT = "clock",
  WAITING_APPROVE = "clock",
  VENDOR_DRAFT = "clock",
  VENDOR_CONFIRMED = "check-circle",
  VENDOR_REJECTED = "global-error",
  PARTIALLY_APPROVED = "no-check-circle",
}
export enum StatusOrder {
  sent = "SENT",
  customerDraft = "CUSTOMER_DRAFT",
  vendorDraft = "VENDOR_DRAFT",
  vendorConfirmed = "VENDOR_CONFIRMED",
  vendorRejected = "VENDOR_REJECTED",
}

export enum statusApprove {
  APPROVED = "APPROVED",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
}

export enum statusReject {
  REJECTED = "REJECTED",
}

export enum statusWating {
  WAITING_APPROVE = "WAITING_APPROVE",
}

export enum statusDraft {
  VENDOR_DRAFT = "VENDOR_DRAFT",
}

export const ORDER_STATUS: OrderType = {
  REJECTED: "Rejeitado",
  PARTIALLY_APPROVED: "Aprovado com ressalva",
  APPROVED: "Aprovado",
};

export const setStatusIcon = (
  status: keyof typeof StatusOrderIcon,
  step: keyof typeof StatusOrderIcon
) => {
  if (step === statusApprove.PARTIALLY_APPROVED) {
    return StatusOrderIcon[step];
  }
  return StatusOrderIcon[status];
};
