import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { SubsidiaryDTO } from "../../modules/distributors/models/DistributorListDTO";
import Select from "../Select/Select";

interface SelectSubsidiariesProps {
  label?: string;
  onChangeValue?: (e?: SubsidiaryDTO) => void;
  onChange?: (selectedOption: string) => void;
  onBlurValue?: () => void;
  hasErrorValue?: boolean;
  messageErrorValue?: string;
  isDisabledValue?: boolean;
  valueSubsidiary: SubsidiaryDTO | undefined;
}

const SelectSubsidiaries: React.FC<SelectSubsidiariesProps> = ({
  label,
  valueSubsidiary,
  onChangeValue,
  onBlurValue,
  hasErrorValue,
  messageErrorValue,
  isDisabledValue,
}) => {
  const { subsidiaries } = useDistributor();

  return (
    <div>
      <Select
        label={label}
        options={subsidiaries.map((option) => ({
          option,
        }))}
        value={valueSubsidiary ?? null}
        getOptionItemLabel={(value) => value?.description ?? ""}
        getSelectedOptionLabel={(value) => value?.description ?? ""}
        onChange={(selectedOption) => {
          onChangeValue?.(selectedOption ?? undefined);
        }}
        isDisabled={isDisabledValue}
        onBlur={() => {
          onBlurValue?.();
        }}
        hasError={hasErrorValue}
        messageError={messageErrorValue}
      />
    </div>
  );
};

export default SelectSubsidiaries;
