import { inject, injectable } from "inversify";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import {
  GetContractsAnalyticsSubsidiary,
  IHomeService,
  IQueryAnalytics,
} from "./models/IHomeService";

@injectable()
export class HomeService implements IHomeService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async getContractsAnalytics(
    query: IQueryAnalytics
  ): Promise<GetContractsAnalyticsSubsidiary> {
    return await this.httpInstance.get(
      "/ms-contracts/contract/analytics?groupBy=subsidiary",
      {
        params: query,
      }
    );
  }
}
