import * as React from "react";
import { useEffect } from "react";
import { animated, useSpring } from "react-spring";

interface AnimatedBoxProps {
  children: React.ReactNode;
}

const AnimatedBox: React.FC<AnimatedBoxProps> = ({ children }) => {
  const [props, set] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(-50%, 0, 0)",
  }));

  useEffect(() => {
    set({
      opacity: 1,
      transform: "translate3d(0, 0%, 0)",
    });
  }, [set]);

  return (
    <animated.div
      style={{
        ...props,
        width: "100%",
      }}
    >
      {children}
    </animated.div>
  );
};

export default AnimatedBox;
