/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import { routesConfig } from "../../config/routes";
import { getFormattedTimestamp } from "../../utils/dateFormatter";
import { useAuth } from "../../contexts/authentication/AuthenticationContext";
import { Roles } from "../../components/SideBar/Content/ListItem";
import { TypesPermissions } from "../../components/Routes/TypesPermissions";
import { AuthenticationContextData } from "../../contexts/authentication/domain";
import { CustomButtonVariant } from "../../components/CustomButton/CustomButton";
import { sidebar } from "../../utils/featureFlags";
import { useFlags } from "flagsmith/react";
import { commonFilter } from "./models/SearchFilterOptions";
import SacFilter from "./Sections/SacFilter";
import OpenCallsTab from "./Sections/OpenCallsTab";
import { SacRegisterQuery } from "../../modules/information/models/SacRegisterQuery";
import { useSac } from "../../contexts/sac/sacContext";
import AwaitingConfirmationCallsTab from "./Sections/AwaitingConfirmationCallsTab";
import ClosedCallsTab from "./Sections/ClosedCallsTab";

const SacPage: React.FC = () => {
  const flags = useFlags(sidebar);
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [query, setQuery] = useState<SacRegisterQuery | null>(null);
  const { authByPermission, permissionSet, roles } =
    useAuth() as AuthenticationContextData;
  const navigate = useNavigate();
  const { registersTotal } = useSac();

  const tabsLabel = useMemo(() => {
    const { closed, open, waiting_confirmation } = registersTotal;

    return [
      `Atendimentos em aberto ${open !== 0 ? `(${open})` : ""}`,
      `Atendimentos em andamento ${
        waiting_confirmation !== 0 ? `(${waiting_confirmation})` : ""
      }`,
      `Atendimentos finalizados ${closed !== 0 ? `(${closed})` : ""}`,
    ];
  }, [registersTotal]);

  const handleDebounce = useCallback(
    (e: any): any => {
      setQuery({
        ...commonFilter,
        ...e,
      });
    },
    [setQuery, currentTab]
  );

  useEffect(() => {
    if (state === null) return;
    setCurrentTab(state.tab);
    navigate(routesConfig.INFORMATION().SAC, {});
  }, [state]);

  const isAllowed = useMemo(() => {
    return (
      authByPermission(
        permissionSet.SYSTEM_MODULES,
        roles.has(Roles.ADMIN),
        TypesPermissions.REQUEST
      ) && flags.supply_new_quote.enabled
    );
  }, [authByPermission, flags]);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid item xs={12}>
        <QueryPageTitleCard title="SAC" />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <CustomCard
          sx={{
            boxShadow: "none",
            border: "1px solid #DFE0EB",
            height: "100%",
            padding: 2.4,
          }}
        >
          <CardTitleWithSubtitle
            title="Registros de atendimento"
            subtitle={getFormattedTimestamp(new Date())}
            buttonProps={{
              title: "Novo atendimento",
              onClick: () => {
                navigate(routesConfig.INFORMATION().SAC_REQUEST);
              },
              variant: isAllowed
                ? CustomButtonVariant.CONTAINED
                : CustomButtonVariant.DISABLED,
              disabled: !isAllowed,
            }}
          />

          <SacFilter
            dataFilter={(e) => {
              handleDebounce(e);
            }}
          />
          <CustomTabs
            indexTab={currentTab}
            tabsHeader={tabsLabel}
            onChangeTab={(idx) => {
              setCurrentTab(idx as number);
            }}
            body={[
              <OpenCallsTab dataFilter={query} key={0} />,
              <AwaitingConfirmationCallsTab dataFilter={query} key={1} />,
              <ClosedCallsTab dataFilter={query} key={2} />,
            ]}
          />
        </CustomCard>
      </Grid>
    </Layout>
  );
};

export default SacPage;
