import React, { useEffect } from "react";
import {
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
  styled,
  ListItem,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledIcon } from "../../StyledIcon/StyledIcon";
import { generateFilter } from "colorize-filter";
import { Link, useLocation } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { generatePermissionMatcher } from "../../../utils";
import { TypesPermissions } from "../../Routes/TypesPermissions";
import { useAuth } from "../../../contexts/authentication/AuthenticationContext";
import { useFlags } from "flagsmith/react";
import { sidebar } from "../../../utils/featureFlags";

const collapseDefaultStateOpen = true;

export enum Roles {
  ADMIN = "admin",
  ANALYST = "vendor",
  CLIENT = "customer",
}

interface LisItemProps {
  title?: string;
  titleFooter?: string;
  icon?: React.ReactNode;
  to: string;
  module: string;
  collapseIsOpen: boolean;
  subMenu?: Array<{
    hasPermission?: (_: string) => boolean;
    title: string;
    to: string;
    module: string;
    icon?: React.ReactNode;
    flag: string;
  }>;
}

const listHome: LisItemProps[] = [
  {
    title: "Dashboard",
    icon: <StyledIcon iconType="dashboard" />,
    to: routesConfig.HOME,
    module: "dashboard",
    collapseIsOpen: collapseDefaultStateOpen,
  },
];

const listMenu: LisItemProps[] = [
  // {
  //   title: "Executivo",
  //   icon: <StyledIcon iconType="executive" />,
  //   to: "#",
  //   module: "executivo",
  //   collapseIsOpen: collapseDefaultStateOpen,
  //   subMenu: [
  //     {
  //       title: "Consultar preços",
  //       to: routesConfig.EXECUTIVE().PRICE,
  //       module: "",
  //     },
  //     {
  //       title: "Consultar parâmetros mensais",
  //       to: routesConfig.EXECUTIVE().MONTHLY_PARAMETERS,
  //       module: "",
  //     },
  //     {
  //       title: "Consultar condições operacionais",
  //       to: routesConfig.EXECUTIVE().OPERATING_CONDITIONS,
  //       module: "",
  //     },
  //   ],
  // },
  {
    title: "Financeiro",
    icon: <StyledIcon iconType="finantial" />,
    to: "#",
    module: "financeiro",
    collapseIsOpen: collapseDefaultStateOpen,
    subMenu: [
      {
        flag: "sidebar_financial_invoices",
        hasPermission: generatePermissionMatcher(TypesPermissions.INVOICES),
        title: "Consultar NFE",
        to: routesConfig.FINANCIAL().INVOICES,
        module: "",
      },
      {
        flag: "sidebar_financial_movement",
        hasPermission: generatePermissionMatcher(TypesPermissions.MOVEMENT),
        title: "Consultar movimentação financeira",
        to: routesConfig.FINANCIAL().MOVEMENT,
        module: "",
      },
      {
        flag: "sidebar_financial_statement",
        hasPermission: generatePermissionMatcher(TypesPermissions.STATEMENT),
        title: "Consultar extrato",
        to: routesConfig.FINANCIAL().STATEMENT,
        module: "",
      },
      {
        flag: "sidebar_financial_refunds_icms",
        hasPermission: generatePermissionMatcher(
          TypesPermissions.TAX_REIMBURSEMENT
        ),
        title: "Ressarcimento Fiscal",
        to: routesConfig.FINANCIAL().TAX_REIMBURSEMENT,
        module: "",
      },
    ],
  },
  {
    title: "Suprimentos",
    icon: <StyledIcon iconType="supplies" />,
    to: "#",
    module: "suprimentos",
    collapseIsOpen: collapseDefaultStateOpen,
    subMenu: [
      {
        flag: "sidebar_supply_quote_extract",
        hasPermission: generatePermissionMatcher(
          TypesPermissions.QUOTE_EXTRACT
        ),
        title: "Extrato de cota",
        to: routesConfig.SUPPLIES().QUOTE_EXTRACT,
        module: "",
      },
      {
        flag: "sidebar_supply_quote_balance",
        hasPermission: generatePermissionMatcher(
          TypesPermissions.QUOTE_BALANCE
        ),
        title: "Saldo de cota",
        to: routesConfig.SUPPLIES().QUOTE_BALANCE,
        module: "",
      },
      {
        flag: "sidebar_supply_quote",
        hasPermission: generatePermissionMatcher(TypesPermissions.QUOTE),
        title: "Pedidos de cota",
        to: routesConfig.SUPPLIES().QUOTE,
        module: "",
      },
      // {
      //   title: "Consultar cota do mês seguinte",
      //   to: routesConfig.SUPPLIES().QUOTE,
      //   module: "",
      // },
    ],
  },
  {
    title: "Operacional",
    icon: <StyledIcon iconType="operational" />,
    to: "#",
    module: "operacional",
    collapseIsOpen: collapseDefaultStateOpen,
    subMenu: [
      {
        flag: "sidebar_operational_pipelines",
        hasPermission: generatePermissionMatcher(TypesPermissions.PIPELINES),

        title: "Entregas dutoviárias",
        to: routesConfig.OPERATIONAL().PIPELINES,
        module: "",
      },
      {
        flag: "sidebar_operational_road_stations",
        hasPermission: generatePermissionMatcher(
          TypesPermissions.ROAD_STATIONS
        ),

        title: "Entregas rodoviárias",
        to: routesConfig.OPERATIONAL().ROAD_STATIONS,
        module: "",
      },
      {
        flag: "sidebar_operational_river",
        hasPermission: generatePermissionMatcher(TypesPermissions.RIVER),

        title: "Entregas Fluviais",
        to: routesConfig.OPERATIONAL().RIVER,
        module: "",
      },
    ],
  },
  {
    title: "Informação",
    icon: <StyledIcon iconType="sac" />,
    to: "#",
    module: "informacao",
    collapseIsOpen: collapseDefaultStateOpen,
    subMenu: [
      {
        flag: "sidebar_operational_road_stations",
        hasPermission: () => true,
        title: "SAC",
        to: routesConfig.INFORMATION().SAC,
        module: "",
      },
    ],
  },
];

const menuFooter: LisItemProps[] = [
  // {
  //   title: "Informação",
  //   icon: <StyledIcon iconType="operational" />,
  //   to: "#",
  //   module: "informacao",
  //   collapseIsOpen: collapseDefaultStateOpen,
  //   subMenu: [
  //     {
  //       title: "Visualizar avisos",
  //       to: routesConfig.WARNINGS,
  //       module: "avisos",
  //       icon: <StyledIcon iconType="suitcase" />,
  //     },
  //     {
  //       title: "SAC",
  //       to: routesConfig.SAC,
  //       module: "sac",
  //       icon: <StyledIcon iconType="sac" />,
  //     },
  //   ],
  // },
  // {
  //   title: "Configurações",
  //   icon: <StyledIcon iconType="operational" />,
  //   to: "#",
  //   module: "informacao",
  //   collapseIsOpen: collapseDefaultStateOpen,
  //   subMenu: [
  //     {
  //       title: "Acessos",
  //       to: routesConfig.HITS,
  //       module: "acessos",
  //       icon: <StyledIcon iconType="suitcase" />,
  //     },
  //   ],
  // },
];

const List = styled("div")({
  "& #home": {
    fontFamily: "Archivo, sans-serif",
    fontSize: "20px",
    color: "#58595B",
    fontWeight: "600",
    paddingLeft: "16px",
    paddingBottom: "10px",
  },
  "& #titleMenu": {
    fontFamily: "Archivo",
    color: "#58595B",
    fontWeight: "600",
  },
  "& #subTitleMenu": {
    fontFamily: "Archivo, sans-serif",
    paddingLeft: "10px",
    fontStyle: "normal",
    fontSize: "14px",
    color: "#58595B",
  },
  "& .footerTitle": {
    paddingTop: "20px",
  },
});

const listItemSideBar: React.FC = () => {
  const flags = useFlags(sidebar);
  const auth = useAuth();
  const location = useLocation();
  const [menuList, setListMenu] = React.useState(listMenu);

  useEffect(() => {
    const filteredMenus = listMenu.map((menuEntry: LisItemProps) => ({
      ...menuEntry,
      subMenu: filterSubMenu(menuEntry.subMenu),
    }));
    setListMenu([...listHome, ...filteredMenus]);
  }, []);

  const filterSubMenu = (subMenu: LisItemProps["subMenu"]) => {
    if (!auth) return;
    const isAdmin = auth.roles.has(Roles.ADMIN);

    if (isAdmin) {
      return subMenu?.filter((subMenuEntry) => {
        if (subMenuEntry?.flag) {
          return flags[subMenuEntry.flag].enabled;
        }
        return true;
      });
    } else {
      const userPermissions = auth.permissionSet.SYSTEM_MODULES;
      return subMenu?.filter((subMenuEntry) => {
        return (
          !subMenuEntry.hasPermission ||
          (userPermissions.some(subMenuEntry.hasPermission) &&
            flags[subMenuEntry.flag].enabled)
        );
      });
    }
  };

  return (
    <Box sx={{ px: "20px", py: "10px" }}>
      <List
        sx={{
          width: "100%",
          minWidth: 230,
          maxWidth: 230,
          bgcolor: "background.paper",
        }}
        aria-labelledby="nested-list-subheader"
      >
        <div id="home">Home</div>
        {menuList?.map((item, index) => (
          <List key={index}>
            <ListItem
              component={Link}
              to={item.to}
              selected={location.pathname.includes(item.module)}
              sx={{
                borderRadius: "4px",
                "& .MuiSvgIcon-root": {
                  color: "#58595B",
                },

                "&:hover": {
                  background: "rgba(226, 15, 26, 0.1);",
                  color: "#E20F1A",
                  cursor: "pointer",
                  filter: generateFilter("#E20F1A"),
                },
                ...(location.pathname.includes(item.module) &&
                !item.subMenu?.length
                  ? {
                      color: "#E20F1A",
                      filter: generateFilter("#E20F1A"),
                      bgcolor: "rgba(226, 15, 26, 0.1);",
                    }
                  : {
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "#E20F1A",
                        filter: generateFilter("#E20F1A"),
                      },
                    }),
              }}
              onClick={() => {
                const newListMenu = [...menuList];
                newListMenu[index].collapseIsOpen =
                  !newListMenu[index].collapseIsOpen;

                setListMenu(newListMenu);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>
                <div id="titleMenu">{item.title}</div>
              </ListItemText>
              {item.subMenu?.length &&
                (item.collapseIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </ListItem>

            {item.collapseIsOpen && (
              <Collapse in={item.collapseIsOpen} timeout="auto" unmountOnExit>
                {item.subMenu?.map((subItem, indexSubMenu) => (
                  <List key={indexSubMenu}>
                    <ListItem
                      component={Link}
                      to={subItem.to}
                      selected={location.pathname.includes(subItem.to)}
                      sx={{
                        pl: 4,
                        borderRadius: "4px",
                        "&:hover": {
                          background: "rgba(88, 89, 91, 0.10);",
                          "& .MuiListItem-root": {
                            bgcolor: "rgba(88, 89, 91, 0.10)",
                          },
                        },
                        ...(location.pathname.includes(subItem.to) && {
                          color: "#E20F1A",
                          filter: generateFilter("#E20F1A"),
                        }),
                      }}
                    >
                      <StyledIcon
                        iconType="dot"
                        sx={{
                          width: "0.6rem",
                          height: "0.6rem",
                        }}
                      />
                      <div id="subTitleMenu">{subItem.title}</div>
                    </ListItem>
                  </List>
                ))}
              </Collapse>
            )}
          </List>
        ))}

        {menuFooter?.map((item, index) => (
          <List key={index}>
            <div id="home" className="footerTitle">
              {item.title}
            </div>
            {item.subMenu?.map((subItem, indexSubMenu) => (
              <List key={indexSubMenu}>
                <ListItem
                  component={Link}
                  to={subItem.to}
                  selected={location.pathname.includes(subItem.module)}
                  sx={{
                    borderRadius: "4px",
                    "& .MuiSvgIcon-root": {
                      color: "#58595B",
                    },

                    "&:hover": {
                      background: "rgba(226, 15, 26, 0.1);",
                      color: "#E20F1A",
                      cursor: "pointer",
                      filter: generateFilter("#E20F1A"),
                    },
                    ...(location.pathname.includes(subItem.module)
                      ? {
                          color: "#E20F1A",
                          filter: generateFilter("#E20F1A"),
                          bgcolor: "rgba(226, 15, 26, 0.1);",
                        }
                      : {
                          "&.Mui-selected": {
                            backgroundColor: "transparent",
                            color: "#E20F1A",
                            filter: generateFilter("#E20F1A"),
                          },
                        }),
                  }}
                  onClick={() => {
                    const newListMenu = [...menuList];
                    newListMenu[index].collapseIsOpen =
                      !newListMenu[index].collapseIsOpen;
                    setListMenu(newListMenu);
                  }}
                >
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText>
                    <div id="titleMenu">{subItem.title}</div>
                  </ListItemText>
                </ListItem>
              </List>
            ))}
          </List>
        ))}
      </List>
    </Box>
  );
};
export default listItemSideBar;
