import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "../../../components/Select/Select";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { FilterICMSTypes, filter, statusIcms } from "../models/Filters";
import TextFieldCustom from "../../../components/TextFieldCustom/TextFieldCustom";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import { GetRefundIcmsQuery } from "../../../modules/financial/models/GetRefundIcmsQuery";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import CalendarSingle from "../../../components/CalendarSingle/CalendarSingle";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import MultipleSelectDistributors from "../../../components/MultipleSelectDistributors/MultipleSelectDistributors";

interface ButtonSearch {
  dataFilter: (query: any) => void;
}

const FilterTableIcmsSection: React.FC<ButtonSearch> = ({ dataFilter }) => {
  const { selectedDistributorsStoraged } = useDistributor();
  const [searchQuery, setSearchQuery] = useState<GetRefundIcmsQuery>({});
  const [FilterSelected, setFilterSelected] = useState(FilterICMSTypes.ALL);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | any, field: string) => {
      setSearchQuery({
        [field]: event,
      });
    },
    [setSearchQuery]
  );

  useEffect(() => {
    if (
      selectedDistributorsStoraged.length !== 0 &&
      FilterSelected === FilterICMSTypes.CNPJ
    ) {
      handleSearchChange(
        selectedDistributorsStoraged.join(","),
        "customerCompanyIds"
      );
    } else {
      setSearchQuery({});
    }
  }, [selectedDistributorsStoraged, setSearchQuery]);

  const handleConsultChange = useCallback(() => {
    dataFilter(searchQuery);
  }, [searchQuery]);

  const currentFilterInput = useMemo(() => {
    switch (FilterSelected) {
      case FilterICMSTypes.CNPJ:
        return <MultipleSelectDistributors maxWidth={1200} label={"CNPJ"} />;
      case FilterICMSTypes.DATE:
        return (
          <CalendarSingle
            name="date"
            label="Data de solicitação"
            onChangeDateStartEnd={(dateBegin) => {
              handleSearchChange(
                format(dateBegin, "dd-MM-yyyy"),
                "requestDate"
              );
            }}
          />
        );
      case FilterICMSTypes.ID:
        return (
          <TextFieldCustom
            label="ID"
            placeholder="Informe o Id"
            value={searchQuery.refIds}
            onChange={(value) =>
              handleSearchChange(
                value.target.value.replace(/^-\D/g, ""),
                "refIds"
              )
            }
            endComponent={<StyledIcon iconType="search" />}
          />
        );
      case FilterICMSTypes.STATUS:
        return (
          <Select
            label="Status"
            options={statusIcms.map((item) => ({ option: item }))}
            value={statusIcms.find((ele) => ele.value === searchQuery.status)}
            getOptionItemLabel={(value) => value.label}
            getSelectedOptionLabel={(value) => value.label}
            onChange={(value) => handleSearchChange(value.value, "status")}
          />
        );
      default:
        break;
    }
  }, [FilterSelected, searchQuery]);

  return (
    <Grid container>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6} md={4}>
            <Select
              label="Pesquisar por"
              options={filter.map((item) => ({ option: item }))}
              value={filter.find((ele) => ele.value === FilterSelected)}
              getOptionItemLabel={(value) => value.label}
              getSelectedOptionLabel={(value) => value.label}
              onChange={(value) => {
                setFilterSelected(value.value);
                setSearchQuery({});
              }}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            {currentFilterInput}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        alignItems="flex-end"
        justifyContent={"flex-end"}
        alignContent={"flex-start"}
        marginTop={"25px"}
      >
        <CustomButton
          title="Limpar filtros"
          variant={CustomButtonVariant.OUTLINED}
          onClick={() => {
            setSearchQuery({});
            setFilterSelected(FilterICMSTypes.ALL);
            dataFilter({});
          }}
        />

        <CustomButton
          sx={{ marginLeft: "16px" }}
          title="Consultar"
          variant={
            !isEmpty(searchQuery)
              ? CustomButtonVariant.CONTAINED
              : CustomButtonVariant.DISABLED
          }
          onClick={handleConsultChange}
          disabled={isEmpty(searchQuery)}
        />
      </Grid>
    </Grid>
  );
};

export default FilterTableIcmsSection;
