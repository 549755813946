import { Drawer } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import ListItem from "./Content/ListItem";

interface SideBarProps {
  open?: boolean;
  onClose?: () => void;
}
const SideBar: React.FC<SideBarProps> = ({ open, onClose }) => {
  const isMobile = useMedia({ maxWidth: 900 });
  return (
    <Drawer
      {...(isMobile ? { open, onClose } : { variant: "permanent" })}
      sx={{
        width: 280,
        flexShrink: 0,
        paddingTop: 50,
        "& .MuiDrawer-paper": {
          width: 280,
          boxSizing: "border-box",
          marginTop: isMobile ? "0" : "100px",
          height: isMobile ? `calc(100% - ${20}px)` : `calc(100% - ${100}px)`,
        },
      }}
    >
      <ListItem />
    </Drawer>
  );
};

export default SideBar;
