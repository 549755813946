import React, { useCallback, useEffect, useState } from "react";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { ISupplyService } from "../../../modules/supply/models/ISupplyService";
import { Types } from "../../../ioc/types";
import useDialogAlert from "../../../hooks/DialogAlert";
import { GetDetailedStatementQuery } from "../../../modules/supply/models/GetDetailedStatementQuery";
import {
  TableHeaderDetailsStatementColumns,
  labelToNotShowUnity,
} from "../models/TableHeaderStatementColumns";
import { applyVolumeMask, getColorIfGreaterThanZero } from "../../../utils";
import { Grid, Typography } from "@mui/material";
import CustomButton, {
  CustomButtonVariant,
} from "../../../components/CustomButton/CustomButton";
import { useDistributor } from "../../../contexts/distributor/DistributorContext";
import { downloadExcel } from "../../../utils/download";
import AppError from "../../../utils/appError";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";

interface ITableRow {
  day: number;
  dailyQuota: string;
  dailyQuotaAccumulated: string;
  invoicedWithdrawals: string;
  invoicedAccumulated: string;
  balance: string;
  consumptionPercentage: string;
  position: number;
}

interface IDetailsStatementProps {
  dataFilter: GetDetailedStatementQuery | null;
  isClear: boolean;
}

const DetailedStatementTableSection: React.FC<IDetailsStatementProps> = ({
  dataFilter,
  isClear = false,
}) => {
  const [data, setData] = useState<ITableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { serviceContainer } = useIocContext();
  const { snackbar } = useDialogAlert();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const { selectedDistributor } = useDistributor();
  const { productSelected } = useOrderQuote();

  const supplyService = serviceContainer.get<ISupplyService>(
    Types.Supply.ISupplyService
  );

  useEffect(() => {
    if (isClear) {
      setData([]);
    }
  }, [isClear, setData]);

  const getDetailedStatementData = useCallback(
    async (query: GetDetailedStatementQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);
        const response = await supplyService.getAllDetailedStatement?.(query);
        const newArray = response;

        if (newArray?.length === 0) {
          setErrorData(true);
        }
        const newData: ITableRow[] = newArray.map((item) => ({
          ...item,
          dailyQuota: applyVolumeMask(`${item.dailyQuota ?? ""}`),
          dailyQuotaAccumulated: applyVolumeMask(
            `${item.dailyQuotaAccumulated ?? ""}`
          ),
          invoicedWithdrawals: applyVolumeMask(
            `${item.invoicedWithdrawals ?? ""}`
          ),
          invoicedAccumulated: applyVolumeMask(
            `${item.invoicedAccumulated ?? ""}`
          ),
          balance: applyVolumeMask(`${item.balance ?? ""}`),
          consumptionPercentage: `${Math.round(
            item.consumptionPercentage * 100
          )}`,
          positionWithColor: (
            <Typography color={getColorIfGreaterThanZero(item.position)}>
              {item.position}
            </Typography>
          ),
        }));
        setData(newData);
      } catch (error) {
        setErrorData(true);
        snackbar({
          message: (error as AppError).message,
          variant: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData]
  );

  useEffect(() => {
    if (dataFilter !== null) {
      getDetailedStatementData(dataFilter);
    }
  }, [dataFilter]);

  const downloadReportDetailedStatement = useCallback(async () => {
    try {
      if (dataFilter !== null) {
        setIsLoadingDownload(true);
        const response = await supplyService.downloadReportDetailed(dataFilter);
        downloadExcel(response);
        snackbar({
          message: "Download feito com sucesso",
          variant: "success",
        });
      }
    } catch (error) {
      snackbar({
        message: "Erro ao fazer download. Tentar novamente",
        variant: "error",
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [dataFilter]);

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <>
          <Grid
            item
            xs={8}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            style={{ paddingBottom: "2px" }}
          >
            <CustomButton
              title="Baixar relatório"
              variant={
                isLoadingDownload
                  ? CustomButtonVariant.CONTAINED_DOWNLOAD_LOADING
                  : CustomButtonVariant.CONTAINED_DOWNLOAD
              }
              onClick={() => {
                if (!selectedDistributor?.document?.length) {
                  snackbar({
                    message: "Selecione um cliente para baixar relatório.",
                    variant: "warning",
                  });
                } else {
                  downloadReportDetailedStatement();
                }
              }}
            />
          </Grid>
          <TablePaginated
            columns={TableHeaderDetailsStatementColumns.map((item) => ({
              ...item,
              label: `${item.label} ${
                productSelected && !labelToNotShowUnity.includes(item.id)
                  ? `(${productSelected})`
                  : ""
              }`,
            }))}
            data={data.map((item) => ({
              ...item,
            }))}
            rowsPerPage={31}
            isLoadingData={isLoadingData}
          />
        </>
      )}
    </>
  );
};

export default DetailedStatementTableSection;
