/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState, useMemo, useCallback, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Option {
  value: string;
  label: string;
}

interface Props {
  label: string;
  options: Option[];
  value: string[];
  onChange: (values: string[]) => void;
  hasError?: boolean;
  messageError?: string;
  isDisabled?: boolean;
  maxWidth?: number;
  minWidth?: number;
}

const MultipleSelectCheckbox: React.FC<Props> = ({
  label,
  options,
  value,
  onChange,
  hasError,
  messageError,
  isDisabled = false,
  maxWidth = 342,
  minWidth = 100,
}) => {
  const theme = useTheme();

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedValues([]);
      setSelectAll(false);
    } else {
      setSelectedValues(options.map((option) => option.value));
      setSelectAll(true);
    }
  }, [setSelectedValues, setSelectAll, selectAll]);

  const handleOptionChange = useCallback(
    (optionValue: string, optionSelected: boolean) => {
      setSelectedValues((prevSelectedValues) => {
        if (optionSelected) {
          if (prevSelectedValues?.includes(optionValue)) {
            return prevSelectedValues;
          } else {
            return prevSelectedValues
              ? [...prevSelectedValues, optionValue]
              : [optionValue];
          }
        } else {
          return prevSelectedValues?.filter((value) => value !== optionValue);
        }
      });
    },
    [setSelectedValues]
  );

  const allOptionsSelected = useMemo(
    () => selectedValues?.length === options?.length || false,
    [selectedValues, options]
  );

  const someOptionsSelected = useMemo(
    () => selectedValues?.length > 0 && !allOptionsSelected,
    [selectedValues, allOptionsSelected]
  );

  const handle = useCallback(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    handle();
  }, [handle]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value, setSelectedValues]);

  const handleRenderValue = useCallback(
    (selected: string[]) => {
      const allSelected =
        Array.isArray(selected) && selected?.length === options?.length;
      if (selected.length === 0) {
        setSelectAll(false);
        return "Selecione";
      } else if (allSelected) {
        setSelectAll(true);
        return "Todos";
      }
    },
    [selectedValues, setSelectAll]
  );

  const handleLabels = (a: any) => {
    const selectedLabels = selectedValues?.map(
      (value) => options.find((option) => option.value === value)?.label
    );

    return selectedValues.length === 0
      ? "Selecione"
      : allOptionsSelected
      ? "Todos"
      : selectedLabels?.join(", ") || "";
  };

  return (
    <div style={{ display: "flex" }}>
      <FormControl style={{ display: "flex" }} fullWidth>
        {label && (
          <Typography
            sx={{
              color:
                hasError !== undefined
                  ? (theme) => theme.palette.error.light
                  : "shadesOfDark.dark",
            }}
            fontFamily={"Open Sans"}
            fontWeight={700}
            mb={0.5}
          >
            {label}
          </Typography>
        )}
        {selectedValues && (
          <Select
            multiple
            displayEmpty
            fullWidth
            disabled={isDisabled}
            placeholder={selectedValues.length === 0 ? "Selecione" : ""}
            value={selectedValues}
            renderValue={(selected) => handleLabels(selected)}
            IconComponent={KeyboardArrowDownIcon}
            onChange={(e: SelectChangeEvent<string[]>) => {
              handleRenderValue(e.target.value as string[]);
            }}
            sx={{
              bgcolor: isDisabled
                ? "shadesOfDark.ultraLight"
                : "background.paper",
              border: `1px solid ${
                hasError !== undefined && !isDisabled
                  ? theme.palette.systemColors.error.main ?? ""
                  : theme.palette.shadesOfDark.light ?? ""
              }`,

              borderRadius: 1,
              padding: 0,
              maxWidth,
              minWidth,
              height: 40,
              "& .MuiSelect-select": {
                color:
                  hasError !== undefined
                    ? (theme) => theme.palette.error.light
                    : "#888",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#888",
                fontStyle: "italic",
                opacity: 1,
              },
              "& .MuiSelect-root": {
                backgroundColor: "transparent",
                paddingLeft: 0,
                paddingRight: 0,
                "&:focus": {
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none !important",
                },
                "&:hover": {
                  border: theme.palette.shadesOfDark.light,
                },
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 12px)",
              },
              "&& fieldset": {
                border: "none",
              },
            }}
          >
            {value.length && (
              <MenuItem value="" disabled>
                Selecione
              </MenuItem>
            )}

            {value.length === 0 ? (
              <Typography color={"shadesOfDark.steel"} p={2}>
                Lista vazia
              </Typography>
            ) : (
              <MenuItem key="select-all">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                    border: "0px solid red",
                  }}
                >
                  <Checkbox
                    checked={allOptionsSelected}
                    indeterminate={someOptionsSelected}
                    onChange={handleSelectAll}
                    sx={{
                      "&.MuiCheckbox-colorPrimary": {
                        color: theme.palette.shadesOfDark.dark,
                      },
                    }}
                  />
                  <ListItemText primary={"Todos"} />
                </label>
              </MenuItem>
            )}

            {options?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => {
                  handleOptionChange(
                    option.value,
                    !selectedValues?.includes(option.value)
                  );
                }}
                sx={{
                  minWidth: 200,
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  "&.Mui-selected": {
                    color: "shadesOfDark.dark",
                    backgroundColor: "shadesOfDark.ultraLight",
                    fontWeight: 700,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "shadesOfDark.ultraLight",
                  },
                }}
              >
                <Checkbox
                  checked={selectedValues?.includes(option.value)}
                  sx={{
                    "&.Mui-checked": {
                      color: theme.palette.shadesOfDark.dark,
                    },
                  }}
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        )}
        {hasError !== undefined && (
          <Typography
            sx={{ ml: 1, color: (theme) => theme.palette.error.light }}
          >
            {messageError}
          </Typography>
        )}
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckbox;
