import { Box, BoxProps, Skeleton, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../CustomCard/CustomCard";
import QueryTableHeader from "../QueryTableHeader/QueryTableHeader";

export interface IQueryTableColumn {
  id: number;
  name: string;
  key: string;
  align?: "left" | "center" | "right";
  flex?: number;
  boxProps?: BoxProps;
}

export interface IQueryTableRow {
  id: number;
  [key: string]: any;
}

interface QueryTableProps {
  title?: string;
  columns?: IQueryTableColumn[];
  data?: IQueryTableRow[];
  isLoading?: boolean;
}

const QueryTable: React.FC<QueryTableProps> = ({
  title,
  columns,
  data,
  isLoading = false,
}) => {
  const TableLoading: React.FC = () => {
    return (
      <Box>
        {Array.from(Array(4)).map((item, index) => (
          <Box key={index} display={"flex"} border={"1px solid #DFE0EB"}>
            {columns?.map((column) => (
              <Box
                key={column.key}
                display={"flex"}
                p={4}
                flex={column.flex ?? 1}
              >
                <Skeleton
                  key={item}
                  variant="rectangular"
                  height={"5rem"}
                  width={"100%"}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <CustomCard pb={5} sx={{ boxShadow: "none", border: "1px solid #DFE0EB" }}>
      <Box p={3.2}>
        <Typography fontWeight={700} fontSize={19}>
          {title}
        </Typography>
      </Box>
      <Box>
        <QueryTableHeader
          headCells={
            columns?.map((column) => ({
              label: column.name,
              textAlign: column.align,
              flex: column.flex,
              boxProps: column.boxProps,
            })) ?? []
          }
        />
      </Box>
      <Box>
        {isLoading ? (
          <TableLoading />
        ) : (
          data?.map((row) => (
            <Box
              key={row.id}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              border={"1px solid #DFE0EB"}
            >
              {columns?.map((column) => (
                <Box
                  key={column.key}
                  display={"flex"}
                  px={4}
                  flex={column.flex ?? 1}
                  justifyContent={column.align ?? "right"}
                  {...column.boxProps}
                >
                  {row[column.key]}
                </Box>
              ))}
            </Box>
          ))
        )}
      </Box>
    </CustomCard>
  );
};

export default QueryTable;
