export const styleButton = {
  cursor: "pointer",
  color: "shadesOfDark.steel",
  ":hover": {
    backgroundColor: "shadesOfDark.whiteLight",
    borderRadius: "50%",
  },
};

export type RiverModalData = Record<
  string,
  {
    title: string;
    value: string;
  }
>;
export interface RiverRequestFormData {
  receiverCustomerId?: string;
  customerCompanyId?: string;
  shippingCompanyId?: string;
  productId?: string;
  contractId?: string;
  mod?: string;
  volumeRequested?: number;
  paymentCondition?: string;
  scheduleDate?: string;
  scheduleLikeDate?: Date | null;
  note?: string;
  subsidiaryId?: string;
  scheduleToken?: string;
}
