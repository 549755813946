import React from "react";
import { Box, Typography } from "@mui/material";
import { IconType, StyledIcon } from "../StyledIcon/StyledIcon";

interface ICardDataNotFoundProps {
  icon: IconType;
  title: string;
  subtitle?: string;
}

const CardDataNotFound: React.FC<ICardDataNotFoundProps> = ({
  icon,
  title,
  subtitle,
}) => {
  return (
    <Box
      sx={{
        padding: "12rem",
        border: "1px dashed #D2D3D6",
        height: "100%",
        borderRadius: 1,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          width: "100%",
          textAlign: "center",
        }}
      >
        <StyledIcon
          iconType={icon}
          sx={{
            height: "auto",
            width: {
              lg: 120,
              md: 120,
              sm: 100,
              xs: 100,
            },
          }}
        />
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Open Sans",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "shadesOfDark.dark",
            my: 2,
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontSize: 14,
              lineHeight: "24px",
              color: "shadesOfDark.dark",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CardDataNotFound;
