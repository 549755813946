import { Avatar, Box, Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardDataNotFound from "../../components/CardDataNotFound/CardDataNotFound";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import ModalExtract from "../../components/ModalExtract/ModalExtract";
import ModalExtractContent from "../../components/ModalExtractContent/ModalExtractContent";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import Select from "../../components/Select/Select";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import TablePaginated from "../../components/TablePaginated/TablePaginated";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { Types } from "../../ioc/types";
import { GetAllFinancialQuery } from "../../modules/financial/models/GetAllFinancialQuery";
import { IFinancialService } from "../../modules/financial/models/IFinancialService";
import { formatCurrency } from "../../utils/currencyFormatter";
import { ISODateToFormat, convertToISO } from "../../utils/dateFormatter";
import { downloadExcel, downloadOpenWindow } from "../../utils/download";
import { statusInvoiceList } from "./models/StatusInvoice";
import { TableHeaderColumns } from "./models/TableHeaderColumns";
import useDialogAlert from "../../hooks/DialogAlert";
import CalendarRange from "../../components/CalendarRange/CalendarRange";
import { useOrderQuote } from "../../contexts/orderQuote/OrderQuoteContext";
import {
  StatusInvoiceToString,
  calcPagesQuantity,
  convertToOptionsList,
} from "../../utils";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import {
  CategoryType,
  Content,
} from "../../modules/financial/models/GetAllFinancialDTO";
import MultipleSelectDistributors from "../../components/MultipleSelectDistributors/MultipleSelectDistributors";
import MultipleSelectCheckbox from "../../components/MultipleSelectCheckbox";

const ROW_PER_PAGE = 10;
interface TableRow {
  id: string;
  document: string;
  dateOfIssue: string;
  dueDate: string;
  financialStatus: string | undefined;
  branch: string;
  value: string;
  icon?: JSX.Element;
  accessKey: string;
  companyBank: string;
  paymentMethod: string;
  paymentType: string;
  ourNumber: string;
  compensationDate: string;
  description: string;
  openDialog?: boolean;
  setOpenDialog?: (value: boolean) => void;
  atemId: string;
  invoiceId: string;
  installmentNumber: string;
  year: string;
  categoryTranslation: string;
  category: string;
  CNPJ: string;
  companyCode: string;
  fiscalYear: string;
  documentItem: string;
}

interface SelectItem {
  value: string;
  label: string;
}

const ConsultStatementPage: React.FC = () => {
  const [data, setData] = useState<TableRow[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { getCategoryFromGroup } = useOrderQuote();
  const [category, setCategory] = useState<SelectItem[]>([]);
  const [errorData, setErrorData] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const { snackbar } = useDialogAlert();
  const { distributors, selectedDistributorsStoraged } = useDistributor();
  const { serviceContainer } = useIocContext();

  const distributorsIds = distributors.map((item) => item.externalId).join(",");

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categoryIDStorage, setProductIDStorage] = useState<string>("");
  const codeCategoryStorage = localStorage.getItem("categoryIDStorage");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openOrCloseStatusInvoice, setOpenOrCloseStatusInvoice] = useState("");
  const [clearValueCalendar, setClearValueCalendar] = useState<boolean>(false);

  const [isClickedInFilter, setIsClickedInFilter] = useState("");
  const isClickedInFilterStorage = localStorage.getItem("isClickedInFilter");

  const currentDay = new Date();
  const firstDay = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);

  const storageExternalIds = useMemo(() => {
    if (selectedDistributorsStoraged.length === 0) return;
    return distributors
      .filter((ele) => selectedDistributorsStoraged.includes(ele.id as string))
      .map((ele) => ele.externalId)
      .join(",");
  }, [selectedDistributorsStoraged, distributors]);

  const initFilters: GetAllFinancialQuery = {
    orderDirection: "desc",
    orderBy: "dueDate",
    service: "EXTRACT",
    statusInvoice: "",
    dueDateBegin: firstDay.toISOString(),
    dueDateEnd: currentDay.toISOString(),
    customerNumber: storageExternalIds || distributorsIds,
    code: codeCategoryStorage || "",
    page: 1,
    limit: ROW_PER_PAGE,
  };
  const [filters, setFilters] = useState<GetAllFinancialQuery>(initFilters);

  const financialService = serviceContainer.get<IFinancialService>(
    Types.Financial.IFinancialService
  );

  const getCategoryFromGroupsData = useCallback(async () => {
    const response = await getCategoryFromGroup?.();
    if (response === undefined) return;
    const categoryMapped: SelectItem[] = response?.map((category) => ({
      value: category.code ?? "",
      label: category.description ?? "",
    }));

    if (response?.length) setCategory(categoryMapped);
  }, [getCategoryFromGroup, setCategory]);

  const getAllFinancial = useCallback(async () => {
    try {
      setErrorData(false);
      setIsLoadingData(true);
      const response = await financialService.getAllFinancial(filters);

      const newData = response.content.map((item: Content, index) => ({
        id: `${index}${item.customerNumber}`,
        document: item.documentNumber,
        dateOfIssue: ISODateToFormat(item.documentDate),
        dueDate: ISODateToFormat(item.dueDate),
        financialStatus: StatusInvoiceToString(item.statusInvoice),
        branch: item.divisionDescription ? item.divisionDescription : "-",
        value: formatCurrency(item.value),
        accessKey: item.referenceKey,
        companyBank: item.bankName,
        paymentMethod: item.paymentMethod,
        paymentType: item.paymentType,
        ourNumber: item.reference3,
        compensationDate: ISODateToFormat(item.quittanceDate),
        description: item.documentItemDescription,
        atemId: item.companyCode,
        invoiceId: item.documentNumber,
        installmentNumber: item.documentItem,
        year: item.fiscalYear,
        categoryTranslation: item.categoryTranslation,
        category: item.category,
        CNPJ: item.CNPJ ? item.CNPJ : "-",
        fiscalYear: item.fiscalYear,
        companyCode: item.companyCode,
        documentItem: item.documentItem,
        customerName: item.customerName,
      }));
      if (response.content.length === 0) {
        setErrorData(true);
      }
      setTotal(response.total);
      setData(newData);
    } catch (error) {
      snackbar({
        message: "Erro ao carregar extrato. Tentar novamente",
        variant: "error",
      });
      setErrorData(true);
    } finally {
      setIsLoadingData(false);
    }
  }, [filters]);

  const downloadReport = useCallback(async () => {
    try {
      setIsLoadingDownload(true);
      const response = await financialService.downloadReport(filters);
      downloadExcel(response);
      snackbar({
        message: "Download feito com sucesso",
        variant: "success",
      });
    } catch (error) {
      snackbar({
        message: "Erro ao fazer download. Tentar novamente",
        variant: "error",
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }, [filters]);

  useEffect(() => {
    getCategoryFromGroupsData();
  }, [filters.page, filters.statusInvoice, getCategoryFromGroupsData]);

  useEffect(() => {}, [initFilters]);

  useEffect(() => {
    if (storageExternalIds?.length && isClickedInFilterStorage) {
      setFilters((oldState) => ({
        ...oldState,
        customerNumber: storageExternalIds,
        dueDateBegin: firstDay.toISOString(),
        dueDateEnd: currentDay.toISOString(),
      }));
    }
  }, [selectedDistributorsStoraged]);

  useEffect(() => {
    if (storageExternalIds?.length && isClickedInFilterStorage) {
      getAllFinancial();
    }
  }, [filters]);

  useEffect(() => {}, [
    selectedCategories,
    setSelectedCategories,
    categoryIDStorage,
    setProductIDStorage,
    setSelectedStatus,
    selectedStatus,
    setOpenOrCloseStatusInvoice,
    openOrCloseStatusInvoice,
    isClickedInFilter,
    setIsClickedInFilter,
  ]);

  const clearFilters = () => {
    setClearValueCalendar(true);
    setFilters((oldState) => ({
      ...oldState,
      statusInvoice: undefined,
      dueDateBegin: undefined,
      dueDateEnd: undefined,
      code: undefined,
    }));
    setSelectedCategories([]);
    setProductIDStorage("");
    setSelectedStatus("");
    localStorage.removeItem("openOrCloseStatusInvoice");
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("categoryIDStorage");
    localStorage.removeItem("selectedStatus");
    getAllFinancial();
  };

  const handleChangeSelectedCategories = useCallback(
    (event: string[]) => {
      if (event?.length > 0) {
        setSelectedCategories(event);
        setFilters((oldState) => ({
          ...oldState,
          code: event.join(","),
        }));
      }
    },
    [selectedDistributorsStoraged]
  );

  const LoadDataOnModal: React.FC<{ index: number; row: TableRow }> = (
    props
  ) => {
    const [isDownloadPaymentSlip, setIsDownloadPaymentSlip] = useState(false);

    const downloadPaymentSlip = useCallback(async () => {
      try {
        setIsDownloadPaymentSlip(true);
        const response = await financialService.downloadPaymentSlip({
          atemId: props.row.atemId,
          invoiceId: props.row.invoiceId,
          installmentNumber: props.row.installmentNumber,
          year: props.row.year,
        });
        downloadOpenWindow(response, "pdf");
      } catch (error) {
        snackbar({
          message: "Erro ao fazer download. Tentar novamente",
          variant: "error",
        });
      } finally {
        setIsDownloadPaymentSlip(false);
      }
    }, [filters]);

    const getTypeService = (typeCategory: string) => {
      if (typeCategory === "CreditNote") {
        return "CREDIT";
      } else if (typeCategory === "DebitNote") {
        return "DEBIT";
      }
      return undefined;
    };

    const downloadCreditNoteOrDebitNote = useCallback(async () => {
      try {
        setIsDownloadPaymentSlip(true);
        const response = await financialService.downloadCreditNoteOrDebitNote({
          service: getTypeService(props.row.category),
          customerNumber: storageExternalIds,
          documentNumber: props.row.invoiceId,
          companyCode: props.row.companyCode,
          fiscalYear: props.row.fiscalYear,
          documentItem: props.row.documentItem,
        });
        downloadOpenWindow(response, "pdf");
      } catch (error) {
        snackbar({
          message: "Erro ao fazer download. Tentar novamente",
          variant: "error",
        });
      } finally {
        setIsDownloadPaymentSlip(false);
      }
    }, [filters]);

    const componentContentExtract = (
      <ModalExtractContent
        icon={
          <StyledIcon
            iconType="data-not-found"
            sx={{
              width: "8rem",
              height: "8rem",
            }}
          />
        }
        category={{
          title: `Categoria: ${props.row.categoryTranslation}`,
          value: `Documento ${props.row.document}`,
        }}
        value={{ title: "Valor", value: props.row.value }}
        accessKey={{
          title: "Chave de acesso",
          value: `${props.row.accessKey} `,
        }}
        bank={{
          title: "Banco",
          value: props.row.companyBank ? props.row.companyBank : "-",
        }}
        valueNumber={{
          title: "Nosso número",
          value: props.row.ourNumber ? props.row.ourNumber : "-",
        }}
        formOfPayment={{
          title: "Forma de pagamento",
          value: `${props.row.paymentMethod} `,
        }}
        compensationDate={{
          title: "Data de compensação",
          value: props.row.compensationDate ? props.row.compensationDate : "-",
        }}
        description={{
          title: "Descrição",
          value: props.row.description,
        }}
      />
    );

    const handleOpenClose = (open: boolean) => {
      const newData = [...data];

      newData[props.index].openDialog = open;

      setData(newData);
    };

    const getLabelButtonDownloadOfModalExtract = (typeCategory: string) => {
      if (typeCategory === "CreditNote") {
        return "Baixar Nota de crédito";
      } else if (typeCategory === "DebitNote") {
        return "Baixar Nota de débito";
      } else {
        return "Baixar Boleto";
      }
    };

    const disabledButtonDownloadTicket = ({
      category,
      paymentMethod,
      companyBank,
      ourNumber,
    }: TableRow): boolean => {
      const isCashCategory = category === CategoryType.Cash;

      const isDeferredPaymentWithDeposit =
        category === CategoryType.DeferredPayment &&
        paymentMethod === "Depósito Bancário";

      const isDeferredPaymentWithBoletoAndMissingInfo =
        category === CategoryType.DeferredPayment &&
        paymentMethod === "Boleto a Receber" &&
        (!companyBank || !ourNumber);

      return (
        isCashCategory ||
        isDeferredPaymentWithDeposit ||
        isDeferredPaymentWithBoletoAndMissingInfo
      );
    };

    return (
      <ModalExtract
        openDialog={props.row.openDialog ?? false}
        handleClose={() => handleOpenClose(false)}
        openElement={
          <IconButton onClick={() => handleOpenClose(true)}>
            <StyledIcon iconType="info" />
          </IconButton>
        }
        componentItems={componentContentExtract}
        titleModal={"Detalhes do extrato"}
        buttons={[
          {
            title: getLabelButtonDownloadOfModalExtract(props.row.category),

            variant: disabledButtonDownloadTicket(props.row)
              ? CustomButtonVariant.DISABLED
              : isDownloadPaymentSlip
              ? CustomButtonVariant.OUTLINED_LOADING
              : CustomButtonVariant.OUTLINED,
            onClick: () => {
              if (
                props.row.category === "CreditNote" ||
                props.row.category === "DebitNote"
              ) {
                downloadCreditNoteOrDebitNote();
              } else {
                downloadPaymentSlip();
              }
            },
          },
          {
            title: "Fechar",
            variant: CustomButtonVariant.CONTAINED,
            onClick: () => handleOpenClose(false),
          },
        ]}
      />
    );
  };

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={2} height="auto" padding={2}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="Consultar Extrato" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
            }}
            padding={2.4}
          >
            <CardTitleWithSubtitle
              title="Consultar Extrato"
              subtitle="Informe os filtros abaixo para consultar o extrato"
              buttonProps={{
                title: "Baixar relatório",
                variant: isLoadingDownload
                  ? CustomButtonVariant.CONTAINED_DOWNLOAD_LOADING
                  : CustomButtonVariant.CONTAINED_DOWNLOAD,
                onClick: () => {
                  if (selectedDistributorsStoraged.length === 0) {
                    snackbar({
                      message: "Selecione um cliente para baixar relatório.",
                      variant: "warning",
                    });
                  } else {
                    downloadReport();
                  }
                },
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={10} sm={6} md={3}>
                    <MultipleSelectDistributors
                      maxWidth={1200}
                      label={"Selecione o cliente"}
                    />
                  </Grid>

                  <Grid item xs={10} sm={6} md={3}>
                    <MultipleSelectCheckbox
                      label="Categoria"
                      maxWidth={1200}
                      minWidth={100}
                      options={convertToOptionsList(category, "value", "label")}
                      value={selectedCategories}
                      onChange={handleChangeSelectedCategories}
                    />
                  </Grid>

                  <Grid item xs={10} sm={6} md={3}>
                    <Select
                      value={
                        statusInvoiceList.find(
                          (item) => item.value === filters.statusInvoice
                        ) ?? { label: "", value: "ALL" }
                      }
                      label="Status financeiro"
                      isDisabled={!storageExternalIds}
                      options={statusInvoiceList.map((item) => ({
                        option: item,
                      }))}
                      getOptionItemLabel={(value) => value.label}
                      getSelectedOptionLabel={(value) => value.label}
                      onChange={(value) => {
                        if (value.value === "ALL") {
                          setFilters((oldState) => ({
                            ...oldState,
                            statusInvoice: "",
                          }));
                        } else {
                          setFilters((oldState) => ({
                            ...oldState,
                            statusInvoice: value.value,
                          }));
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={10} sm={6} md={3}>
                    <CalendarRange
                      disabledTextField={!storageExternalIds}
                      name="date"
                      value={[
                        filters?.dueDateBegin
                          ? new Date(filters?.dueDateBegin)
                          : firstDay,
                        filters?.dueDateEnd
                          ? new Date(filters?.dueDateEnd)
                          : currentDay,
                      ]}
                      label="Período de vencimento"
                      clearValueCalendar={clearValueCalendar}
                      onChangeDateStartEnd={(dateBegin, dateEnd) => {
                        setClearValueCalendar(false);
                        if (
                          dateBegin !== null &&
                          dateEnd !== null &&
                          dateBegin.getTime() === dateEnd.getTime()
                        ) {
                          setFilters((oldState) => ({
                            ...oldState,
                            dueDateBegin: convertToISO(dateBegin),
                            dueDateEnd: convertToISO(dateEnd),
                          }));
                        } else {
                          if (dateBegin !== null && dateEnd !== null) {
                            setFilters((oldState) => ({
                              ...oldState,
                              dueDateBegin: convertToISO(dateBegin),
                              dueDateEnd: convertToISO(dateEnd),
                            }));
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={3}
                container
                alignItems="flex-end"
                justifyContent={"flex-end"}
                alignContent={"flex-start"}
                marginTop={"25px"}
              >
                <CustomButton
                  title="Limpar filtros"
                  variant={CustomButtonVariant.OUTLINED}
                  onClick={clearFilters}
                />

                <CustomButton
                  sx={{ marginLeft: "16px" }}
                  title="Consultar"
                  onClick={() => {
                    if (!storageExternalIds?.length) {
                      snackbar({
                        message: "Selecione um cliente para filtrar.",
                        variant: "warning",
                      });
                    } else {
                      getAllFinancial();
                      localStorage.setItem("isClickedInFilter", "true");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12} mt={2}>
                {!storageExternalIds && !isClickedInFilterStorage && (
                  <Box alignSelf={"center"}>
                    <CardDataNotFound
                      icon="search-empty"
                      title="Selecione o CNPJ para começar"
                      subtitle="Informe outros critérios de busca e comece a consultar!"
                    />
                  </Box>
                )}

                {storageExternalIds && !isClickedInFilterStorage && (
                  <Box alignSelf={"center"}>
                    <CardDataNotFound
                      icon="search-empty"
                      title="Agora clique em CONSULTAR"
                      subtitle="Informe outros critérios de busca e comece a consultar!"
                    />
                  </Box>
                )}

                {errorData && (
                  <CardDataNotFound
                    icon="data-not-found"
                    title="Não foi encontrado resultado"
                    subtitle="Informe outros critérios de busca e consulte novamente"
                  />
                )}

                {!errorData && storageExternalIds && data.length !== 0 && (
                  <TablePaginated
                    columns={TableHeaderColumns}
                    data={data.map((item, index) => ({
                      ...item,
                      icon: <LoadDataOnModal index={index} row={item} />,
                    }))}
                    rowsPerPage={ROW_PER_PAGE}
                    isLoadingData={isLoadingData}
                    loadFromServer
                    onChangePage={(newPage) => {
                      setFilters((oldState) => ({
                        ...oldState,
                        page: newPage,
                      }));
                    }}
                    paginationProps={{
                      pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
                      page: filters.page,
                    }}
                    resultsTotal={total}
                  />
                )}
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ConsultStatementPage;
// eslint-disable-next-line prettier/prettier
