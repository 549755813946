type ProductsType = Record<string, { title: string }>;

export const ProductstList: ProductsType = {
  combustivel_escuro: {
    title: "Combustíveis escuros",
  },
  combustivel_claro: {
    title: "Combustíveis claros",
  },
};
