import { Grid, Typography } from "@mui/material";
import React from "react";
import SelectDistributors from "../SelectDistributors/SelectDistributors";

interface WelcomeCardProps {
  userName?: string;
  gender?: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ userName, gender }) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        background: "#FFFFFF",
        padding: "10px",
        py: 1.2,
        color: "#475569",
      }}
    >
      <Grid item xs={6}>
        <Typography fontSize={20} color={"#475569"}>
          Olá,{" "}
          <span style={{ color: "#E83F48", fontWeight: "600" }}>
            {userName}
          </span>
          , bem vind
          {gender} de volta!
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "end",
          textAlignItems: "end",
        }}
      >
        <SelectDistributors />
      </Grid>
    </Grid>
  );
};

export default WelcomeCard;
