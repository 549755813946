import { CssBaseline, Grid } from "@mui/material";
import React from "react";
// import CardBanner from "../../../components/CardBanner/CardBanner";
import CardSlider from "../../../components/CardSlider/CardSlider";

const BannerTemp = () => {
  return (
    <div style={{ height: 390 }} key="divb">
      <Grid
        container
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={"images/banner.jpg"} key={2} style={{ width: "inherit" }} />
      </Grid>
    </div>
  );
};

const itemsCards = [
  // <CardBanner
  //   key={1}
  //   title="A mais nova empresa de refino de petróleo da Amazônia!"
  //   description="Para a Ream, relacionamento é a base de nossas ações: valorizamos o capital humano  traduzido em nossos colaboradores, fornecedores, parceiros e a comunidade à nossa volta, incluindo aí também o relacionamento com o meio ambiente. Movidos pelo DNA Amazônico, contamos com você para ir mais longe."
  // />,
  // eslint-disable-next-line react/jsx-key
  <BannerTemp />,
  // <CardBanner
  //   key={2}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide2]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
  // <CardBanner
  //   key={3}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide3]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
  // <CardBanner
  //   key={4}
  //   title="Banner de marketing, sustentabilidade, tecnologia e inovação[slide4]"
  //   description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
  // />,
];

const src: React.FC = () => {
  return (
    <div>
      <CssBaseline />
      <CardSlider componentItems={itemsCards} />
    </div>
  );
};

export default src;
