import { inject, injectable } from "inversify";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import { IInformationService } from "./models/IInformationService";
import {
  FollowUp,
  GetSacRegisterDTO,
  SacRegister,
} from "./models/GetSacRegisterDTO";
import { CustomerServiceDepartment } from "./models/GetDepartments";
import { IFileUploadDTO } from "../financial/models/IFileUpload";
import { NewSacRegister } from "./models/SacRegisterQuery";
import { Subjects } from "./models/GetSubjects";

@injectable()
export class InformationService implements IInformationService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async getRegisters(query: any): Promise<GetSacRegisterDTO> {
    return await this.httpInstance.get("/ms-contracts/customer-service", {
      params: query,
    });
  }

  public async getDepartments(): Promise<CustomerServiceDepartment[]> {
    return await this.httpInstance.get(
      "/ms-data-guard/static-entity?group=CUSTOMER_SERVICE_DEPARTMENT"
    );
  }

  public async getSubjects(department: string): Promise<Subjects[]> {
    return await this.httpInstance.get(
      `/ms-data-guard/static-entity?group=CUSTOMER_SERVICE_SUBJECT&meta.department=${department}`
    );
  }

  public async uploadSacFiles(file: FormData): Promise<IFileUploadDTO[]> {
    return await this.httpInstance.post(
      `/ms-contracts/customer-service/upload`,
      file
    );
  }

  public async createSacRegister(data: NewSacRegister): Promise<SacRegister> {
    return await this.httpInstance.post("/ms-contracts/customer-service", data);
  }

  public async deleteSacRegister(id: string): Promise<string> {
    return await this.httpInstance.delete(
      `/ms-contracts/customer-service/${id}`
    );
  }

  public async followUpRegister(
    id: string,
    data: { message: string }
  ): Promise<FollowUp> {
    return await this.httpInstance.post(
      `/ms-contracts/customer-service/${id}/follow-up`,
      data
    );
  }

  public async commitRegister(id: string): Promise<string> {
    return await this.httpInstance.patch(
      `/ms-contracts/customer-service/${id}/commit`
    );
  }

  public async getSacReportFiles(
    CUSTOMER_SERVICE_ID: string,
    FOLLOW_UP_ID: string,
    ATTACHMENT_ID: string
  ): Promise<Blob> {
    return await this.httpInstance.get(
      `ms-contracts/customer-service/${CUSTOMER_SERVICE_ID}/follow-up/${FOLLOW_UP_ID}/${ATTACHMENT_ID}`,
      {
        responseType: "blob",
      }
    );
  }
}
