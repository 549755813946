import { Box, IconButton, Typography } from "@mui/material";
import { CustomContentProps, SnackbarContent, closeSnackbar } from "notistack";
import React, { useCallback } from "react";
import { IconType, StyledIcon } from "../StyledIcon/StyledIcon";
import CloseIcon from "@mui/icons-material/Close";

interface CustomNotificationProps extends CustomContentProps {
  actions: React.ReactNode[];
}
const CustomNotification = React.forwardRef<
  HTMLDivElement,
  CustomNotificationProps
>(({ id, variant, message }, ref) => {
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const getIcon = () => {
    let iconType: IconType | undefined;
    switch (variant) {
      case "success":
        iconType = "notification-message-success";
        break;
      case "error":
        iconType = "notification-message-error";
        break;
      case "warning":
        iconType = "notification-message-warning";
        break;
    }
    if (iconType) {
      return (
        <StyledIcon
          iconType={iconType}
          sx={{
            width: "6.4rem",
            height: "6.4rem",
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <SnackbarContent ref={ref}>
      <Box
        bgcolor={"#58595B"}
        display={"flex"}
        borderRadius={1}
        alignItems={"center"}
      >
        {getIcon()}
        <Box p={2}>
          <Typography color={"white"} fontFamily={"Open Sans"}>
            {message}
          </Typography>
        </Box>
        <IconButton size="small" onClick={handleDismiss} sx={{ padding: 2 }}>
          <CloseIcon fontSize="small" sx={{ color: "white" }} />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
});

export default CustomNotification;
