import { Grid, Typography } from "@mui/material";
import React from "react";
import Pagination from "../Pagination/Pagination";

interface DeliveryTableFooterProps {
  displayedResults: number;
  pagesTotalTable: number;
  pagesQuantity: number;
  pagesTotalLine: number;
  onChangePage: (newPage: number) => void;
}

const RANGE_NUMBER_PAGE = 10;

const DeliveryTableFooter: React.FC<DeliveryTableFooterProps> = ({
  displayedResults,
  pagesQuantity,
  pagesTotalTable,
  pagesTotalLine,
  onChangePage,
}) => {
  const handleChangePage = (_event: unknown, newPage: number) => {
    onChangePage(newPage + 1 ?? 0);
  };

  const firstRowIndex = (displayedResults - 1) * RANGE_NUMBER_PAGE + 1;
  const lastRowIndex =
    Number((displayedResults - 1) * RANGE_NUMBER_PAGE) + pagesTotalLine;

  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          background: "#FFFFFF",
          py: 2.5,
          px: 2.4,
        }}
      >
        <Grid item xs={6} sx={{ margin: "auto" }}>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: "shadesOfDark.dark",
            }}
          >
            Mostrando {firstRowIndex} - {lastRowIndex} de {pagesTotalTable}{" "}
            resultados
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Pagination
            pagesTotal={pagesQuantity}
            onPageChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DeliveryTableFooter;
