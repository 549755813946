/* eslint-disable react/no-unknown-property */
export const Analasys = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6895 20C5.144 20 0.689453 15.5455 0.689453 10C0.689453 4.45455 5.144 0 10.6895 0C16.2349 0 20.6895 4.45455 20.6895 10C20.6895 15.5455 16.2349 20 10.6895 20ZM10.6895 1.81818C6.144 1.81818 2.50763 5.45455 2.50763 10C2.50763 14.5455 6.144 18.1818 10.6895 18.1818C15.2349 18.1818 18.8713 14.5455 18.8713 10C18.8713 5.45455 15.2349 1.81818 10.6895 1.81818ZM13.125 12.2178C13.5811 12.4458 14.1352 12.247 14.3423 11.781C14.5365 11.3441 14.3527 10.8316 13.925 10.6178L11.5985 9.45455V4.54545C11.5985 4 11.2349 3.63636 10.6895 3.63636C10.144 3.63636 9.78036 4 9.78036 4.54545V10C9.78036 10.3636 9.96218 10.6364 10.3258 10.8182L13.125 12.2178Z"
      fill={props.fill ?? "#E3B420"}
    />
  </svg>
);

export const Approved = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2804 10.2598C20.7783 10.2598 20.3713 10.6668 20.3713 11.1689V12.0052C20.3693 15.6282 17.9841 18.8187 14.5093 19.8461C11.0346 20.8735 7.29792 19.493 5.32575 16.4532C3.35357 13.4135 3.61567 9.4386 5.96992 6.68413C8.32416 3.92967 12.2097 3.05177 15.5195 4.52653C15.9781 4.73087 16.5156 4.52475 16.7199 4.06614C16.9242 3.60752 16.7181 3.07009 16.2595 2.86575C12.2142 1.06327 7.4652 2.13625 4.58778 5.50282C1.71037 8.86939 1.39003 13.7276 3.80047 17.4428C6.2109 21.1581 10.7779 22.8453 15.0249 21.5897C19.2718 20.334 22.187 16.4344 22.1895 12.0057L22.1895 11.1689C22.1895 10.6668 21.7825 10.2598 21.2804 10.2598ZM21.9229 4.08935C21.5677 3.73451 20.9921 3.7348 20.6373 4.09L12.1892 12.5465L10.1051 10.4624C9.75004 10.1074 9.17444 10.1074 8.81941 10.4624C8.46439 10.8174 8.46439 11.393 8.81941 11.7481L11.5467 14.4753C11.9018 14.8305 12.4777 14.8303 12.8327 14.475L21.9236 5.375C22.2784 5.0198 22.2781 4.4442 21.9229 4.08935Z"
      fill={props.fill ?? "#1AB83F"}
    />
  </svg>
);

export const Rejected = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.399 11.7435L16.1998 19.0006H7.8007L3.60235 11.7435L7.8007 4.48636H16.1998L20.399 11.7435ZM21.9014 11.3518L17.2721 3.35265C17.1423 3.12898 16.908 3 16.6288 3H7.37171C7.09253 3 6.85824 3.12898 6.72845 3.35347L2.09908 11.3608C1.96684 11.5918 1.96684 11.8677 2.0999 12.0995L6.72845 20.1158C6.86069 20.3444 7.1064 20.4864 7.37171 20.4864H16.6288C16.8933 20.4864 17.1398 20.3436 17.2729 20.1134L21.9014 12.0955C22.0329 11.8661 22.0329 11.5812 21.9014 11.3518ZM15.7714 14.4685L13.0465 11.7436L15.7714 9.01876C15.9151 8.87509 15.9935 8.68978 15.9935 8.4955C15.9935 8.30121 15.9151 8.11509 15.7714 7.97224C15.4726 7.67346 15.022 7.67509 14.7249 7.97224L12 10.6971L9.27512 7.97224C8.97717 7.67428 8.52737 7.67346 8.2286 7.97224C8.08492 8.11509 8.00656 8.30121 8.00656 8.4955C8.00656 8.68978 8.08492 8.87509 8.2286 9.01876L10.9535 11.7436L8.2286 14.4685C7.93064 14.7665 7.93064 15.2171 8.22941 15.5151C8.53635 15.8212 8.96574 15.8212 9.27512 15.5151L12 12.7894L14.7257 15.5159C14.8792 15.6685 15.0988 15.7371 15.2482 15.7371C15.3975 15.7371 15.6171 15.6685 15.7714 15.5151C16.0694 15.2171 16.0694 14.7665 15.7714 14.4685Z"
      fill={props.fill ?? "#CC3D3D"}
    />
  </svg>
);

export const ApprovedNote = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8713 11.1689C20.8713 10.6668 21.2783 10.2598 21.7804 10.2598C22.2825 10.2598 22.6895 10.6668 22.6895 11.1689L22.6895 12.0057C22.687 16.4344 19.7718 20.334 15.5249 21.5897C11.2779 22.8453 6.7109 21.1581 4.30047 17.4428C1.89003 13.7276 2.21037 8.86939 5.08778 5.50282C7.9652 2.13625 12.7142 1.06327 16.7595 2.86575C17.2181 3.07009 17.4242 3.60752 17.2199 4.06614C17.0156 4.52475 16.4781 4.73087 16.0195 4.52653C12.7097 3.05177 8.82416 3.92967 6.46992 6.68413C4.11567 9.4386 3.85357 13.4135 5.82575 16.4532C7.79792 19.493 11.5346 20.8735 15.0093 19.8461C18.4841 18.8187 20.8693 15.6282 20.8713 12.0052V11.1689Z"
      fill="#1AB83F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1369 4.08983C21.4917 3.73463 22.0673 3.73434 22.4225 4.08919C22.7777 4.44403 22.778 5.01964 22.4232 5.37484L13.3322 14.4748C12.9773 14.8302 12.4014 14.8303 12.0463 14.4752L9.319 11.7479C8.96398 11.3929 8.96398 10.8173 9.319 10.4622C9.67402 10.1072 10.2496 10.1072 10.6047 10.4622L12.6888 12.5464L21.1369 4.08983Z"
      fill="#8C8D8F"
    />
  </svg>
);
export const Requested = (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6895 22.8C18.6541 22.8 23.4895 17.9647 23.4895 12C23.4895 6.03532 18.6541 1.2 12.6895 1.2C6.72478 1.2 1.88945 6.03532 1.88945 12C1.88945 17.9647 6.72478 22.8 12.6895 22.8ZM12.6895 0C19.3169 0 24.6895 5.37258 24.6895 12C24.6895 18.6274 19.3169 24 12.6895 24C6.06204 24 0.689453 18.6274 0.689453 12C0.689453 5.37258 6.06204 0 12.6895 0ZM12.6895 3.6C8.05026 3.6 4.28945 7.36081 4.28945 12C4.28945 16.6392 8.05026 20.4 12.6895 20.4C17.3286 20.4 21.0895 16.6392 21.0895 12C21.0895 7.36081 17.3286 3.6 12.6895 3.6Z"
      fill={props.fill ?? "#58595B"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.20195 11.5H15.002L12.352 8.85C12.152 8.65 12.152 8.35 12.352 8.15C12.552 7.95 12.852 7.95 13.052 8.15L16.552 11.65C16.602 11.7 16.652 11.75 16.652 11.8C16.702 11.9 16.702 12.05 16.652 12.2C16.602 12.25 16.602 12.3 16.552 12.35L13.052 15.85C12.952 15.95 12.802 16 12.702 16C12.602 16 12.452 15.95 12.352 15.85C12.152 15.65 12.152 15.35 12.352 15.15L15.002 12.5H9.20195C8.90195 12.5 8.70195 12.3 8.70195 12C8.70195 11.7 8.90195 11.5 9.20195 11.5Z"
      fill={props.arrow ?? "#fff"}
    />
  </svg>
);
