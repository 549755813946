import { Column } from "../../../components/TablePaginated/TablePaginated";

export const TableCommonHeaderColumns: Column[] = [
  {
    label: "N. Solicitação",
    id: "refId",
    align: "left",
    showIcon: true,
    sortable: true,
  },
  {
    label: "Filial",
    id: "subsidiaryDescription",
    align: "left",
    showIcon: true,
  },
  {
    label: "Usuário solicitante",
    id: "customerCompany",
    align: "left",
    showIcon: true,
  },
  {
    label: "Distribuidora",
    id: "subsidiary",
    align: "left",
    showIcon: true,
  },
  {
    label: "Produto",
    id: "productName",
    align: "left",
    showIcon: true,
  },
  { label: "Modalidade", id: "mod", align: "left", showIcon: true },
  {
    label: "Pedido para o mês",
    id: "monthReference",
    align: "left",
    showIcon: true,
  },
];

export const TableIconHeaderColumn: Column[] = [
  {
    label: "",
    id: "icon",
    align: "center",
  },
];

export const TableDraftHeaderColumns: Column[] = [
  ...TableCommonHeaderColumns,
  {
    label: "Última atualização",
    id: "lastUpdate",
    align: "right",
    showIcon: true,
  },
  ...TableIconHeaderColumn,
];

export const TablePlacedHeaderColumns: Column[] = [
  ...TableCommonHeaderColumns,
  {
    label: "Data de Solicitação",
    id: "createdAt",
    align: "left",
    showIcon: true,
  },
  {
    label: "",
    id: "status",
    align: "center",
  },
  ...TableIconHeaderColumn,
];
