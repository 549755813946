type Variant = "error" | "warning" | "success";

class AppError {
  public readonly message: string;
  public readonly variant: Variant;
  public readonly statusCode?: number;

  constructor(message: string, variant: Variant, statusCode?: number) {
    this.message = message;
    this.variant = variant;
    this.statusCode = statusCode;
  }
}

export default AppError;
