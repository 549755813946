import { PipelineScheduleStatus } from "../../../modules/operational/models/GetPipelineSchedulesDTO";

export const ROW_PER_PAGE = 10;

export interface TableRow {
  id: string;
  refId: string;
  scheduleDate: string;
  createdAt: string;
  quantity: string;
  product: string;
  unit: string;
  customer: string;
  customerRequester: string;
  customerRequesterNote: string;
  subsidiary: string;
  paymentCondition: string;
  statusText: PipelineScheduleStatus;
  mod: string;
  price: string;
  cnpj: string;
  receiver?: string;
  shippingCompany?: string;
}

export interface TabsDeliveriesRiverProps {
  onChangeCount?: (value: number) => void;
  dateFilterFrom?: Date | null | undefined;
  dateFilterTo?: Date | null | undefined;
  menuIcon?: (item: TableRow) => React.ReactNode;
  customerCompanyIds?: string;
  subsidiary?: string;
}
