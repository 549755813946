import { inject, injectable } from "inversify";
import "reflect-metadata";
import { Types } from "../../ioc/types";
import { IHttpClientService } from "../http/models/IHttpClientService";
import {
  CategoryDTO,
  DistributorListDTO,
  ModalityDTO,
  ProductDTO,
  SubsidiaryListDTO,
} from "./models/DistributorListDTO";
import { IDistributorsService } from "./models/IDistributorsService";

@injectable()
export class DistributorsService implements IDistributorsService {
  @inject(Types.IHttpService)
  private readonly httpInstance!: IHttpClientService;

  public async listDistributors(): Promise<DistributorListDTO> {
    return await this.httpInstance.get("/ms-data-guard/customer-company");
  }

  public async listSubsidiaries(): Promise<SubsidiaryListDTO> {
    return await this.httpInstance.get("/ms-data-guard/subsidiary");
  }

  public async getProducts(): Promise<ProductDTO[]> {
    return await this.httpInstance.get("/ms-data-guard/product");
  }

  public async getCategoryFromGroup(): Promise<CategoryDTO[]> {
    return await this.httpInstance.get(
      "/ms-data-guard/static-entity?group=CATEGORY_FINANCIAL"
    );
  }

  public async getModality(): Promise<ModalityDTO[]> {
    return await this.httpInstance.get(
      "/ms-data-guard/static-entity?group=MOD"
    );
  }
}
