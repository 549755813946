import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

export interface BreadcrumbChild {
  link?: any;
  label?: string;
  icon?: React.ReactNode;
  isIcon?: boolean;
}

export interface BreadcrumbProps {
  data?: BreadcrumbChild[];
}

const BreadcrumbCustom: React.FC<BreadcrumbProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string
  ) => {
    event.preventDefault();
    navigate(link);
  };

  const breadcrumbs = data?.map((child, idx) => {
    if (idx === data.length - 1 && data.length !== 1) {
      return (
        <Typography
          sx={{ fontWeight: 700, fontSize: 10 }}
          key="3"
          color="text.primary"
        >
          {child.label && child.label.toUpperCase()}
        </Typography>
      );
    }
    return (
      <Link
        sx={{ fontWeight: 700, fontSize: 10, cursor: "pointer" }}
        underline="hover"
        key="2"
        color="inherit"
        onClick={(e) =>
          handleClick(
            e as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            child.link
          )
        }
      >
        {child.isIcon && child.icon}
        {child.label && child.label.toUpperCase()}
      </Link>
    );
  });

  return (
    <Breadcrumbs
      sx={{ marginLeft: 1 }}
      separator={
        <NavigateNextIcon sx={{ color: "#D2D3D6" }} fontSize="medium" />
      }
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbCustom;
