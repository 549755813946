import React, { useCallback, useEffect, useState } from "react";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { GetOrdersPaginatedQuery } from "../../../modules/supply/models/GetOrdersPaginatedQuery";
import { ISODateToFormat } from "../../../utils/dateFormatter";
import useDialogAlert from "../../../hooks/DialogAlert";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import { DataOrder } from "../../QuoteRequest/models/ModalOrdes";
import { format } from "date-fns";
import { calcPagesQuantity, formatCNPJ } from "../../../utils";
import { ROW_PER_PAGE } from "../../ConsultQuotaOrders/models/SearchFilterOptions";
import { TablePlacedHeaderColumns } from "../models/TableHeaderColumns";
import { IFinancialService } from "../../../modules/financial/models/IFinancialService";
import { Types } from "../../../ioc/types";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { GetRefundIcmsQuery } from "../../../modules/financial/models/GetRefundIcmsQuery";
import { StyledMenuProps, TableRowIcms } from "../models/TableRowIcms";
import {
  RefundIcmsStatusIcons,
  RefundIcmsStatusTooltip,
} from "../models/Filters";
import { CommonMenuList, RedirectPage } from "../models/OptionsIcmsMenu";
import ModalIcmsLoader from "./ModalIcmsLoader";
import { GetRefundIcms } from "../../../modules/financial/models/GetRefundIcmsDTO";
interface PlacedOrdersSectionProps {
  dataFilter?: GetOrdersPaginatedQuery | null;
}

const TaxReimbursementTableSection: React.FC<PlacedOrdersSectionProps> = ({
  dataFilter,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [data, setData] = useState<TableRowIcms[]>([]);
  const [rawRata, setRawData] = useState<GetRefundIcms[]>([]);

  const [errorData, setErrorData] = useState(false);
  const { snackbar } = useDialogAlert();
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<DataOrder>();
  const [currentPage, setCurrentPage] = useState(1);
  const { serviceContainer } = useIocContext();

  const finacialService = serviceContainer.get<IFinancialService>(
    Types.Financial.IFinancialService
  );

  const handleClose = useCallback(async (open: boolean, flag: string) => {
    switch (flag) {
      case "seeOrder": {
        break;
      }
    }
    setIsOpen(open);
  }, []);

  const StyledMenu: React.FC<StyledMenuProps> = (data: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async (type: RedirectPage) => {
      setAnchorEl(null);
      switch (type) {
        case "viewOrder": {
          modalStepClose(data.data);
          break;
        }
        case "deleteOrder":
          break;
        default:
          break;
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {CommonMenuList.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(option.type);
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const modalStepClose = useCallback(
    (data: TableRowIcms) => {
      const completeData = rawRata.find((ele) => ele.refId === data.id);
      setModalData({
        content: {
          petition: {
            title: "Petição",
          },
          xmlNotes: {
            title: "Notas",
          },
          refundValues: {
            title: "Valor do ressarcimento",
          },
          requestNote: {
            title: "Observação",
          },
          analistNote: {
            title: "Observação (analista)",
          },
        },
        header: {
          distributor: {
            title: "Nome da razão social",
            value: completeData?.customerCompany.description,
          },
          requestDate: {
            title: "Data da Solicitação",
            value: format(new Date(), "dd/MM/yyyy"),
          },
          requester: {
            title: "Solicitado por",
            value: completeData?.customerRequester,
          },
          requestNumber: {
            title: "ID do Pedido",
            value: completeData?.refId ?? "",
          },
        },
        petition: completeData?.files[0]?.fileName ?? "",
        xmlNotes: completeData?.files[1]?.fileName ?? "",
        refundValues: completeData?.value.toString() ?? "",
        requestNote: completeData?.customerRequesterNote ?? "",
        status: completeData?.status ?? "",
        analistNote: completeData?.vendorAuthorityNote ?? "",
      });
    },
    [rawRata, setModalData]
  );

  useEffect(() => {
    setIsOpen(modalData !== undefined);
  }, [modalData]);

  const getRefundIcmsData = useCallback(
    async (query: GetRefundIcmsQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);

        const response = await finacialService.getRefundIcms(query);
        const newArray = response?.content;
        setTotal(response?.total ?? 0);
        if (newArray?.length === 0) {
          setErrorData(true);
        }
        setRawData(newArray);
        const newDate: TableRowIcms[] =
          newArray?.map(
            (item): TableRowIcms => ({
              id: item.refId,
              customerCompany: item?.customerCompany.description,
              cnpj: formatCNPJ(item?.customerCompany.document) ?? "",
              createdAt: ISODateToFormat(
                new Date(item.requestDate).toISOString() ?? "-"
              ),
              customerRequester: item.customerRequester,
              icon: <StyledMenu data={item} />,
              status: (
                <StyledIcon
                  iconType={
                    RefundIcmsStatusIcons[
                      item.status as keyof typeof RefundIcmsStatusIcons
                    ]
                  }
                  tooltip={{
                    title:
                      RefundIcmsStatusTooltip[
                        item.status as keyof typeof RefundIcmsStatusIcons
                      ],
                  }}
                />
              ),
              updatedAt: `${ISODateToFormat(item.updatedAt ?? "-")} às ${format(
                new Date(item.updatedAt),
                "HH:mm"
              )}h`,
            })
          ) ?? [];

        setData(newDate);
      } catch (error) {
        snackbar({
          message:
            "Erro ao carregar tabela de pedidos realizados. Tentar novamente",
          variant: "error",
        });
        setErrorData(true);
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData, setRawData]
  );

  useEffect(() => {
    if (dataFilter !== null) {
      getRefundIcmsData(dataFilter as GetOrdersPaginatedQuery);
      setCurrentPage(dataFilter?.page ?? 1);
    }
  }, [dataFilter, setCurrentPage]);

  const getbyPage = useCallback(
    (page: number) => {
      if (dataFilter !== null && page) {
        const pageFilter = { ...dataFilter, page: page };
        setCurrentPage(page);
        getRefundIcmsData(pageFilter as GetOrdersPaginatedQuery);
      }
    },
    [dataFilter, setCurrentPage]
  );

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <TablePaginated
          columns={TablePlacedHeaderColumns}
          data={data.map((item, index) => ({
            ...item,
            icon: <StyledMenu data={item} />,
          }))}
          isLoadingData={isLoadingData}
          loadFromServer
          onChangePage={(newPage) => {
            getbyPage(newPage);
          }}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: currentPage,
          }}
          resultsTotal={total}
        />
      )}
      {modalData && (
        <ModalIcmsLoader
          handleClose={async (open: boolean, flag: string) =>
            await handleClose(open, flag)
          }
          isOpen={isOpen}
          data={modalData}
          withTab={false}
          isView
        />
      )}
    </>
  );
};

export default TaxReimbursementTableSection;
