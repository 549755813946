import React, { useCallback, useEffect, useState } from "react";
import {
  IconType,
  StyledIcon,
} from "../../../components/StyledIcon/StyledIcon";
import CardDataNotFound from "../../../components/CardDataNotFound/CardDataNotFound";
import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  CommonMenuList,
  RedirectPage,
  UpdateMenuList,
  orderMenuStatus,
  orderModalStatus,
} from "../models/OptionsMenu";
import { GetOrdersPaginatedQuery } from "../../../modules/supply/models/GetOrdersPaginatedQuery";
import { ISODateToFormat, getMonthName } from "../../../utils/dateFormatter";
import { StyledMenuProps, TableRow } from "../models/OrderSectionsTableProps";
import useDialogAlert from "../../../hooks/DialogAlert";
import { TablePlacedHeaderColumns } from "../models/TableHeaderColumns";
import TablePaginated from "../../../components/TablePaginated/TablePaginated";
import OrderStatusProgress from "../../../components/OrderStatusProgress/OrderStatusProgress";
import ModalCustom from "../../../components/ModalCustom/ModalCustom";
import { CustomButtonVariant } from "../../../components/CustomButton/CustomButton";
import {
  ORDER_STATUS,
  StatusOrderIcon,
  setStatusIcon,
  statusDraft,
} from "../models/StatusOrderIcon";
import {
  Analasys,
  Approved,
  ApprovedNote,
  Rejected,
  Requested,
} from "./StepIcons";
import { useOrderQuote } from "../../../contexts/orderQuote/OrderQuoteContext";
import { ROW_PER_PAGE } from "../models/SearchFilterOptions";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import ModalOrderLoader from "../../QuoteRequest/Section/ModalOrderLoader";
import { DataOrder, content } from "../../QuoteRequest/models/ModalOrdes";
import { format } from "date-fns";
import { calcPagesQuantity } from "../../../utils";
interface PlacedOrdersSectionProps {
  dataFilter?: GetOrdersPaginatedQuery | null;
}

const PlacedOrdersSection: React.FC<PlacedOrdersSectionProps> = ({
  dataFilter,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [data, setData] = useState<TableRow[]>([]);
  const [errorData, setErrorData] = useState(false);
  const { snackbar } = useDialogAlert();
  const navigate = useNavigate();
  const { getOrders, setItemInView, setItemUpdate } = useOrderQuote();
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [idOrder, setIdOrder] = useState<string | null>(null);
  const [modalData, setModalData] = useState<DataOrder>();
  const [currentPage, setCurrentPage] = useState(1);

  const handleClose = useCallback(
    async (open: boolean, flag: string, id: string | null) => {
      switch (flag) {
        case orderModalStatus.SEE_ORDER: {
          if (id !== null) {
            navigate(routesConfig.SUPPLIES().REQUEST);
            setItemInView?.(id);
            setItemUpdate("");
          }
          break;
        }
        case orderMenuStatus.UPDATE_ORDER: {
          if (id !== null) {
            navigate(routesConfig.SUPPLIES().REQUEST);
            setItemInView?.("");
            setItemUpdate(id);
          }
          break;
        }
      }

      setIsOpen(open);
      setIsTab(false);
    },
    [idOrder]
  );

  const StyledMenu: React.FC<StyledMenuProps> = (data: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const idOrder = data?.data?.dataComplete?.id ?? null;
    const statusOrder = data?.data?.dataComplete?.status;

    const menuList =
      statusOrder === statusDraft.VENDOR_DRAFT
        ? UpdateMenuList
        : CommonMenuList;

    const handleMenuItemClick = async (type: RedirectPage) => {
      setAnchorEl(null);

      switch (type) {
        case orderMenuStatus.VIEW_ORDER: {
          modalStepClose(data?.data?.dataComplete);
          break;
        }
        case orderMenuStatus.UPDATE_ORDER:
          handleClose(false, orderMenuStatus.UPDATE_ORDER, idOrder);
          break;
        case orderMenuStatus.DELETE_ORDER:
          break;
        default:
          break;
      }
    };

    const handleCloseModal = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton onClick={handleClickListItem}>
          <StyledIcon iconType="three-vertical-points" />
        </IconButton>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseModal}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          sx={{
            minWidth: 300,
          }}
        >
          {menuList.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuItemClick(option.type);
              }}
              sx={{
                minWidth: 200,
                fontSize: 14,
                fontFamily: "Open Sans",
                "&.Mui-selected": {
                  color: "shadesOfDark.dark",
                  backgroundColor: "shadesOfDark.ultraLight",
                  fontWeight: 700,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "shadesOfDark.ultraLight",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const modalStepClose = (order: TableRow) => {
    if (order.id !== undefined) setIdOrder(order?.id);
    setIsTab(order.step === "PARTIALLY_APPROVED");
    const commonData = {
      productName: order?.contractRequestEntry?.product?.description ?? "",
      modality: order.contractRequestEntry?.mod ?? "",
      quantity: order.contractRequestEntry?.volumeRequested?.toString() ?? "",
      measurementUnit:
        order?.contractRequestEntry?.product?.measurementUnit ?? "",
    };
    setModalData({
      content: { ...content },
      header: {
        distributor: {
          title: "Nome da distribuidora",
          value: order.customerCompany.description,
        },
        requestNumber: {
          title: "N. do pedido",
          value: order.refId ?? "",
        },
        requestDate: {
          title: "Data do pedido",
          value: order?.requestedAt
            ? format(new Date(order?.requestedAt), "dd/MM/yyyy").toString()
            : "-",
        },
        subsidiaryDescription: {
          title: "Filial",
          value: order?.contractRequestEntry?.subsidiary?.description ?? "",
        },
      },
      ...(order.step !== "PARTIALLY_APPROVED"
        ? { ...commonData }
        : {
            orded: {
              ...commonData,
              label: "Solicitado",
              quantity:
                order.contractRequestEntry?.volumeRequested?.toString() ?? "",
            },
            approved: {
              ...commonData,
              quantity:
                order.contractRequestEntry?.volumeProvided?.toString() ?? "",
              label: "Aprovado",
            },
          }),
    });
  };

  useEffect(() => {
    setIsOpen(modalData !== undefined);
  }, [modalData]);

  const LoaderStepModal: React.FC<{ order: TableRow }> = ({ order }) => {
    const orderStatus = (order.step && ORDER_STATUS[order.step]) || "Aprovado";
    return (
      <ModalCustom
        buttons={[
          {
            title: "Ver pedido",
            variant: CustomButtonVariant.OUTLINED,
            onclick() {
              modalStepClose(order);
            },
          },
          {
            title: "Fechar",
            variant: CustomButtonVariant.CONTAINED,
            onclick() {},
            isClose: true,
          },
        ]}
        titleModal="Status do pedido"
        withIcon={true}
        icon={
          `${setStatusIcon(
            order.status as keyof typeof StatusOrderIcon,
            order?.step as keyof typeof StatusOrderIcon
          )}` as IconType
        }
        maxWidth="sm"
        componentItems={
          <Grid
            container
            marginBottom={2}
            justifyContent={"end"}
            display={"flex"}
          >
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={12}
              marginBottom={2}
              display={"flex"}
              justifyContent={"left"}
            >
              <Typography
                fontSize={14}
                fontWeight={400}
                color={"shadesOfDark.dark"}
              >
                Acompanhe o status do pedido.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={10}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <OrderStatusProgress
                statusList={[
                  {
                    icon: <Requested fill={"#D2D3D6"} />,
                    description: "Solicitado",
                  },
                  {
                    icon: (
                      <Analasys
                        fill={
                          order.step !== "WAITING_APPROVE"
                            ? "#D2D3D6"
                            : "#E3B420"
                        }
                      />
                    ),
                    description: "Em análise",
                    isMarked: order.step === "WAITING_APPROVE",
                  },
                  {
                    icon:
                      order.step === "REJECTED" ? (
                        <Rejected />
                      ) : order.step === "PARTIALLY_APPROVED" ? (
                        <ApprovedNote />
                      ) : order.step === "APPROVED" ? (
                        <Approved />
                      ) : (
                        <Approved fill="#D2D3D6" />
                      ),
                    isMarked:
                      order.step === "REJECTED" ||
                      order.step?.includes("APPROVED"),

                    description: orderStatus,
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={12}
              marginTop={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <Typography
                fontSize={14}
                fontWeight={400}
                color={"shadesOfDark.dark"}
              >
                {order.step === "REJECTED"
                  ? "Revise o pedido ou faça uma nova solicitação."
                  : order.step === "PARTIALLY_APPROVED" &&
                    "O pedido foi aprovado, mas houve alterações por parte da refinaria."}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    );
  };

  const getOrdersData = useCallback(
    async (query: GetOrdersPaginatedQuery) => {
      try {
        setErrorData(false);
        setIsLoadingData(true);
        const response = await getOrders?.(query);
        const newArray = response?.content;
        setTotal(response?.total ?? 0);
        if (newArray?.length === 0) {
          setErrorData(true);
        }
        const newDate: TableRow[] =
          newArray?.map(
            (item): TableRow => ({
              id: item.id,
              refId: item.refId,
              vendorAuthorityId: item.vendorAuthorityId,
              monthReference: getMonthName(item.monthReference ?? ""),
              customerCompany: item?.customerRequester,
              productName:
                item?.contractRequestEntry?.product?.description?.toUpperCase() ??
                "",
              mod: item?.contractRequestEntry?.mod,
              subsidiary: item?.customerCompany?.description,
              subsidiaryDescription:
                item?.contractRequestEntry?.subsidiary?.description ?? "",
              lastUpdate: ISODateToFormat(item.updatedAt ?? ""),
              accessKey: item?.id,
              createdAt:
                item.requestedAt !== null
                  ? ISODateToFormat(item.requestedAt ?? "")
                  : "-",
              status: (
                <LoaderStepModal key={item.id} order={item as TableRow} />
              ),
              dataComplete: item,
            })
          ) ?? [];

        setData(newDate);
      } catch (error) {
        snackbar({
          message:
            "Erro ao carregar tabela de pedidos realizados. Tentar novamente",
          variant: "error",
        });
        setErrorData(true);
      } finally {
        setIsLoadingData(false);
      }
    },
    [setData]
  );

  useEffect(() => {
    if (dataFilter !== null) {
      getOrdersData(dataFilter as GetOrdersPaginatedQuery);
      setCurrentPage(dataFilter?.page ?? 1);
    }
  }, [dataFilter, setCurrentPage]);

  const getbyPage = useCallback(
    (page: number) => {
      if (dataFilter !== null && page) {
        const pageFilter = { ...dataFilter, page: page };
        setCurrentPage(page);
        getOrdersData(pageFilter as GetOrdersPaginatedQuery);
      }
    },
    [dataFilter, setCurrentPage]
  );

  return (
    <>
      {errorData ? (
        <CardDataNotFound
          icon="cactus"
          title="Não foram encontradas informações"
          subtitle="Informe outros critérios de busca e consulte novamente"
        />
      ) : (
        <TablePaginated
          columns={TablePlacedHeaderColumns}
          data={data.map((item, index) => ({
            ...item,
            icon: item.isLoadingMenuTable ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <StyledMenu data={item} />
            ),
          }))}
          isLoadingData={isLoadingData}
          loadFromServer
          onChangePage={(newPage) => {
            getbyPage(newPage);
          }}
          paginationProps={{
            pagesTotal: calcPagesQuantity(ROW_PER_PAGE, total),
            page: currentPage,
          }}
          resultsTotal={total}
        />
      )}

      {modalData && (
        <ModalOrderLoader
          handleClose={async (open, flag) =>
            await handleClose(open, flag, idOrder)
          }
          isOpen={isOpen}
          withTab={isTab}
          data={modalData}
          isView={true}
        ></ModalOrderLoader>
      )}
    </>
  );
};

export default PlacedOrdersSection;
