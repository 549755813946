import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import ConsultInvoicesPage from "../../pages/ConsultInvoices/ConsultInvoicesPage";
import ConsultMonthlyParametersPage from "../../pages/ConsultMonthlyParameters/ConsultMonthlyParametersPage";
import ConsultOperatingConditions from "../../pages/ConsultOperatingConditions/ConsultOperatingConditionsPage";
import ConsultQuotaOrdersPage from "../../pages/ConsultQuotaOrders/ConsultQuotaOrdersPage";
import ConsultQuoteStatementPage from "../../pages/ConsultQuotaStatement/ConsultQuotaStatementPage";
import ConsultQuoteBalancePage from "../../pages/ConsultQuoteBalance/ConsultQuoteBalance";
import ConsultStatementPage from "../../pages/ConsultStatement/ConsultStatementPage";
import FinancialMovementPage from "../../pages/FinancialMovement/FinancialMovementPage";
import HomePage from "../../pages/Home/HomePage";
import PipelineDeliveriesPage from "../../pages/PipelineDeliveries/PipelineDeliveriesPage";
import PumpRequestPage from "../../pages/PumpRequest/PumpRequestPage";
import QuoteRequestPage from "../../pages/QuoteRequest/QuoteRequestPage";
import RoadDeliveriesPage from "../../pages/RoadDeliveriesPage/RoadDeliveriesPage";
import RoadScheduleRequestPage from "../../pages/RoadScheduleRequest/RoadScheduleRequestPage";
import SacPage from "../../pages/SacPage/SacPage";
import SacRequestPage from "../../pages/SacRequestPage/SacRequestPage";
import TaxReimbursement from "../../pages/TaxReimbursement/TaxReimbursement";
import TaxReimbursementRequest from "../../pages/TaxReimbursementRequest/TaxReimbursementRequest";
import { generatePermissionMatcher } from "../../utils";
import { TypesPermissions } from "./TypesPermissions";
import RouteGuard from "./routeGuard";
import RiverDeliveriesPage from "../../pages/RiverDeliveries/RiverDeliveriesPage";
import RiverRequestPageContainer from "../../pages/RiverRequest/RiverRequestPage";

const RoutesComponent: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to={routesConfig.HOME} />} />
        <Route path={routesConfig.HOME} element={<HomePage />} />

        <Route path={routesConfig.EXECUTIVE().PRICE} element={<HomePage />} />
        <Route
          path={routesConfig.EXECUTIVE().MONTHLY_PARAMETERS}
          element={<ConsultMonthlyParametersPage />}
        />
        <Route
          path={routesConfig.EXECUTIVE().OPERATING_CONDITIONS}
          element={
            <RouteGuard
              rules={generatePermissionMatcher(
                TypesPermissions.OPERATING_CONDITIONS
              )}
            >
              <ConsultOperatingConditions />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.FINANCIAL().TAX_REIMBURSEMENT}
          element={
            <RouteGuard
              flag="sidebar_financial_refunds_icms"
              rules={generatePermissionMatcher(
                TypesPermissions.TAX_REIMBURSEMENT
              )}
            >
              <TaxReimbursement />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.FINANCIAL().TAX_REIMBURSEMENT_REQUEST}
          element={
            <RouteGuard
              rules={generatePermissionMatcher(
                TypesPermissions.TAX_REIMBURSEMENT_REQUEST
              )}
            >
              <TaxReimbursementRequest />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.FINANCIAL().INVOICES}
          element={
            <RouteGuard
              flag="sidebar_financial_invoices"
              rules={generatePermissionMatcher(TypesPermissions.INVOICES)}
            >
              <ConsultInvoicesPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.FINANCIAL().STATEMENT}
          element={
            <RouteGuard
              flag="sidebar_financial_statement"
              rules={generatePermissionMatcher(TypesPermissions.STATEMENT)}
            >
              <ConsultStatementPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.FINANCIAL().MOVEMENT}
          element={
            <RouteGuard
              flag="sidebar_financial_movement"
              rules={generatePermissionMatcher(TypesPermissions.MOVEMENT)}
            >
              <FinancialMovementPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.SUPPLIES().FINANCIAL_MOVEMENT}
          element={<HomePage />}
        />
        <Route
          path={routesConfig.SUPPLIES().QUOTE_EXTRACT}
          element={
            <RouteGuard
              flag="sidebar_supply_quote_extract"
              rules={generatePermissionMatcher(TypesPermissions.QUOTE_EXTRACT)}
            >
              <ConsultQuoteStatementPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.SUPPLIES().QUOTE_BALANCE}
          element={
            <RouteGuard
              flag="sidebar_supply_quote_balance"
              rules={generatePermissionMatcher(TypesPermissions.QUOTE_BALANCE)}
            >
              <ConsultQuoteBalancePage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.SUPPLIES().REQUEST}
          element={
            <RouteGuard
              flag="supply_new_quote"
              rules={generatePermissionMatcher(TypesPermissions.REQUEST)}
            >
              <QuoteRequestPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.SUPPLIES().QUOTE}
          element={
            <RouteGuard
              flag="sidebar_supply_quote"
              rules={generatePermissionMatcher(TypesPermissions.QUOTE)}
            >
              <ConsultQuotaOrdersPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.OPERATIONAL().PIPELINES}
          element={
            <RouteGuard
              flag="sidebar_operational_pipelines"
              rules={generatePermissionMatcher(TypesPermissions.PIPELINES)}
            >
              <PipelineDeliveriesPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.OPERATIONAL().PIPELINES_REQUEST}
          element={
            <RouteGuard
              flag="operational_new_pipelines"
              rules={generatePermissionMatcher(
                TypesPermissions.PIPELINES_REQUEST
              )}
            >
              <PumpRequestPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.OPERATIONAL().ROAD_STATIONS}
          element={
            <RouteGuard
              flag="sidebar_operational_road_stations"
              rules={generatePermissionMatcher(TypesPermissions.ROAD_STATIONS)}
            >
              <RoadDeliveriesPage />
            </RouteGuard>
          }
        />

        <Route
          path={routesConfig.OPERATIONAL().ROAD_STATIONS_REQUEST}
          element={
            <RouteGuard
              flag="operational_new_road_stations"
              rules={generatePermissionMatcher(
                TypesPermissions.ROAD_STATIONS_REQUEST
              )}
            >
              <RoadScheduleRequestPage />
            </RouteGuard>
          }
        />

        <Route
          path={routesConfig.OPERATIONAL().RIVER}
          element={
            <RouteGuard
              flag="sidebar_operational_river"
              rules={generatePermissionMatcher(TypesPermissions.RIVER)}
            >
              <RiverDeliveriesPage />
            </RouteGuard>
          }
        />
        <Route
          path={routesConfig.OPERATIONAL().RIVER_REQUEST}
          element={
            <RouteGuard
              flag="operational_new_river"
              rules={generatePermissionMatcher(TypesPermissions.RIVER_REQUEST)}
            >
              <RiverRequestPageContainer />
            </RouteGuard>
          }
        />

        <Route
          path={routesConfig.OPERATIONAL().MARITIME}
          element={<HomePage />}
        />
        <Route
          path={routesConfig.OPERATIONAL().PROGRAMMING}
          element={<HomePage />}
        />
        <Route
          path={routesConfig.INFORMATION().WARNINGS}
          element={<HomePage />}
        />
        <Route path={routesConfig.INFORMATION().SAC} element={<SacPage />} />
        <Route
          path={routesConfig.INFORMATION().SAC_REQUEST}
          element={<SacRequestPage />}
        />
        <Route
          path={routesConfig.INFORMATION().SAC_DETAILS}
          element={<SacRequestPage />}
        />
        <Route path={routesConfig.HITS} element={<HomePage />} />
      </Routes>
    </HashRouter>
  );
};

export default RoutesComponent;
