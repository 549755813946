import { Grid } from "@mui/material";
import React from "react";
import useMedia from "use-media";
import { DetailInputsModalProps } from "../../../components/ModalExtractContent/ModalExtractContent";
import ModalTitleAndDetail from "../../../components/ModalTitleAndDetail/ModalTitleAndDetail";
import { StyledIcon } from "../../../components/StyledIcon/StyledIcon";

interface ModalPumpDetailsHeaderProps {
  icon?: React.ReactNode;
  distributor: DetailInputsModalProps;
  requestNumber?: DetailInputsModalProps;
  requestDate: DetailInputsModalProps;
  requestUser: DetailInputsModalProps;
  subsidiaryDescription?: DetailInputsModalProps;
}

const ModalPumpDetailsHeader: React.FC<ModalPumpDetailsHeaderProps> = ({
  icon,
  distributor,
  requestNumber,
  requestDate,
  requestUser,
  subsidiaryDescription,
}) => {
  const isMobile = useMedia({ maxWidth: 901 });
  return (
    <Grid container padding={3} paddingLeft={0} paddingTop={1}>
      <Grid container>
        <Grid
          item
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
          xs={6}
          sm={2}
          md={1}
          lg={1}
          display={"flex"}
          justifyContent={"center"}
        >
          {icon ?? (
            <StyledIcon
              iconType="list"
              sx={{
                width: "8rem",
                height: "8rem",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sm={10}
          md={10}
          lg={2}
          margin={"auto"}
          display={"flex"}
          sx={{
            justifyContent: {
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <ModalTitleAndDetail
            title={distributor.title}
            detail={distributor.value}
            textAlign={"left"}
          />
        </Grid>
        {requestNumber?.title ? (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={2}
            margin={"auto"}
            display={"flex"}
            justifyContent={isMobile ? "left" : "right"}
            paddingTop={isMobile ? "20px" : "0px"}
          >
            <ModalTitleAndDetail
              title={requestNumber?.title ?? "vazio"}
              detail={requestNumber?.value ?? "vazio"}
              textAlign={isMobile ? "left" : "right"}
            />
          </Grid>
        ) : null}
        {subsidiaryDescription?.title ? (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={2}
            margin={"auto"}
            display={"flex"}
            justifyContent={isMobile ? "left" : "right"}
            paddingTop={isMobile ? "20px" : "0px"}
          >
            <ModalTitleAndDetail
              title={subsidiaryDescription?.title ?? "vazio"}
              detail={subsidiaryDescription?.value ?? "vazio"}
              textAlign={isMobile ? "left" : "right"}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <ModalTitleAndDetail
            title={requestDate.title}
            detail={requestDate.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={2}
          margin={"auto"}
          display={"flex"}
          justifyContent={isMobile ? "left" : "right"}
          paddingTop={isMobile ? "20px" : "0px"}
        >
          <ModalTitleAndDetail
            title={requestUser.title}
            detail={requestUser.value}
            textAlign={isMobile ? "left" : "right"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalPumpDetailsHeader;
