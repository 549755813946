import * as _ from "lodash";
import { StatusTagType } from "../components/StatusTag/StatusTag";
import { IconType } from "../components/StyledIcon/StyledIcon";
import appConfig from "../config/default";
import { IDistributors } from "../contexts/distributor/DistributorContext";
import { PipelineScheduleStatus } from "../modules/operational/models/GetPipelineSchedulesDTO";
import { StatusInvoiceValueType } from "../pages/ConsultStatement/models/StatusInvoice";
import { NEGATIVE_COLOR, POSITIVE_COLOR } from "./colorsByNegativeOrPositive";
import { STATUS_NOTE_CANCELLED, STATUS_NOTE_SENT } from "./enumStatusNote";
import { ExternalSubsidiaries } from "../modules/financial/models/IRegisterFilters";
import {
  addMonths,
  endOfMonth,
  format,
  getDay,
  isSameDay,
  startOfMonth,
  subDays,
} from "date-fns";

export function goToLogout() {
  window.location.href =
    process.env.REACT_APP_ENV === "development" ||
    window.location.hostname === "localhost"
      ? appConfig.urlLogoutDev
      : appConfig.urlLogout;
}

export function getFirstNameUser(name: string | undefined): string {
  return name ? name.split(" ")[0] : "-";
}

export function cutText(description: string, numberVisibleCharacters: number) {
  if (description.length <= numberVisibleCharacters) {
    return description;
  } else {
    return `${description.slice(0, numberVisibleCharacters)}...`;
  }
}

export function formatCNPJ(cnpj?: string): string {
  if (cnpj === undefined) {
    return "CNPJ inválido";
  }
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

  return cnpj;
}

export const formatInfoCompany = (company: string, cnpj: string) =>
  company.length && cnpj.length ? `${company} - ${formatCNPJ(cnpj)}` : "";

export const getFormatCNPJ = (
  distritutor: IDistributors | ExternalSubsidiaries,
  isOptionSelected?: boolean,
  isCut?: boolean,
  maxLengh?: number
) => {
  const { description, document } = distritutor;

  const descriptionAndCNPJ = formatInfoCompany(
    description.toUpperCase(),
    document ?? ""
  );

  if (isCut && isOptionSelected && maxLengh) {
    return cutText(descriptionAndCNPJ, maxLengh);
  } else {
    return descriptionAndCNPJ;
  }
};

export const applyVolumeMask = (str: string): string => {
  if (!str) {
    return "";
  }
  const numericValue = parseFloat(str.replaceAll(",", "."));
  const maskApplied = Math.abs(numericValue).toLocaleString("pt-BR", {
    style: "decimal",
  });

  return numericValue < 0 ? `-${maskApplied}` : maskApplied;
};

export const getTitleAndValue = (title: string, value?: string) => ({
  title,
  value: value ?? "",
});

export const getStatusIcon = (status: PipelineScheduleStatus) => {
  let iconType: IconType = "analysis";
  let tooltipText = "Em análise";

  switch (status) {
    case PipelineScheduleStatus.INVOICE_CLEARED:
      iconType = "approved";
      tooltipText = "Faturado";
      break;
    case PipelineScheduleStatus.VENDOR_APPROVED:
      iconType = "analysis";
      tooltipText = "Em análise financeira";
      break;
    case PipelineScheduleStatus.INVOICE_PENDING:
      iconType = "progress";
      tooltipText = "Em programação";
      break;
    case PipelineScheduleStatus.VENDOR_PENDING:
      iconType = "analysis";
      tooltipText = "Em análise comercial";
      break;
    case PipelineScheduleStatus.VENDOR_REJECTED:
      iconType = "blocked";
      tooltipText = "Reprovado";
      break;
    case PipelineScheduleStatus.INVOICE_BLOCKED:
    case PipelineScheduleStatus.FINANCIAL_BLOCKED:
      iconType = "blocked";
      tooltipText = "Bloqueado";
      break;
  }

  return {
    iconType,
    tooltipText,
  };
};

export const removeSpecialCharactersAndLetters = (value: string) => {
  const regex = /[^0-9]/g;
  return value.replace(regex, "");
};

export const getColorIfGreaterThanZero = (
  value: number | undefined
): string => {
  const parsedValue: number = parseFloat(`${value ?? "0"}`);
  return parsedValue >= 0 ? POSITIVE_COLOR : NEGATIVE_COLOR;
};

export function StatusInvoiceToString(status: StatusInvoiceValueType) {
  switch (status) {
    case "OPEN":
      return "Aberto";
    case "CLOSE":
      return "Compensado";
  }
}

export function calcPagesQuantity(size: number, total: number) {
  return Math.ceil(total / size);
}

export const getThousand = (value: number) => {
  return value.toLocaleString("pt-BR", { minimumFractionDigits: 0 });
};

export const sanitizeParameters = (
  data: Record<string, unknown>
): Record<string, unknown> => {
  const entries = Object.entries(data).filter(([, value]) => !!value);
  return Object.fromEntries(entries);
};

export function isCancelled(isCancelled: boolean) {
  return isCancelled ? STATUS_NOTE_CANCELLED : STATUS_NOTE_SENT;
}

export function getTypeTagCancelledNote(isCancelled: string | boolean) {
  return isCancelled === STATUS_NOTE_CANCELLED || isCancelled === true
    ? StatusTagType.LATE
    : StatusTagType.ON_TIME;
}

export const convertToOptionsList = (
  items: any[],
  valueProp: string,
  labelProp: string
) => {
  if (items?.length <= 0) {
    return [];
  }
  const optionsList = items?.map((item) => {
    const option = {
      value: item[valueProp],
      label: item[labelProp],
    };
    return option;
  });
  return optionsList;
};

export const convertToOptionsListPlus = (
  items: [],
  keyProp: string,
  valueProp: string
) => {
  if (items?.length <= 0) {
    return [];
  }
  const optionsList = items?.map((item) => {
    const option = {
      key: item[keyProp],
      value: item[valueProp],
    };
    return option;
  });
  return optionsList;
};

export const stringToVetor = (str: string | string[]) => {
  if (str === "" || !str.length) {
    return null;
  }

  if (Array.isArray(str)) {
    return str;
  }

  if (_.isDate(new Date(str))) {
    return str.toString().split(",");
  }
};

export const generatePermissionMatcher = (permissionRef: string) => {
  const exp = new RegExp(
    permissionRef.replace(/\*/g, "(.+)?").replace(/READ$/g, "(READ|WRITE)")
  );
  return (permissionRef: string) => Boolean(permissionRef.match(exp));
};

export const toReplaceAsterisk = (permission: string): string[] => {
  const permissionMethods = ["READ", "WRITE"];
  const replaceResult: string[] = [];

  if (permission.includes("*")) {
    permissionMethods.forEach((replace) => {
      const valueReplaced = permission.replace(/\*/, replace);
      replaceResult.push(valueReplaced);
    });
  } else {
    replaceResult.push(permission);
  }
  return replaceResult;
};

export const StatusTypeEnum = [
  { value: PipelineScheduleStatus.INVOICE_CLEARED, label: "Faturado" },
  {
    value: PipelineScheduleStatus.INVOICE_PENDING,
    label: "Aguardando Nota Fiscal",
  },
  {
    value: PipelineScheduleStatus.IN_SCHEDULING,
    label: "Programação",
  },
  {
    value: PipelineScheduleStatus.IN_LOADING,
    label: "Carregamento",
  },
  {
    value: PipelineScheduleStatus.VENDOR_PENDING,
    label: "Em análise comercial",
  },
  {
    value: PipelineScheduleStatus.VENDOR_APPROVED,
    label: "Em análise financeira",
  },
  {
    value: PipelineScheduleStatus.VENDOR_REJECTED,
    label: "Rejeitado",
  },
  {
    value: PipelineScheduleStatus.FINANCIAL_BLOCKED,
    label: "Bloqueado",
  },
  { value: PipelineScheduleStatus.INVOICE_CANCELED, label: "Estorno" },
  {
    value: PipelineScheduleStatus.INVOICE_BLOCKED,
    label: "Nota fiscal bloqueada",
  },
];

export const getCurrentMonthYear = () => {
  const currentDate = new Date();
  return format(currentDate, "MM-yyyy");
};

export const getNextMonthYear = () => {
  const currentDate = new Date();
  const nextMonth = addMonths(currentDate, 1);
  return format(nextMonth, "MM-yyyy");
};

export function isLastBusinessDayOfMonth(date: Date): boolean {
  const Saturday = 6;
  const Sunday = 0;

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid date input");
  }

  const lastDayOfMonth = endOfMonth(date);
  const lastDayOfWeek = getDay(lastDayOfMonth);
  const lastBusinessDay =
    lastDayOfWeek === Saturday
      ? subDays(lastDayOfMonth, 1)
      : lastDayOfWeek === Sunday
      ? subDays(lastDayOfMonth, 2)
      : lastDayOfMonth;

  return isSameDay(lastBusinessDay, date);
}

export const getFirstDayOfCurrentMonth = () => {
  return startOfMonth(new Date());
};

export const getLastDayOfCurrentMonth = () => {
  const currentDate = new Date();
  return endOfMonth(currentDate);
};

export const transformPhrase = (phrase: string) => {
  return phrase
    .replace(
      /\b\w+/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .replace(
      /\(([^)]+)\)/g,
      (_, contents) => `(${(contents as string).toUpperCase()})`
    );
};

export const formatNumberWithDots = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
